import {
  Alert,
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography,
} from '@mui/material';
import { MUTLI_SHIPMENT_PROJECTS_BILLING_FOR_CARRIERS_BLOG_URL } from '../../../../../../constants';

interface Props {
  onModalClose: VoidFunction;
  onAcknowledge: VoidFunction;
  isOpen: boolean;
}

export function MultiShipmentPricingModal(props: Props) {
  const {
    onModalClose, onAcknowledge, isOpen,
  } = props;

  const loading = false;
  const error = null;

  const onCancel = () => {
    onModalClose();
  };

  const onAccept = () => {
    onAcknowledge();
  };

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogTitle>
        Project Pricing Advisory
      </DialogTitle>
      <DialogContent>
        {loading
          ? <Stack alignItems="center"><CircularProgress /></Stack>
          : (
            <Stack direction="column" spacing={1}>
              <Alert severity="info">
                <Typography variant="h6">
                  Multi project loads are billed differently than single loads.
                  For every load booked OVER your current allowance,
                  you will be charged $25 CAD per booked load.
                </Typography>
              </Alert>
              <Typography variant="body1">
                {'If you require more information please refer to the billing '}
                <a
                  href={MUTLI_SHIPMENT_PROJECTS_BILLING_FOR_CARRIERS_BLOG_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  guide.
                </a>
              </Typography>

            </Stack>
          )}
        {error != null && !loading && <Typography color="red" align="center">{error}</Typography>}
      </DialogContent>
      {!loading && (
      <DialogActions>
        <Button
          autoFocus
          onClick={onCancel}
        >
          Cancel Quote
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onAccept}
        >
          Submit Quote
        </Button>
      </DialogActions>
      )}
    </Dialog>
  );
}
