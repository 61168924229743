import { Delete, MarkEmailRead } from '@mui/icons-material';
import {

  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography,
} from '@mui/material';
import { notificationIsSupported } from '../NotificationCard/content';
import { NotificationCard } from '../NotificationCard/NotificationCard';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  notifications: Notification[]
  loading: boolean;
  error: null | string;
  onMarkAllAsRead: VoidFunction;
  onDeleteAll: VoidFunction;
  onMarkNotifictionAsRead: (notification: Notification) => void;
  onMarkNotifictionAsDeleted: (notification: Notification) => void;
  isUpdating: boolean;
}

export function NotificationModal(props: Props) {
  const {
    open,
    onClose,
    notifications,
    loading,
    error,
    onMarkAllAsRead,
    onDeleteAll,
    onMarkNotifictionAsRead,
    onMarkNotifictionAsDeleted,
    isUpdating,
  } = props;

  const getModalContent = () => {
    if (loading || isUpdating) {
      return (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      );
    } if (notifications.length === 0) {
      return (
        <Typography sx={{ ml: '2rem' }} variant="body1" textAlign="center">
          You do not currently have any notifications.
        </Typography>
      );
    }
    if (error != null) {
      return (
        <Stack>
          <Typography color="red" align="center">{error}</Typography>
        </Stack>

      );
    }
    return (notifications.sort((a: Notification, b: Notification) => {
      if (a.read_at != null && b.read_at != null) {
        return 0;
      }
      if (a.read_at == null && b.read_at == null) {
        return 0;
      }
      if (a.read_at == null) {
        return -1;
      }
      return 1;
    }).map((notification) => (
      notificationIsSupported(notification.type) && (
        <NotificationCard
          key={notification.id}
          notification={notification}
          onClose={onClose}
          onMarkNotifictionAsRead={onMarkNotifictionAsRead}
          onMarkNotifictionAsDeleted={onMarkNotifictionAsDeleted}
        />
      )
    )));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth sx={{ m: '1rem' }}>
      <DialogTitle sx={{ fontSize: '1.5rem' }}>
        Notifications
        <Button disabled={notifications.length < 1} sx={{ mx: '2rem' }} onClick={onMarkAllAsRead} variant="contained" color="warning" endIcon={<MarkEmailRead />}>
          <Typography fontSize="0.8rem">Mark all as read</Typography>
        </Button>
        <Button disabled={notifications.length < 1} variant="contained" color="error" onClick={onDeleteAll} endIcon={<Delete />}>
          <Typography fontSize="0.8rem">Delete all</Typography>
        </Button>
      </DialogTitle>
      <DialogContent>
        <Stack>
          {getModalContent()}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
