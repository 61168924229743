import {
  Box, Stack, Typography,
} from '@mui/material';

import { AddCompanyMemberAccountButton } from 'components/AddCompanyMemberAccountButton';
import { CompanyBranches } from 'components/CompanyBranches';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { ManageSubscriptionButton } from 'components/ManageSubscriptionButton';
import { RefreshPageButton } from 'components/RefreshPageButton';
import { useRoleContext } from 'hooks';
import { useSubscriptionStatus } from 'hooks/useSubscriptionStatus';
import { formatText, ISOStringToReadableDate, renderInfo } from 'utils';
import { CompanyDetailRoles } from '../../CompanyDetailRoles';
import { ActiveJoinCompanyRequestTable } from '../ActiveJoinCompanyRequestTable';

interface Props {
  company: CompanyDetailInfo | null;
  onRefreshCompanyInfo: () => void;
}

export function AdminCompanyDetails(props: Props) {
  const { company, onRefreshCompanyInfo } = props;
  const { subscription } = useSubscriptionStatus();

  const { getAdminRole } = useRoleContext();

  const admin = getAdminRole();

  if (admin == null) {
    return <div />;
  }

  return (
    <Stack direction="column">
      { company?.company.id && (
      <Stack direction="row" alignItems="stretch" spacing={1} pt="1rem" pb="1rem">
        <ManageSubscriptionButton />
        <AddCompanyMemberAccountButton />
      </Stack>
      )}
      <Typography variant="h4" mt=".5rem">Company Information</Typography>
      <EvotruxDivider />
      <Box mt="1rem" flex={1}>
        {subscription != null && renderInfo(subscription?.subscription_plan) ? (
          <Typography variant="h6">
            Subscription Plan:
            {' '}
            { subscription.subscription_plan === 'CARRIER_ESSENTIALS' ? 'CARRIER TRIAL' : formatText(subscription.subscription_plan)}
          </Typography>
        ) : null}
        {renderInfo(company?.company.description) ? (
          <Typography variant="h6">
            Description:
            {' '}
            {company?.company.description}
          </Typography>
        ) : null}
        {renderInfo(company?.company.email) ? (
          <Typography variant="h6">
            Company email:
            {company?.company.email}
          </Typography>
        ) : null}
        {renderInfo(company?.company.mc_number) ? (
          <Typography variant="h6">
            MC number:
            {company?.company.mc_number}
          </Typography>
        ) : null}
        {company?.company.address?.map((address) => (
          <Box key={address.address}>
            <Typography variant="h6">
              Address:
              {' '}
              {address.address}
              ,
              {' '}
              {address.city}
              ,
              {' '}
              {address.province}
              ,
              {' '}
              {address.country}
              {' '}
            </Typography>
          </Box>
        ))}
        <Typography variant="h6" color="text.secondary">
          Member since:
          {' '}
          {ISOStringToReadableDate(company?.company.created_at || '')}
        </Typography>
      </Box>
      {company != null && (
      <Box mt="2rem" flex={1}>
        <Stack direction="row">
          <Typography variant="h4">Join Company Requests</Typography>
          <RefreshPageButton id="refresh-join-requests" onRefresh={onRefreshCompanyInfo} iconButton />
        </Stack>
        <EvotruxDivider />
        <Box display="flex" mt="1rem" flexWrap="wrap">
          <ActiveJoinCompanyRequestTable company={company} />
        </Box>
      </Box>
      )}
      {company?.company.company_type === 'SHIPPER' && company.is_admin && (
      <Box mt="2rem" flex={1}>
        <Stack direction="row">
          <Typography variant="h4">Company Branches</Typography>
          <RefreshPageButton id="refresh-branches" onRefresh={onRefreshCompanyInfo} iconButton />
        </Stack>
        <EvotruxDivider />
        {company && (
          <CompanyBranches
            branches={company.branches}
            viewingAsRoles={[admin]}
            viewingAsCompanyAdmin
          />
        )}
      </Box>
      )}
      <Box mt="2rem" flex={1}>
        <Stack direction="row">
          <Typography variant="h4">Company Members</Typography>
          <RefreshPageButton id="refresh-branches" onRefresh={onRefreshCompanyInfo} iconButton />
        </Stack>
        <EvotruxDivider />
        <Box mt="1rem" display="flex" flexWrap="wrap">
          { company?.admins && company.admins.length > 0 && (
          <CompanyDetailRoles roles={company.admins} title="Administrators" />
          )}
          { company?.carriers && company.carriers.length > 0 && (
          <CompanyDetailRoles roles={company.carriers} title="Carriers" />
          )}
          { company?.shippers && company?.shippers.length > 0 && (
          <CompanyDetailRoles roles={company.shippers} title="Shippers" />
          )}
          { company?.branch_admins && company?.branch_admins.length > 0 && (
          <CompanyDetailRoles roles={company.branch_admins} title="Branch Administrators" />
          )}
        </Box>
      </Box>
    </Stack>
  );
}
