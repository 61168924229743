import { useEffect, useState } from 'react';
import { useAPI } from './useAPI';

interface QuoteCountResponse {
  quote_count: number;
}

export function useShipmentQuoteCount(shipmentId: number) {
  const [loading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | string>(null);
  const { api } = useAPI();
  const [quoteCount, setQuoteCount] = useState<number | null>(null);

  useEffect(() => {
    if (loading && shipmentId !== 0) {
      api<QuoteCountResponse>('get', `/shipments/${shipmentId}/quote-count`).then((response) => {
        setQuoteCount(response.data.quote_count);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch price');
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [loading, quoteCount, api, shipmentId]);

  useEffect(() => {
    setIsLoading(true);
  }, [shipmentId]);

  const refetchQuoteCount = () => {
    setIsLoading(true);
  };
  return {
    quoteCount,
    isLoading: loading,
    error,
    refetchQuoteCount,
  };
}
