import qs from 'qs';
import { useEffect, useState } from 'react';
import { useFetchingComponentState } from './useFetchingComponentState';
import { useRoleContext } from './useRoleContext';

interface Props{
  currenPage: number;
  pageSize: number;
  filters: QuoteFilters;
}

interface ListCondensedQuotesForCarrierResponse {
  quotes: CondensedQuote[];
  count: number;
}

export function useCarrierQuotes(props: Props) {
  let offset: number | null = null;
  let limit: number | null = null;
  if (props !== undefined) {
    const { currenPage: page, pageSize } = props;
    limit = pageSize;
    offset = (page - 1) * limit;
  }

  const { filters } = props;

  const getStatus = () => {
    if (filters?.quoteStatus === 'ALL') {
      return '';
    }
    return filters?.quoteStatus;
  };

  const getShipmentTypes = () => {
    if (filters?.shipmentTypes?.length === 1 && filters.shipmentTypes[0] === 'ALL') {
      return [];
    }
    return filters?.shipmentTypes;
  };

  const buildFilterQueryParameters = () => {
    const filtersObject = {
      quote_status: getStatus() ?? null,
      shipment_name: filters?.shipmentName ?? null,
      shipment_type: getShipmentTypes() ?? null,
      offset: offset ?? null,
      limit: limit ?? null,
    };

    return qs.stringify(filtersObject, { skipNulls: true, arrayFormat: 'repeat' });
  };

  const { role } = useRoleContext();
  const [quotesResponse, setQuotesResponse] = useState<ListCondensedQuotesForCarrierResponse
  | null>(null);
  const {
    error,
    fetch,
    success,
    loading,
    resetState,
  } = useFetchingComponentState<ListCondensedQuotesForCarrierResponse>(
    {
      errorMessage: 'Failed to fetch quotes',
      errorMessageKey: 'message',
      fetchConfig: { url: `/carriers/${role?.id || 0}/quotes/condensed?${buildFilterQueryParameters()}` },
    },
  );

  useEffect(() => {
    if (role != null && !success && error == null) {
      fetch((res: ListCondensedQuotesForCarrierResponse) => {
        setQuotesResponse(res);
      });
    }
  }, [role, success, error, fetch]);

  const refetchQuotes = () => {
    resetState();
  };

  return {
    quotes: quotesResponse != null ? quotesResponse.quotes : null,
    loading,
    error,
    count: quotesResponse != null ? quotesResponse.count : null,
    refetchQuotes,
  };
}
