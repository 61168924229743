import {
  Alert, Stack,
} from '@mui/material';
import { TERMS_OF_SERVICE_URL } from '../../constants';

export function PersonalInformationDisclaimer() {
  return (
    <Stack>
      <Alert variant="outlined" severity="info" sx={{ mt: '1rem' }}>
        Including information such as email addresses and phone numbers could result in
        the suspension or deletion of your account in agreement with the Evotrux
        <a href={TERMS_OF_SERVICE_URL} target="_blank" rel="noopener noreferrer">{' Terms of Service.'}</a>
      </Alert>
    </Stack>
  );
}
