import { CircularProgress, Stack } from '@mui/material';
import { StandAloneLiveChat } from 'components/StandAloneLiveChat/StandAloneLiveChat';
import { useInterval, useShipperShipmentPageContext, useUserChatInfo } from 'hooks';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { buildBookedShipmentChannelName, channelExistsInChatInfo } from 'utils';

interface Props {
  dividerComponent: JSX.Element;
}

const REFETCH_USER_CHANNELS_INTERVAL_MILLISECONDS = 3000;

export function ShipperShipmentOverviewChat(props: Props) {
  const { dividerComponent } = props;
  const { shipment } = useShipperShipmentPageContext();
  const { chatInfo, refreshChatInfo, refreshChatTokens } = useUserChatInfo();
  const [channelExists, setChannelExists] = useState<boolean>(false);
  const [tokensRefreshed, setTokensRefreshed] = useState<boolean>(false);

  const [channelName, setChannelName] = useState<string | null>(null);
  const shipmentRef = useRef<undefined | CondensedShipment>();

  useEffect(() => {
    /*
    This hook keeps track of the shipment ID between renders to ensure
    that the channel name is reset when another shipment is selected.
    */
    if (shipment && shipmentRef.current?.info?.id !== shipment.info.id) {
      shipmentRef.current = shipment;
      setChannelName(buildBookedShipmentChannelName(shipment?.info.id || 0));
    }
  }, [shipment, setChannelName]);

  const refetchUserChatInfoOnChannelNotExisting = useCallback(() => {
    // nothing to do if no shipment or no user chat info available
    if (!shipment || !chatInfo || !channelName) {
      return;
    }

    // channel does not exist yet, poll
    if (!channelExists && !channelExistsInChatInfo(chatInfo, channelName)) {
      refreshChatInfo();
    }

    /*
      Channel exists now, update state variable and re-fetch tokens,
      such that user can make use of the chat
    */
    if (channelExistsInChatInfo(chatInfo, channelName) && !channelExists) {
      // chat was just created, need tokens that include the chat
      refreshChatTokens(() => { setTokensRefreshed(true); });
      setChannelExists(true);
    }
  }, [channelExists,
    chatInfo,
    channelName,
    refreshChatInfo,
    shipment,
    refreshChatTokens,
    setTokensRefreshed,
  ]);
  /*
    Poll until the chat conversation is created
  */
  useInterval(refetchUserChatInfoOnChannelNotExisting, REFETCH_USER_CHANNELS_INTERVAL_MILLISECONDS);

  // if shipment is not booked, this component has nothing to render.
  if (!shipment || shipment?.shipment_status !== 'BOOKED' || !channelName) {
    return <div />;
  }

  // circular spinner until chat is created
  if (!channelExists || !tokensRefreshed) {
    return (
      <>
        {dividerComponent}
        <Stack alignItems="center">
          <p>Opening Live Chat...</p>
          <CircularProgress />
        </Stack>
      </>
    );
  }

  return (
    <>
      {dividerComponent}
      <StandAloneLiveChat channelName={channelName} />
    </>
  );
}
