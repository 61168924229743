import Rating from '@mui/material/Rating';

interface Props {
  onStarRatingSet: (val: CompanyRatingValue | null) => void;
  rating: CompanyRatingValue | null;
}

export function StarRatingMenu(props: Props) {
  const { onStarRatingSet, rating } = props;
  return (
    <Rating
      size="large"
      value={rating}
      onChange={(event, newValue) => {
        if (newValue) {
          onStarRatingSet(newValue as CompanyRatingValue);
        } else {
          onStarRatingSet(null);
        }
      }}
    />
  );
}
