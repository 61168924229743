import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box, Stack, Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { MultiShipmentChildShipmentChip } from 'components/MultiShipmentChildShipmentChip';
import { PaymentMethodRequiredWrapper } from 'components/PaymentMethodRequiredWrapper';
import { RefreshPageButton } from 'components/RefreshPageButton';
import { RouteTab } from 'components/ShipmentOverviewTab/components/RouteTab';
import { ShipmentQATab } from 'components/ShipmentOverviewTab/components/ShipmentQATab';
import { WhatAreMultiShipmentProjectsAlert } from 'components/WhatAreMultiShipmentProjectsAlert';
import { useCarrierShipmentPageContext } from 'hooks';
import { PrivateShipmentChip } from '../../../PrivateShipmentChip';
import { ShipmentDetails } from '../../../Shipment/components/ShipmentDetails';
import { ShipmentStatusBadge } from '../../../ShipmentStatusBadge';
import { BookNowButton } from '../BookNowButton';
import { CarrierQuoteTab } from '../CarrierQuoteTab';
import { PartnerShipmentChip } from '../PartnerShipmentChip';
import { QuoteCountChip } from '../QuoteCountChip';
import { ShipmentOverviewSubheader } from '../ShipmentOverviewSubheader';
import { SubmitOrCancelQuote } from '../SubmitOrCancelQuote';
import { SubmitQuote } from '../SubmitQuote';
import { ShipmentDetailTitle } from './ShipmentDetailTitle';
import { AddPaymentInformationLabel } from './components/AddPaymentInformationLabel';
import { CarrierShipmentOverviewChat } from './components/CarrierShipmentOverviewChat';
import { ChildShipmentProjectBookingsSection } from './components/ChildShipmentProjectBookingsSection';
import { MultiShipmentProjectBookingsSection } from './components/MultiShipmentProjectBookingsSection';
import { ShipmentCancellationSection } from './components/ShipmentCancellationSection';
import { ShipmentDataSection } from './components/ShipmentDataSection';
import { ShipmentTrackingLinkSection } from './components/ShipmentTrackingLinkSection';

const SUBMIT_QUOTE_FORM_ID = 'submit-quote-form';

export function ShipmentOverviewExpanded() {
  const { shipment, onRefresh } = useCarrierShipmentPageContext();

  const sectionHeaderType = 'h5';

  const SECTION_SPACE = '4rem';

  return (
    <Box boxShadow={1}>
      <Box p="2rem">
        <Stack
          id="header-stack"
          mb="1.5rem"
          direction="row"
          alignItems="baseline"
          flexWrap="wrap"
        >
          <Box width="60%">
            <Stack id="shipment-date-status" direction="column">
              <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
                <ShipmentDetailTitle shipment={shipment} />
                <ShipmentStatusBadge status={shipment?.shipment_status} size="small" />
                <PartnerShipmentChip partner={shipment?.is_partner_shipment ?? false} />
                <PrivateShipmentChip
                  isPrivate={shipment?.is_private ?? false}
                  isProject={shipment?.info?.type === 'MULTI_SHIPMENT_PROJECT'}
                />
                <MultiShipmentChildShipmentChip isMultiShipmentChildShipment={shipment?.info.type === 'MULTI_SHIPMENT_PROJECT_CHILD'} />
                {shipment && <QuoteCountChip shipment={shipment} />}
                <RefreshPageButton
                  id="carrier-shipment-overview-refresh"
                  iconButton
                  hardReload={false}
                  onRefresh={onRefresh || (() => {})}
                />
              </Stack>

              {shipment && <ShipmentOverviewSubheader shipment={shipment} />}
            </Stack>
          </Box>
          <Box id="submit-quote-half" width="40%">
            <Stack id="button-stack" justifyContent="end" direction="row" spacing={7}>
              <SubmitOrCancelQuote
                shipment={shipment}
              />

            </Stack>
          </Box>

        </Stack>
        <ShipmentCancellationSection dividerComponent={(
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant={sectionHeaderType}>Cancellation Reason</Typography>
              <Tooltip title={
                <Typography variant="h5">The reason provided by the shipper when cancelling the shipment.</Typography>
           }
              >
                <InfoOutlinedIcon color="primary" fontSize="large" />
              </Tooltip>
            </Stack>

            <EvotruxDivider />
          </>
                )}
        />
        <ShipmentDataSection
          shipment={shipment}
          dividerComponent={(
            <>
              <Typography mt="2rem" variant={sectionHeaderType}>Booked Price</Typography>
              <EvotruxDivider />
            </>
                )}
        />
        <ShipmentTrackingLinkSection dividerComponent={(
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant={sectionHeaderType}>
                Shipment Tracking
              </Typography>
              <Tooltip title={
                <Typography variant="h5">Manage your booked shipment&apos;s tracking here.</Typography>
        }
              >
                <InfoOutlinedIcon color="primary" fontSize="large" />
              </Tooltip>
            </Stack>
            <EvotruxDivider />
          </>
        )}
        />
        <MultiShipmentProjectBookingsSection
          dividerComponent={(
            <>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant={sectionHeaderType}>
                  Project Bookings
                </Typography>
                <Tooltip title={
                  <Typography variant="h5">Shipments booked by you in this project will collect here.</Typography>
          }
                >
                  <InfoOutlinedIcon color="primary" fontSize="large" />

                </Tooltip>
              </Stack>
              <EvotruxDivider />
            </>
        )}
          shipmentId={shipment?.project?.project}
        />
        <ChildShipmentProjectBookingsSection
          dividerComponent={(
            <>
              <Stack direction="row" alignItems="center" spacing={1} mt="1rem">
                <Typography variant={sectionHeaderType}>
                  Related Project Bookings
                </Typography>
                <Tooltip title={
                  <Typography variant="h5">The rest of bookings assigned to you in this project.</Typography>
          }
                >
                  <InfoOutlinedIcon color="primary" fontSize="large" />

                </Tooltip>
              </Stack>
              <EvotruxDivider />
            </>
        )}
          shipmentId={shipment?.project?.project}
        />
        {shipment && shipment?.shipment_status === 'BOOKED'
         && shipment?.info.type !== 'MULTI_SHIPMENT_PROJECT' && (
         <CarrierShipmentOverviewChat dividerComponent={(
           <>
             <Stack direction="row" alignItems="center" spacing={1}>
               <Typography variant={sectionHeaderType}>
                 Live Chat
               </Typography>
               <Tooltip title={
                 <Typography variant="h5">Speak to the shipper in real time here.</Typography>
          }
               >
                 <InfoOutlinedIcon color="primary" fontSize="large" />
               </Tooltip>
             </Stack>
             <EvotruxDivider />
           </>
        )}
         />
        )}
        { shipment?.book_now_info?.is_book_now
        && shipment.shipment_status !== 'BOOKED'
        && shipment.is_partner_shipment
        && shipment.info.type !== 'MULTI_SHIPMENT_PROJECT'
        && shipment.info.type !== 'MULTI_SHIPMENT_PROJECT_CHILD' && (
          <>
            <Stack id={SUBMIT_QUOTE_FORM_ID} direction="row" mt={SECTION_SPACE} alignItems="center" spacing={1}>
              <Typography variant={sectionHeaderType}>
                Book Now
              </Typography>
              <Tooltip title={
           (
             <Typography variant="h5">You can instantly book this load for the price listed below, skipping the quoting process. If the price/equipment doesn&apos;t work for you, please submit a quote.</Typography>
           )
          }
              >
                <InfoOutlinedIcon color="primary" fontSize="large" />
              </Tooltip>
            </Stack>
            <EvotruxDivider />
            <BookNowButton shipment={shipment} />
          </>
        )}

        { shipment?.info.booked_at == null && (
          <>
            <Stack id={SUBMIT_QUOTE_FORM_ID} direction="row" mt={SECTION_SPACE} alignItems="center" spacing={1}>
              <Typography variant={sectionHeaderType}>
                Submit A Quote
              </Typography>
              <Tooltip title={
           (
             <Typography variant="h5">Your quote will be sent directly to the shipper for review. You will be notified immediately if they book your truck or send you a counteroffer.</Typography>
           )
          }
              >
                <InfoOutlinedIcon color="primary" fontSize="large" />
              </Tooltip>
            </Stack>
            <EvotruxDivider />
            <SubmitQuote />
          </>
        )}

        <Stack direction="row" mt={SECTION_SPACE} alignItems="center" spacing={1}>
          <Typography variant={sectionHeaderType}>
            My Quotes
          </Typography>
          <Tooltip title={
            <Typography variant="h5">These are quotes you have already placed on this shipment.</Typography>
          }
          >
            <InfoOutlinedIcon color="primary" fontSize="large" />
          </Tooltip>
        </Stack>
        <EvotruxDivider />
        <CarrierQuoteTab shipment={shipment} />
        <Typography mt="2rem" variant={sectionHeaderType}>Shipment Details</Typography>
        <EvotruxDivider />
        <WhatAreMultiShipmentProjectsAlert shipment={shipment} />
        <ShipmentDetails shipment={shipment} />

        <Typography mt={SECTION_SPACE} variant={sectionHeaderType}>Route</Typography>
        <EvotruxDivider />
        <RouteTab shipment={shipment} />

        <Stack direction="row" mt={SECTION_SPACE} alignItems="center" spacing={1}>
          <Typography variant={sectionHeaderType}>
            Questions & Answers
          </Typography>
          <Tooltip title={
            <Typography variant="h5">Ask the shipper a question about their load.  All questions and answers are publicly visible.  Do not discuss rates or send contact information here.</Typography>
          }
          >
            <InfoOutlinedIcon color="primary" fontSize="large" />
          </Tooltip>
        </Stack>
        <EvotruxDivider />
        {!shipment?.is_partner_shipment ? (
          <PaymentMethodRequiredWrapper
            enableWithSubscriptionPlan="CARRIER_PAY_PER_BOOKING"
            noPaymentMethodComponent={<AddPaymentInformationLabel />}
          >
            <ShipmentQATab shipment={shipment} />
          </PaymentMethodRequiredWrapper>
        ) : <ShipmentQATab shipment={shipment} />}

      </Box>
    </Box>
  );
}
