import {
  CircularProgress, Paper,
  Stack,
  Table,
  TableBody, TableCell, TableContainer, TableHead,
  TableRow, Typography,
} from '@mui/material';
import { JoinCompanyRequestTableRow } from '../JoinCompanyRequestTableRow';

interface Props {
  error: string | null;
  loading: boolean;
  joinRequests: ActiveJoinCompanyRequest[];
  onRespondToRequest: (requestId: number, requestApproved: boolean) => void;
}

export function ActiveJoinCompanyRequestTable(props: Props) {
  const {
    error, loading, joinRequests, onRespondToRequest,
  } = props;
  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  if (error) {
    return (
      <Stack>
        <Typography color="red">{error}</Typography>
      </Stack>
    );
  }
  if (joinRequests.length === 0) {
    return (
      <Stack>
        <Typography variant="h6">You do not have any active join requests for the moment.</Typography>
      </Stack>
    );
  }
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { joinRequests.length > 0 ? joinRequests.map((request) => (
            <JoinCompanyRequestTableRow
              key={request.id}
              request={request}
              onRespondToRequest={onRespondToRequest}
            />
          )) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
