import {
  Box, CircularProgress, Divider, Stack, Typography,
} from '@mui/material';
import { orderQuotesByQuoteStatus } from '../../../../utils';
import { ShipmentQuote } from '../../../ShipmentQuote';

interface Props {
  quotes: CondensedQuote[];
  loading: boolean;
  error: string | null;
}

export function ShipmentQuoteList(props: Props) {
  const { quotes, loading, error } = props;
  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  if (quotes.length === 0) {
    return (
      <Typography variant="body1" textAlign="center" mt="1rem">
        You do not have any quotes on this shipment.
      </Typography>
    );
  }
  if (error != null) {
    return (
      <Stack>
        <Typography color="red" align="center">{error}</Typography>
      </Stack>
    );
  }
  const sortedQuotes = orderQuotesByQuoteStatus(quotes);
  return (
    <>
      {sortedQuotes.map((quote) => (
        <div key={quote.quote}>
          <ShipmentQuote quote={quote} />
          <Box pt={1}>
            <Divider />
          </Box>
        </div>
      ))}
    </>
  );
}
