import {
  Box,
  CircularProgress, FormControl,
  FormControlLabel, Radio, RadioGroup, Stack,
  TextField,
  Typography,
} from '@mui/material';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { useState } from 'react';

interface Props {
  cancellationReason: string;
  onCancellationUpdate: (newValue: string) => void;
  loading: boolean;
  error: string | null;
}

export function CancelShipmentFormModalContent(props: Props) {
  const {
    loading,
    cancellationReason,
    onCancellationUpdate,
    error,
  } = props;

  const [value, setValue] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const chosenOption = (event.target as HTMLInputElement).value;
    setValue(chosenOption);
    if (chosenOption === 'other') {
      onCancellationUpdate('');
    } else {
      onCancellationUpdate((event.target as HTMLInputElement).value);
    }
  };

  if (loading) {
    return (<Stack alignItems="center"><CircularProgress /></Stack>);
  }
  return (
    <Stack
      direction="column"
      spacing={1}

    >
      <Typography variant="h5">
        Why are you cancelling this shipment?
      </Typography>
      <Typography variant="body2" color="text.secondary">
        The cancellation reason will be shared with carriers who quoted on this shipment.
      </Typography>
      <EvotruxDivider />
      <Box mb="2rem">
        <FormControl>
          <RadioGroup
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel value="Customer cancelled the order" control={<Radio />} label="Customer cancelled the order" />
            <FormControlLabel value="I did not receive any suitable quotes" control={<Radio />} label="I did not receive any suitable quotes" />
            <FormControlLabel value="The product is not ready/I was only looking for quotes at this time" control={<Radio />} label="The product is not ready/I was only looking for quotes at this time" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
      </Box>
      {value === 'other' && (
      <TextField
        id="cancellation-reason"
        variant="outlined"
        onChange={(e) => onCancellationUpdate(e.target.value)}
        value={cancellationReason}
      />
      )}
      {error != null && !loading && <Typography color="red" align="center">{error}</Typography>}
    </Stack>
  );
}
