import { Stack, Typography } from '@mui/material';
import { RefreshPageButton } from 'components/RefreshPageButton';
import { useCarrierQuotes, useRoleContext } from 'hooks';
import { DashboardItemLayout } from 'layouts';
import { useState } from 'react';
import { getPageCount, sortQuotes } from '../../../../utils';
import { QuoteFilters } from '../QuoteFilters';
import { CarrierQuoteList } from './CarrierQuoteList';

const CARRIER_QUOTES_PAGE_SIZE = 10;

interface Props {
  useColumnLayout: boolean;
}

const INITIAL_QUOTE_FILTER_STATE: QuoteFilters = {
  quoteStatus: 'ALL',
  shipmentName: '',
  shipmentTypes: [],
};

export function CarrierQuoteListContainer(props: Props) {
  const { useColumnLayout } = props;
  const { role } = useRoleContext();
  const [page, setPage] = useState<number>(1);
  const [filterState, setFilterState] = useState<QuoteFilters>(INITIAL_QUOTE_FILTER_STATE);
  const {
    quotes, loading, error, count, refetchQuotes,
  } = useCarrierQuotes({
    currenPage: page,
    pageSize: CARRIER_QUOTES_PAGE_SIZE,
    filters: filterState,
  });
  const pages = getPageCount(count != null ? count : 0, CARRIER_QUOTES_PAGE_SIZE);

  const handleValueChange = (key: keyof QuoteFilters, value: string | string[]): void => {
    setFilterState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  if (role == null || role.type !== 'CARRIER') {
    return <div />;
  }

  const onPageSelected = (newPageNumber: number) => {
    setPage(newPageNumber);
    refetchQuotes();
  };

  const handleQuoteStatusChange = (value: string): void => {
    if (value === 'ALL') {
      handleValueChange('quoteStatus', '');
    } else {
      handleValueChange('quoteStatus', value);
    }
    refetchQuotes();
  };

  const handleShipmentNameChange = (value: string): void => {
    handleValueChange('shipmentName', value);
    refetchQuotes();
  };

  const handleShipmentTypeChange = (value: string[]) => {
    handleValueChange('shipmentTypes', value);
    refetchQuotes();
  };

  return (
    <DashboardItemLayout
      style={{ width: !useColumnLayout ? '35%' : undefined }}
      title={(
        <Stack direction="column" spacing={1}>
          <Stack direction="row" sx={{ m: '1rem' }} spacing={4} alignItems="baseline" justifyContent="space-between">
            <Typography variant="h6">My Quotes</Typography>
            <RefreshPageButton
              id="carrier-my-quotes-refresh"
              iconButton
              onRefresh={refetchQuotes}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <QuoteFilters
              onQuoteStatusChange={handleQuoteStatusChange}
              onShipmentNameChange={handleShipmentNameChange}
              onShipmentTypeChange={handleShipmentTypeChange}
              filters={filterState}
            />
          </Stack>
        </Stack>
        )}
    >
      <CarrierQuoteList
        quotes={quotes != null ? sortQuotes(quotes) : quotes}
        loading={loading}
        error={error}
        currentPage={page}
        pageCount={pages}
        onPageSelected={(newPageNumber) => onPageSelected(newPageNumber)}
      />
    </DashboardItemLayout>
  );
}
