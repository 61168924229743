import {
  useAPI, useDisclosure, useError, useInterval, useLoading,
} from 'hooks';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { ShipmentProgress } from './ShipmentProgress';
import { ShipmentProgressDetail } from './ShipmentProgressDetail';

interface Props{
  shipment: ShipperCondensedShipment | undefined;
}

const SHADOW_REFETCH_INTERVAL_MILLISECONDS = 8000;

export function ShipmentProgressContainer(props: Props) {
  const { shipment } = props;
  const { api } = useAPI();
  const { loading, setLoading } = useLoading(false);
  const { error, setError } = useError();
  const [shipmentProgress, setShipmentProgress] = useState<ShipmentProgress | null>(null);
  const shipmentRef = useRef<undefined | ShipperCondensedShipment>();
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    if (loading && shipment) {
      setError(null);
      api<ShipmentProgress>('get', `/shipments/${shipment.info.id}/progress`).then((res) => {
        setError(null);
        setShipmentProgress(res.data);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to book shipment progress. Please contact us if the issue persists.');
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [setError, loading, api, setLoading, shipment]);

  /*
    Refetches shipment progress without using loading state variables such that
    the user does not see a loading spinner constantly
    (since this function is called on an interval)
  */
  const shadowFetchProgress = useCallback(() => {
    if (shipment) {
      api<ShipmentProgress>('get', `/shipments/${shipment.info.id}/progress`).then((res) => {
        setError(null);
        setShipmentProgress(res.data);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to book shipment progress. Please contact us if the issue persists.');
      });
    }
  }, [api, setShipmentProgress, shipment, setError]);

  const refreshProgressOnNewShipment = useCallback(() => {
    if (shipment && shipment.shipment_status !== 'BOOKED') {
      shadowFetchProgress();
    }
  }, [shipment, shadowFetchProgress]);
  /*
    Run refetch fn on an interval to keep the shipment progress
    with live data in the first few minutes after a shipment posting.
  */
  useInterval(refreshProgressOnNewShipment, SHADOW_REFETCH_INTERVAL_MILLISECONDS);

  useEffect(() => {
    if (shipment && shipmentRef.current?.info?.id !== shipment.info.id) {
      shipmentRef.current = shipment;
      setLoading(true);
    }
  }, [shipment, setLoading]);

  useEffect(() => {
    if (shipment && shipmentRef.current?.version !== shipment.version) {
      shipmentRef.current = shipment;
      setLoading(true);
    }
  }, [shipment, setLoading]);

  useEffect(() => {
    setLoading(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ShipmentProgress
      shipmentProgress={shipmentProgress}
      shipment={shipment}
      error={error}
      onOpenProgressDetail={onOpen}
      shipmentProgressDetail={(
        <ShipmentProgressDetail
          shipment={shipment}
          progress={shipmentProgress}
          open={isOpen}
          onClose={onClose}
        />
)}
    />
  );
}
