import {
  FormControl, InputLabel, MenuItem, Select as MUISelect, SelectProps,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

interface SelectOption<T> {
  value: T;
  label: string;
}

export interface Props<T> {
  inputLabel: string;
  defaultValue: T;
  options: SelectOption<T>[];
  selectProps?: SelectProps & { 'data-testid': string; };
  onChange: (value: T) => void;
  disabled?: boolean;
  value?: T;
}

export function Select<T extends string | number>(props: Props<T>) {
  const {
    defaultValue, inputLabel, options, selectProps, onChange, disabled, value: v,
  } = props;
  const [value, setValue] = useState<T>(defaultValue);

  useEffect(() => {
    if (v != null) {
      setValue(v);
    }
  }, [v]);

  const handleChange = useCallback((newValue: T) => {
    setValue(newValue);
    onChange(newValue);
  }, [onChange]);

  const menuItems = options.map((o) => (
    <MenuItem key={o.value.toString()} value={o.value.toString()}>{o.label}</MenuItem>
  ));

  return (
    <FormControl sx={{ minWidth: 160, width: '100%' }}>
      <InputLabel>{inputLabel}</InputLabel>
      <MUISelect
        autoWidth
        {...selectProps}
        label={inputLabel}
        value={value}
        onChange={(e) => {
          handleChange(e.target.value as T);
        }}
        disabled={disabled}
      >
        {menuItems}
      </MUISelect>
    </FormControl>
  );
}

Select.defaultProps = {
  selectProps: {},
  value: undefined,
  disabled: false,
};
