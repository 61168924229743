import { getShipmentIdFromShipmentBookedChatName } from 'utils';

function currentShipmentMatchesChannelName(
  shipment: CondensedShipment,
  channelName: string,
): boolean {
  const shipmentIdForChannel = getShipmentIdFromShipmentBookedChatName(channelName);
  return shipment.info.id.toString() === shipmentIdForChannel;
}

function getLastPathPart(pathname: string) {
  const parts = pathname.split('/');
  return parts.pop() || parts.pop(); // Handle trailing slash
}

export function channelShipmentMatchesPathAndContext(
  shipment: CondensedShipment,
  channelName: string,
  pathName: string,
): boolean {
  const shipmentIdFromPath = getLastPathPart(pathName);
  const shipmentMatchesChannel = currentShipmentMatchesChannelName(shipment, channelName);
  return shipmentIdFromPath === shipment.info.id.toString() && shipmentMatchesChannel;
}
