import { Stack } from '@mui/material';
import { MessageEnvelope, isFilePayload } from '@pubnub/react-chat-components';
import { FileMessage } from './components/FileMessage';
import { TextMessage } from './components/TextMessage';

interface Props {
  message: MessageEnvelope;
  userIsAuthor: boolean;
}

export function Message(props: Props) {
  const { message, userIsAuthor } = props;

  let node = <FileMessage message={message} userIsAuthor={userIsAuthor} />;

  if (!isFilePayload(message.message)) {
    node = (
      <TextMessage
        message={message}
        userIsAuthor={userIsAuthor}
      />
    );
  }
  return (
    <Stack
      direction="row"
      ml="2rem"
      mr="2rem"
      mb="1rem"
    >
      {node}
    </Stack>
  );
}
