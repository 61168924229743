const CLIENT_DATA_TTL_KEY = 'client_data_ttl';
export const ONE_MINUTE_IN_MILLISECONDS = 60000;

interface SessionTTL {
  expiry: number;
}

export function storeSessionTTL(sessionTTLMinutes: number) {
  const DEFAULT_DATA_TTL_MILLISECONDS = ONE_MINUTE_IN_MILLISECONDS * sessionTTLMinutes;
  const now = new Date();
  const ttl = {
    expiry: now.getTime() + DEFAULT_DATA_TTL_MILLISECONDS,
  };
  localStorage.setItem(CLIENT_DATA_TTL_KEY, JSON.stringify(ttl));
}

export function getTimeBoundedLocalStorageValue(key: string): null | string {
  const item = localStorage.getItem(key);
  // first get the item from where it 'should' be
  if (!item) {
    return null;
  }

  // next get the TTL object from where it should be
  const ttl = localStorage.getItem(CLIENT_DATA_TTL_KEY);
  if (!ttl) {
    return null;
  }

  // if both the item and the TTL are defined, only return the item if TTL is still live
  const now = new Date();
  if (JSON.parse(ttl)?.expiry && now.getTime() > JSON.parse(ttl)?.expiry) {
    return null;
  }
  return item;
}

export function clearLocalStorage() {
  localStorage.clear();
}

export function storeValue(key: string, value: string) {
  localStorage.setItem(key, value);
}

function getLocalDataTTL(): SessionTTL | null {
  const ttl = localStorage.getItem(CLIENT_DATA_TTL_KEY);
  if (!ttl) {
    return null;
  }
  const parsedTTL = JSON.parse(ttl);
  if (!parsedTTL?.expiry) {
    return null;
  }
  return parsedTTL;
}

export function localDataIsStale(): boolean {
  const ttl = getLocalDataTTL();
  if (!ttl) {
    return true;
  }
  const now = new Date();
  return now.getTime() > ttl.expiry;
}

export function getMillisecondsLeftInSession(): number {
  const ttl = getLocalDataTTL();
  if (!ttl) {
    return 0;
  }
  const now = new Date();
  return ttl.expiry - now.getTime();
}

export function removeLocalData(key: string) {
  localStorage.removeItem(key);
}
