import { useEffect, useState } from 'react';
import { useFetchingComponentState } from './useFetchingComponentState';
import { useRoleContext } from './useRoleContext';

export function useMultiShipmentProjectBookingsAsCarrier(shipmentId: number | undefined) {
  const { role } = useRoleContext();
  const [projectBookings, setProjectBookings] = useState<MultiShipmentProjectBooking[]>([]);

  const {
    fetch, loading, error, success, resetState,
  } = useFetchingComponentState<MultiShipmentProjectBooking[]>({
    errorMessage: 'Failed to fetch project bookings',
    fetchConfig: {
      url: role != null && shipmentId !== undefined ? `/carriers/${role.id}/projects/${shipmentId}/bookings` : '/',
    },
  });

  useEffect(() => {
    if (role != null && !success && error == null && shipmentId !== undefined) {
      fetch((res: MultiShipmentProjectBooking[]) => {
        setProjectBookings(res);
      });
    }
  }, [role, success, error, fetch, shipmentId]);

  const refetchBookings = () => {
    resetState();
  };

  return {
    projectBookings,
    refetchBookings,
    loading,
    error,
    success,
  };
}
