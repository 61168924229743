import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function DashboardBackButton() {
  const navigate = useNavigate();
  return (
    <Button style={{ justifyContent: 'flex-start' }} onClick={() => navigate('/')}>
      <KeyboardBackspaceIcon />
      Dashboard
    </Button>
  );
}
