import {
  CircularProgress, Stack, Typography, useMediaQuery,
} from '@mui/material';
import {
  useAuthContext, useChannelAPI, useSubscriptionStatus, useUserChatInfo,
} from 'hooks';

import { ChatConversation } from 'components/ChatConversation';
import { ChatSidebar } from 'components/ChatSidebar';
import { ShipperUpgradeRequestInfoButton } from 'components/Shipper/components/ShipperUpgradePage/components';
import { PageLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sortShipmentBookedChannelsList } from 'utils';
import { DesktopChatPage } from './DesktopChatPage';
import { MobileChatPage } from './MobileChatPage';

export function ChatPage() {
  const [currentChannel, setCurrentChannel] = useState<string | null | undefined>(null);
  const [channelsWithNewMessages, setChannelsWithNewMessages] = useState<Set<string>>(new Set());
  const { subscription } = useSubscriptionStatus();

  const { chatInfo, loading } = useUserChatInfo();
  const { updateTimeTokenForChannel, getChannelsWithUnreadMessages } = useChannelAPI();

  const useDesktopLayout = !useMediaQuery('(max-width:950px)');

  const { user, pubnub: pubnubUserInfo, isLoading } = useAuthContext();
  const params = useParams();

  const onStartSetChannelsWithUnreadMessages = (channels: Set<string> | undefined): void => {
    if (channels !== undefined) {
      setChannelsWithNewMessages(new Set<string>(channels?.values()));
    }
  };

  useEffect(() => {
    if (currentChannel == null || currentChannel === undefined) {
      // if params.id is not defined, default to the first channel
      if (!params.id && chatInfo != null && chatInfo?.channels.length > 0) {
        setCurrentChannel(sortShipmentBookedChannelsList(chatInfo.channels)[0].channel.name);
      } else if (params.id && chatInfo != null && chatInfo?.channels.length > 0) {
      // if params is defined, try to find the requested chat id from the user's conversation

        const found = chatInfo.channels.find((ch: GroupChannel) => ch.channel.name === params.id);
        if (found) {
          setCurrentChannel(found.channel.name);
        } else {
          setCurrentChannel(sortShipmentBookedChannelsList(chatInfo.channels)[0].channel.name);
        }
      }

      // check for unread messages received before user arrived to chat page
      getChannelsWithUnreadMessages(
        chatInfo?.channels.map((c: GroupChannel) => c.channel.name),
        onStartSetChannelsWithUnreadMessages,
      );
    }
  }, [chatInfo, params, currentChannel, getChannelsWithUnreadMessages]);

  useEffect(() => {
    /*
      On current channel change, remove the channel from the channelsWithNewMessages set,
      as it can be assumed that the messages are now read
    */
    if (currentChannel && channelsWithNewMessages.has(currentChannel)) {
      channelsWithNewMessages.delete(currentChannel);
      setChannelsWithNewMessages((prev: any) => new Set(prev.values()));

      updateTimeTokenForChannel(currentChannel);
    }
  }, [channelsWithNewMessages, currentChannel,
    setChannelsWithNewMessages, updateTimeTokenForChannel]);

  const onNewMesage = (channel: string | undefined) => {
    /*
      Only add the channel to the list of channels if the channel is not already selected.
      if the chhanel is already selected, it is assumed the user saw the msg
    */
    if (channel && channel !== currentChannel) {
      // using any because if using Set<string> tsconfig would need to require es6 on the client
      setChannelsWithNewMessages((previous: any) => new Set([...previous, channel]));
    }
  };

  if (loading || isLoading) {
    return (
      <PageLayout>
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      </PageLayout>
    );
  }

  if (subscription != null
    && subscription.subscription_plan === 'SHIPPER_TRIAL'
    && !subscription.is_active) {
    return (
      <PageLayout>
        <Stack
          textAlign="center"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            p="2rem"
            direction="column"
            justifyContent="center"
          >
            <Typography
              textAlign="center"
              variant="h4"
            >
              Live Chat is a Premium feature.
            </Typography>
            <Typography
              textAlign="center"
              variant="h4"
              sx={{ mt: '2rem', mb: '2rem' }}
            >
              Go Premium for powerful communication tools and more!
            </Typography>
          </Stack>
          <ShipperUpgradeRequestInfoButton />
        </Stack>
      </PageLayout>
    );
  }

  if (pubnubUserInfo == null
    || user == null
    || currentChannel === undefined
    || currentChannel == null
    || chatInfo == null) {
    return (
      <PageLayout>
        <Stack alignItems="center">
          <Typography variant="h4">You do not have any chats. Book a shipment to start a chat! </Typography>
        </Stack>
      </PageLayout>
    );
  }

  const onChannelSelected = (channelName: string): void => {
    setCurrentChannel(channelName);
    // mark all of the channel's messages as read
    updateTimeTokenForChannel(channelName);
  };

  const channels = chatInfo.channels.map((c: GroupChannel) => c.channel);

  const chatSideBar = (
    <ChatSidebar
      onChannelSelected={onChannelSelected}
      channels={channels}
      channelsWithNewMessages={channelsWithNewMessages}
      currentChannel={currentChannel}
      useDesktopLayout={useDesktopLayout}
    />
  );
  const chatConversation = (
    <ChatConversation
      currentChannel={currentChannel}
      channelGroup={chatInfo.user_channel_group}
      userPubnubData={pubnubUserInfo}
      onNewMessage={onNewMesage}
      useDesktopLayout={useDesktopLayout}
    />
  );

  return (
    <PageLayout>
      {useDesktopLayout
        ? <DesktopChatPage chatSidebar={chatSideBar} chatConversation={chatConversation} />
        : <MobileChatPage chatSidebar={chatSideBar} chatConversation={chatConversation} />}
    </PageLayout>
  );
}
