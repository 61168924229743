import {
  Box, Button, Divider, Stack, Typography,
} from '@mui/material';
import axios from 'axios';
import { ConfirmForgotPassword } from 'components/Authentication/components/ConfirmForgotPassword';
import { CreateAPIKeyForUserMenu } from 'components/CreateAPIKeyForUserMenu';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { useAuthContext, useRoleContext } from 'hooks';
import { PageLayout } from 'layouts';
import { useState } from 'react';

export function UserAccountPage() {
  const user = useAuthContext();
  const { role: userRole } = useRoleContext();
  const [showCodeSent, setShowCodeSent] = useState<boolean>(false);
  const [changeSuccess, setChangeSuccess] = useState<boolean>(false);

  if (user.user == null) {
    return (
      <PageLayout>
        <Typography color="red" align="center" variant="h4"> Error loading user information - please contact us if this error persists</Typography>
      </PageLayout>
    );
  }

  const {
    email, first_name: firstName, last_name: lastName, roles,
  } = user.user;

  const handleSendCode = () => {
    if (user.user != null) {
      axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, {
        email,
      });
    }
  };

  return (
    <PageLayout>
      <Typography variant="h4" fontWeight={600} mb="2rem">Account Details</Typography>
      <Stack direction="column">
        <Box m="1rem">
          <Typography variant="h4">User Information</Typography>
          <EvotruxDivider />
          <Box mt="1rem">
            <Typography variant="h6">
              {firstName}
              {' '}
              {lastName}
            </Typography>
            <Typography variant="h6">
              {email}
            </Typography>
          </Box>
        </Box>
        <Box m="1rem">
          <Typography variant="h4">Active Roles</Typography>
          <EvotruxDivider />
          <Box mt="1rem">
            {roles.filter((role) => role.company != null).map((role) => (
              <Box key={role.id} mt=".5rem" mb=".5rem">
                <Typography variant="h6">
                  {role.type}
                </Typography>
                <Divider />
              </Box>
            ))}
          </Box>
        </Box>
        <Box m="1rem">
          <Typography variant="h4">Change Password</Typography>
          <EvotruxDivider />
          <Stack direction="column">
            <Typography>To change your password follow these instructions:</Typography>
            <ol>
              <li>
                <Typography>Click &apos;SEND CODE TO EMAIL&apos; button.</Typography>
              </li>
              <li>
                <Typography>Check your email for the code we sent you.</Typography>
              </li>
              <li>
                <Typography>
                  Enter the code in the menu below
                  along with your new password.

                </Typography>
              </li>
            </ol>
            <Box mt="1rem" ml=".5rem">
              <Button
                variant="contained"
                onClick={() => { handleSendCode(); setShowCodeSent(true); }}
              >
                Send Code to email
              </Button>
            </Box>

            {showCodeSent && <Typography variant="h6" color="green" ml="1rem">Code sent!</Typography>}
          </Stack>
          <Box p="1rem">
            <ConfirmForgotPassword
              email={email}
              backToLogin={false}
              onSuccessFromAccountPage={setChangeSuccess}
            />
            { changeSuccess && <Typography variant="h6" color="green" align="center">Password changed successfully!</Typography>}
          </Box>
        </Box>
        {userRole != null && userRole.type === 'SHIPPER' && (
        <Box ml="1rem" mr="1rem">
          <Typography variant="h4">Evotrux API</Typography>
          <EvotruxDivider />
          <CreateAPIKeyForUserMenu />
        </Box>
        )}
      </Stack>
    </PageLayout>
  );
}
