import { useAPI, useShipmentPageContext } from 'hooks';
import { useEffect, useState } from 'react';
import { isInvalidNumber } from 'utils';
import { CounterQuoteInteraction } from './CouterQuoteInteraction';
import { CounterQuoteFormState } from './types';

const initialState: CounterQuoteFormState = {
  currency: '',
  price: '',
  notes: '',
};

interface Props {
  quoteId: number;
  onClose: VoidFunction;
}

export function CounterQuoteInteractionContainer(props: Props) {
  const { onClose, quoteId } = props;
  const [loading, setIsLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState<CounterQuoteFormState>(initialState);
  const [error, setError] = useState<null | string>(null);
  const { api } = useAPI();
  const { onRefresh } = useShipmentPageContext();

  useEffect(() => {
    if (loading) {
      if (isInvalidNumber(formState.price)) {
        setError('Price must be a number.');
        setIsLoading(false);
        return;
      }

      api<unknown>('post', `/quotes/${quoteId}/counter`, formState).then(() => {
        setError(null);

        if (onRefresh) {
          onRefresh();
        }
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to counter quote');
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [loading, quoteId, api, formState, onRefresh]);

  const onConfirmAction = () => {
    setIsLoading(true);
  };

  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const onValueChanged = <T,>(key: keyof CounterQuoteFormState, value: T): void => {
    setFormState((prev) => ({
      ...prev,
      [key]: value,
    }));
    if (key === 'price') {
      if (Number.isNaN(value)) {
        setError('Invalid price');
      } else {
        setError(null);
      }
    }
  };

  return (
    <CounterQuoteInteraction
      onClose={onClose}
      onConfirmAction={onConfirmAction}
      error={error}
      loading={loading}
      onInputChange={onValueChanged}
      disableSubmit={formState.currency === '' || Number.isNaN(formState.price)}
      formState={formState}
    />
  );
}
