import {
  CircularProgress, Divider, Stack, Typography,
} from '@mui/material';
import { ShipmentTemplateListElement } from './ShipmentTemplateListElement';

interface Props {
  error: string | null;
  loading: boolean;
  templates: ShipmentTemplate[];
  onTemplateClicked: (template: ShipmentTemplate) => void;
  selectedTemplateId: number | null;
  onDeleteTemplate: (template: ShipmentTemplate) => void;

}
export function ShipmentTemplateList(props: Props) {
  const {
    error,
    loading,
    templates,
    onTemplateClicked,
    selectedTemplateId,
    onDeleteTemplate,
  } = props;
  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  if (error != null) {
    return (
      <Stack>
        <Typography color="red" align="center">{error}</Typography>
      </Stack>
    );
  }
  if (templates.length === 0) {
    return (
      <Typography variant="body1" textAlign="center">
        You have not posted any shipment templates.
        Create a template today for quick and easy shipment posting!
      </Typography>
    );
  }
  return (
    <div>
      {templates.map((template) => (
        <div key={template.id}>
          <ShipmentTemplateListElement
            template={template}
            onTemplateClicked={onTemplateClicked}
            selectedTemplateId={selectedTemplateId}
            onDeleteTemplate={onDeleteTemplate}
          />
          <Divider />
        </div>
      ))}
    </div>
  );
}
