import { ISOStringToReadableDate } from '../../../../utils';

const getShipmentPostedReadableDate = (shipment: CondensedShipment): string | null => {
  if (shipment?.info?.created_at) {
    return ISOStringToReadableDate(shipment.info.created_at);
  }
  return null;
};

const getPrivilegedSubheader = (shipment: CondensedShipment): string => `Posted ${getShipmentPostedReadableDate(shipment) ?? '-'} by ${shipment?.shipment_company?.name ?? '-'}`;

const getHiddenShipperName = (shipment: CondensedShipment): string => `${shipment?.shipment_creator?.first_name ?? '-'}`;

const getUnprivilegedShipmentSubheader = (shipment: CondensedShipment): string => `Posted ${getShipmentPostedReadableDate(shipment) ?? '-'} by  ${getHiddenShipperName(shipment)} from `;

export const getShipmentSubheader = (
  shipment: CondensedShipment,
  isPartnerShipment: boolean,
  bookedByCarrier: boolean,
  isPayingCarrier?: boolean,
) => {
  if (isPartnerShipment || bookedByCarrier || isPayingCarrier) {
    return getPrivilegedSubheader(shipment);
  }
  return getUnprivilegedShipmentSubheader(shipment);
};
