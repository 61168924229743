import {
  useAPI, useFetchingComponentState, useRoleContext, useShipperShipmentPageContext,
} from 'hooks';
import { useEffect, useState } from 'react';
import { AddCarrierToNetowrkButton } from './AddCarrierToNetworkButton';

interface Props {
  carrierId: number;
  buttonVariant?: 'contained' | 'outlined';
  checkForRequestAlreadySent?: boolean;
  sentRequests?: FollowShipperRequestAndResponse[] | null;
}

interface AddCarrierToNetworkResponse {
  code?: 'AUTO_ACCEPT_FAILED';
}

export function AddCarrierToNetworkButtonContainer(props: Props) {
  const {
    carrierId, buttonVariant, checkForRequestAlreadySent, sentRequests,
  } = props;
  const { role } = useRoleContext();
  const [requestSentStatus, setRequestSentStatus] = useState<'AUTO_ACCEPT_SUCCESS' | 'AUTO_ACCEPT_FAILED' | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const { api } = useAPI();
  const { onRefresh } = useShipperShipmentPageContext();

  const [requestAlreadySentForCarrier,
    setRequestAlreadySentForCarrier] = useState<boolean | null>(null);

  const [followShipperRequests,
    setFollowShipperRequests] = useState<FollowShipperRequestAndResponse[] | null>(null);

  const {
    fetch: fetchSentRequests,
  } = useFetchingComponentState<FollowShipperRequestAndResponse[]>({
    errorMessage: '',
    fetchConfig: {
      url: role != null ? `/shippers/${role.id}/follow_requests/sent` : '/',
    },
  });

  useEffect(() => {
    setRequestAlreadySentForCarrier(null);
  }, [carrierId]);

  useEffect(() => {
    // find if carrierId prop is inside requests
    if (followShipperRequests != null) {
      const requestAlreadyCreated = followShipperRequests.some(
        (
          request: FollowShipperRequestAndResponse,
        ) => request?.request?.target_role?.id === carrierId,
      );
      setRequestAlreadySentForCarrier(requestAlreadyCreated);
    }
  }, [followShipperRequests, setRequestAlreadySentForCarrier, carrierId]);

  // if sentRequests prop was passed, set followShipperRequests
  useEffect(() => {
    if (sentRequests != null && followShipperRequests == null) {
      setFollowShipperRequests(sentRequests);
    }
  }, [sentRequests, setFollowShipperRequests, followShipperRequests]);

  useEffect(() => {
    if (role != null && requestAlreadySentForCarrier === null && checkForRequestAlreadySent) {
      fetchSentRequests((res) => {
        setFollowShipperRequests(res);
      });
    }
  }, [role,
    fetchSentRequests,
    carrierId,
    requestAlreadySentForCarrier,
    checkForRequestAlreadySent]);

  const onSubmit = () => {
    setIsLoading(true);
    setError(null);
    api<AddCarrierToNetworkResponse>('post', '/follow_requests/add_carrier', { carrier_id: carrierId }).then((res) => {
      setError(null);
      if (res.data?.code && res.data?.code === 'AUTO_ACCEPT_FAILED') {
        setRequestSentStatus('AUTO_ACCEPT_FAILED');
      } else {
        setRequestSentStatus('AUTO_ACCEPT_SUCCESS');
      }
      if (onRefresh) {
        onRefresh();
      }
    }).catch((e) => {
      setError(e?.response?.data?.message || 'Failed to send follow request.');
    }).finally(() => {
      setIsLoading(false);
    });
  };

  /*
  do not render the component until its known
  that the shipper has not already createad an invite for this carrier
  */
  if (requestAlreadySentForCarrier == null && checkForRequestAlreadySent) {
    return <div />;
  }

  return (
    <AddCarrierToNetowrkButton
      onSubmit={onSubmit}
      requestSentStatus={requestSentStatus}
      requestAlreadySentForCarrier={requestAlreadySentForCarrier}
      error={error}
      isLoading={isLoading}
      buttonVariant={buttonVariant}
    />
  );
}

AddCarrierToNetworkButtonContainer.defaultProps = {
  buttonVariant: 'outlined',
  checkForRequestAlreadySent: true,
  sentRequests: null,
};
