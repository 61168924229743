import { Stack, TextField } from '@mui/material';
import { RegionSelect } from 'components/RegionSelect';

interface Props {
  onUpdateAddressValue: (addressType: keyof CompanyAddress, value: string) => void;
  city: string;
  province: string;
  country: string;
  postal: string;
  addressType: string;
  addressAutocomplete: React.ReactNode;
}

export function AddressAutocompleteForm(props: Props) {
  const {
    onUpdateAddressValue,
    city,
    province,
    country,
    postal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addressType,
    addressAutocomplete,
  } = props;
  return (
    <Stack spacing={2}>
      {addressAutocomplete}
      <TextField
        required
        label="City"
        value={city}
        onChange={(e) => onUpdateAddressValue('city', e.target.value)}
      />
      <TextField
        required
        label="Postal"
        value={postal}
        onChange={(e) => onUpdateAddressValue('postal', e.target.value)}
      />
      <RegionSelect
        onRegionChange={(value) => onUpdateAddressValue('province', value)}
        onCountryChange={(value) => onUpdateAddressValue('country', value)}
        country={country}
        region={province}
      />
    </Stack>
  );
}
