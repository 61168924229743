import { useSubscriptionStatus } from 'hooks';
import { BannerStack } from '../BannerStack';
import { CompanyProfileBanner } from '../CompanyProfileBanner';
import { LimitedBookingsCarrierRemainingBookingsText } from '../LimitedBookingsCarrierRemainingBookingsText';

interface Props {
  showNavBar: boolean;
  navBar: React.ReactNode;
}

export function PaidCarrierBanner(props: Props) {
  const { showNavBar, navBar } = props;
  const { subscription } = useSubscriptionStatus();
  return (
    <BannerStack
      borderRadius={3}
      showNavBar={showNavBar}
      navBar={navBar}
    >
      <CompanyProfileBanner />
      {(subscription?.subscription_plan === 'CARRIER_BRONZE'
      || subscription?.subscription_plan === 'CARRIER_SILVER'
      || subscription?.subscription_plan === 'CARRIER_GOLD'
      || subscription?.subscription_plan === 'CARRIER_PLATINUM') && (
        <LimitedBookingsCarrierRemainingBookingsText />
      )}
    </BannerStack>
  );
}
