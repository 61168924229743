import { TextField } from '@mui/material';
import { DateTime } from 'luxon';

type DateTimeFormat = 'datetime-local' | 'date';

interface Props {
  label: string;
  onChange: (value: string) => void;
  initialValue?: string;
  timeFormat?: DateTimeFormat;
}

export function TimeInput(props: Props) {
  const {
    label, timeFormat, onChange, initialValue,
  } = props;

  return (
    <TextField
      id={`${label}-${timeFormat}`}
      label={label}
      type={timeFormat}
      defaultValue={initialValue ?? DateTime.now().toFormat("yyyy-MM-dd'T'T")}
      sx={{ width: 250 }}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}

TimeInput.defaultProps = {
  initialValue: undefined,
  timeFormat: 'datetime-local',
};
