import { Tooltip, Typography, Zoom } from '@mui/material';

interface Props {
  shipment: CarrierCondensedShipment | undefined
}

export function ShipmentDetailTitle(props: Props) {
  const { shipment } = props;
  if (!shipment) {
    return (
      <div>
        <Typography variant="h3">Shipment -</Typography>
      </div>
    );
  }

  let shipmentNoun = 'Shipment';
  if (shipment?.info?.type === 'MULTI_SHIPMENT_PROJECT') {
    shipmentNoun = 'Project';
  }

  const tooltipHint = shipment.custom_name != null ? `${shipmentNoun} ${shipment.info.id} with reference ${shipment.custom_name} provided by the shipper.` : `Shipment ${shipment.info.id}`;
  const titleText = shipment.custom_name != null ? `${shipmentNoun} ${shipment?.info.id}  (${shipment.custom_name})` : `${shipmentNoun} ${shipment.info.id}`;

  return (
    <Tooltip followCursor title={tooltipHint} placement="bottom" TransitionComponent={Zoom}>
      <Typography variant="h3">{titleText}</Typography>
    </Tooltip>
  );
}
