import {
  Box, Button,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { BranchAdminShipperMultiSelect } from 'components/BranchAdminShipperMultiSelect';
import { BranchMultiSelect } from 'components/BranchMultiSelect';
import { CompanyAdminShipperMultiSelect } from 'components/CompanyAdminShipperMultiSelect';
import { EquipmentTypeMultiSelect } from 'components/EquipmentTypeSelect';
import { ProvinceStateMultiSelect } from 'components/ProvinceStateMultiSelect';
import { RefreshPageButton } from 'components/RefreshPageButton';
import { ShipmentServiceMultiSelect } from 'components/ShipmentServiceMultiSelect';
import { ShipmentStatusSelect } from 'components/ShipmentStatusSelect';
import { useRoleContext } from 'hooks';
import { FilterShipmentByTypeDropdown } from './components/FilterShipmentByTypeDropdown';
import { IncludeBranchShipmentsCheckbox } from './components/IncludeBranchShipmentsCheckbox';
import { IncludeCompanyShipmentsCheckbox } from './components/IncludeCompanyShipmentsCheckbox';
import { ShipmentSearchByName } from './components/ShipmentSearchByName';

interface Props {
  title: string;
  filters: ShipmentsFilterState;
  filterToolsAreVisible: boolean;
  toggleFilterTools: (value: boolean) => void;
  onLocationChanged: (
    key: keyof Location,
    addressKey: keyof ShipmentLocationFilter,
    value: string[],
  ) => void;
  onEquipmentTypeChanged: (value: string[]) => void;
  onServiceTypeChanged: (value: string[]) => void;
  onShipmentStatusChanged: (value: string) => void;
  shipmentCount: number;
  refetchSearch: VoidFunction;
  updateIncludeCompanyShipments: (value: boolean) => void;
  updateIncludeBranchShipments: (value: boolean) => void;
  updateBranches: (value: string[]) => void;
  updateShippers: (value: string[]) => void;
}

export function ShipmentsFilter(props: Props) {
  const {
    title,
    filters,
    filterToolsAreVisible,
    toggleFilterTools,
    onLocationChanged,
    onEquipmentTypeChanged,
    onServiceTypeChanged,
    onShipmentStatusChanged,
    shipmentCount,
    refetchSearch,
    updateIncludeCompanyShipments,
    updateIncludeBranchShipments,
    updateBranches,
    updateShippers,
  } = props;
  const {
    hasBranchAdminRole,
    hasCompanyAdminRole,
  } = useRoleContext();

  console.log('has admin role: ', hasBranchAdminRole(), 'has company admin role', hasCompanyAdminRole());

  return (
    <Stack direction="column" margin={2}>
      <Stack direction="column" mb={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{`${title} (${shipmentCount ?? ''})`}</Typography>
          <RefreshPageButton
            id="dashboard-shipments-refresh"
            onRefresh={refetchSearch}
            iconButton
          />
        </Stack>
        <Divider />
        <Stack mt="1rem" direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <ShipmentSearchByName />
          <FilterShipmentByTypeDropdown />
          <Button
            variant="outlined"
            onClick={() => toggleFilterTools(!filterToolsAreVisible)}
          >
            {filterToolsAreVisible ? 'Hide filters' : 'More filters'}
          </Button>
        </Stack>

      </Stack>
      { filterToolsAreVisible && (
        <Stack
          direction="row"
          spacing={1}
          flexWrap="wrap"
          alignItems="center"
          sx={{ rowGap: '1rem' }}
        >
          <Box>
            <EquipmentTypeMultiSelect
              onChange={onEquipmentTypeChanged}
              defaultValue={filters.equipmentTypes}
              value={filters.equipmentTypes}
            />
          </Box>
          <Box>
            <ShipmentServiceMultiSelect
              label="Services"
              value={filters.services}
              onChange={(newValue) => onServiceTypeChanged(newValue)}
            />
          </Box>
          <Box>
            <ProvinceStateMultiSelect
              label="Origins"
              value={filters.origin.provinces}
              onChange={(value) => onLocationChanged('origin', 'provinces', value)}
            />
          </Box>
          <Box>
            <ProvinceStateMultiSelect
              label="Destinations"
              value={filters.destination.provinces}
              onChange={(value) => onLocationChanged('destination', 'provinces', value)}
            />
          </Box>
          <Box>
            <ShipmentStatusSelect
              defaultValue={filters.shipmentStatus}
              onChange={(newValue) => onShipmentStatusChanged(newValue)}
              showAllOption
            />
          </Box>
          <Box />
          {hasCompanyAdminRole() && (
          <IncludeCompanyShipmentsCheckbox
            value={filters.includeCompanyShipments}
            onChange={updateIncludeCompanyShipments}
          />
          )}
          {hasBranchAdminRole() && !hasCompanyAdminRole() && (
          <IncludeBranchShipmentsCheckbox
            value={filters.includeBranchShipments}
            onChange={updateIncludeBranchShipments}
          />
          )}
          {
            (hasBranchAdminRole() || hasCompanyAdminRole()) && (
              <BranchMultiSelect
                value={filters.branches}
                onChange={updateBranches}
              />
            )
          }
          {
            (hasCompanyAdminRole() && !hasBranchAdminRole()) && (
              <CompanyAdminShipperMultiSelect
                value={filters.shippers}
                onChange={updateShippers}
                selectedBranchIds={filters.branches}
              />
            )
          }
          {
            (hasBranchAdminRole() && !hasCompanyAdminRole()) && (
              <BranchAdminShipperMultiSelect
                value={filters.shippers}
                onChange={updateShippers}
                selectedBranchIds={filters.branches}
              />
            )
          }
        </Stack>
      )}
    </Stack>
  );
}
