import {
  CarrierMultiShipmentLoadsAssignedMetadata,
  CarrierProjectLostMetadata,
  CarrierShipmentMatchedMetadata,
  FollowShipperRequestAcceptedMetadata,
  FollowShipperRequestInternalMetadata,
  FollowedCarrierNewShipmentMetadata,
  PrivateShipmentCreatedNotificationMetadata,
  ShipmentBookedChatChannelMetadata,
  ShipperProjectCompletedMetadata,
} from './types';

// Remember to add new NotificationTypes to frontend/src/react-app-env.d.ts/NotificationType
const SUPPORTED_NOTIFICATIONS = {
  SHIPPER_NEW_QUOTE: (shipmentID: number) => `There's a new quote on shipment ${shipmentID}! Click to view details.`,
  SHIPPER_COUNTER_QUOTE_RECEIVED: (shipmentID: number) => `There is a counter-quote on shipment ${shipmentID}. Click to view details.`,
  SHIPPER_COUNTER_QUOTE_EXPIRED: (shipmentID: number) => `Your counter-quote on shipment ${shipmentID} has expired. Click to view other quotes.`,
  SHIPPER_QUESTION_ASKED: (shipmentID: number) => `You've received a question about shipment ${shipmentID}. Click to view and answer now.`,
  SHIPPER_CARRIER_CONTACT_INFO: (shipmentID: number) => `Congrats on booking shipment ${shipmentID}! You have been sent an email with the carrier's contact info.`,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CARRIER_COUNTER_QUOTE_RECEIVED: (shipmentID: number) => `The shipper countered your quote on shipment ${shipmentID}. Click to accept or counter their quote.`,
  CARRIER_QUOTE_EXPIRED: (shipmentID: number) => `Your quote on shipment ${shipmentID} has expired. Click to view details.`,
  CARRIER_SHIPMENT_CANCELLED: (shipmentID: number) => `Shipment ${shipmentID} has been cancelled by the shipper. Click to view details.`,
  CARRIER_QUESTION_ANSWERED: (shipmentID: number) => `Your question about shipment ${shipmentID} has been answered! Click to view the answer now.`,
  CARRIER_SHIPPER_CONTACT_INFO: (shipmentID: number) => `Congrats on booking shipment ${shipmentID}! You have been sent an email with the shipper's contact info.`,
  CARRIER_QUOTE_QUESTION_ASKED: (shipmentID: number) => `The shipper has asked a question about your quote on shipment ${shipmentID}. Click here to answer.`,
  SHIPPER_QUOTE_QUESTION_ANSWERED: (shipmentID: number) => `The carrier has answered your question on shipment ${shipmentID}. Click here to view.`,
  SHIPPER_SHIPMENT_TRACKING_LINK_ADDED: (shipmentID: number) => `The carrier has added a tracking link to shipment ${shipmentID}. Click here to view.`,
  SHIPPER_SHIPMENT_TRACKING_LINK_DELETED: (shipmentID: number) => `The carrier has deleted the tracking link from shipment ${shipmentID}. Click here to view the shipment.`,
  CARRIER_SHIPMENT_CANCELLATION_FEEDBACK_CREATED: (shipmentID: number) => `The shipper has left cancellation feedback on shipment ${shipmentID}. Click here to view.`,
  SHIPPER_SHIPMENT_SHARED: (shipmentID: number) => `Shipment ${shipmentID} has been shared with you. Click here to view the shipment.`,
  CARRIER_QUOTE_DECLINED: (shipmentID: number) => `The shipper has declined your quote on shipment ${shipmentID}. Click here to view the feedback.`,
  SHIPPER_QUOTE_DECLINED: (shipmentID: number) => `The carrier has declined your counter quote on shipment ${shipmentID}. Click here to view the feedback.`,
};

const CUSTOM_FORMATTED_NOTIFICATION_TYPES = {
  CARRIER_FOLLOW_SHIPPER_REQUESTED_INTERNAL: 'CARRIER_FOLLOW_SHIPPER_REQUESTED_INTERNAL',
  CARRIER_FOLOWED_SHIPPER_SHIPMENT_CREATED: 'CARRIER_FOLOWED_SHIPPER_SHIPMENT_CREATED',
  SHIPPER_FOLLOW_REQUEST_ACCEPTED: 'SHIPPER_FOLLOW_REQUEST_ACCEPTED',
  COMPANY_REVIEW_CREATED: 'COMPANY_REVIEW_CREATED',
  SHIPMENT_BOOKED_CHAT_CHANNEL_CREATED: 'SHIPMENT_BOOKED_CHAT_CHANNEL_CREATED',
  CARRIER_PRIVATE_SHIPMENT_INVITE: 'CARRIER_PRIVATE_SHIPMENT_INVITE',
  CARRIER_PRIVATE_SHPMENT_ALL_NETWORK_INVITE: 'CARRIER_PRIVATE_SHPMENT_ALL_NETWORK_INVITE',
  CARRIER_FAILED_TO_JOIN_NETWORK_ON_INSUFFICIENT_SLOTS: 'CARRIER_FAILED_TO_JOIN_NETWORK_ON_INSUFFICIENT_SLOTS',
  CARRIER_ESSENTIALS_REACHED_MAXIMUM_BOOKINGS: 'CARRIER_ESSENTIALS_REACHED_MAXIMUM_BOOKINGS',
  PAID_SUBSCRIPTION_CARRIER_REACHED_MAXIMUM_BOOKINGS: 'PAID_SUBSCRIPTION_CARRIER_REACHED_MAXIMUM_BOOKINGS',
  CARRIER_SHIPMENT_MATCHED: 'CARRIER_SHIPMENT_MATCHED',
  CARRIER_MULTI_SHIPMENT_PROJECT_LOADS_ASSIGNED: 'CARRIER_MULTI_SHIPMENT_PROJECT_LOADS_ASSIGNED',
  CARRIER_SHIPMENT_LOST: 'CARRIER_SHIPMENT_LOST',
  SHIPPER_PROJECT_COMPLETED: 'SHIPPER_PROJECT_COMPLETED',
};

function getFollowShipperRequestInternalText(meta: FollowShipperRequestInternalMetadata): string {
  return `${meta.first_name} ${meta.last_name} at ${meta.company_name} invites you to follow them to be notified about their shipments. Click to respond now.`;
}

function getFollowedCarrierNewShipmentText(metadata: FollowedCarrierNewShipmentMetadata): string {
  return `${metadata.first_name} ${metadata.last_name} at ${metadata.company_name} has posted a new ${metadata?.is_project ? 'project' : 'shipment'} ! Click to submit a quote now.`;
}

function
getShipperFollowRequestAcceptedText(metadata: FollowShipperRequestAcceptedMetadata): string {
  return `${metadata.first_name} ${metadata.last_name} at ${metadata.company_name} is now following you! Click to post a shipment now to start sending them notifications.`;
}

function
getShipmentBookedChatChannelCreated(metadata: ShipmentBookedChatChannelMetadata): string {
  return `Congrats on booking shipment ${metadata.shipment_id}! Click to chat with ${metadata.counterpart_first_name} from ${metadata.counterpart_company_name}.`;
}

function
getPrivateShipmentCreatedMetadata(metadata: PrivateShipmentCreatedNotificationMetadata): string {
  return `${metadata.company_name} just posted ${metadata?.is_project ? 'project' : 'shipment'} ${metadata.shipment_id} privately and they’d like you to quote it.  Click here to view the ${metadata?.is_project ? 'project' : 'shipment'} details.`;
}

function getCarrierFailedToJoinNetworkText(): string {
  return 'A shipper is inviting you to their private network.  Upgrade your account to accept the invite.';
}

function getCarrierShipmentMatchedText(metadata: CarrierShipmentMatchedMetadata) {
  return `One of your saved lanes has matched with ${metadata?.is_project ? 'project' : 'shipment'} ${metadata.shipment_id}. Click to view the ${metadata?.is_project ? 'project' : 'shipment'}  and start sending quotes.`;
}

function getProjectCompletedText(metadata: ShipperProjectCompletedMetadata) {
  return `Congrats! Project ${metadata.project_id} has been completed. Click to view the details.`;
}

function
getCarrierMultiShipmentProjectLoadsAssignedText(metadata:
CarrierMultiShipmentLoadsAssignedMetadata): string {
  return `Congrats! shipper ${metadata.shipper_company_name} has assigned you ${metadata.loads_assigned} shipments on project ${metadata.project_id} at $${metadata.price} ${metadata.currency} per load. Click to view the project.`;
}

function
getCarrierShipmentLost(metadata:CarrierProjectLostMetadata) {
  return `Your quote on ${metadata?.is_project ? 'project' : 'shipment'} ${metadata.shipment_id} was not selected and you lost the ${metadata?.is_project ? 'project' : 'shipment'}. Click to view the details.`;
}

function getCustomFormatNotificationDisplayText(type: NotificationType, metadata: unknown): string {
  try {
    if (type === CUSTOM_FORMATTED_NOTIFICATION_TYPES.SHIPPER_PROJECT_COMPLETED) {
      return getProjectCompletedText(metadata as ShipperProjectCompletedMetadata);
    }
    if (type === CUSTOM_FORMATTED_NOTIFICATION_TYPES.CARRIER_SHIPMENT_LOST) {
      return getCarrierShipmentLost(metadata as CarrierProjectLostMetadata);
    }
    if (type
      === CUSTOM_FORMATTED_NOTIFICATION_TYPES.CARRIER_MULTI_SHIPMENT_PROJECT_LOADS_ASSIGNED) {
      return getCarrierMultiShipmentProjectLoadsAssignedText(metadata as
         CarrierMultiShipmentLoadsAssignedMetadata);
    }
    if (type === CUSTOM_FORMATTED_NOTIFICATION_TYPES.CARRIER_SHIPMENT_MATCHED) {
      return getCarrierShipmentMatchedText(metadata as CarrierShipmentMatchedMetadata);
    }
    if (type === CUSTOM_FORMATTED_NOTIFICATION_TYPES.CARRIER_FOLLOW_SHIPPER_REQUESTED_INTERNAL) {
      return getFollowShipperRequestInternalText(metadata as FollowShipperRequestInternalMetadata);
    } if (type === CUSTOM_FORMATTED_NOTIFICATION_TYPES.CARRIER_FOLOWED_SHIPPER_SHIPMENT_CREATED) {
      return getFollowedCarrierNewShipmentText(metadata as FollowedCarrierNewShipmentMetadata);
    }
    if (type === CUSTOM_FORMATTED_NOTIFICATION_TYPES.SHIPPER_FOLLOW_REQUEST_ACCEPTED) {
      return getShipperFollowRequestAcceptedText(metadata as FollowShipperRequestAcceptedMetadata);
    }
    if (type === CUSTOM_FORMATTED_NOTIFICATION_TYPES.COMPANY_REVIEW_CREATED) {
      return 'A shipper has left you a review. Click to view.';
    }
    if (type === CUSTOM_FORMATTED_NOTIFICATION_TYPES.SHIPMENT_BOOKED_CHAT_CHANNEL_CREATED) {
      return getShipmentBookedChatChannelCreated(metadata as ShipmentBookedChatChannelMetadata);
    }
    if (type === CUSTOM_FORMATTED_NOTIFICATION_TYPES.CARRIER_PRIVATE_SHIPMENT_INVITE
      || type === CUSTOM_FORMATTED_NOTIFICATION_TYPES.CARRIER_PRIVATE_SHPMENT_ALL_NETWORK_INVITE) {
      return getPrivateShipmentCreatedMetadata(
        metadata as PrivateShipmentCreatedNotificationMetadata,
      );
    }
    if (
      // eslint-disable-next-line
      type === CUSTOM_FORMATTED_NOTIFICATION_TYPES.CARRIER_FAILED_TO_JOIN_NETWORK_ON_INSUFFICIENT_SLOTS
    ) {
      return getCarrierFailedToJoinNetworkText();
    }
    if (type === CUSTOM_FORMATTED_NOTIFICATION_TYPES.CARRIER_ESSENTIALS_REACHED_MAXIMUM_BOOKINGS) {
      return 'You have reached the end of your free trial bookings. Upgrade today to book more loads!';
    }
    if (
      type
      === CUSTOM_FORMATTED_NOTIFICATION_TYPES.PAID_SUBSCRIPTION_CARRIER_REACHED_MAXIMUM_BOOKINGS) {
      return 'You have reached the maximum number of bookings in your subscription. Upgrade to book more loads!';
    }
    return '-';
  } catch (e: unknown) {
    return '-';
  }
}

export function getNotificationMessage(type: NotificationType, metadata: unknown): string {
  if (type in SUPPORTED_NOTIFICATIONS) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return SUPPORTED_NOTIFICATIONS[type](metadata.shipment_id);
  }
  if (type in CUSTOM_FORMATTED_NOTIFICATION_TYPES) {
    return getCustomFormatNotificationDisplayText(type, metadata);
  }
  return '-';
}

export function notificationIsSupported(notificationType: NotificationType): boolean {
  return notificationType in SUPPORTED_NOTIFICATIONS
  || notificationType in CUSTOM_FORMATTED_NOTIFICATION_TYPES;
}
