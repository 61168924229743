import {
  TextField,
} from '@mui/material';
import { useInterval } from 'hooks';
import { useCallback, useState } from 'react';

interface Props {
  onShipmentNameChange: (value: string) => void;
  shipmentName: string;
}

export function QuoteFilterByShipmentNameTextField(props: Props) {
  const { onShipmentNameChange, shipmentName } = props;

  const [searchValue, setSearchValue] = useState<string>('');
  const [lastValueSearched, setLastValueSearched] = useState<string | null>(null);

  const search = useCallback(() => {
    if (searchValue === '' && shipmentName !== '') {
      onShipmentNameChange(searchValue);
    } else if (lastValueSearched !== searchValue) {
      onShipmentNameChange(searchValue);
      setLastValueSearched(searchValue);
    }
  }, [searchValue,
    lastValueSearched,
    setLastValueSearched,
    onShipmentNameChange,
    shipmentName]);

  useInterval(search, 3000);

  return (
    <TextField
      onChange={(e) => setSearchValue(e.target.value)}
      label="Shipment Ref/ID"
      value={searchValue}
    />
  );
}
