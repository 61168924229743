import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
} from '@mui/material';

interface Props {
  showPassword: boolean;
  onIconClick: () => void;
}

export function PasswordToggableIconEndAdornment(props: Props) {
  const { showPassword, onIconClick } = props;
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={onIconClick}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
}
