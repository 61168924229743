import { Box, Divider, Pagination } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompactShipmentPreview } from '../ShipmentPreview';

interface Props {
  shipments: ShipperCondensedShipment[];
  shipmentSelectionIsDisabled: boolean;
  handlePageSelected: (e: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  pages: number;
}

export function QuickShipmentsBrowser(props: Props) {
  const params = useParams();
  const shipmentId = params.id;
  const {
    shipments, shipmentSelectionIsDisabled, handlePageSelected, page, pages,
  } = props;
  const [selected, setSelected] = useState<number | undefined>(shipmentId
    ? +shipmentId : undefined);
  return (
    <Box boxShadow={1}>
      {shipments.map((s, i) => (
        <Box key={s.info.id}>
          {i > 0 && <Divider />}
          <CompactShipmentPreview
            shipment={s}
            selected={s.info.id === selected}
            select={setSelected}
            selectionIsDisabled={shipmentSelectionIsDisabled}
          />
        </Box>
      ))}
      <Divider />
      <Box my="1rem" display="flex" alignItems="center" justifyContent="center">
        <Pagination
          count={pages}
          onChange={handlePageSelected}
          page={page}
        />
      </Box>
    </Box>
  );
}
