import { ErrorTypography } from 'components/ErrorTypography';
import { useRoleContext } from 'hooks';
import { InputRenderConfig } from '../../types';
import { BranchAdminInputs } from './BranchAdminInputs';
import { CompanyAdminInputs } from './CompanyAdminInputs';
import { InputsContainer } from './InputsContainer';

interface Props {
  renderConfig: InputRenderConfig,
  handleBranchIdChange: (branchId: string) => void,
  handleShipperIdChange: (shipperId: string) => void,
  branchId: number | null | undefined;
  shipperRoleId: number | null | undefined;
  adminResetFilters: VoidFunction;
}

export function CompanyDashboardInputs(props: Props) {
  const {
    renderConfig,
    handleBranchIdChange,
    handleShipperIdChange,
    branchId,
    shipperRoleId,
    adminResetFilters,
  } = props;
  const { role, hasBranchAdminRole, hasCompanyAdminRole } = useRoleContext();

  if (renderConfig == null
    || branchId === undefined
    || shipperRoleId === undefined
    || role === null
    || renderConfig.access_type === 'SHIPPER') {
    return <div />;
  }

  let inputComponent = <div />;
  let containerTitle = '';
  if (renderConfig.access_type === 'COMPANY_ADMIN' && hasCompanyAdminRole()) {
    inputComponent = (
      <CompanyAdminInputs
        renderConfig={renderConfig}
        handleBranchIdChange={handleBranchIdChange}
        handleShipperIdChange={handleShipperIdChange}
        branchId={branchId}
        shipperRoleId={shipperRoleId}
        adminResetFilters={adminResetFilters}
      />
    );
    containerTitle = 'Filter Company Data';
  } else if (renderConfig.access_type === 'BRANCH_ADMIN' && hasBranchAdminRole()) {
    inputComponent = (
      <BranchAdminInputs
        renderConfig={renderConfig}
        handleBranchIdChange={handleBranchIdChange}
        handleShipperIdChange={handleShipperIdChange}
        branchId={branchId}
        shipperRoleId={shipperRoleId}
      />
    );
    containerTitle = 'Filter Branch Data';
  } else {
    return (
      <ErrorTypography error="Error. Please contact us." />
    );
  }

  return (
    <InputsContainer containerTitle={containerTitle}>
      {inputComponent}
    </InputsContainer>
  );
}
