import {
  LocalShippingOutlined, MeetingRoom, Notifications,
} from '@mui/icons-material';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import {
  Badge, Drawer, IconButton, Stack, Tooltip, Zoom,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChatNavBarButton } from 'components/ChatNavBarButton';
import { CompanyAdminDetailsButton } from 'components/CompanyAdminDetailsButton';
import { CompanyProfileButton } from 'components/CompanyProfileNavBarButton';
import { NetworkButton } from 'components/NetworkButton';
import { NotificationModal } from 'components/Notifcation/components/NotificationModal';
import { UserAccountButton } from 'components/UserAccountButton';
import { NewChatPollWrapper } from 'poll/NewChatPollWrapper';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userHasAdminRoleInCompany } from 'utils';
import { useAuthContext, useDisclosure, useRoleContext } from '../../hooks';
import { DataPageNavBarButton } from '../DataPageNavBarButton';

const drawerWidth = 100;

const DrawerHeader = styled('div')(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
}));

export function NavigationBar() {
  const navigate = useNavigate();
  const { signout, user } = useAuthContext();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [unreadNotificationAmount, setUnreadNotificationAmount] = useState<number>(0);
  const { hasBranchAdminRole, company, hasCompanyAdminRole } = useRoleContext();

  useEffect(() => {
    const newTitle = unreadNotificationAmount > 0 ? `Evotrux 2.0 (${unreadNotificationAmount})` : 'Evotrux 2.0';
    if (document.title !== newTitle) {
      document.title = newTitle;
    }
  }, [unreadNotificationAmount]);

  return (
    <>
      <Drawer
        variant="permanent"
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#0d2e45',
          },
        }}
      >
        <DrawerHeader>
          <a href="/">
            <img
              style={{
                height: '80px', width: '80px', paddingTop: '1rem', cursor: 'pointer',
              }}
              src="/images/e_logo.jpg"
              alt="badge logo"
              onClick={() => navigate('/')}
              role="presentation"
            />
          </a>
        </DrawerHeader>
        <Stack alignItems="center" color="white" py="2rem" spacing={1}>
          <Tooltip title="Dashboard" placement="right" TransitionComponent={Zoom}>
            <IconButton color="inherit" onClick={() => navigate('/')}>
              <LocalShippingOutlined fontSize="large" />
            </IconButton>
          </Tooltip>
          <DataPageNavBarButton />
          <Tooltip title="Notifications" placement="right" TransitionComponent={Zoom}>
            <IconButton color="inherit" onClick={onOpen}>
              {
                unreadNotificationAmount > 0 ? (
                  <Badge color="primary" badgeContent={`${unreadNotificationAmount}`} max={99}>
                    <Notifications fontSize="large" />
                  </Badge>
                ) : (
                  <Notifications fontSize="large" />
                )
              }
            </IconButton>
          </Tooltip>
          <NetworkButton />

          <NewChatPollWrapper>
            <ChatNavBarButton />
          </NewChatPollWrapper>

          {company != null && hasBranchAdminRole() && !hasCompanyAdminRole() && (
          <Tooltip title="Company Branches" placement="right" TransitionComponent={Zoom}>
            <IconButton color="inherit" onClick={() => navigate(`/companies/${company.id}/branches`)}>
              <PeopleOutlineIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          )}

          <UserAccountButton />
          { user != null && userHasAdminRoleInCompany(user)
            ? <CompanyAdminDetailsButton />
            : null}
          {}
          <CompanyProfileButton />
          <Tooltip title="Log out" placement="right" TransitionComponent={Zoom}>
            <IconButton color="inherit" onClick={signout}>
              <MeetingRoom fontSize="large" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Drawer>
      <NotificationModal
        open={isOpen}
        onClose={onClose}
        onUnreadNotificationAmountChanged={(amount: number) => setUnreadNotificationAmount(amount)}
      />
    </>
  );
}
