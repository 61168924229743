import { CheckShipperCompanyExists } from '../CheckShipperCompanyExists';
import { CreateShipperCompanyForm } from '../CreateShipperCompanyForm';
import { CheckShipperCompanyDetails } from '../types';

interface Props {
  handleSearchCompanySelected: () => void;
  onCompanyDoesNotAlreadyExist: (companyInfo: CheckShipperCompanyDetails) => void;
  companyExists: boolean;
  companyData: CheckShipperCompanyDetails;
  handleResetCompanyData: () => void;
}

export function CreateShipperCompany(props: Props) {
  const {
    handleSearchCompanySelected,
    companyExists,
    onCompanyDoesNotAlreadyExist,
    companyData,
    handleResetCompanyData,
  } = props;

  if (companyExists) {
    return (
      <CheckShipperCompanyExists
        handleSearchCompanySelected={handleSearchCompanySelected}
        onCompanyDoesNotAlreadyExist={onCompanyDoesNotAlreadyExist}
      />
    );
  }
  return (
    <CreateShipperCompanyForm
      companyData={companyData}
      handleSearchCompanySelected={handleSearchCompanySelected}
      handleResetCompanyData={handleResetCompanyData}
    />
  );
}
