import { Button, Typography, Stack } from '@mui/material';

interface Props {
  onResendVerify: VoidFunction;
  error: boolean;
  messageResent: boolean
}

export function SignUpSuccess(props: Props) {
  const { onResendVerify, error, messageResent } = props;
  return (
    <Stack alignItems="center">
      <Typography variant="h6">
        <b>Account Created Successfully!</b>
      </Typography>
      <Typography>
        Check your email and open the link to verify your account.
      </Typography>
      <Stack my="2rem" direction="row" alignItems="baseline">
        <Typography mr="1rem">
          Didn&apos;t receive the verification email?
        </Typography>
        <Button variant="contained" onClick={onResendVerify} disabled={messageResent}>Resend Email</Button>
      </Stack>
      {error && (
        <Typography my="1rem" color="red">
          An error occured while resending the verification email. Please contact support.
        </Typography>
      )}
    </Stack>
  );
}
