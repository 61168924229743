import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import {
  IconButton,
  Tooltip, Zoom,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useUserChatInfo, useWriterComponentState } from 'hooks';
import { useEffect, useState } from 'react';
import { channelExistsInChatInfo, getGroupChannelForChannel } from 'utils';

interface Props {
  currentChannel: string;
}

export function MuteChatButton(props: Props) {
  const { currentChannel } = props;
  const { chatInfo, loading: loadingChatInfo, refreshChatInfo } = useUserChatInfo();
  const [notificationsEnabled, setNotificationsEnabled] = useState<boolean | null>(null);
  const {
    write, error, loading,
  } = useWriterComponentState({
    errorMessage: 'Failed to mute chat. Please contact us directly using the chat bubble.',
    writeConfig: {
      method: 'patch',
      url: '/user/channels/update',
      body: {
        channel_name: currentChannel,
        notifications_enabled: !notificationsEnabled,
      },
    },
  });

  useEffect(() => {
    if (chatInfo != null
      && channelExistsInChatInfo(chatInfo, currentChannel)
      && getGroupChannelForChannel(
        currentChannel,
        chatInfo.channels,
      ).notifications_enabled !== notificationsEnabled) {
      const groupChannel = getGroupChannelForChannel(currentChannel, chatInfo.channels);
      setNotificationsEnabled(groupChannel.notifications_enabled);
    }
  }, [chatInfo, setNotificationsEnabled, currentChannel, notificationsEnabled]);

  if (chatInfo == null || !channelExistsInChatInfo(chatInfo, currentChannel)) {
    return <div />;
  }

  const onUpdateSuccess = () => {
    refreshChatInfo();
  };

  const onMuteChat = () => {
    write(onUpdateSuccess);
  };

  return (
    <LoadingWrappedNode loading={loading || loadingChatInfo}>
      <Tooltip title={notificationsEnabled ? 'Click to mute unread message email reminders.' : 'Click to enable unread message email reminders.'} placement="right" TransitionComponent={Zoom}>
        <IconButton onClick={onMuteChat}>
          <NotificationsOffIcon fontSize="large" color={notificationsEnabled ? undefined : 'error'} />
        </IconButton>
      </Tooltip>

      <ErrorTypography error={error} />
    </LoadingWrappedNode>
  );
}
