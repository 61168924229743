import { MessageEnvelope, isFilePayload } from '@pubnub/react-chat-components';

export interface TextMessageFile {
  fileUrl: string;
  fileName: string;
}

export function userIdIsMessageAuthor(userId: number, message: MessageEnvelope) {
  if (isFilePayload(message.message)) {
    return message.message.message?.sender?.id === `user.${userId}`;
  }
  return message?.message?.sender?.id === `user.${userId}`;
}

export function textMessageContainsFile(message: MessageEnvelope) {
  /*
  File Messages initially received "live" count as non File payloads
  but they may contain files *shrugs*
  File messages received from history are File payloads - see display in FileMessage.tsx
  */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return message?.file?.url !== undefined && message?.file?.name !== undefined;
}

export function getFileTextMessageFilenameAndURL(message: MessageEnvelope): TextMessageFile {
  let fileUrl = null;
  let fileName = null;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (message?.file?.url) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
    fileUrl = message?.file?.url;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fileName = message?.file?.name;
  }
  return { fileUrl, fileName };
}
