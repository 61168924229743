export default [
  {
    label: 'United States',
    value: 'US',
    code: 'US',
  },
  {
    label: 'Canada',
    value: 'CA',
    code: 'CA',
  },
];
