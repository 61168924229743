import {
  Box,
} from '@mui/material';
import { Select } from '../Select';

interface Props {
  onChange: (value: string) => void;
  defaultValue?: string;
  disabled?: boolean
}

export function CurrencySelect(props: Props) {
  const { onChange, defaultValue, disabled } = props;
  return (
    <Box mr="1rem" mt="1rem">
      <Select<string>
        inputLabel="Currency"
        disabled={disabled}
        defaultValue={defaultValue !== undefined ? defaultValue : ''}
        value={defaultValue !== undefined ? defaultValue : ''}
        onChange={onChange}
        options={[
          {
            value: 'CAD',
            label: 'CAD',
          },
          {
            value: 'USD',
            label: 'USD',
          },
        ]}
      />
    </Box>
  );
}

CurrencySelect.defaultProps = {
  defaultValue: '',
  disabled: false,
};
