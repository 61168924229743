import {
  CircularProgress, Divider, Stack, Typography,
} from '@mui/material';
import { DashboardItemLayout } from 'layouts';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode | null;
  loading: boolean;
  error: string | null;
  reviewCount: null | number;
}

export function CompanyReviewList(props: Props) {
  const {
    loading, error, children, reviewCount,
  } = props;
  if (loading) {
    return (
      <Stack>
        <CircularProgress />
      </Stack>
    );
  }

  if (error) {
    return (
      <Stack>
        <Typography color="red" align="center">{error}</Typography>
      </Stack>
    );
  }

  if (reviewCount == null || reviewCount === 0) {
    return (
      <Stack alignItems="center">
        <Typography variant="body1" color="text.secondary">No reviews created yet.</Typography>
      </Stack>
    );
  }

  return (
    <DashboardItemLayout>
      <Divider />
      {children}
    </DashboardItemLayout>
  );
}
