import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Typography } from '@mui/material';
import { IconText } from './IconText';

interface Props {
  shipment: CondensedShipment
}

export function ShipmentIDText(props: Props) {
  const { shipment } = props;
  const shipmentId = shipment?.info.id || '';
  return (
    <IconText
      icon={<LocalShippingIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
      text={(
        <Typography>
          {shipment.info.type === 'MULTI_SHIPMENT_PROJECT'
            ? `Project ID: ${shipmentId}` : `Shipment ID: ${shipmentId}`}
        </Typography>
)}
    />
  );
}
