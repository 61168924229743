import {
  Alert,
  Button,
} from '@mui/material';
import {
  useDisclosure,
} from 'hooks';
import { AddShipmentTrackingLinkModal } from '../AddShipmentTrackingLinkModal';

interface Props {
  dividerComponent: JSX.Element;
  trackingLinkInfo: ShipmentTrackingLinkInfo;
}

export function AddShipmentTrackingLinkForm(props: Props) {
  const { dividerComponent, trackingLinkInfo } = props;
  const { isOpen, onOpen, onClose } = useDisclosure(false);

  return (
    <>
      {dividerComponent}
      {trackingLinkInfo.link_expected
        && trackingLinkInfo.link_due
        && <Alert severity="warning">Pick-up date for shipment date has arrived. Shipper is expecting a tracking link.</Alert>}
      <Button
        variant="contained"
        color="info"
        onClick={onOpen}
        sx={{ m: '1rem' }}
      >
        Add Tracking Link
      </Button>
      <AddShipmentTrackingLinkModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
