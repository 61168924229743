import {
  Chip,
  Tooltip,
  Typography,
} from '@mui/material';

interface Props {
  isMultiShipmentChildShipment: boolean;
}

export function MultiShipmentChildShipmentChip(props: Props) {
  const { isMultiShipmentChildShipment } = props;

  if (!isMultiShipmentChildShipment) {
    return (
      <div />
    );
  }

  return (
    <Tooltip title={
      <Typography variant="h5">This shipment was created from a multi-shipment project booking.</Typography>
}
    >

      <Chip
        label="Project Booking"
        color="info"
        variant="outlined"
        size="small"
      />
    </Tooltip>

  );
}
