import { Stack, Typography } from '@mui/material';
import { useShipperShipmentPageContext } from 'hooks';
import { ISOStringToLocalReadableTimestamp } from 'utils';

interface Props {
  dividerComponent: JSX.Element;
}

export function ShipmentCancellationSection(props: Props) {
  const { dividerComponent } = props;
  const { shipment } = useShipperShipmentPageContext();

  // scenarios where no component is rendered.
  if (!shipment || shipment.shipment_status !== 'CANCELLED') {
    return <div />;
  }

  if (shipment.cancellation_info == null) {
    return (
      <>
        {dividerComponent}
        <Typography p="1rem" variant="body1" color="text.secondary">No cancellation reason provided.</Typography>
      </>
    );
  }
  return (
    <>
      {dividerComponent}
      <Stack direction="column">
        <Typography variant="h6" sx={{ mt: '1rem' }}>
          <b>{'Reason: '}</b>
          {shipment.cancellation_info.feedback}
        </Typography>
        <Typography variant="h6">
          <b>{'Visibility: '}</b>
          {shipment.cancellation_info.is_public ? 'Public' : 'Private'}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
        >
          {`Added ${ISOStringToLocalReadableTimestamp(shipment.cancellation_info.created_at)}`}
        </Typography>
      </Stack>
    </>
  );
}
