import {
  Box, CircularProgress, Divider,
  Pagination,
  Stack, Typography,
} from '@mui/material';
import { QuotePreview } from '../../../QuotePreview';

interface Props {
  quotes: CondensedQuote[] | null;
  loading: boolean;
  error: string | null;
  currentPage: number;
  pageCount: number;
  onPageSelected(newPageNumber: number): void;
}

export function CarrierQuoteList(props: Props) {
  const {
    quotes,
    loading,
    error,
    currentPage,
    pageCount,
    onPageSelected,
  } = props;

  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  if (error != null) {
    return (
      <Stack>
        <Typography color="red" align="center">{error}</Typography>
      </Stack>
    );
  }
  if (quotes === null) {
    return (<div />);
  }
  if (quotes.length === 0) {
    return (
      <Typography sx={{ ml: '2rem', mb: '2rem' }} variant="body1" textAlign="center">
        You do not have any quotes.
      </Typography>
    );
  }

  return (
    <Stack direction="column" justifyContent="center">
      {quotes.map((quote, i) => (
        <Box key={quote.quote_action}>
          <Divider />
          <QuotePreview quote={quote} />
          {i === quotes.length - 1 && <Divider />}
        </Box>
      ))}
      <Box my="1rem" display="flex" alignItems="center" justifyContent="center">
        <Pagination
          count={pageCount}
          onChange={(e: React.ChangeEvent<unknown>, value: number) => onPageSelected(value)}
          page={currentPage}
        />
      </Box>
    </Stack>
  );
}
