import { Box, Stack, Typography } from '@mui/material';
import { useCarrierCompanyBookingLimits, useRoleContext } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { CARRIER_UPGRADE_RELATIVE_URL } from '../../../../constants';

export function CarrierEssentialsBookingRemainingBannerText() {
  const { company } = useRoleContext();
  const { limits } = useCarrierCompanyBookingLimits();
  const navigate = useNavigate();

  if (limits == null) {
    return <div />;
  }

  const getBookingsRemainingLabel = () => {
    if (limits.remainingBookings === 1) {
      return (
        <Typography variant="h6" color="white">
          {company?.name ? `${company.name} has ` : 'You have '}
          <span style={{ color: 'yellow' }}>
            1
          </span>
          {' non-partner booking left - Partner bookings are always free!'}
        </Typography>
      );
    } if (limits.remainingBookings === 0 || limits.remainingBookings < 0) {
      return (
        <Typography variant="h6" color="white">
          {company?.name ? `${company.name} has ` : 'You have '}
          <span style={{ color: 'red' }}>
            0
          </span>
          {' non-partner bookings left - Partner bookings are always free!'}
        </Typography>
      );
    }
    return (
      <Typography variant="h6" color="white">
        {company?.name ? `${company.name} has ${limits.remainingBookings} non-partner bookings left - Partner bookings are always free!`
          : `You have ${limits.remainingBookings} non-partner bookings left - Partner bookings are always free!`}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={() => navigate(CARRIER_UPGRADE_RELATIVE_URL)}
    >
      <Stack direction="column" alignItems="center">
        <Typography variant="h6" color="white">{getBookingsRemainingLabel()}</Typography>
        <Typography variant="h6" color="yellow">
          <u>Upgrade to Premium or Pay As You Go</u>
        </Typography>
      </Stack>
    </Box>

  );
}
