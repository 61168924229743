import { Box } from '@mui/material';
import {
  useFetchingComponentState, useRoleContext, useSubscriptionStatus,
} from 'hooks';
import { useEffect, useState } from 'react';

interface Props {
  enableWithSubscriptionPlan: SubscriptionPlan;
  noPaymentMethodComponent: React.ReactNode;
  children: React.ReactNode;
}

export function PaymentMethodRequiredWrapper(props: Props): JSX.Element {
  const { enableWithSubscriptionPlan, children, noPaymentMethodComponent } = props;
  const { subscription } = useSubscriptionStatus();
  const { company } = useRoleContext();
  const [hasPaymentMethod, setHasPaymentMethod] = useState<boolean | null>(null);
  const {
    loading: loadingHasPayment,
    error: hasPaymentError,
    fetch: fetchCompanyHasPaymentMethod,
  } = useFetchingComponentState<HasPaymentMethodResponse>({
    errorMessage: 'Failed to check payment method availability. Please contact us if the issue persists.',
    fetchConfig: {
      url: company != null && company.id != null ? `/stripe/companies/${company.id}/has-payment-method` : '/',
    },
  });

  useEffect(() => {
    if (subscription?.subscription_plan === enableWithSubscriptionPlan
      && hasPaymentMethod == null) {
      fetchCompanyHasPaymentMethod((res) => {
        setHasPaymentMethod(res.has_payment_method);
      });
    }
  }, [subscription, hasPaymentMethod,
    fetchCompanyHasPaymentMethod, setHasPaymentMethod, enableWithSubscriptionPlan]);

  if (subscription?.subscription_plan === enableWithSubscriptionPlan
    && !hasPaymentMethod && hasPaymentError == null && !loadingHasPayment) {
    return (
      <Box>
        {noPaymentMethodComponent}
      </Box>
    );
  }
  return (
    <Box>
      {children}
    </Box>
  );
}
