import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button, Stack, TableCell, TableRow, Typography,
  useMediaQuery,
} from '@mui/material';

interface ResultsTableRowProps {
  company: Company;
  onCompanySelected: (company: Company) => void;
}

export function CompanyResultsTableRow(props: ResultsTableRowProps) {
  const { company, onCompanySelected } = props;
  const isMobileLayout = useMediaQuery('(max-width:500px)');
  let companyAddress = '';
  if (company.address) {
    if (company.address[0]?.city) {
      companyAddress += `${company.address[0]?.city}, `;
    }
    if (company.address[0]?.province) {
      companyAddress += `${company.address[0]?.province}, `;
    }
    if (company.address[0]?.country) {
      companyAddress += `${company.address[0]?.country}`;
    }
  }
  return (
    <TableRow
      key={company.name}
    >
      <TableCell component="th" scope="row">
        <Stack
          direction={isMobileLayout ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Box mr="1rem">
            <Stack direction="column">
              <Typography variant="h5">{company.name}</Typography>
              <Typography variant="body1">{companyAddress}</Typography>
            </Stack>
          </Box>
          <Box mt={isMobileLayout ? '1.5rem' : undefined}>
            <Button variant="outlined" onClick={() => onCompanySelected(company)} endIcon={<SendIcon />}>
              Send join request
            </Button>
          </Box>
        </Stack>
      </TableCell>
    </TableRow>
  );
}
