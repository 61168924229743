import { Select } from '../Select';

interface Props {
  onChange: (value: string) => void;
  showAllOption?: boolean;
  defaultValue?: string;
}

const DEFAULT_OPTIONS = [
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'CANCELLED',
    label: 'Cancelled',
  },
  {
    value: 'EXPIRED',
    label: 'Expired',
  },
  {
    value: 'BOOKED',
    label: 'Booked',
  },
];
const ALL_OPTION = [
  {
    value: 'ALL',
    label: 'All',
  },
];

export function ShipmentStatusSelect(props: Props) {
  const { onChange, showAllOption, defaultValue } = props;
  const shipmentStatusOptions = showAllOption
    ? ALL_OPTION.concat(DEFAULT_OPTIONS)
    : DEFAULT_OPTIONS;

  return (
    <Select<string>
      inputLabel="Shipment Status"
      defaultValue={defaultValue || ''}
      onChange={onChange}
      options={shipmentStatusOptions}
    />
  );
}

ShipmentStatusSelect.defaultProps = {
  showAllOption: false,
  defaultValue: '',
};
