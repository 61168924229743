import {
  Chip, Tooltip, Typography,
} from '@mui/material';

interface Props {
  shipment: ShipperCondensedShipment
  size?: 'small' | 'medium' | undefined
}

export function SharedShipmentChip(props: Props) {
  const { shipment, size } = props;

  if (shipment.access_type !== 'SHAREE_READ_AND_WRITE') {
    return <div />;
  }

  return (
    <Tooltip title={
      <Typography variant="h5">This shipment was shared with you by the owner.</Typography>
}
    >
      <Chip
        variant="outlined"
        size={size}
        label={(
          <div className="quotes">
            SHARED
          </div>
  )}
        color="info"
      />
    </Tooltip>
  );
}

SharedShipmentChip.defaultProps = {
  size: undefined,
};
