import {
  Chip,
} from '@mui/material';

interface Props {
  isPrivate: boolean;
  isProject: boolean;
}

export function PrivateShipmentChip(props: Props) {
  const { isPrivate, isProject } = props;

  if (!isPrivate) {
    return (
      <div />
    );
  }

  return (
    <Chip
      label={isProject ? 'PRIVATE' : 'PRIVATE SHIPMENT'}
      color="success"
      variant="outlined"
      size="small"
    />
  );
}
