import CreateIcon from '@mui/icons-material/Create';
import { Button } from '@mui/material';
import { CreateShipmentTemplateModal } from '../CreateShipmentTemplateModal';

interface Props {
  loading: boolean;
  modalOpen: boolean;
  onModalClose: VoidFunction;
  onModalOpen: VoidFunction;
  onSubmit: VoidFunction;
  templateName: string;
  setTemplateName: (newValue: string) => void;
  error: string | null;
  success: boolean;
}

export function CreateShipmentTemplateButton(props: Props) {
  const {
    loading,
    modalOpen,
    onModalClose,
    onModalOpen,
    onSubmit,
    templateName,
    setTemplateName,
    error,
    success,
  } = props;
  return (
    <>
      <CreateShipmentTemplateModal
        modalOpen={modalOpen}
        error={error}
        setTemplateName={setTemplateName}
        templateName={templateName}
        onSubmit={onSubmit}
        loading={loading}
        success={success}
        onModalClose={onModalClose}
      />
      <Button variant="outlined" size="large" onClick={onModalOpen}>
        <CreateIcon />
        Create Template
      </Button>
    </>
  );
}
