import {
  AccountCircle, ConnectWithoutContact, Group, LocalShippingOutlined, Notifications,
} from '@mui/icons-material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import {
  Drawer, IconButton, Stack, Tooltip, Zoom,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CompanyProfileButton } from 'components/CompanyProfileNavBarButton';

const drawerWidth = 100;

const DrawerHeader = styled('div')(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
}));

export function FakeNavigationBar() {
  return (
    <Drawer
      variant="permanent"
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#0d2e45',
        },
      }}
    >
      <DrawerHeader>
        <img
          style={{
            height: '80px', width: '80px', paddingTop: '1rem', cursor: 'pointer',
          }}
          src="/images/e_logo.jpg"
          alt="badge logo"
          role="presentation"
        />
      </DrawerHeader>
      <Stack alignItems="center" color="white" py="2rem" spacing={1}>
        <Tooltip title="Dashboard" placement="right" TransitionComponent={Zoom}>
          <IconButton color="inherit" onClick={() => {}}>
            <LocalShippingOutlined fontSize="large" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Notifications" placement="right" TransitionComponent={Zoom}>
          <IconButton color="inherit" onClick={() => {}}>
            <Notifications fontSize="large" />

          </IconButton>
        </Tooltip>
        <Tooltip title="Network" placement="right" TransitionComponent={Zoom}>
          <IconButton color="inherit" onClick={() => {}}>
            <ConnectWithoutContact fontSize="large" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Chat" placement="right" TransitionComponent={Zoom}>
          <IconButton color="inherit" onClick={() => {}}>
            <ChatBubbleIcon fontSize="large" />
          </IconButton>

        </Tooltip>

        <Tooltip title="Account" placement="right" TransitionComponent={Zoom}>
          <IconButton color="inherit" onClick={() => {}}>
            <AccountCircle fontSize="large" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Company Details" placement="right" TransitionComponent={Zoom}>
          <IconButton color="inherit" onClick={() => {}}>
            <Group fontSize="large" />
          </IconButton>
        </Tooltip>

        <CompanyProfileButton />
      </Stack>
    </Drawer>
  );
}
