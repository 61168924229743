export function
getCommdityNameForCondensedShipmentShipment(shipment: CondensedShipmentShipment): string {
  if (shipment?.commodity?.name) {
    return shipment.commodity.name;
  } if (shipment?.commodity_freetext && shipment?.commodity_freetext !== '') {
    return shipment.commodity_freetext;
  }
  return '';
}

export function shipmentIsBooked(shipment: CondensedShipment) {
  return shipment.info.booked_at != null;
}

export function shipmentIsActive(shipment: CondensedShipment) {
  return shipment.info.booked_at == null
  && shipment.info.cancelled_at == null
  && shipment.info.expired === false;
}
