import {
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { CurrencySelect } from 'components/CurrencySelect';
import { NumberInput } from 'components/NumberInput';
import { useSubscriptionStatus } from 'hooks';
import { ShipperUpgradeNowButton } from '../ShipperUpgradeNowButton';

interface Props {
  currency: string;
  price: string;
  isBookNow: boolean;
  setCurrency: (newValue: string) => void;
  setPrice: (newPrice: string) => void;
  setIsBookNow: (newValue: boolean) => void;
}
const dividerStyle = { my: 4 };

export function BookNowMenu(props: Props) {
  const {
    currency,
    price,
    isBookNow,
    setCurrency,
    setPrice,
    setIsBookNow,
  } = props;

  const { subscription } = useSubscriptionStatus();

  if (subscription == null) {
    return <div />;
  }

  const isShipperFreeTier = subscription.subscription_plan === 'SHIPPER_FREE_TIER' || subscription.subscription_plan === 'SHIPPER_SHIP_A'
  || subscription.subscription_plan === 'SHIPPER_SHIP_B' || subscription.subscription_plan === 'SHIPPER_SHIP_C';

  const handleNetworkNotificationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsBookNow((event.target as HTMLInputElement).value === 'true' as
      any);
  };
  return (
    <>
      <Divider sx={dividerStyle} />
      <Stack direction="column">
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6">Add a Book Now price</Typography>
          {isShipperFreeTier && (<ShipperUpgradeNowButton buttonSize="small" />)}
        </Stack>
        <Typography variant="body1" color="text.secondary">
          Your partners will be able to instantly book the load at this price.
        </Typography>
        <FormControl>
          <RadioGroup
            value={isBookNow}
            onChange={handleNetworkNotificationChange}
          >
            <Stack direction="row" mb="1rem" spacing={2}>
              <FormControlLabel value control={<Radio disabled={isShipperFreeTier} />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </Stack>
          </RadioGroup>
        </FormControl>
        <Stack direction="row" spacing={2}>
          <NumberInput
            inputLabel="Price"
            defaultValue={price}
            onChange={setPrice}
            disabled={!isBookNow}
          />
          <CurrencySelect
            defaultValue={currency ?? 'CAD'}
            onChange={setCurrency}
            disabled={!isBookNow}
          />
        </Stack>
      </Stack>
    </>
  );
}
