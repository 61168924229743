import { Typography } from '@mui/material';
import { ShipmentCancellationReasonDisplay } from 'components/ShipmentCancellationReasonDisplay';
import { useCarrierShipmentPageContext } from 'hooks';

interface Props {
  dividerComponent: JSX.Element;
}

export function ShipmentCancellationSection(props: Props) {
  const { dividerComponent } = props;
  const { shipment } = useCarrierShipmentPageContext();

  // scenarios where no component is rendered.
  if (!shipment || shipment.shipment_status !== 'CANCELLED') {
    return <div />;
  }

  if (shipment.cancellation_info == null || !shipment?.cancellation_info.is_public) {
    return (
      <>
        {dividerComponent}
        <Typography p="1rem" variant="body1" color="text.secondary">No cancellation reason provided by shipper.</Typography>
      </>
    );
  }
  return (
    <>
      {dividerComponent}
      <ShipmentCancellationReasonDisplay cancellationInfo={shipment.cancellation_info} />
    </>
  );
}
