import {
  MessageEnvelope,
} from '@pubnub/react-chat-components';
import { usePubNub } from 'pubnub-react';
import { useEffect } from 'react';

interface Props {
  onNewChannelMessage: (message: MessageEnvelope) => void;
  onNewFileMessage: (message: MessageEnvelope) => void;
  channels: string[];
}

export function usePubNubChannelListener(props: Props) {
  const { onNewChannelMessage, channels, onNewFileMessage } = props;
  const pubnubClient = usePubNub();

  useEffect(() => {
    const listener = {
      message: (message: MessageEnvelope) => {
        onNewChannelMessage(message);
      },
      file: (message: MessageEnvelope) => {
        onNewFileMessage(message);
      },
    };
    pubnubClient.addListener(listener);
    pubnubClient.subscribe({ channels });

    return () => {
      pubnubClient.removeListener(listener);
      pubnubClient.unsubscribeAll();
    };
  }, [onNewChannelMessage,
    pubnubClient,
    channels,
    onNewFileMessage]);
}
