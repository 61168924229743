import {
  Alert, Button, ButtonGroup, Snackbar, Stack,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { useDisclosure } from 'hooks';

interface Props {
  value: QuoteSortType;
  onChange: (value: QuoteSortType) => void;
}

export function QuoteSortMenu(props: Props) {
  const { value, onChange: OnMenuOptionSelected } = props;

  const { isOpen, onClose, onOpen } = useDisclosure(false);

  const onChange = (selection: QuoteSortType) => {
    onOpen();
    OnMenuOptionSelected(selection);
  };

  return (
    <Stack p=".2rem" alignItems="center">
      <ButtonGroup>
        <Button onClick={() => onChange('ALL')} variant={value === 'ALL' ? 'contained' : 'outlined'}>All</Button>
        <Button onClick={() => onChange('LATEST')} variant={value === 'LATEST' ? 'contained' : 'outlined'}>Newest</Button>
        <Button onClick={() => onChange('OLDEST')} variant={value === 'OLDEST' ? 'contained' : 'outlined'}>Oldest</Button>
        <Button onClick={() => onChange('LOWEST_AMOUNT')} variant={value === 'LOWEST_AMOUNT' ? 'contained' : 'outlined'}>Lowest Price</Button>
        <Button onClick={() => onChange('HIGHEST_AMOUNT')} variant={value === 'HIGHEST_AMOUNT' ? 'contained' : 'outlined'}>Highest Price</Button>
      </ButtonGroup>
      <Snackbar
        id={value}
        open={isOpen}
        autoHideDuration={1000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Slide}
      >
        <Alert onClose={onClose} severity="success" sx={{ width: '100%' }}>
          Sorting Criteria Applied!
        </Alert>
      </Snackbar>
    </Stack>
  );
}
