import { Typography } from '@mui/material';
import { ShipmentFiltersProvider } from 'context';
import { useAuthContext, useRoleContext } from '../../hooks';
import { Shipment as CarrierShipment } from '../Carrier/components/Shipment';
import { Shipment as ShipperShipment } from '../Shipper/components/Shipment';

export function ShipmentContainer() {
  const { user, isLoading } = useAuthContext();
  const { role } = useRoleContext();

  if (isLoading || user == null || role == null) {
    return <div />;
  }

  if (role.type === 'SHIPPER') {
    return <ShipperShipment />;
  }

  if (role.type === 'CARRIER') {
    return <CarrierShipment />;
  }

  return <Typography variant="h1">Invalid role. Please contact us for assistance.</Typography>;
}

export function WrappedShipmentContainer() {
  return (
    <ShipmentFiltersProvider>
      <ShipmentContainer />
    </ShipmentFiltersProvider>
  );
}
