import {
  FormControl, InputLabel, OutlinedInput, Tooltip, Typography,
} from '@mui/material';

interface Props {
  defaultValue: number | string;
  inputLabel: string;
  onChange: (value: string) => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  tooltip?: string;
  disabled?: boolean;
}

export function NumberInput(props: Props) {
  const {
    startAdornment,
    endAdornment,
    defaultValue,
    inputLabel,
    onChange,
    tooltip,
    disabled,
  } = props;

  const onValueChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <FormControl>
      <InputLabel htmlFor={`number-input-${inputLabel}`}>{inputLabel}</InputLabel>
      <Tooltip title={tooltip === null ? '' : <Typography>{tooltip}</Typography>} arrow placement="right">
        <OutlinedInput
          id={`number-input-${inputLabel}`}
          value={defaultValue}
          onChange={(e) => onValueChange(e)}
          startAdornment={startAdornment ?? null}
          endAdornment={endAdornment ?? null}
          label={inputLabel}
          disabled={disabled}
        />
      </Tooltip>
    </FormControl>
  );
}

NumberInput.defaultProps = {
  startAdornment: null,
  endAdornment: null,
  tooltip: null,
  disabled: false,
};
