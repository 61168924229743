import { MessageEnvelope } from '@pubnub/react-chat-components';
import { getFileTextMessageFilenameAndURL, textMessageContainsFile } from '../../utils';
import { FileMessageDisplay } from '../FileMessageDisplay';

interface Props {
  message: MessageEnvelope;
  userIsAuthor: boolean;
}
export function TextMessageFileDisplay(props: Props) {
  const { message, userIsAuthor } = props;
  if (!textMessageContainsFile(message)) {
    return <div />;
  }

  const file = getFileTextMessageFilenameAndURL(message);
  return (
    <FileMessageDisplay
      userIsAuthor={userIsAuthor}
      fileName={file.fileName}
      fileURL={file.fileUrl}
    />
  );
}
