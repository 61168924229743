import {
  Box, Divider, Modal, Typography,
} from '@mui/material';

interface Props {
  form: React.ReactNode;
  open: boolean;
  onClose: VoidFunction;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  p: 4,
};

export function RoutesFormModal(props: Props) {
  const { open, onClose, form } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">Create Route Stop</Typography>
        <Divider sx={{ my: '1rem' }} />
        {form}
      </Box>
    </Modal>
  );
}
