import { Network as CarrierNetwork } from 'components/Carrier/components/Network';
import { Network as ShipperNetwork } from 'components/Shipper/components/Network';
import { useRoleContext } from 'hooks';

export function Network() {
  const { role } = useRoleContext();

  if (role && role.type === 'CARRIER') {
    return <CarrierNetwork />;
  } if (role && role.type === 'SHIPPER') {
    return <ShipperNetwork />;
  }
  return <div />;
}
