import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userBelongsToCompany } from '../utils';
import { useAuthContext } from './useAuthContext';
import { useSubscriptionStatus } from './useSubscriptionStatus';

/**
 * Sets up which path the user should be navigated to based on
 * their current state.
 */
export function useLoadPath() {
  const { isAuthenticated, user } = useAuthContext();
  const navigate = useNavigate();
  const { subscription } = useSubscriptionStatus();

  const redirectTo = useCallback((url: string) => {
    if (window.location.pathname !== url) {
      navigate(url);
    }
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated && user != null) {
      if (!userBelongsToCompany(user) && window.location.pathname !== '/setup-company') {
        redirectTo('/setup-company');
      }

      if (subscription == null) {
        return;
      }

      if (subscription?.subscription_plan === 'CARRIER_NO_SUBSCRIPTION') {
        redirectTo('/setup-subscription');

      // shipper had trial, trial is now over. Must setup subscription
      } else if (subscription?.subscription_plan === 'SHIPPER_TRIAL' && !subscription.is_active) {
        redirectTo('/setup-subscription');
      } else if (!subscription?.is_active) {
        redirectTo('/manage-inactive-subscription');

        /*
          if user does have an active subscription, and the current
          url is setup or manage inactive and user
          is not n sucess page, then :

          if user in setup sub or manage inactive -> redirect to dashboard

          else user can stay in whatever url they currently are

        */
      } else if (subscription?.is_active
            && (
              window.location.pathname === '/setup-subscription'
              || window.location.pathname === '/manage-inactive-subscription'
              || window.location.pathname !== '/subscription-success'
            )
      ) {
        if (window.location.pathname === '/setup-subscription'
        || window.location.pathname === '/manage-inactive-subscription') {
          navigate('/');
        } else {
          navigate(window.location.pathname);
        }
      }
    }
  }, [isAuthenticated, user, navigate, subscription, redirectTo]);
}
