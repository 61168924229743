import { Stack } from '@mui/material';
import { QuoteDetail } from './components/QuoteDetail';
import { QuoteHistory } from './components/QuoteHistory';
import { QuoteInteraction } from './components/QuoteInteraction';

interface Props {
  quote: CondensedQuote | ShipperCondensedQuote;
  historyIsExpanded: boolean;
  viewQuoteHistory: React.ReactNode;
}

export function ShipmentQuote(props: Props) {
  const {
    quote,
    historyIsExpanded,
    viewQuoteHistory,
  } = props;

  return (
    <Stack pt="1rem" direction="column">
      <QuoteDetail
        quote={quote}
      />
      <QuoteInteraction
        viewQuoteHistory={viewQuoteHistory}
        actions={quote.actions}
        quote={quote}
      />
      {historyIsExpanded && (
      <QuoteHistory quoteId={quote.quote} />
      )}
    </Stack>
  );
}
