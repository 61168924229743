import {
  CircularProgress, Paper, Stack, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { PendingRequestTableRow } from './PendingRequestTableRow';

interface Props{
  error: string | null;
  loading: boolean;
  followRequests: FollowShipperRequest[];
  onRespondToRequest: (requestId: number, requestApproved: boolean) => void;
}

export function PendingRequests(props: Props) {
  const {
    error, loading, followRequests, onRespondToRequest,
  } = props;
  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  if (followRequests.length === 0) {
    return (
      <Stack>
        <Typography variant="h6">You do not have any active follow requests.</Typography>
      </Stack>
    );
  }
  return (
    <Stack direction="column" spacing={1}>
      {error != null && <Typography color="red" align="center">{error}</Typography>}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contact</TableCell>
              <TableCell>Company</TableCell>
              {/* Action heading */}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            { followRequests.length > 0 ? followRequests.map((request) => (
              <PendingRequestTableRow
                key={request.id}
                request={request}
                onRespondToRequest={onRespondToRequest}
              />
            )) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
