import { DateTime } from 'luxon';
import { getPlaceDetails } from '../../../../utils/googlePlacesApi';

export function getTimeInputLabel(rp: Partial<RoutePoint>): [string, string] {
  switch (rp.stop_operation) {
    case 'PICKUP':
      return ['Earliest Pickup Time', 'Latest Pickup Time'];
    case 'DROPOFF':
      return ['Earliest Dropoff Time', 'Latest Dropoff Time'];
    default:
      return ['Earliest Pickup Time', 'Latest Pickup Time'];
  }
}

export async function getPlaceDetailsAsPointLocation(addressId: string) {
  const pointLocation: Partial<RoutePointLocation> = {};
  let addressReadable = '';
  await getPlaceDetails(addressId).then((res) => {
    pointLocation.place_id = res?.place_id;
    pointLocation.latitude = res?.geometry?.location?.lat();
    pointLocation.longitude = res?.geometry?.location?.lng();
    pointLocation.address = res?.name;
    pointLocation.country = res?.address_components?.find((a) => a.types.includes('country'))?.short_name;
    pointLocation.province = res?.address_components?.find((a) => a.types.includes('administrative_area_level_1'))?.short_name;
    pointLocation.postal = res?.address_components?.find((a) => a.types.includes('postal_code'))?.short_name;
    let cityComponent = res?.address_components?.find((a) => a.types.includes('locality'))?.short_name;
    // Certain regions, like Brooklyn, NY for example, can't be found in the locality.
    if (cityComponent == null) {
      cityComponent = res?.address_components?.find((a) => a.types.includes('sublocality_level_1'))?.short_name;
    }
    pointLocation.city = cityComponent;
    addressReadable = res?.formatted_address ?? '';
  });

  return { pointLocation, addressReadable };
}

export function areRouteTimesValid(
  startTime: string | undefined,
  endTime: string | undefined,
): string | null {
  if (startTime == null || startTime === '') {
    return 'Pickup start time can not be empty.';
  }

  if (endTime == null || endTime === '') {
    return 'Pickup end time can not be empty.';
  }

  if (DateTime.fromISO(startTime) > DateTime.fromISO(endTime)) {
    return 'Route point start pickup time is after pickup end time.';
  }

  return null;
}

export function toFixedNumber(number: number):number {
  return parseFloat(Number(number).toFixed(1));
}
