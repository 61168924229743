import {
  Box, Button, ButtonGroup, CircularProgress, Divider, Stack, Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import {
  useDisclosure, useRoleContext, useSubscriptionStatus, useWriterComponentState,
} from 'hooks';
import { formatText } from 'utils';
import { PayAsYouGoPlanSelection } from './components/PayAsYouGoPlanSelection';

declare global {
// eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export function CarrierEssentialsUpgradeMenu() {
  const { role, company } = useRoleContext();
  const {
    isOpen: showingPPB,
    onOpen: onShowPPB,
    onClose: onShowPricingTable,
  } = useDisclosure(false);
  const { subscription } = useSubscriptionStatus();
  const {
    write, error, loading, success,
  } = useWriterComponentState({
    errorMessage: 'Failed to notify system. Please contact us directly using the chat bubble.',
    writeConfig: {
      method: 'post',
      url: '/sales/carrier-enterprise-info',
    },
  });

  if (role == null || role.type == null || role.type !== 'CARRIER' || company == null) {
    return (
      <Stack alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  const getSubscriptionComponent = () => {
    if (showingPPB) {
      return <PayAsYouGoPlanSelection />;
    }
    return (
      <stripe-pricing-table
        style={{ height: '100%' }}
        pricing-table-id={process.env.REACT_APP_CARRIER_ESSENTIALS_PRICING_TABLE_ID}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
        client-reference-id={`CC${company.id}`}
      />
    );
  };

  const userHasPaidSubscription = () => {
    if (subscription == null) {
      return false;
    }
    return subscription.subscription_plan === 'CARRIER_OWNER_OPERATOR'
    || subscription.subscription_plan === 'CARRIER_SMALL_BUSINESS'
    || subscription.subscription_plan === 'CARRIER_ENTERPRISE'
    || subscription.subscription_plan === 'CARRIER_BRONZE'
    || subscription.subscription_plan === 'CARRIER_SILVER'
    || subscription.subscription_plan === 'CARRIER_GOLD'
    || subscription.subscription_plan === 'CARRIER_PLATINUM'
    || subscription.subscription_plan === 'CARRIER_ENTERPRISE_2'
    || subscription.subscription_plan === 'CARRIER_PAY_PER_BOOKING';
  };

  return (
    <Box height="100%" sx={{ backgroundColor: 'white', paddingTop: '2rem', borderRadius: '1rem' }}>
      <Stack direction="column">
        {subscription != null && <Typography ml="1rem">{`Your current subscription: ${formatText(subscription.subscription_plan)}`}</Typography>}
        { subscription?.subscription_plan !== 'CARRIER_PAY_PER_BOOKING' && (
          <Stack direction="column">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" mb="2rem">
              <ButtonGroup
                disableElevation
              >
                <Button variant={showingPPB ? 'outlined' : 'contained'} onClick={onShowPricingTable}>Subscription</Button>
                {!userHasPaidSubscription() && <Button variant={showingPPB ? 'contained' : 'outlined'} onClick={onShowPPB}>Pay as you go</Button>}
              </ButtonGroup>
            </Stack>
            <Divider sx={{ mb: '1rem' }} />
          </Stack>
        )}
        {getSubscriptionComponent()}
        <LoadingWrappedNode loading={loading}>
          <Box
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => write()}
          >
            {success ? (
              <Typography sx={{ mt: '1rem' }} variant="h6" color="text.secondary" textAlign="center">
                Thank you for your interest. An Evotrux representative
                will contact you shortly.
              </Typography>
            ) : (
              <Typography sx={{ mt: '1rem', mb: '4rem' }} variant="h6" color="text.secondary" textAlign="center">
                Need more great features and bookings? Click here for more info.
              </Typography>
            )}
            <ErrorTypography error={error} />
          </Box>
        </LoadingWrappedNode>
      </Stack>
    </Box>
  );
}
