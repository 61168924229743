import { Button, ButtonProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export function AddStopButton(props: ButtonProps) {
  return (
    <Button variant="contained" sx={{ ml: 'auto' }} {...props}>
      <AddIcon />
      Add Stop
    </Button>
  );
}
