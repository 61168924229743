import {
  CircularProgress, Paper, Stack, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { useAPI } from 'hooks';
import { useState } from 'react';
import { ShipperNetworkTableRow } from './ShipperNetworkTableRow';

interface Props {
  role: Role;
  loading: boolean;
  followers: FollowingCarrier[];
  error: string | null;
  onRefetchFollowers: VoidFunction;
}

export function ShipperNetworkTable(props: Props) {
  const { role } = props;
  const {
    loading, error, followers, onRefetchFollowers,
  } = props;

  const [unfollowError, setUnfollowError] = useState<string | null>(null);

  const { api } = useAPI();

  const onUnfollowCarrierClicked = (carrier: FollowingCarrier) => {
    if (role != null) {
      api<FollowedShipper[]>('post', `/shippers/${role.id}/followers/${carrier.id}/delete`, {}).then(() => {
        onRefetchFollowers();
      }).catch((e) => {
        setUnfollowError(e?.response?.data?.message || 'Failed to unfollow carrier');
      });
    }
  };

  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  if (error) {
    return (
      <Stack>
        <Typography color="red">{error}</Typography>
      </Stack>
    );
  }
  if (followers.length === 0) {
    return (
      <Stack>
        <Typography variant="h6">There is currently no one following you.</Typography>
      </Stack>
    );
  }
  return (
    <>
      {unfollowError != null && (
      <Typography color="red">{unfollowError}</Typography>
      )}
      {error != null && (
      <Typography color="red">{error}</Typography>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contact</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Status</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            { followers.length > 0 ? followers.map((ar) => (
              <ShipperNetworkTableRow
                key={ar?.id}
                carrier={ar}
                onUnfollowCarrierClicked={onUnfollowCarrierClicked}
              />
            )) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
