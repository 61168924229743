import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAPI, useAuthContext } from '../../../hooks';
import { CompanySearch } from '../components/CompanySearchBar';
import { CreateCompany } from '../components/CreateCompany';
import { CompanySetupLayout } from '../layouts';

export function CompanySetupContainer() {
  const { user, isLoading, refreshUser } = useAuthContext();
  const { api } = useAPI();
  const [companyJoinRequestSent, setCompanyJoinRequestSent] = useState<boolean>(false);
  const [creatingCompany, setCreatingCompany] = useState<boolean>(false);

  useEffect(() => {
    if (companyJoinRequestSent) {
      refreshUser();
    }
  }, [companyJoinRequestSent, refreshUser]);

  useEffect(() => {
    if (!isLoading && user != null) {
      api<ListJoinCompanyRequest[]>('get', '/join-company-requests')
        .then((response) => {
        /*
          CompanySetup component is only mounted if a user has no role associated with a company.
          Therefore we can safely 'block' the user from accessing the application with the
          'waiting for request to be approved' screen if the user has any existing company join
          requests saved in the backend.
        */
          setCompanyJoinRequestSent(response.data.length > 0);
        });
    }
  }, [api, isLoading, user]);

  if (isLoading || user == null) {
    return <div />;
  }

  const handleCreateCompanySelected = (): void => {
    setCreatingCompany(true);
  };

  const handleJoinCompany = (): void => {
    setCompanyJoinRequestSent(true);
  };

  const handleSearchCompanySelected = (): void => {
    setCreatingCompany(false);
  };

  let companySetupComponent = (
    <CompanySearch
      onJoinCompanyRequestSent={handleJoinCompany}
      onCreateCompanySelected={handleCreateCompanySelected}
    />
  );
  if (creatingCompany) {
    companySetupComponent = (
      <CreateCompany
        handleSearchCompanySelected={handleSearchCompanySelected}
      />
    );
  } else if (companyJoinRequestSent) {
    companySetupComponent = (
      <Typography
        textAlign="center"
        variant="h4"
        fontWeight="bold"
      >
        You will receive an email as soon as your company administrator has approved your request.
        For more information or to find out who your company administrator is contact us anytime at
        info@evotrux.com
      </Typography>
    );
  }

  return (
    <CompanySetupLayout
      companySetupComponent={companySetupComponent}
    />
  );
}
