import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton } from '@mui/material';

interface Props{
  onOpen: VoidFunction;
  buttonLabel: string;
}

export function OpenDrawerMenuButton(props: Props) {
  const { onOpen, buttonLabel } = props;
  return (
    <Box>
      <IconButton
        onClick={onOpen}
      >
        <MenuIcon />
        {' '}
        {buttonLabel}
      </IconButton>
    </Box>
  );
}
