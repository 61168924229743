/* eslint-disable max-len */
import { usePubNub } from 'pubnub-react';
import { useAPI } from './useAPI';

interface UserLatestChannelTimeTokensResponse {
  [key: string]: ReadChannelTimeToken; // the key is a channel name i.e "shipment.booked.19"
}

export function useChannelAPI() {
  const { api } = useAPI();
  const pubnubClient = usePubNub();

  const updateTimeTokenForChannel = (channelName: string, timeToken?: string) => {
    const startTimeToken = Math.floor((new Date().getTime() / 1000) * 10000000).toString();
    const body = { channel_name: channelName, time_token: timeToken ?? startTimeToken };
    api('post', '/user/channels/time-tokens', body).then(() => {
    });
  };

  const getChannelsWithUnreadMessages = (
    channelNames: string[] | undefined,
    callback: (channelsWithUnreadMessages: Set<string> | undefined) => void,
  ) => {
    /*
    Returns a set of channel names for which the user has unread messages:

    1. Fetches all of a user's time tokens (a point in time at which a user has read all messages)
    2. For each channel referenced by the user's time tokens, fetch the message count after each said time token (aka the new msg count)

    NOTE: The channelNames param is required because the user may have a channel for which no time tokens have been created,
    and therefore the channel name will not come up when fetching the time tokens. The channel with no time tokens
    may have unread messages, so in the case where there is a channel name not included in the time tokens response:

    3. Call the PubNub fetch message API to fetch unread messages for which a user has no time tokens.

    */
    if (channelNames !== undefined) {
      api<UserLatestChannelTimeTokensResponse>('get', '/user/channels/time-tokens/latest').then((response) => {
        const channelsWithUnreadMessages : Set<string> = new Set([]);

        const channelsToLatestTokenMap = response.data;

        const channelNameArray: string[] = [];
        const timeTokenArray: string[] = [];

        /* build the arrays, the time token idx corresponds to the channel name.
        In other words, each index of these two separate arrays corresponds to each other
        */
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(channelsToLatestTokenMap)) {
          channelNameArray.push(key);
          timeTokenArray.push(value.time_token);
        }

        // 2. get the message count for channels for which the user has a time token
        pubnubClient.messageCounts({
          channels: channelNameArray,
          channelTimetokens: timeTokenArray,
        }).then((countResponse) => {
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(countResponse.channels)) {
            if (countResponse.channels[key] > 0) {
              channelsWithUnreadMessages.add(key);
            }
          }

          // Start of 3: Find out if there is a channel for which there has been no unread message check
          if (channelNameArray.length < channelNames.length) {
            const channelsLeft = channelNames.filter((name: string) => !channelNameArray.includes(name));
            /* 3. Fetch messages for the remaining channels. Since the user has no time tokens for
            these channels, if the channels have any messages, these must be unread.
            */
            pubnubClient.fetchMessages({
              channels: channelsLeft,
            }).then((hasMessagesResponse) => {
              // eslint-disable-next-line
              for (const [key, value] of Object.entries(hasMessagesResponse.channels)) {
                // if there are any messages for the channel, add it to the list
                if (hasMessagesResponse.channels[key].length > 0) {
                  channelsWithUnreadMessages.add(key);
                }
              }
              callback(channelsWithUnreadMessages);
            });
          } else {
            // all time tokens corresponded to all existing channels
            callback(channelsWithUnreadMessages);
          }
        });
      });
    }
    return callback(undefined);
  };

  return {
    updateTimeTokenForChannel, getChannelsWithUnreadMessages,
  };
}
