import {
  Box, Button, Stack, TextField, Typography,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { PasswordToggableIconEndAdornment } from '../PasswordToggableIconEndAdornment';
import { ForceChangePasswordError } from './types';

interface Props {
  onSubmit: VoidFunction;
  onPasswordChange: (value: string) => void;
  onShowPasswordChange: () => void;
  showPassword: boolean;
  error: null | ForceChangePasswordError;
  invalidChallenge: boolean;
}

export function ForceChangePassword(props: Props) {
  const {
    onSubmit, onPasswordChange, onShowPasswordChange, showPassword, error, invalidChallenge,
  } = props;

  const [searchParams] = useSearchParams();

  const getButtonText = () => {
    const voluntellText = searchParams.get('activate') ?? null;
    if (voluntellText) {
      return 'Activate Account';
    }
    return 'Change Password';
  };

  const submitOnEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Box>
      <Typography textAlign="center" variant="h4" fontWeight="bold">
        Set your password to use Evotrux
      </Typography>
      {(invalidChallenge) ? (
        <Typography color="red" align="center">
          There was an unexpected issue attempting to initialize your account. Please contact us.
        </Typography>
      ) : (
        <Stack my="2rem" spacing={2}>
          <TextField
            data-testid="change-password-input"
            id="change-password-input"
            label="New Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            onChange={(e) => onPasswordChange(e.target.value)}
            InputProps={{
              endAdornment: (<PasswordToggableIconEndAdornment
                showPassword={showPassword}
                onIconClick={onShowPasswordChange}
              />
              ),
            }}
            onKeyPress={submitOnEnter}
          />
          {error !== null && <Typography color="red" align="center">{error.message}</Typography>}
          <Button variant="contained" onClick={onSubmit} disabled={error?.type === 'validation'}>{getButtonText()}</Button>
        </Stack>
      )}
    </Box>
  );
}
