import FmdGoodIcon from '@mui/icons-material/FmdGood';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {
  Box, Stack, Typography,
} from '@mui/material';
import { ShipmentEquipmentTypeText } from 'components/Texts/ShipmentEquipmentTypeText';
import { ShipmentLocationText } from 'components/Texts/ShipmentLocationText';
import { ShipmentPriceText } from 'components/Texts/ShipmentPriceText';
import { ShipmentShipperReferenceText } from 'components/Texts/ShipmentShipperReferenceText';
import { useNavigate } from 'react-router-dom';
import { getPointDisplayTextForPointLocation } from 'utils';
import { QuoteStatusBadge } from '../QuoteStatusBadge';
import { QuotePreviewShipmentIDText } from './components/QuotePreviewShipmentIDText';

interface Props {
  quote: CondensedQuote;
}

export function QuotePreview(props: Props) {
  const { quote } = props;
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
          title: 'View Quote',
        },
      }}
      onClick={() => navigate(`/shipments/${quote.shipment}?focus=quotes`)}
    >
      <Stack
        direction="column"
        spacing={1}
        mb="1rem"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          ml="1rem"
          mr="1rem"
          mt=".5rem"
        >
          <Stack justifyContent="space-between" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body1">Status: </Typography>
              <QuoteStatusBadge status={quote?.internal_state ?? 'unknown'} size="medium" isHidden={quote.hidden_at != null} />
            </Stack>
            <QuotePreviewShipmentIDText quote={quote} />
            {quote && quote.shipment_custom_name != null && (
            <ShipmentShipperReferenceText data={quote.shipment_custom_name} />
            )}
          </Stack>
          <Stack ml="2rem" justifyContent="space-between">
            <ShipmentLocationText
              data={getPointDisplayTextForPointLocation(quote.origin_location)}
              icon={<TripOriginIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
            />
            <ShipmentLocationText
              data={getPointDisplayTextForPointLocation(quote.destination_location)}
              icon={<FmdGoodIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
            />
          </Stack>
          <Box ml="2rem" display="flex" flexDirection="column" justifyContent="space-between">
            <ShipmentEquipmentTypeText data={[{ name: quote?.equipment_type.toLowerCase().split('_').join(' ') } as Equipment]} />
            {quote?.price && quote.price != null
          && (
          <ShipmentPriceText
            price={quote.price}
            currency={quote?.currency}
          />
          )}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
