import ScaleIcon from '@mui/icons-material/Scale';
import { Typography } from '@mui/material';
import { lbToKg } from '../../utils';
import { IconText } from './IconText';

export function ShipmentWeightText<T extends number>(props: ShipmentInfoTextProps<T>) {
  const { data } = props;

  const pounds = parseFloat(data.toString());

  const text = `${pounds} lb (${lbToKg(pounds).toFixed(1)}kg)`;

  return (
    <IconText
      icon={<ScaleIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
      text={<Typography sx={{ fontSize: '0.9rem' }}>{text}</Typography>}
    />
  );
}
