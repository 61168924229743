import {
  Box,
  useMediaQuery,
} from '@mui/material';
import { CarrierUpgradeNowButton } from 'components/Carrier/components/CarrierUpgradeNowButton';
import { useSubscriptionStatus } from 'hooks';
import { BannerStack } from '../BannerStack';
import { CarrierPayPerBookingCenterBannerText } from '../CarrierPayPerBookingCenterBannerText';
import { CarrierEssentialsBookingRemainingBannerText } from '../CarrrierEssentialsBookingsRemaining';
import { CompanyProfileBanner } from '../CompanyProfileBanner';

interface Props {
  showNavBar: boolean;
  navBar: React.ReactNode;
}
export function FreeCarrierBanner(props: Props) {
  const { showNavBar, navBar } = props;
  const { subscription } = useSubscriptionStatus();
  const screenBigEnoughForRemainingBookings = !useMediaQuery('(max-width:950px)');
  return (
    <BannerStack
      borderRadius={2}
      showNavBar={showNavBar}
      navBar={navBar}
    >
      <CompanyProfileBanner />
      {screenBigEnoughForRemainingBookings && (
      <Box>
        {subscription?.subscription_plan === 'CARRIER_ESSENTIALS'
          ? <CarrierEssentialsBookingRemainingBannerText />
          : <CarrierPayPerBookingCenterBannerText />}
      </Box>
      )}
      <Box>
        <CarrierUpgradeNowButton />
      </Box>
    </BannerStack>
  );
}
