export const createShipmentRequestBodyConstraints = {
  equipment_types: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  'shipment.service': {
    presence: true,
    inclusion: {
      within: ['TRUCK_LOAD', 'LESS_THAN_TRUCK_LOAD'],
      message: 'Invalid Service',
    },
  },
  'shipment.weight_lb': {
    presence: true,
    numericality: true,
  },
  'shipment.footage': {
    numericality: {
      greaterThan: 0,
    },
  },
  'shipment.preferred_price': {
    numericality: {
      greaterThan: 0,
    },
  },
  'shipment.special_requirements': {
    presence: true,
  },
};
