import {
  Chip,
} from '@mui/material';

interface Props {
  partner: boolean;
}

export function PartnerShipmentChip(props: Props) {
  const { partner } = props;

  if (!partner) {
    return (
      <div />
    );
  }

  return (
    <Chip
      label="Partner"
      color="primary"
      variant="outlined"
      size="small"
    />
  );
}
