import { useAPI, useCompanyProfile } from 'hooks';
import { useEffect, useState } from 'react';
import { CreateCompanyReviewMenu } from './CreateCompanyReviewMenu';

interface Props {
  companyId: number;
  onReviewSubmittedRefresh: VoidFunction;
}

export function CreateCompanyReviewMenuContainer(props: Props) {
  const { companyId, onReviewSubmittedRefresh } = props;
  const { api } = useAPI();
  const { companyProfile } = useCompanyProfile(companyId);
  const [error, setError] = useState<null | string>(null);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [review, setReview] = useState<string>('');
  const [rating, setRating] = useState<CompanyRatingValue | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const setReviewText = (val: string) => {
    setReview(val);
  };

  const setReviewRating = (val: CompanyRatingValue | null) => {
    setRating(val);
  };

  useEffect(() => {
    if (loading) {
      const body = {
        rating,
        text: review,
      };
      setSuccess(false);
      api('post', `/companies/${companyId}/reviews`, body).then(() => {
        setError(null);
        onReviewSubmittedRefresh();
        setSuccess(true);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to create review.');
      }).finally(() => { setIsLoading(false); });
    }
  }, [loading, companyId, api, rating, review, onReviewSubmittedRefresh]);

  const onSubmit = () => {
    if (rating != null) {
      setIsLoading(true);
    }
  };

  return (
    <CreateCompanyReviewMenu
      setReviewText={setReviewText}
      setReviewRating={setReviewRating}
      onSubmit={onSubmit}
      canSubmit={rating != null}
      companyProfile={companyProfile}
      rating={rating}
      error={error}
      loading={loading}
      success={success}
    />
  );
}
