import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent, DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

interface Props {
  saveSearchModalIsOpen: boolean;
  searchName: string;
  onCloseModal: () => void;
  onOpenModal: () => void;
  handleChangeSearchName: (name: string) => void;
  onSubmitSaveSearch: (name: string) => void;
  submitSaveSearchIsLoading: boolean;
  submitSaveSearchError: string | null;
}

export function ShipmentSaveSearch(props: Props) {
  const {
    saveSearchModalIsOpen,
    searchName,
    onCloseModal,
    onOpenModal,
    handleChangeSearchName,
    onSubmitSaveSearch,
    submitSaveSearchIsLoading,
    submitSaveSearchError,
  } = props;

  return (
    <div>
      <Button
        variant="contained"
        onClick={onOpenModal}
      >
        Save Lane(s)
      </Button>
      <Dialog
        open={saveSearchModalIsOpen}
        onClose={onCloseModal}
        aria-labelledby="alert-save-search-title"
      >
        <DialogTitle id="alert-save-search-title">Lane</DialogTitle>
        <DialogContent>
          {submitSaveSearchIsLoading
            ? <Stack alignItems="center"><CircularProgress /></Stack>
            : (
              <>
                <Stack direction="row" spacing={1} mt="1rem" mb="1rem">
                  <TextField
                    id="save-search-input"
                    label="Name"
                    variant="outlined"
                    value={searchName}
                    required
                    fullWidth
                    onChange={(e) => handleChangeSearchName(e.target.value)}
                  />
                </Stack>
                <Typography variant="body1">Enter a truck number, driver name, etc. to quickly identify your posting. Only you can see this name.</Typography>
                <Stack direction="row" spacing={1} ml="1rem" mr="1rem">
                  {submitSaveSearchError != null && <Typography color="red">{submitSaveSearchError}</Typography>}
                </Stack>
              </>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseModal}>Cancel</Button>
          <Button
            onClick={() => { onSubmitSaveSearch(searchName); }}
            autoFocus
            disabled={searchName.length < 1}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
