import { Stack } from '@mui/material';
import { CreateShipmentForm } from 'components/Shipper/components/CreateShipmentForm';
import { CreateAccountModal } from '../CreateAccountModal';
import { CreateAccountAndCompanyFormState } from '../types';

interface Props {
  setPostShipmentBody: (body: object) => void;
  showAccountCreationModal: boolean;
  loading: boolean;
  error: string | null;
  handleCreateAccountFormValueChange: (key: keyof CreateAccountAndCompanyFormState,
    value: string) => void;
  createAccountFormState: CreateAccountAndCompanyFormState;
  onSubmitAccountForm: VoidFunction;
  shipmentPosted: boolean;
  setIsNotBroker: (value: boolean) => void;
}

export function CreateShipmentAndAccountForm(props: Props) {
  const {
    setPostShipmentBody,
    showAccountCreationModal,
    loading,
    error,
    handleCreateAccountFormValueChange,
    createAccountFormState,
    onSubmitAccountForm,
    shipmentPosted,
    setIsNotBroker,
  } = props;
  return (
    <Stack>
      {
        showAccountCreationModal && (
          <CreateAccountModal
            handleCreateAccountFormValueChange={handleCreateAccountFormValueChange}
            onSubmit={onSubmitAccountForm}
            error={error}
            loading={loading}
            createAccountFormState={createAccountFormState}
            shipmentPosted={shipmentPosted}
            setIsNotBroker={setIsNotBroker}
          />
        )
      }
      <CreateShipmentForm
        showShipmentNotificationSettings={false}
        postShipmentUrl="/new-shippers"
        onPreSubmitShipment={setPostShipmentBody}
        submitShipment={false}
        showCreateTemplate={false}
        showBookNow={false}
      />
    </Stack>
  );
}
