import { Button, Stack, Typography } from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useWriterComponentState } from 'hooks';

export function ShipperUpgradeRequestInfoButton() {
  const {
    write, error, loading, success,
  } = useWriterComponentState({
    errorMessage: 'Failed to connect with system. Please contact us directly using the chat bubble.',
    writeConfig: {
      method: 'post',
      url: '/sales/shipper-premium-info',

    },
  });

  return (
    <Stack justifyContent="center" direction="column">
      {!success && (
      <LoadingWrappedNode loading={loading}>
        <Stack direction="column" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => write()}
          >
            <Typography variant="body1">
              <b>I&apos;m interested in Premium</b>
            </Typography>
          </Button>
          <Typography variant="body2" color="text.secondary">
            An Evotrux member will contact you shortly after clicking the button.
          </Typography>
        </Stack>
      </LoadingWrappedNode>
      )}
      <ErrorTypography error={error} />
      {success
      && (
      <Typography variant="h6">
        Thanks for your interest in Premium. An Evotrux associate will contact you shortly.
      </Typography>
      )}
    </Stack>
  );
}
