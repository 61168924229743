import {
  Stack, Typography,
} from '@mui/material';
import { useAPI, useRoleContext, useShipmentQuotes } from 'hooks';
import { useSubscriptionStatus } from 'hooks/useSubscriptionStatus';
import { useEffect, useState } from 'react';
import { hasPaidCarrierSubscription } from 'utils';
import { CompanyBusinessTypeChip } from '../../../CompanyBusinessTypeChip';
import { CompanyNameUpgradeText } from '../CompanyNameUpgradeText';
import { getShipmentSubheader } from './utils';

interface Props {
  shipment: CondensedShipment
}

export function ShipmentOverviewSubheader(props: Props) {
  const { shipment } = props;
  const { api } = useAPI();
  const [followed, setFollowed] = useState<FollowedShipper[]>([]);
  const { role } = useRoleContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [isPartnerShipment, setIsPartnerShipment] = useState<boolean>(false);
  const [bookedByCarrier, setBookedByCarrier] = useState<boolean>(false);
  const { subscription } = useSubscriptionStatus();
  const [isPayingCarrier, setIsPayingCarrier] = useState<boolean>(false);

  const {
    quotes, isLoading,
  } = useShipmentQuotes(shipment.info.id);

  useEffect(() => {
    if (role != null && role.type === 'CARRIER' && !isPayingCarrier
    && hasPaidCarrierSubscription(subscription)
    ) {
      setIsPayingCarrier(true);
    }
  }, [role, subscription, isPayingCarrier]);

  useEffect(() => {
    if (!isLoading) {
      const booked = quotes.find((quote) => quote.internal_state === 'ACCEPTED');
      setBookedByCarrier(booked !== undefined);
    }
  }, [quotes, isLoading]);

  useEffect(() => {
    const partner = followed.find((shipper) => shipper?.user?.id
      === shipment?.shipment_creator?.id);

    setIsPartnerShipment(partner !== undefined);
  }, [followed, shipment]);

  useEffect(() => {
    if (loading && role) {
      api<FollowedShipper[]>('get', `/carriers/${role.id}/followed_shippers/following`).then((response) => {
        setFollowed(response.data);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [api, role, loading]);

  return (
    <Stack direction="row" alignItems="baseline" spacing={1}>
      <Typography variant="body2" color="text.secondary">{getShipmentSubheader(shipment, isPartnerShipment, bookedByCarrier, isPayingCarrier)}</Typography>
      {!isPartnerShipment && !bookedByCarrier && !isPayingCarrier && (
        <CompanyNameUpgradeText />
      )}
      <CompanyBusinessTypeChip businessType={shipment.shipment_company.business_type} />
    </Stack>

  );
}
