import { Delete, MarkEmailRead } from '@mui/icons-material';
import {
  Card, IconButton, Stack, Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ISOStringToLocalReadableTimestamp } from '../../../../utils';
import { getNotificationMessage } from './content';

interface Props{
  notification: Notification;
  onClose: VoidFunction;
  onMarkNotifictionAsRead: (notification: Notification) => void;
  onMarkNotifictionAsDeleted: (notification: Notification) => void;
}

export function NotificationCard(props: Props) {
  const {
    notification, onClose,
    onMarkNotifictionAsRead,
    onMarkNotifictionAsDeleted,
  } = props;

  const closeModalAndRedirect = (url: string): void => {
    onClose();
    window.location.href = url;
  };

  const onCardClick = (url: string): void => {
    closeModalAndRedirect(url);
    onMarkNotifictionAsRead(notification);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
        },
        bgcolor: notification.read_at == null ? '#ffffff' : '#e6e5e3',
      }}
    >
      <Stack direction="row" p=".3rem">
        <Stack
          direction="column"
        >
          <Box
            onClick={() => (notification?.url ? onCardClick(notification.url) : null)}
          >
            <Typography variant="h6">{notification?.type ? getNotificationMessage(notification.type, notification.metadata) : ''}</Typography>
            <Typography variant="body2" color="text.secondary">{notification?.created_at ? ISOStringToLocalReadableTimestamp(notification.created_at) : ''}</Typography>
            <Typography variant="body2" color="text.secondary">{notification?.read_at !== null ? `Marked as read on ${ISOStringToLocalReadableTimestamp(notification.read_at)}` : ''}</Typography>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="right" justifyContent="right">
          <IconButton onClick={() => onMarkNotifictionAsDeleted(notification)}>
            <Delete />
          </IconButton>
          <IconButton onClick={() => onMarkNotifictionAsRead(notification)}>
            <MarkEmailRead />
          </IconButton>
        </Stack>

      </Stack>
    </Card>
  );
}
