import {
  Box, Button, CircularProgress, Stack, TextField, Typography,
} from '@mui/material';

interface Props{
  onSubmit: (emailBlock: string) => void;
  error: string | null;
  text: string;
  onTextChange: (text: string) => void;
  isLoading: boolean;
  emailError: string | null;
  success: boolean;
}

export function ShareShipmentMenu(props: Props) {
  const {
    onSubmit, error, text, onTextChange, isLoading, emailError, success,
  } = props;

  return (
    <Box flex={1}>
      <Typography variant="h5">
        Who else needs to know about your shipment?
      </Typography>
      <Typography variant="h5" mb="1rem">
        Enter their email addresses below to receive quotes from them on Evotrux.
      </Typography>
      <TextField
        placeholder="carrier-1@example.com carrier-2@example.com"
        helperText="Enter a single email or multiple emails. Emails must be separated by spaces."
        multiline
        minRows={3}
        fullWidth
        value={text}
        onChange={(e) => onTextChange(e.target.value)}
      />
      { error && <Typography mt="1rem" color="red">{error}</Typography>}
      { emailError && <Typography mt="1rem" color="red">{emailError}</Typography>}
      { success && <Typography mt="1rem" color="green">Shipment shared.</Typography>}
      <Stack mt="1rem" direction="row" justifyContent="flex-end">
        {(!isLoading) && (
          <Button variant="contained" disabled={emailError !== null} onClick={() => onSubmit(text)}>Share shipment</Button>
        )}
        {(isLoading) && (
          <CircularProgress />
        )}
      </Stack>
    </Box>

  );
}
