import {
  Box, Button, CircularProgress, TextField, Typography,
} from '@mui/material';

interface Props{
  onSubmit: (emailBlock: string) => void;
  error: string | null;
  text: string;
  onTextChange: (text: string) => void;
  isLoading: boolean;
}

export function CreateFollowRequest(props: Props) {
  const {
    onSubmit, error, text, onTextChange, isLoading,
  } = props;

  return (
    <Box boxShadow={1} borderRadius={4} p="1rem" flex={1} my="2rem">
      <Typography variant="h5" fontWeight={400}>
        Invite Carriers to Follow You
      </Typography>
      <Typography variant="subtitle1" mb="1rem">
        You can submit up to 20 invitations at once. If you need to send more than that,
        {' '}
        please contact us.
      </Typography>
      <TextField
        placeholder="email@carrier.com, other@email.com"
        helperText="Enter a single email or a list of emails. Emails must be separated by commas."
        multiline
        fullWidth
        value={text}
        onChange={(e) => onTextChange(e.target.value)}
      />
      { error && <Typography mt="1rem" color="red">{error}</Typography>}
      <Box mt="1rem">
        {(!isLoading) && (
          <Button variant="contained" onClick={() => onSubmit(text)}>Send Invite(s)</Button>
        )}
        {(isLoading) && (
          <CircularProgress />
        )}
      </Box>
    </Box>

  );
}
