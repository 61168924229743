import { Stack, Typography } from '@mui/material';
import { QuickShipmentsBrowser } from 'components/Carrier/components/QuickShipmentsBrowser';
import { DashboardBackButton } from 'components/Shipment/components/DashboardBackButton';
import { useShipmentFiltersContext } from 'hooks';
import { PartnerShipmentDropdown } from '../ShipmentsFilter/components/PartnerShipmentDropdown';

interface Props {
  shipments: CarrierCondensedShipment[];
  shipmentSelectionIsDisabled: boolean;
  handlePageSelected: (e: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  pages: number;
  shipmentCount: number;
  showDashboardBackButton?: boolean;
}

export function ShipmentSideBar(props: Props) {
  const {
    shipments,
    shipmentSelectionIsDisabled,
    handlePageSelected,
    page,
    pages,
    shipmentCount,
    showDashboardBackButton,
  } = props;
  const { filters, updateShipmentRelation } = useShipmentFiltersContext();

  const getShipmentsAvailableCopy = () => {
    if (filters && filters?.shipmentRelationship === 'partner') {
      return 'My Partner Shipments';
    }
    return 'Public Shipments';
  };

  return (

    <Stack direction="column" spacing={0.5}>
      {showDashboardBackButton && (<DashboardBackButton />)}
      <PartnerShipmentDropdown
        onShipmentRelationChange={updateShipmentRelation}
        value={filters && filters?.shipmentRelationship ? filters.shipmentRelationship : 'public'}
      />
      <Typography variant="h6" mb="0.5rem">
        {getShipmentsAvailableCopy()}
        {' '}
        (
        {shipmentCount ?? '-'}
        )
      </Typography>
      <QuickShipmentsBrowser
        shipments={shipments}
        shipmentSelectionIsDisabled={shipmentSelectionIsDisabled}
        page={page}
        pages={pages}
        handlePageSelected={handlePageSelected}
      />
    </Stack>
  );
}

ShipmentSideBar.defaultProps = {
  showDashboardBackButton: true,
};
