import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthContext, usePasswordFieldToggle } from '../../../../hooks';
import { validatePassword } from '../utils';
import { ForceChangePassword } from './ForceChangePassword';
import { ForceChangePasswordError } from './types';

export function ForceChangePasswordContainer() {
  const [searchParams] = useSearchParams();
  const { user, challenge, forceChangePassword } = useAuthContext();
  const { passwordIsVisible, handleShowPasswordChange } = usePasswordFieldToggle();
  const [error, setError] = useState<null | ForceChangePasswordError>(null);
  const [newPassword, setNewPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [session, setSession] = useState<string>('');
  const [invalidChallenge, setInvalidChallenge] = useState<boolean>(false);
  const [shipment, setShipment] = useState<string | null>(null);

  useEffect(() => {
    const readShipment = searchParams.get('shipment') ?? null;
    setShipment(readShipment);
  }, [searchParams]);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
    }
    if (challenge) {
      setSession(challenge.Session);
    }
    if (!user
      || !challenge
      || (user && user.email.length === 0)
      || (challenge && challenge.Session.length === 0)
    ) {
      setInvalidChallenge(true);
    } else {
      setInvalidChallenge(false);
    }
  }, [user, challenge]);

  const handleOnPasswordChange = (password: string) => {
    setNewPassword(password);
    const passwordError = validatePassword(password);
    if (passwordError) {
      setError({
        message: passwordError,
        type: 'validation',
      });
    } else {
      setError(null);
    }
  };

  const handleForceChangePasswordSubmit = () => {
    forceChangePassword(email, newPassword, session, shipment).catch(
      () => {
        setError({
          message: 'There was an error while changing your password. Please contact us if the issue persists.',
          type: 'submission',
        });
      },
    );
  };

  return (
    <ForceChangePassword
      onSubmit={handleForceChangePasswordSubmit}
      onPasswordChange={handleOnPasswordChange}
      onShowPasswordChange={handleShowPasswordChange}
      showPassword={passwordIsVisible}
      error={error}
      invalidChallenge={invalidChallenge}
    />
  );
}
