import { Typography } from '@mui/material';
import { useRoleContext, useShipmentQuotes } from 'hooks';
import { useEffect, useState } from 'react';
import { getBookedQuoteFromQuotes } from 'utils';

interface Props {
  shipment: CondensedShipment
}

export function ShipmentBookedBy(props: Props) {
  const { shipment } = props;
  const [bookedBy, setBookedBy] = useState<string | null>(null);
  const [bookedByCompany, setBookedByCompany] = useState<string | null>(null);
  const { quotes } = useShipmentQuotes(shipment.info.id);
  const { role } = useRoleContext();

  useEffect(() => {
    if (role && role.type === 'SHIPPER' && quotes.length > 0) {
      const booked = getBookedQuoteFromQuotes(quotes);
      if (booked?.created_by && booked?.created_by_company) {
        setBookedBy(`${booked.created_by.first_name} ${booked.created_by.last_name}`);
        setBookedByCompany(booked.created_by_company.name);
      }
    }
  }, [quotes, role]);

  useEffect(() => {
    if (role && role.type === 'CARRIER' && shipment.shipment_company && shipment.shipment_creator) {
      setBookedBy(`${shipment.shipment_creator.first_name} ${shipment.shipment_creator.last_name}`);
      setBookedByCompany(shipment.shipment_company.name);
    }
  }, [role, shipment]);

  if (!bookedBy || !bookedByCompany) {
    return <div />;
  }

  return (
    <Typography variant="body1">
      <b>{role != null && role.type === 'SHIPPER' ? 'Main carrier contact: ' : 'Main shipper contact: '}</b>
      {bookedBy}
      <b> from </b>
      {bookedByCompany}
    </Typography>
  );
}
