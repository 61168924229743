import { Box, Stack } from '@mui/material';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { useSubscriptionStatus } from 'hooks';
import { useState } from 'react';
import { CreateShipmentForm } from '../CreateShipmentForm';
import { CreateShipmentFormState } from '../CreateShipmentForm/types';
import { ShipmentTemplateMenu } from '../ShipmentTemplateMenu';
import {
  getCreateShipmentFormStateFromTemplateContent,
} from '../ShipmentTemplateMenu/utils';

interface Props {
  refreshShipments: VoidFunction
}

export function ShipmentTemplateAndCreateShipmentFormDashboardItem(props: Props) {
  const { subscription } = useSubscriptionStatus();
  const { refreshShipments } = props;

  const [shipmentState, setShipmentState] = useState<undefined
  | CreateShipmentFormState>(undefined);

  const onTemplateClicked = (template: ShipmentTemplate) => {
    const createFormState = getCreateShipmentFormStateFromTemplateContent(
      template,
    );
    setShipmentState(createFormState);
  };

  return (
    <Stack direction="column">
      <ShipmentTemplateMenu onTemplateClick={onTemplateClicked} />
      <Box mb="1rem" mt="1rem">
        <EvotruxDivider />
      </Box>
      <CreateShipmentForm
        postShipmentUrl={
                subscription?.subscription_plan === 'SHIPPER_PAY_PER_POSTING' ? '/shipments/pay-per-posting' : '/shipments'
              }
        shipmentState={shipmentState}
        useCustomCommodity={shipmentState === undefined ? true
          : shipmentState?.commodityFreetext !== undefined}
        onSubmitShipmentSuccessCallback={refreshShipments}
        showShareMenu
      />
    </Stack>
  );
}
