import { Alert, Box } from '@mui/material';
import { UpdatePaymentInformationButton } from 'components/UpdatePaymentInformationButton';

export function AddPaymentInformationLabel() {
  return (
    <Box sx={{ mb: '1rem', w: '100%' }}>
      <Alert severity="error">
        You do not have a payment method. Pay per booking requires a payment method.
        {' '}
        <UpdatePaymentInformationButton />
      </Alert>
    </Box>
  );
}
