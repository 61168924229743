import { ShipperShipmentPageProvider } from 'context';
import {
  useAPI, useShipments,
} from 'hooks';

import { useGetShipmentsUrl } from 'hooks/useGetShipmentsUrl';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPageCount } from 'utils';
import { ShipmentOverviewExpanded } from '../ShipmentOverviewExpanded';
import { Shipment } from './Shipment';

const QUICK_SHIPMENT_BROWSER_PAGE_SIZE = 5;

export function ShipmentContainer() {
  const [allShipments, setAllShipments] = useState<ShipperCondensedShipment[]>([]);
  const params = useParams();
  const shipmentId = params.id;
  const [page, setPage] = useState<number>(1);
  // eslint-disable-next-line
  const { shipments, 
    isLoading: shipmentsAreLoading,
    count,
    refreshShipments,
  } = useShipments({ page, pageSize: QUICK_SHIPMENT_BROWSER_PAGE_SIZE });
  const { api } = useAPI();
  const { url } = useGetShipmentsUrl(shipmentId);
  const [selectedShipment, setSelectedShipment] = useState<ShipperCondensedShipment | undefined>(
    undefined,
  );
  const pages = getPageCount(count, QUICK_SHIPMENT_BROWSER_PAGE_SIZE);

  const handlePageChange = (e: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  useEffect(() => {
    setAllShipments(
      [...shipments as ShipperCondensedShipment[]],
    );
  }, [shipments]);

  const refreshShipment = () => {
    if (url != null) {
      api<ShipperCondensedShipment>('get', url).then((response) => {
        setSelectedShipment(response.data);
      }).then(refreshShipments);
    }
  };

  /*
  Fires when shipmentId (obtained from url path parameter) changes or
  the selected shipement changes.

   * Attempts to find the shipment in the list of fetched shipments
  and sets it as the new selectedShipment as long as the shipment actually changed.
  * If the shipment is not found in the list of fetched shipments,
    attempts to fetch the shipment
  */
  useEffect(() => {
    const foundShipment = allShipments.find((shipment) => shipment.info.id === parseInt(shipmentId ?? '0', 10));
    if (foundShipment === undefined && selectedShipment === undefined && url !== null) {
      api<ShipperCondensedShipment>('get', url).then((response) => {
        setSelectedShipment(response.data);
      });
    } else if (foundShipment !== undefined) {
      if (selectedShipment !== undefined && foundShipment.info.id === selectedShipment.info.id) {
        /*
          shipment did not change, no need to set the selected shipment from the list of shipments
          this is to avoid setting an outdated version of the shipment replacing the latest.
          This case will happen whenever uses chooses to refresh a shipment using the icons, or
          whenever a user action triggers a refetching of the shipment. See refreshShipment whic
          refetches the shipments and also brings up the list of shipments to date.
        */
      } else if (selectedShipment !== undefined
         && foundShipment.info.id !== selectedShipment.info.id) {
        // shipment actually changed (user click on different shipment from sidebar)
        setSelectedShipment(foundShipment as ShipperCondensedShipment);
      }
    }
  }, [api, selectedShipment, shipmentId, allShipments, url]);

  return (
    <ShipperShipmentPageProvider shipment={selectedShipment} onRefresh={refreshShipment}>
      <Shipment
        shipments={allShipments}
        shipmentSelectionIsDisabled={shipmentsAreLoading}
        shipmentOverview={(
          <ShipmentOverviewExpanded />
  )}
        page={page}
        pages={pages}
        handlePageSelected={handlePageChange}
        shipmentCount={count}

      />
    </ShipperShipmentPageProvider>
  );
}
