import {
  Box, Chip, Divider, Stack, Typography,
} from '@mui/material';
import { getReadableRoutePointLocation, ISOStringToReadableDate } from '../../../utils';

interface Props {
  routePoint: RoutePoint;
}

export function RoutePointDetail(props: Props) {
  const { routePoint } = props;

  const getPointTimestamps = () => (
    <Stack direction="column" spacing={1} mr="1rem">
      <Box>
        <Typography sx={{ fontSize: '0.7rem', color: '#b9b9b9' }}>Earliest</Typography>
        <Typography>
          {ISOStringToReadableDate(routePoint.start_time)}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: '0.7rem', color: '#b9b9b9' }}>Latest</Typography>
        <Typography>
          {ISOStringToReadableDate(routePoint.end_time)}
        </Typography>
      </Box>
    </Stack>
  );

  return (
    <div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row">
          <Chip
            variant="outlined"
            sx={{ minWidth: '50px', mr: '1rem' }}
            color="primary"
            label={routePoint?.point_number ? `${routePoint?.point_number}` : '-'}
          />
          <Chip sx={{ minWidth: '50px', mr: '1rem' }} variant="outlined" label={routePoint?.stop_operation ?? '-'} />
          <Typography variant="h6">{getReadableRoutePointLocation(routePoint)}</Typography>
        </Stack>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Box>
            {getPointTimestamps()}
          </Box>
        </Stack>
      </Stack>
      <Divider />
    </div>
  );
}
