import {
  Box, Stack, Typography,
} from '@mui/material';
import { ShipmentProgressStats } from './ShipmentProgressStats';
import { ShipmentProgressStepper } from './ShipmentProgressStepper';

interface Props {
  shipmentProgress: ShipmentProgress | null;
  shipment: ShipperCondensedShipment | undefined;
  error: string | null;
  shipmentProgressDetail: JSX.Element
  onOpenProgressDetail: VoidFunction;
}

export function ShipmentProgress(props: Props) {
  const {
    shipmentProgress,
    shipment,
    error,
    shipmentProgressDetail,
    onOpenProgressDetail,
  } = props;
  if (error) {
    return (
      <Stack>
        {error != null && <Typography color="red" align="center">{error}</Typography>}
      </Stack>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={onOpenProgressDetail}
      >
        <Stack
          direction="column"
          spacing={2}
          p={1}
        >
          <ShipmentProgressStats
            shipment={shipment}
            progress={shipmentProgress}
            onClick={onOpenProgressDetail}
          />
          <ShipmentProgressStepper shipment={shipment} progress={shipmentProgress} />
        </Stack>
      </Box>
      {shipmentProgressDetail}
    </Box>
  );
}
