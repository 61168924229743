import { Stack } from '@mui/material';
import { MessageEnvelope } from '@pubnub/react-chat-components';
import { useAuthContext } from 'hooks';
import { Message } from './Message';
import { userIdIsMessageAuthor } from './utils';

interface Props {
  messages: MessageEnvelope[] | undefined;
  currentChannel: string;
}

export function MessageHistoryDisplay(props: Props) {
  const { messages, currentChannel } = props;
  const { user } = useAuthContext();

  if (!user) {
    return (<div />);
  }
  let channelMessages: MessageEnvelope[] = [];
  if (messages) {
    channelMessages = messages.filter((message) => message.channel === currentChannel);
    // sort messages
  }

  return (
    <Stack id="message-history-container" direction="column">
      {channelMessages.map((message: MessageEnvelope, index: number) => (
        <Stack
          key={index.toString()}
          id={`message-container-${index.toString()}`}
          justifyContent="flex-start"
          flexDirection={userIdIsMessageAuthor(user.id, message) ? 'row-reverse' : 'row'}
          direction="row"
        >
          <Message
            message={message}
            userIsAuthor={userIdIsMessageAuthor(user.id, message)}
          />
        </Stack>
      ))}
    </Stack>
  );
}
