import LaunchIcon from '@mui/icons-material/Launch';
import { Button } from '@mui/material';
import { useAuthContext } from 'hooks';
import { userHasAdminRoleInCompany } from 'utils';

export function AddCompanyMemberAccountButton() {
  const { user } = useAuthContext();

  if (user == null || !userHasAdminRoleInCompany(user)) {
    return <div />;
  }

  const adminRole = user.roles.find((r: Role) => r.type === 'ADMINISTRATOR');
  if (!adminRole || !adminRole?.company?.id) {
    return <div />;
  }
  const baseUrl = 'https://form.jotform.com/240026125557248';
  const fullUrl = `${baseUrl}?role_id=${adminRole.id}&company_id=${adminRole.company.id}`;

  const redirectToCompany = () => {
    window.open(fullUrl, '_blank');
  };

  return (
    <Button
      variant="contained"
      size="small"
      onClick={redirectToCompany}
      endIcon={<LaunchIcon />}
    >
      Add Team Members
    </Button>
  );
}
