import { useEffect, useState } from 'react';
import { useAPI } from './useAPI';

export function useCompanyProfile(companyId: number | null) {
  const [companyProfile, setCompanyProfile] = useState<CompanyProfile | null>(null);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | string>(null);
  const { api } = useAPI();

  useEffect(() => {
    if (loading && companyId !== null) {
      api<CompanyProfile>('get', `/companies/${companyId}/profile`).then((response) => {
        setCompanyProfile(response.data);
        setError(null);
      }).catch((e) => {
        if (e?.response?.status === 403 && e?.response?.data?.detail) {
          setError(e.response.data.detail);
        } else {
          setError(e?.response?.data?.message || 'Failed to fetch company');
        }
      });
      setIsLoading(false);
    }
  }, [loading, companyId, api]);

  const refetchProfile = () => {
    setIsLoading(true);
  };
  return {
    companyProfile,
    loading,
    error,
    refetchProfile,
  };
}
