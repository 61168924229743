import { Stack, useMediaQuery } from '@mui/material';
import { QuoteQA } from '../../../QuoteQA';
import { MultiShipmentProjectQuoteDetail } from '../MultiShipmentProjectQuoteDetail';
import { SingleShipmentQuoteDetail } from '../SingleShipmentQuoteDetail';

interface Props {
  quote: CondensedQuote | ShipperCondensedQuote;
}

export function QuoteDetail(props: Props) {
  const {
    quote,
  } = props;
  const useColumnLayout = useMediaQuery('(max-width:450px)');

  let quoteDetailsComponent = (
    <SingleShipmentQuoteDetail
      quote={quote}
      useColumnLayout={useColumnLayout}
    />
  );
  if (quote?.quote_type === 'MULTI_SHIPMENT_PROJECT') {
    quoteDetailsComponent = (
      <MultiShipmentProjectQuoteDetail
        quote={quote}
        useColumnLayout={useColumnLayout}
      />
    );
  }

  return (
    <Stack
      direction={useColumnLayout ? 'column' : 'row'}
      mb="1rem"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      {quoteDetailsComponent}
      <Stack
        width={useColumnLayout ? undefined : '40%'}
        direction="column"
        id="quote-qa-container"
        pr="1rem"
      >
        <QuoteQA quote={quote} />
      </Stack>
    </Stack>
  );
}
