import {
  CircularProgress,
  Stack,
} from '@mui/material';
import { useAuthContext } from 'hooks';
import { PageLayout } from 'layouts';
import { useParams } from 'react-router-dom';
import { CompanyProfileHeader } from './components/CompanyProfileHeader';
import { CompanyProfileReviews } from './components/CompanyProfileReviews';

export function CompanyProfilePage() {
  const { isLoading } = useAuthContext();
  const params = useParams();

  if (isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (!params.id) {
    return <div />;
  }

  const companyId = parseInt(params.id, 10);

  return (
    <PageLayout>
      <CompanyProfileHeader companyId={companyId} />
      <CompanyProfileReviews companyId={companyId} />
    </PageLayout>
  );
}
