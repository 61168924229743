import {
  Box, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../hooks';
import { CreateCarrierCompany } from '../CreateCarrierCompany';
import { CreateShipperCompany } from '../CreateShipperCompany';

interface Props {
  handleSearchCompanySelected: () => void;
}

export function CreateCompany(props: Props) {
  const { user } = useAuthContext();
  const { handleSearchCompanySelected } = props;
  const navigate = useNavigate();

  let component = (
    <Typography
      textAlign="center"
      variant="h4"
      fontWeight="bold"
    >
      We can not help you create a company because you do not have any roles.
      Please contact us for assistance.
    </Typography>
  );
  if (user && user?.roles.length > 0) {
    if (user.roles[0].company != null) {
      // user belongs to a company, redirect to dashboard
      navigate('/');
    } else if (user.roles[0].type === 'CARRIER') {
      component = (
        <CreateCarrierCompany
          handleSearchCompanySelected={handleSearchCompanySelected}
        />
      );
    } else if (user.roles[0].type === 'SHIPPER') {
      component = (
        <CreateShipperCompany
          handleSearchCompanySelected={handleSearchCompanySelected}
        />
      );
    }
  }

  return (
    <Box>
      {component}
    </Box>
  );
}
