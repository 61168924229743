import { useAPI } from 'hooks';
import { useEffect, useState } from 'react';
import { ActiveJoinCompanyRequestTable } from './ActiveJoinCompanyRequestTable';

interface Props {
  company: CompanyDetailInfo ;
}

export function ActiveJoinCompanyRequestTableContainer(props: Props) {
  const {
    company,
  } = props;
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { api } = useAPI();
  const [joinRequests, setJoinRequests] = useState<ActiveJoinCompanyRequest[]>([]);

  useEffect(() => {
    if (loading) {
      api<ActiveJoinCompanyRequest[]>('get', `/companies/${company.company.id}/active_join_requests`).then((response) => {
        setJoinRequests(response.data);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch active join requests.');
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [api, company.company.id, loading]);

  const onRespondToRequest = (requestId: number, requestApproved: boolean) => {
    const body = { accepted: requestApproved };
    api<unknown>('post', `/join-company-requests/${requestId}/respond`, body).then(() => {
      setLoading(true);
    }).catch((e) => {
      setError(e?.response?.data?.message || 'Failed to respond to join company request.');
    });
  };

  return (
    <ActiveJoinCompanyRequestTable
      error={error}
      loading={loading}
      joinRequests={joinRequests}
      onRespondToRequest={onRespondToRequest}
    />
  );
}
