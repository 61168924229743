import { Card, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { ISOStringToShorthandDate } from 'utils';

interface Props {
  branchMembers: BranchMembers;
  onManageBranch: (branch: BranchMembers) => void;
}

export function BranchOverview(props: Props) {
  const { branchMembers, onManageBranch } = props;
  const { branch, members } = branchMembers;
  return (
    <Card sx={{ pb: '.5rem' }}>
      <CardContent>
        <Typography variant="h5">{branch.name}</Typography>
        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{`${members.length} ${members.length === 1 ? 'member' : 'members'}`}</Typography>
        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{`Created on ${ISOStringToShorthandDate(branch.created_at)}`}</Typography>
      </CardContent>
      <CardActions>
        <Button onClick={() => onManageBranch(branchMembers)} variant="outlined">Manage</Button>
      </CardActions>
    </Card>

  );
}
