import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box, IconButton, Stack, Typography,
} from '@mui/material';
import { getRegionByCode } from 'components/ProvinceStateAutoComplete/utils';
import { useShipmentFiltersContext } from 'hooks/useShipmentFiltersContext';
import {
  ISOStringToLocalReadableTimestamp,
  getEquipmentTypesDisplay,
} from 'utils';
import { getServiceTypeDisplayLabel } from '../../../../constants';

interface Props {
  shipmentSearch: ShipmentSearch;
  onOpenDeleteShipmentSearch: VoidFunction;
  onClick: VoidFunction;
}

export function ShipmentSearch(props: Props) {
  const {
    shipmentSearch, onOpenDeleteShipmentSearch,
    onClick,
  } = props;
  const { clearFilters, setFilters } = useShipmentFiltersContext();

  const onSeachClick = () => {
    clearFilters();
    const savedSearch: ShipmentFilters = {
      equipment_types: shipmentSearch.equipment_types ? shipmentSearch.equipment_types : [],
      services: shipmentSearch.services ? shipmentSearch.services : [],
      status: 'ACTIVE',
      origin: {
        provinces: shipmentSearch.origin_provinces ? shipmentSearch.origin_provinces : [],
      },
      destination: {
        provinces: shipmentSearch.destination_provinces ? shipmentSearch.destination_provinces : [],
      },
      order_by: '-created_at',
    };
    setFilters(savedSearch);
    onClick();
  };

  return (
    <Box
      display="flex"
      px="2rem"
      py="1rem"
      sx={{
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
        },
      }}
    >
      <Box flex={1} onClick={() => onSeachClick()}>
        <Stack direction="row">
          <Box>
            <Stack my="0.3rem" direction="row" alignItems="center" spacing={4}>
              <Typography>
                Name:
                {' '}
                {shipmentSearch.name}
              </Typography>
              <Typography>
                {shipmentSearch.equipment_types ? `Equipment: ${getEquipmentTypesDisplay(shipmentSearch.equipment_types)}` : ''}
              </Typography>
              <Typography>
                {shipmentSearch.services ? `Services: ${shipmentSearch.services.map((service: string) => (getServiceTypeDisplayLabel(service)))}` : ''}

              </Typography>
            </Stack>
            <Stack my="0.5rem" direction="row" alignItems="center" spacing={2}>
              <Typography>{shipmentSearch.origin_provinces ? `Origins: ${shipmentSearch.origin_provinces.map((origin: string) => (getRegionByCode(origin)))}` : ''}</Typography>
              <Typography>{shipmentSearch.destination_provinces ? `Destinations: ${shipmentSearch.destination_provinces.map((origin: string) => (getRegionByCode(origin)))}` : ''}</Typography>
            </Stack>
            <Typography variant="body2" color="text.secondary">
              Created:
              {' '}
              {ISOStringToLocalReadableTimestamp(shipmentSearch.created_at)}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box alignItems="center" justifyContent="center" display="flex">
        <IconButton onClick={onOpenDeleteShipmentSearch}>
          <DeleteIcon fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
}
