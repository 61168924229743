import { Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/system';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import { useState } from 'react';
import { ISOStringToLocalReadableTimestamp } from 'utils';

interface Props {
  actions?: JSX.Element | null;
  trackingLink: ShipmentTrackingLink;
  showWarningOnCopy?: boolean;
}

export function ShipmentTrackingLinkDisplay(props: Props) {
  const { actions, trackingLink, showWarningOnCopy } = props;
  const [showCopyWarning, setShowCopyWarning] = useState<boolean>(false);
  return (
    <Stack
      direction="column"
      spacing={1}
      mb="2rem"
    >
      <Stack direction="row" justifyItems="left" alignItems="flex-end">
        <Typography variant="h6" sx={{ mt: '1rem' }}>
          <b>Tracking URL: </b>
          <a
            href={trackingLink.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {trackingLink.url}
          </a>
        </Typography>
        <CopyToClipboardButton
          stringToCopy={trackingLink.url}
          message="Tracking URL copied to clipboard"
          onClick={() => setShowCopyWarning(true)}
        />
      </Stack>
      <Typography
        variant="body1"
        color="text.secondary"
      >
        {`Added ${ISOStringToLocalReadableTimestamp(trackingLink.created_at)}`}
      </Typography>
      {showCopyWarning && showWarningOnCopy
        && (<Alert severity="info">We recommend not clicking on any links that seem suspicious or unfamiliar.</Alert>)}
      <Box>
        {actions}
      </Box>
    </Stack>
  );
}

ShipmentTrackingLinkDisplay.defaultProps = {
  actions: null,
  showWarningOnCopy: false,
};
