import {
  Box, Button, Divider, Stack, TextField, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ForgotPasswordData } from './types';

export interface Props {
  onForgotPassword: VoidFunction;
  error: string | null;
  onFormChange: (key: keyof ForgotPasswordData, value: string) => void;
  disableSendPassword: boolean;
}

export function ForgotPassword(props: Props) {
  const {
    error, onForgotPassword, onFormChange, disableSendPassword,
  } = props;
  const navigate = useNavigate();
  return (
    <Box>
      <Typography textAlign="center" variant="h4" fontWeight="bold">
        Forgot password
      </Typography>
      <Stack my="2rem" spacing={2}>
        <Typography>
          Enter the email associated with your account and
          we will send you a code to reset your password.
        </Typography>
        <TextField
          data-testid="email-input"
          id="email-input"
          label="Email"
          variant="outlined"
          required
          onChange={(e) => onFormChange('email', e.target.value)}
          error={error != null}
          helperText={error == null ? '' : error}
        />
        <Button disabled={disableSendPassword} variant="contained" onClick={onForgotPassword}>
          Send Password Reset Code
        </Button>
        <Button variant="text" data-testid="button" onClick={() => navigate('/confirm-forgot-password')}>Already have a code?</Button>
        <Divider variant="middle" />
        <Button onClick={() => navigate('/login')}>Back to login</Button>
      </Stack>
    </Box>
  );
}
