import {
  Chip,
} from '@mui/material';

interface Props {
  status: ShipmentStatusValue | undefined;
  size: 'small' | 'medium' | undefined;
}

export function ShipmentStatusBadge(props: Props) {
  const { status, size } = props;

  const getBadgeColor = (): 'primary' | 'success' | 'warning' | 'info' | 'error' => {
    switch (status) {
      case 'ACTIVE':
        return 'primary';
      case 'BOOKED':
        return 'success';
      case 'EXPIRED':
        return 'warning';
      case 'CANCELLED':
      case 'PAYMENT_FAILED':
        return 'error';
      default:
        return 'info';
    }
  };

  const getLabel = (): string => {
    if (status === 'ACTIVE') {
      return 'ACCEPTING QUOTES';
    }
    if (status === 'PAYMENT_FAILED') {
      return 'PAYMENT FAILED';
    }
    return status ?? 'unknown';
  };

  return <Chip variant="outlined" size={size} label={getLabel()} color={getBadgeColor()} />;
}
