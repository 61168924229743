import { useAPI } from 'hooks';
import { useEffect, useState } from 'react';
import { PendingRequests } from './PendingRequests';

interface Props{
  role: Role;
}

export function PendingRequestsContainer(props: Props) {
  const { role } = props;
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { api } = useAPI();
  const [followRequests, setFollowRequests] = useState<FollowShipperRequest[]>([]);

  useEffect(() => {
    if (loading && role.company) {
      api<FollowShipperRequest[]>('get', `/carriers/${role.id}/follow_requests/received?status=ACTIVE`).then((response) => {
        setFollowRequests(response.data);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch active join requests.');
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [api, role, loading]);

  const onRespondToRequest = (requestId: number, requestApproved: boolean) => {
    const body = { approved: requestApproved };
    api<unknown>('post', `/follow_requests/${requestId}/respond`, body).then(() => {
      setLoading(true);
      // refresh page to update data
      const currUrl = `${window.location.href}`;
      window.location.href = currUrl;
    }).catch((e) => {
      setError(e?.response?.data?.message || 'Failed to respond to follow shipper request.');
    });
  };
  return (
    <PendingRequests
      error={error}
      loading={loading}
      followRequests={followRequests}
      onRespondToRequest={onRespondToRequest}
    />
  );
}
