import * as React from 'react';

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthContextState {
  isAuthenticated: boolean;
  user: User | null;
  isLoading: boolean;
  isInitialized: boolean;
  auth: RefreshTokensAuthData | SignInAuthData | null;
  loginError: string | null;
  challengeAuth: ChangePasswordChallengeAuthenticationResult | null;
  attemptedVoluntellLogin: boolean;
  pubnub: PubnubClientData | null
}

interface RefreshTokenAction {
  type: 'refreshToken',
  data: {
    user: User,
    auth: RefreshTokensAuthData,
    pubnub: PubnubClientData
  }
}

interface RequireAuthAction {
  type: 'requireAuth';
}

interface RequestLoginAction {
  type: 'requestLogin';
  data: {
    voluntellLogin: boolean;
  }
}

interface LoginAction {
  type: 'login';
  data: {
    user: User;
    auth: SignInAuthData;
    pubnub: PubnubClientData;
  }
}

interface LoginErrorAction {
  type: 'loginError';
  data: {
    loginErrorMsg: string | null;
  }
}

interface SetUserAction {
  type: 'setUser';
  data: {
    user: User;
  }
}

interface UpdatePubnubData {
  type: 'updatePubnubData',
  data: {
    pubnub: PubnubClientData
  }
}

interface ChangePasswordChallengeAction {
  type: 'changePasswordChallenge',
  data: {
    user: User,
    challengeAuth: ChangePasswordChallengeAuthenticationResult,
    isVoluntell: boolean;
    pubnub: PubnubClientData
  }
}

type AuthActions =
  LoginAction
  | LoginErrorAction
  | RefreshTokenAction
  | RequestLoginAction
  | RequireAuthAction
  | SetUserAction
  | ChangePasswordChallengeAction
  | UpdatePubnubData;

interface AuthContextDispatch {
  dispatch: React.Dispatch<AuthActions>
}

const initalState = {
  isAuthenticated: false,
  user: null,
  isLoading: true,
  isInitialized: false,
  auth: null,
  loginError: null,
  challengeAuth: null,
  attemptedVoluntellLogin: false,
  pubnub: null,
};

export const authContext = React.createContext<AuthContextState & AuthContextDispatch>({
  ...initalState,
  dispatch: () => null,
});

function reducer(state: AuthContextState, action: AuthActions): AuthContextState {
  switch (action.type) {
    case 'setUser':
      return {
        ...state,
        user: action.data.user,
      };
    case 'requestLogin':
      return {
        ...state,
        isLoading: true,
        attemptedVoluntellLogin: action.data.voluntellLogin,
      };
    case 'login':
      return {
        isAuthenticated: true,
        user: action.data.user,
        auth: action.data.auth,
        isLoading: false,
        isInitialized: true,
        challengeAuth: null,
        loginError: null,
        attemptedVoluntellLogin: false,
        pubnub: action.data.pubnub,
      };
    case 'loginError':
      return {
        ...state,
        isLoading: false,
        loginError: action.data.loginErrorMsg,
      };
    case 'changePasswordChallenge':
      return {
        isAuthenticated: false,
        user: action.data.user,
        auth: null,
        isLoading: false,
        isInitialized: true,
        loginError: null,
        challengeAuth: action.data.challengeAuth,
        attemptedVoluntellLogin: true,
        pubnub: action.data.pubnub,
      };
    case 'refreshToken':
      return {
        isAuthenticated: true,
        user: action.data.user,
        auth: action.data.auth,
        isLoading: false,
        isInitialized: true,
        loginError: null,
        challengeAuth: null,
        attemptedVoluntellLogin: false,
        pubnub: action.data.pubnub,
      };
    case 'requireAuth':
      return {
        ...state,
        isLoading: false,
      };
    case 'updatePubnubData':
      return {
        ...state,
        pubnub: action.data.pubnub,
      };
    default:
      return state;
  }
}

export function AuthProvider(props: AuthProviderProps) {
  const { children } = props;

  const [state, dispatch] = React.useReducer(reducer, initalState);

  const memoState = React.useMemo(() => ({ ...state, dispatch }), [state]);

  return (
    <authContext.Provider value={memoState}>
      {children}
    </authContext.Provider>
  );
}
