import {
  Box,
} from '@mui/material';
import { useDisclosure, useShipperShipmentPageContext } from 'hooks';
import { useState } from 'react';
import { CancelShipmentForm } from '../CancelShipmentForm';
import { CreateTemplateFromShipmentModal } from '../CreateTemplateFromShipmentModal';
import { ShareShipmentModal } from '../ShareShipmentModal';
import { UpdateShipmentEmailNotificationSettingsModal } from '../UpdateShipmentEmailNotificationSettingsModal';
import { ShipmentOverviewExpanded } from './ShipmntOverviewExpanded';
import { ShareShipmentWithTeammatesModal } from './components/ShareShipmentWithTeammatesModal';

export function ShipmentOverviewExpandedContainer() {
  const { shipment, onRefresh } = useShipperShipmentPageContext();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: shareShipmentOpen,
    onClose: onShareShipmentClose,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onOpen: onShareShipmentOpen,
  } = useDisclosure();
  const {
    isOpen: createTemplateModalOpen,
    onClose: onCreateTemplateModalClose,
    onOpen: onOpenCreateTemplateModal,
  } = useDisclosure(false);
  const [isShipmentCopyOpen, setIsShipmentCopyOpen] = useState<boolean>(false);

  const {
    isOpen: updateEmailNotificationSettingsOpen,
    onClose: onCloseUpdateEmailNotificationSettings,
    onOpen: onOpenUpdateEmailNotificationSettings,
  } = useDisclosure(false);

  const {
    isOpen: shareShipmentWithTeammatesIsOpen,
    onClose: onCloseShareShipmentWithTeammates,
    onOpen: onOpenShareShipmentWithTeammates,
  } = useDisclosure(false);

  const handleShipmentCopyClose = () => {
    setIsShipmentCopyOpen(false);
  };

  const handleShipmentCopyOpen = () => {
    setIsShipmentCopyOpen(true);
  };

  return (
    <Box>
      <ShipmentOverviewExpanded
        shipment={shipment}
        onOpenCancelShipment={onOpen}
        shipmentCopyFormOpen={isShipmentCopyOpen}
        onShipmentCopyClose={handleShipmentCopyClose}
        onShipmentCopyOpen={handleShipmentCopyOpen}
        onCreateTemplateFromShipmentOpen={onOpenCreateTemplateModal}
        onUpdateEmailNotificationSettingsOpen={onOpenUpdateEmailNotificationSettings}
        onShareShipmentWithTeammatesOpen={onOpenShareShipmentWithTeammates}
      />
      {shipment !== undefined
      && <CancelShipmentForm open={isOpen} onClose={onClose} shipment={shipment} />}
      {
        shipment !== undefined
        && (
        <CreateTemplateFromShipmentModal
          shipment={shipment}
          onTemplateCreated={onCreateTemplateModalClose}
          onModalClose={onCreateTemplateModalClose}
          modalOpen={createTemplateModalOpen}

        />
        )
      }

      {
        shipment !== undefined
        && (
        <ShareShipmentModal
          open={shareShipmentOpen}
          onClose={onShareShipmentClose}
          shipment={shipment}
        />
        )
      }
      {
        shipment !== undefined
        && (
          <UpdateShipmentEmailNotificationSettingsModal
            shipment={shipment}
            onModalClose={() => {
              onCloseUpdateEmailNotificationSettings();
              if (onRefresh) {
                onRefresh();
              }
            }}
            onSettingsUpdated={onCloseUpdateEmailNotificationSettings}
            modalOpen={updateEmailNotificationSettingsOpen}
          />
        )
      }
      {
        shipment !== undefined
        && (
          <ShareShipmentWithTeammatesModal
            shipment={shipment}
            onModalClose={() => {
              onCloseShareShipmentWithTeammates();
              if (onRefresh) {
                onRefresh();
              }
            }}
            onTeammatesAdded={onCloseShareShipmentWithTeammates}
            modalOpen={shareShipmentWithTeammatesIsOpen}
          />
        )
      }

    </Box>
  );
}
