import { Box, Button, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDisclosure, useShipperShipmentPageContext } from 'hooks';
import { useState } from 'react';
import { ISOStringToLocalReadableTimestamp } from 'utils';
import { UpdateShipmentCustomNameModal } from '../UpdateShipmentCustomNameModal';

interface Props {
  bookings: MultiShipmentProjectBooking[];
}

interface EditShipmentCustomNameProps {
  customName: string | null | undefined;
  shipmentId: number | null;
}

export function MultiShipmentProjectBookingsTable(props: Props) {
  const {
    bookings,
  } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { onRefresh } = useShipperShipmentPageContext();
  const [
    editableShipment,
    setEditableShipment] = useState<EditShipmentCustomNameProps | undefined>(undefined);

  const onUpdateShipmentReferenceClick = (booking: MultiShipmentProjectBooking) => {
    setEditableShipment({
      customName: booking.child_shipment.custom_name,
      shipmentId: booking.child_shipment.id,
    });
    onOpen();
  };

  return (
    <Box>
      {
        editableShipment && (
          <UpdateShipmentCustomNameModal
            onModalClose={() => {
              onClose();
              setEditableShipment(undefined);
            }}
            isOpen={isOpen}
            customName={editableShipment.customName}
            shipmentId={editableShipment.shipmentId}
            onUpdateSuccess={() => {
              if (onRefresh) {
                onRefresh();
              }
            }}
          />
        )
      }
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer component={Paper}>
          <Table
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>Shipment ID</TableCell>
                <TableCell>Shipment Reference</TableCell>
                <TableCell>Action</TableCell>
                <TableCell align="right">Carrier Company</TableCell>
                <TableCell align="right">Carrier Contact</TableCell>
                <TableCell align="right">Assigned by</TableCell>
                <TableCell align="right">Booked for $</TableCell>
                <TableCell align="right">Currency</TableCell>
                <TableCell align="right">Booked At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookings.map((booking: MultiShipmentProjectBooking) => (
                <TableRow
                  key={booking.child_shipment.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <a target="_blank" href={`/shipments/${booking.child_shipment.id}`} rel="noreferrer">
                      <Typography sx={{ textDecoration: 'underline blue', color: 'blue' }}>
                        {booking.child_shipment.id}
                      </Typography>
                    </a>
                  </TableCell>
                  <TableCell>{booking.child_shipment.custom_name}</TableCell>
                  <TableCell align="right">
                    <Button sx={{ alignSelf: 'start' }} onClick={() => onUpdateShipmentReferenceClick(booking)}>
                      Update shipment reference
                    </Button>
                  </TableCell>
                  <TableCell align="right">{booking.carrier_company.name}</TableCell>
                  <TableCell align="right">{`${booking.carrier.user.first_name} ${booking.carrier.user.last_name}`}</TableCell>
                  <TableCell align="right">{`${booking.shipper_assigner.user.first_name} ${booking.shipper_assigner.user.last_name}`}</TableCell>
                  <TableCell align="right">{`$${parseFloat(booking.booked_price).toFixed(2)}`}</TableCell>
                  <TableCell align="right">{booking.booked_currency.code}</TableCell>
                  <TableCell align="right">{ISOStringToLocalReadableTimestamp(booking.created_at)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
