import { ShipmentDataSection } from './ShipmentDataSection';

interface Props {
  shipment: CarrierCondensedShipment | undefined;
  dividerComponent: JSX.Element;
}

export function ShipmentDataSectionContainer(props: Props) {
  const { shipment, dividerComponent } = props;
  if (!shipment || shipment?.info?.type === 'MULTI_SHIPMENT_PROJECT' || shipment?.info.type === 'MULTI_SHIPMENT_PROJECT_CHILD') return (<div />);
  return (<ShipmentDataSection shipment={shipment} dividerComponent={dividerComponent} />);
}
