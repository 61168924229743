import { useSubscriptionStatus } from 'hooks/useSubscriptionStatus';
import { useEffect, useState } from 'react';
import { SHIPMENTS_PAGE_SIZE, getPageCount, hasPaidCarrierSubscription } from 'utils';
import { useShipments } from '../../../../hooks/useShipments';
import { ShipmentsList } from '../ShipmentList/ShipmentsList';
import { ShipmentsFilter } from '../ShipmentsFilter';

interface Props {
  readFromContext: boolean;
  resetReadFromContext: VoidFunction;
  onShipmentSearchCreated: VoidFunction;
}

export function ShipmentListContainer(props: Props) {
  const {
    readFromContext,
    resetReadFromContext,
    onShipmentSearchCreated,
  } = props;
  const [page, setPage] = useState<number>(1);

  const {
    shipments, error, count, isLoading,
  } = useShipments({ page, pageSize: SHIPMENTS_PAGE_SIZE });
  const pages = getPageCount(count, SHIPMENTS_PAGE_SIZE);
  const [isPayingCarrier, setIsPayingCarrier] = useState<boolean>(false);

  const { subscription } = useSubscriptionStatus();

  useEffect(() => {
    if (hasPaidCarrierSubscription(subscription) && !isPayingCarrier) {
      setIsPayingCarrier(true);
    }
  }, [subscription, isPayingCarrier]);

  const handlePageChange = (e: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  return (
    <>
      <ShipmentsFilter
        title="Available Shipments"
        readFromContext={readFromContext}
        resetReadFromContext={resetReadFromContext}
        shipmentCount={count}
        onShipmentSearchCreated={onShipmentSearchCreated}
      />
      <ShipmentsList
        shipments={shipments as CarrierCondensedShipment[]}
        error={error}
        handlePageSelected={handlePageChange}
        pages={pages}
        isLoading={isLoading}
        page={page}
        isPayingCarrier={isPayingCarrier}
      />
    </>
  );
}
