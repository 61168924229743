import {
  Box, CircularProgress, Divider, Pagination, Stack, Typography,
} from '@mui/material';
import { ShipmentPreview } from '../ShipmentPreview';

interface Props {
  shipments: ShipperCondensedShipment[]
  error: string | null;
  isLoading: boolean;
  handlePageSelected: (e: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  pages: number;

}

export function ShipmentsList(props: Props) {
  const {
    shipments, error, isLoading, page, pages, handlePageSelected,
  } = props;

  if (isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (error != null) {
    return (
      <Stack mb="1em" alignItems="center">
        <Typography color="red">{error}</Typography>
      </Stack>
    );
  }

  if (shipments.length === 0) {
    return (
      <Stack direction="column" justifyContent="center">
        <Typography sx={{ my: '4rem' }} textAlign="center" variant="body1">
          You have no shipments. Post a shipment to get started!
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack>
      {shipments.map((shipment) => (
        <Box key={shipment.info.id}>
          <Divider style={{ margin: '0' }} />
          <ShipmentPreview shipment={shipment} />
        </Box>
      ))}
      <Divider />
      <Box my="1rem" display="flex" alignItems="center" justifyContent="center">
        <Pagination
          count={pages}
          onChange={handlePageSelected}
          page={page}
        />
      </Box>
    </Stack>
  );
}
