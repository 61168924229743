import { Typography } from '@mui/material';
import { useShipmentQuotes } from 'hooks';
import { useEffect, useState } from 'react';
import { getBookedQuoteFromQuotes } from 'utils';

interface Props {
  shipment: CondensedShipment
}

export function ShipmentBookedPrice(props: Props) {
  const { shipment } = props;
  const [currency, setCurrency] = useState<string | null>(null);
  const [price, setPrice] = useState<number | null>(null);
  const { quotes } = useShipmentQuotes(shipment.info.id);

  useEffect(() => {
    if (quotes.length > 0) {
      const booked = getBookedQuoteFromQuotes(quotes);
      if (booked?.currency && booked?.price) {
        setCurrency(booked.currency);
        setPrice(booked.price);
      }
    }
  }, [quotes]);

  if (!currency || !price) {
    return <div />;
  }

  return (
    <Typography variant="body1">
      <b>Booked at </b>
      $
      {price}
      {' '}
      {currency}
    </Typography>
  );
}
