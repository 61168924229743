import { DisplayableRoutePoint } from 'components/Shipper/components/CreateShipmentForm/components/RoutesForm/types';
import { CreateShipmentFormState, CreateShipmentInfo } from 'components/Shipper/components/CreateShipmentForm/types';

export function createDisplayableRoutePoint(
  routePoint: RoutePoint,
): DisplayableRoutePoint {
  const readableAddress = `${routePoint.point_location.address}, ${routePoint.point_location.city}, ${routePoint.point_location.province} ${routePoint.point_location.postal ?? ''}, ${routePoint.point_location.country}`;

  const displayablePoint: DisplayableRoutePoint = {
    stop_type: routePoint.stop_type,
    stop_operation: routePoint.stop_operation,
    point_number: routePoint.point_number,
    point_location: routePoint.point_location,
    addressId: routePoint.point_location.place_id,
    addressReadable: readableAddress,
  };
  return displayablePoint;
}

export function getCurrencyString(currencyId: number): string {
  if (currencyId === 1) {
    return 'CAD';
  }
  return 'USD';
}

export function createShipmentFormState(
  shipment: ShipperCondensedShipment,
  routePoints: RoutePoint[],
): CreateShipmentFormState {
  /*
  Creates a CreateShipmentFormState from a CondensedShipment in order to use as initial state
  in the shipment creation form.
  */
  const displayableRoutePoints: DisplayableRoutePoint[] = [];
  routePoints.forEach((point) => {
    displayableRoutePoints.push(createDisplayableRoutePoint(point));
  });

  const shipmentState: CreateShipmentFormState = {
    shipmentType: shipment.info.type === 'SINGLE' || shipment.info.type === 'MULTI_SHIPMENT_PROJECT' ? shipment.info.type : 'SINGLE',
    equipmentTypes: shipment.equipments.map(((equipment) => equipment.type)),
    service: shipment.info.service,
    commodity: shipment.info?.commodity ? shipment.info?.commodity.code : '',
    weightLb: shipment.info.weight_lb.toString(),
    footage: shipment.info.footage ? shipment.info.footage.toString() : '',
    specialRequirements: shipment.info.special_requirements,
    additionalDetails: shipment.info.additional_details ? shipment.info.additional_details : '',
    preferredPrice: shipment.info.preferred_price ? shipment.info.preferred_price.toString() : '',
    routePoints: displayableRoutePoints,
    currency: getCurrencyString(shipment.info.currency),
    notificationSettingsType: 'ALL_CARRIER_NETWORK',
    notificationSetingsEmails: [],
    commodityFreetext: shipment?.info?.commodity_freetext ?? '',
    isBookNow: false,
    bookNowCurrency: 'CAD',
    bookNowPrice: '0',
    customName: shipment?.custom_name || '',
    emailNotificationType: 'ALL',
    shareeRoleIds: shipment?.sharees ? shipment.sharees.map(
      (sharee: ShipmentSharee) => sharee.sharee.id,
    ) : [],
    shareShipment: shipment?.sharees && shipment.sharees.length > 0,
    availableLoads: shipment.project?.loads_available.toString() ?? '',
  };
  return shipmentState;
}

export function createShipmentInfo(shipment: CondensedShipment): CreateShipmentInfo {
  const shipmentInfo: CreateShipmentInfo = {
    type: shipment.info.type,
    service: shipment.info.service,
    weightLb: shipment.info.weight_lb,
    footage: shipment.info.footage,
    specialRequirements: shipment.info.special_requirements,
    additionalDetails: shipment.info.additional_details ? shipment.info.additional_details : '',
    preferredPrice: shipment.info.preferred_price,
    currency: getCurrencyString(shipment.info.currency),
  };
  return shipmentInfo;
}
