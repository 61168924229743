import * as React from 'react';

export function usePasswordFieldToggle() {
  const [passwordIsVisible, setPasswordIsVisible] = React.useState<boolean>(false);

  const handleShowPasswordChange = (): void => {
    setPasswordIsVisible(!passwordIsVisible);
  };

  return { passwordIsVisible, handleShowPasswordChange };
}
