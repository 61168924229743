import { Typography } from '@mui/material';

interface Props {
  userIsAuthor: boolean;
  fileName: string;
  fileURL: string;
}

export function FileMessageDisplay(props: Props) {
  const { userIsAuthor, fileName, fileURL } = props;
  return (
    <Typography
      variant="body1"
      textAlign={userIsAuthor ? 'right' : 'left'}
    >
      <a href={fileURL} target="_blank" rel="noopener noreferrer">{fileName}</a>
    </Typography>
  );
}
