import { GetRouteResponse } from 'components/ShipmentOverviewTab/components/RouteTab/types';
import { useAPI } from 'hooks';
import { useEffect, useState } from 'react';
import { ShipmentCopyModal } from './ShipmentCopyModal';
import { createShipmentFormState } from './utils';

interface Props{
  shipment: CondensedShipment | undefined;
  onShipmentCopyClose: VoidFunction;
}

export function ShipmentCopyModalContainer(props: Props) {
  const { shipment, onShipmentCopyClose } = props;
  const { api } = useAPI();
  const [error, setError] = useState<null | string>(null);
  const [routePoints, setRoutePoints] = useState<RoutePoint[]>([]);

  useEffect(() => {
    if (shipment !== undefined) {
      api<GetRouteResponse>('get', `/routes/${shipment.info.route}`).then((response) => {
        setRoutePoints(response?.data.route.route_points);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to copy route information, please re-enter');
      });
    } else {
      setError('Failed to load shipment information');
    }
  }, [shipment, api]);

  let shipmentState;
  if (shipment !== undefined) {
    shipmentState = createShipmentFormState(shipment as ShipperCondensedShipment, routePoints);
  }

  return (
    <ShipmentCopyModal
      shipment={shipment}
      onClose={onShipmentCopyClose}
      shipmentState={shipmentState}
      error={error}
    />
  );
}
