export interface QuoteActionState {
  actionType: CondensedQuoteActionValue;
  isDisplayed: boolean;
}

export interface QuoteActionsState {
  ACCEPT: QuoteActionState;
  COUNTER: QuoteActionState;
  CANCEL: QuoteActionState;
  DECLINE_WITH_FEEDBACK: QuoteActionState;
  DECLINE: QuoteActionState;
}

export const DEFAULT_QUOTE_STATE = {
  ACCEPT: {
    isDisplayed: false,
    actionType: 'ACCEPT' as CondensedQuoteActionValue,
  },
  COUNTER: {
    isDisplayed: false,
    actionType: 'COUNTER' as CondensedQuoteActionValue,
  },
  CANCEL: {
    isDisplayed: false,
    actionType: 'CANCEL' as CondensedQuoteActionValue,
  },
  DECLINE_WITH_FEEDBACK: {
    isDisplayed: false,
    actionType: 'DECLINE_WITH_FEEDBACK' as CondensedQuoteActionValue,
  },
  DECLINE: {
    isDisplayed: false,
    actionType: 'DECLINE' as CondensedQuoteActionValue,
  },
};
