import { Stack, useMediaQuery } from '@mui/material';
import { QuoteQA } from '../../../QuoteQA';
import { MultiShipmentProjectQuoteDetail } from '../MultiShipmentProjectQuoteDetail';
import { SingleShipmentQuoteDetail } from '../SingleShipmentQuoteDetail';

interface Props {
  quote: ShipperCondensedQuote;
}

export function QuoteDetail(props: Props) {
  const {
    quote,
  } = props;
  const useColumnLayout = useMediaQuery('(max-width:650px)');

  let detailComponent = (
    <SingleShipmentQuoteDetail
      quote={quote}
      useColumnLayout={useColumnLayout}
    />
  );

  if (quote?.quote_type === 'MULTI_SHIPMENT_PROJECT') {
    detailComponent = (
      <MultiShipmentProjectQuoteDetail
        quote={quote}
        useColumnLayout={useColumnLayout}
      />
    );
  }

  return (
    <Stack
      direction={useColumnLayout ? 'column' : 'row'}
      flexWrap="wrap"
      mb="1rem"
      justifyContent="space-between"
    >
      {detailComponent}
      <Stack
        id="quote-qa-container"
        pr="1rem"
        width={useColumnLayout ? undefined : '40%'}
        direction="column"
      >
        <QuoteQA quote={quote} />
      </Stack>
    </Stack>
  );
}
