import { useAPI, useInterval } from 'hooks';
import { useCallback, useState } from 'react';
import { CompanySearchBar } from './CompanySearchBar';

interface Props {
  onJoinCompanyRequestSent: () => void;
  onCreateCompanySelected: () => void;
}

export function CompanySearchBarContainer(props: Props) {
  const { onJoinCompanyRequestSent, onCreateCompanySelected } = props;
  const { api } = useAPI();

  const [error, setError] = useState<null | string>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [lastValueSearched, setLastValueSearched] = useState<string | null>(null);

  const fetchCompanySearchResults = useCallback(() => {
    if (searchValue === '') {
      setCompanies([]);
    } else if (lastValueSearched !== searchValue) {
      api<Company[]>('get', `/companies?name=${searchValue}`).then((response) => {
        setCompanies(response.data);
        setError(null);
        setLastValueSearched(searchValue);
      }).catch(() => {
        setError('Failed to get companies.');
      });
      setLoading(false);
    }
  }, [api, setLoading, searchValue, lastValueSearched, setLastValueSearched]);

  const handleSelectedCompany = (companyID: number): void => {
    api('post', '/join-company-requests', { company_id: companyID }).then(() => {
      onJoinCompanyRequestSent();
      setError(null);
    }).catch(() => {
      setError('Failed to send join company request.');
    });
  };

  const handleSearchChange = (value: string): void => {
    setSearchValue(value);
  };

  /*
    hack to make a sort of 'debounce' effect on company name queries.
    Check fetchCompanySearchResults and the use of lastValueSearched in
    order to avoid sending redundant requests
  */
  useInterval(fetchCompanySearchResults, 1500);

  return (
    <CompanySearchBar
      isLoading={isLoading}
      searchText={searchValue}
      searchResults={companies}
      error={error}
      onSearchChange={handleSearchChange}
      handleCreateCompanySelected={onCreateCompanySelected}
      onSelectCompany={handleSelectedCompany}
    />
  );
}
