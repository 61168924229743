import {
  Box, Button, Stack, Typography,
} from '@mui/material';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { useAPI, useRoleContext } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { ShipmentSearchList } from './ShipmentSearchList';

interface Props {
  isOpen: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  onSavedSearchClick: VoidFunction;
  refetchShipmentSearches: boolean;
  onSearchesRefetched: VoidFunction;
}

export function ShipmentSearchListContainer(props: Props) {
  const {
    isOpen, onOpen, onClose, onSavedSearchClick, refetchShipmentSearches, onSearchesRefetched,
  } = props;
  const { role } = useRoleContext();
  const [shipmentSearches, setShipmentSearches] = useState<ShipmentSearch[]>([]);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | string>(null);
  const { api } = useAPI();

  useEffect(() => {
    if (loading && role?.company) {
      api<ShipmentSearch[]>('get', `/carriers/${role?.id}/lanes`).then((response) => {
        setShipmentSearches(response.data);
        setError(null);
      }).catch((e) => {
        if (e?.response?.status === 403 && e?.response?.data?.detail) {
          setError(e.response.data.detail);
        } else {
          setError(e?.response?.data?.message || 'Failed to fetch shipment searches. Please contact us.');
        }
      }).finally(() => { setIsLoading(false); });
    }
  }, [loading, role, api]);

  const reloadSearches = useCallback(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (isOpen) {
      // refetch searches whenever the lanes are displayed
      setIsLoading(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (refetchShipmentSearches) {
      setIsLoading(true);
      onSearchesRefetched();
    }
  }, [refetchShipmentSearches, onSearchesRefetched]);

  return (
    <Box>
      <Stack direction="row" mt="1rem" ml="1rem" mr="1rem">
        <Typography variant="h6">My Lanes</Typography>
        <Box display="flex" ml="auto">
          <Button
            variant="contained"
            onClick={isOpen ? onClose : onOpen}
          >
            {isOpen ? 'Hide My Lanes' : 'Show My Lanes'}
          </Button>
        </Box>
      </Stack>
      <ShipmentSearchList
        shipmentSearches={shipmentSearches}
        error={error}
        loading={loading}
        isOpen={isOpen}
        onSavedSearchClick={onSavedSearchClick}
        reloadSearches={reloadSearches}
      />
      <Box mt="1rem" mb="1rem">
        <EvotruxDivider />
      </Box>
    </Box>
  );
}
