import { useCallback, useEffect, useRef } from 'react';

import {
  Box, Stack,
  Typography,
} from '@mui/material';
import {
  Chat, MessageEnvelope, MessageInput,
  MessagePayload,
} from '@pubnub/react-chat-components';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { getUserEntity } from 'utils';
import { MessageHistoryDisplay } from './components/MessageHistoryDisplay';

interface Props {
  currentChannel: string;
  channelGroup: UserChannelGroup;
  user: User;
  userPubnubData: PubnubClientData;
  onMessageSent: (value: MessagePayload | File | unknown) => void;
  conversationHeader: React.ReactNode;
  onNewMessage: (message: MessageEnvelope) => void;
  useDesktopLayout: boolean;
  isStandaloneChat?: boolean;
}

const CONVERSATION_CONTAINER_ID = 'conversation-container';

export function ChatConversation(props: Props) {
  const {
    currentChannel,
    channelGroup,
    user,
    userPubnubData,
    onMessageSent,
    conversationHeader,
    onNewMessage,
    useDesktopLayout,
    isStandaloneChat,
  } = props;

  const containerRef = useRef(null);

  useEffect(() => {
    function handleResize() {
      if (!isStandaloneChat) {
        const windowHeight = window.innerHeight;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const containerTop = containerRef.current.offsetTop;
        const containerMargin = 100; // adjust as needed
        const containerMaxHeight = windowHeight - containerTop - containerMargin;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        containerRef.current.style.minHeight = `${containerMaxHeight}px`;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        containerRef.current.style.maxHeight = `${containerMaxHeight}px`;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        containerRef.current.style.overflowY = 'auto';
      }
    }

    handleResize(); // set initial height
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isStandaloneChat]);

  const scrollToBottomOnChat = useCallback(() => {
    const container = document.getElementById(CONVERSATION_CONTAINER_ID);
    if (container && container?.scroll) {
      container.scroll({ top: container.scrollHeight, behavior: 'smooth' });
    }
  }, []);

  const onMessage = (message: MessageEnvelope) => {
    if (message?.channel && message.channel === currentChannel) {
      scrollToBottomOnChat();
    }
    onNewMessage(message);
  };

  useEffect(() => {
    if (currentChannel && !isStandaloneChat) {
      // scroll to bottom everytime the channel switches
      scrollToBottomOnChat();
    }
  }, [currentChannel, isStandaloneChat, scrollToBottomOnChat]);

  if (!isStandaloneChat) {
    window.location.hash = CONVERSATION_CONTAINER_ID;
  }

  return (
    <Stack
      borderRadius={2}
      boxShadow={2}
      id="chat-pane"
      direction="column"
      width={useDesktopLayout ? '70%' : '100%'}
      minHeight="100xp"
    >
      {conversationHeader}
      <EvotruxDivider />
      <Chat
        theme="light"
        currentChannel={currentChannel}
        channelGroups={[channelGroup.name]}
        users={[getUserEntity(user, userPubnubData)]}
        onMessage={onMessage}
      >
        <Box id="msg-and-input-container">
          <Box
            id={CONVERSATION_CONTAINER_ID}
            ref={containerRef}
            sx={{
              tabIndex: 0, minHeight: '200px', maxHeight: '500px', overflowY: 'scroll',
            }}
          >
            <MessageHistoryDisplay currentChannel={currentChannel} />
          </Box>
          <Box id="chat-input-boxs">
            <MessageInput
              typingIndicator
              fileUpload="all"
              senderInfo
              onSend={onMessageSent}
            />
          </Box>
          <Typography sx={{ ml: '1rem' }} variant="body2" color="text.secondary">Max file upload size: 5MB</Typography>
        </Box>
      </Chat>
    </Stack>
  );
}

ChatConversation.defaultProps = {
  isStandaloneChat: false,
};
