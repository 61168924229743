import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {
  Box, CircularProgress, Divider, Stack, Typography,
} from '@mui/material';
import { GetPaidFactoringButton } from 'components/Carrier/GetPaidFactoringButton';
import { ShipmentLocationText } from 'components/Texts/ShipmentLocationText';
import { useRoleContext, useShipment } from 'hooks';
import { getPointDisplayText, getShipmentIdFromShipmentBookedChatName } from 'utils';
import { ShipmentBookedBy } from '../ShipmentBookedBy';
import { ShipmentBookedPrice } from '../ShipmentBookedPrice';
import { ChatAddCarrierToNetworkButton } from './ChatAddCarrierToNetworkButton';
import { MuteChatButton } from './MuteChatButton';

interface Props {
  currentChannel: string;
}
export function ChatConversationHeader(props: Props) {
  const { currentChannel } = props;
  const shipmentId = getShipmentIdFromShipmentBookedChatName(currentChannel);
  const { shipment, loading } = useShipment({ shipmentId: parseInt(shipmentId, 10) });
  const { role } = useRoleContext();

  let component = <div />;
  if (loading || shipment == null) {
    component = (
      <Stack alignItems="center" direction="column">
        <Typography variant="h4">{`Shipment ${shipmentId}`}</Typography>
        <CircularProgress />
      </Stack>
    );
  } else if (shipment && shipment.info.id.toString() !== shipmentId) {
    // component returns empty header if the header shpiment id and the shipment passed do not match
    return (<div />);
  } else if (shipment) {
    component = (
      <Box>
        <Stack direction="column" alignItems="center">
          <Box sx={{ cursor: 'pointer' }} onClick={() => { window.open(`/shipments/${shipmentId}`, '_blank'); }}>
            <Typography variant="h4" color="blue">
              <u>
                {`Shipment ${shipment.info.id}`}
                {shipment.custom_name != null ? ` - ${shipment.custom_name}` : ''}
              </u>
            </Typography>
          </Box>
          <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap">
            <Stack direction="row" spacing={1}>
              <ShipmentLocationText
                data={getPointDisplayText(shipment.first_point)}
                icon={<TripOriginIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
              />
              <ArrowForwardIcon />
              <ShipmentLocationText
                data={getPointDisplayText(shipment?.last_point)}
                icon={<FmdGoodIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
              />
            </Stack>
            <Divider orientation="vertical" />
            <ShipmentBookedPrice shipment={shipment} />
            <Divider orientation="vertical" />
            <ShipmentBookedBy shipment={shipment} />

          </Stack>
          <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap">
            {role != null && role.type === 'SHIPPER' && (
            <ChatAddCarrierToNetworkButton shipment={shipment} buttonVariant="contained" />
            )}
            {role != null && role.type === 'CARRIER' && (
              <GetPaidFactoringButton
                referralMetadata={{ source: 'chat', shipment_id: shipment.info.id }}
              />
            )}
            <MuteChatButton
              currentChannel={currentChannel}
            />
          </Stack>
        </Stack>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing={1} p=".7rem" justifyContent="center">
      {component}
    </Stack>
  );
}
