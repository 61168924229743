import { ShipmentFiltersProvider } from 'context';
import React, { useCallback } from 'react';
import { ShipmentList } from '../ShipmentListContainer';
import { ShipmentSearchList } from '../ShipmentSearchList';

interface Props {
  scrollToTop: VoidFunction;
}

export function ShipmentFilterSavedSearch(props: Props) {
  const { scrollToTop } = props;
  const [isSavedSearchOpen, setIsSavedSearchOpen] = React.useState<boolean>(false);
  const [readFromContext, setReadFromContext] = React.useState<boolean>(false);
  const [refetchShipmentSearches, setRefetchShipmentSearches] = React.useState<boolean>(false);

  const onRefetchSearches = useCallback(() => {
    setRefetchShipmentSearches(true);
  }, []);

  const onSearchesRefetched = useCallback(() => {
    setRefetchShipmentSearches(false);
  }, []);

  const resetReadFromContext = (): void => {
    setReadFromContext(false);
  };

  const onSavedSearchOpen = (): void => {
    setIsSavedSearchOpen(true);
  };

  const onSavedSearchClose = (): void => {
    setIsSavedSearchOpen(false);
  };

  const onSavedSearchClick = (): void => {
    onSavedSearchClose();
    setReadFromContext(true);
    scrollToTop();
  };

  return (
    <ShipmentFiltersProvider>
      <ShipmentSearchList
        isOpen={isSavedSearchOpen}
        onOpen={onSavedSearchOpen}
        onClose={onSavedSearchClose}
        onSavedSearchClick={onSavedSearchClick}
        refetchShipmentSearches={refetchShipmentSearches}
        onSearchesRefetched={onSearchesRefetched}
      />
      <ShipmentList
        readFromContext={readFromContext}
        resetReadFromContext={resetReadFromContext}
        onShipmentSearchCreated={onRefetchSearches}
      />
    </ShipmentFiltersProvider>
  );
}
