import { useEffect, useState } from 'react';
import { useFetchingComponentState } from './useFetchingComponentState';
import { useRoleContext } from './useRoleContext';

/*
Returns all branches and their members that the callee has access to.
Callee must have at least one BRANCH_ADMINISTRATOR role or a ADMINISTRATOR role
*/

export function useCompanyBranches() {
  const {
    role,
    company,
    hasBranchAdminRole,
    hasCompanyAdminRole,
  } = useRoleContext();
  const [branches, setBranches] = useState<BranchMembers[]>([]);
  const {
    fetch, loading, error, success,
  } = useFetchingComponentState<BranchMembers[]>({
    errorMessage: 'Failed to fetch branches',
    fetchConfig: {
      url: company != null && (hasBranchAdminRole() || hasCompanyAdminRole()) ? `/companies/${company.id}/branches` : '/',
    },
  });

  useEffect(() => {
    if (role != null && !success) {
      fetch((res) => {
        setBranches(res);
      });
    }
  }, [fetch, loading, error, success, role]);

  return {
    branches,
    error,
    loading,
  };
}
