import {
  Chip, Stack, Typography,
} from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  dashboard: React.ReactNode;
  title: string;
}

export function ExploShipperPage(props: Props) {
  const { dashboard, title } = props;

  return (
    <Box
      id="explo-dashboards-page"
      sx={{
        backgroundColor: '#fefefe',
        height: '100%',
        width: '100%',
        flex: 1,
        borderRadius: 4,
      }}
    >
      <Stack direction="column">
        <Stack direction="row" justifyContent="left" alignItems="center" spacing={2}>
          <Typography variant="h4" ml="1rem" mt="2rem">{title}</Typography>
          <Chip size="medium" label="BETA" variant="outlined" color="primary" />
        </Stack>
        {dashboard}
      </Stack>
    </Box>
  );
}
