import * as React from 'react';

interface RoleProviderProps {
  children: React.ReactNode
}

interface RoleContextState {
  company: Company | null;
  role: Role | null;
}

interface SetRoleAction {
  role: Role
  type: 'setRole'
}

interface ClearRole {
  type: 'clearRole'
}

type RoleActions = SetRoleAction | ClearRole;

interface RoleContextDispatch {
  dispatch: React.Dispatch<RoleActions>
}

const initialState = {
  company: null,
  role: null,
};

export const roleContext = React.createContext<RoleContextState & RoleContextDispatch>({
  ...initialState,
  dispatch: () => null,
});

function reducer(state: RoleContextState, action: RoleActions): RoleContextState {
  switch (action.type) {
    case 'setRole':
      return {
        role: action.role,
        company: action.role.company ?? null,
      };
    case 'clearRole':
      return { company: null, role: null };
    default:
      return state;
  }
}

export function RoleProvider(props: RoleProviderProps) {
  const { children } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const memoState = React.useMemo(() => ({ ...state, dispatch }), [state]);

  return (
    <roleContext.Provider value={memoState}>
      {children}
    </roleContext.Provider>
  );
}
