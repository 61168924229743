import {
  Box, Button, Stack, Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useRoleContext, useWriterComponentState } from 'hooks';

const FONT = '-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Helvetica Neue","Ubuntu",sans-serif';

interface CarrierPPBBillingSessionResponse {
  url: string;
}

export function PayAsYouGoPlanSelection() {
  const { company } = useRoleContext();
  const {
    loading: loadingPaymentMethodUpdate,
    error: paymentMethoUpdateError,
    write: getPaymentMethodUpdateSession,
  } = useWriterComponentState({
    errorMessage: 'Failed to request billing session. Please contact us if the issue persists.',
    writeConfig: {
      method: 'post',
      url: company != null && company.id != null ? `/stripe/companies/ppb/${company.id}/payment-method-update?source=subscription-page` : '/',
    },
  });

  const {
    loading: loadingPpbSubscriptionCreation,
    error: ppbSubscritionCreationError,
    write: createPPBSubscription,
  } = useWriterComponentState({
    errorMessage: 'Failed to create Pay-Per-Booking subscription. Please contact us if the issue persists.',
    writeConfig: {
      method: 'post',
      url: company != null && company.id != null ? `/subscription/pay-per-booking/${company.id}` : '/',
    },
  });

  const submitPaymentUpdateSession = () => {
    getPaymentMethodUpdateSession<CarrierPPBBillingSessionResponse>(
      (response: CarrierPPBBillingSessionResponse) => {
        window.location.href = response.url;
      },
    );
  };

  const requestPPBSubscriptionCreation = () => {
    createPPBSubscription<unknown>(
      () => {
        // request a payment method update session after creating the subscription.
        submitPaymentUpdateSession();
      },
    );
  };

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Stack>
        <Typography
          sx={{ fontFamily: FONT, fontWeight: '600' }}
          variant="h5"
          align="left"
        >
          Pay as you go
        </Typography>
      </Stack>
      <Stack direction="column" alignItems="center" mt="1.5rem">
        <Stack direction="row" justifyContent="left" alignItems="center" spacing={1}>
          <Typography variant="h3" fontWeight="400" sx={{ fontFamily: FONT }}>
            CA$25
          </Typography>
          <Stack direction="column">
            <Typography variant="body2" color="grey">Per</Typography>
            <Typography variant="body2" color="grey">Booking</Typography>
          </Stack>

        </Stack>
        <LoadingWrappedNode loading={loadingPaymentMethodUpdate || loadingPpbSubscriptionCreation}>
          <Button
            sx={{
              pt: '.8rem', pb: '.8rem', pr: '1.5rem', pl: '1.5rem', mt: '1rem', textTransform: 'none', borderRadius: '4',
            }}
            variant="contained"
            onClick={requestPPBSubscriptionCreation}
          >
            <Typography
              sx={{ fontFamily: FONT }}
              variant="h6"
            >
              Add Payment Details
            </Typography>
          </Button>
        </LoadingWrappedNode>
        <ErrorTypography error={paymentMethoUpdateError || ppbSubscritionCreationError} />
        <Box mt="1rem" pl="1rem" pr="1rem">
          <ul>
            <li>
              <Typography sx={{ fontFamily: FONT }} variant="body1">Pay a flat-rate fee of only $25 CAD per booking.</Typography>
            </li>
            <li>
              <Typography sx={{ fontFamily: FONT }} variant="body1">Submit unlimited quotes and start booking more direct shipper freight.</Typography>
            </li>
            <li>
              <Typography sx={{ fontFamily: FONT }} variant="body1">No upfront payment required.</Typography>
            </li>
          </ul>
        </Box>

      </Stack>
    </Stack>
  );
}
