import { Stack } from '@mui/material';
import { MessageEnvelope, isFilePayload } from '@pubnub/react-chat-components';
import { usePubNub } from 'pubnub-react';
import { FileMessageDisplay } from '../FileMessageDisplay';
import { MessageHeader } from '../MessageHeader';

interface Props {
  message: MessageEnvelope;
  userIsAuthor: boolean;
}

export function FileMessage(props: Props) {
  const { message, userIsAuthor } = props;
  const pubnubClient = usePubNub();

  if (!isFilePayload(message.message)) {
    return <div />;
  }

  let fileURL;
  if (message.channel !== undefined && message?.message?.file?.id && message?.message?.file?.name) {
    fileURL = pubnubClient.getFileUrl({
      channel: message.channel,
      id: message.message.file.id,
      name: message.message.file.name,
    });
  }

  return (
    <Stack
      direction="column"
    >
      <MessageHeader
        userIsAuthor={userIsAuthor}
        messageAuthor={message?.message?.message?.sender?.name ?? ''}
        messageCreatedAt={message?.message?.message?.createdAt}
      />
      {fileURL && (
        <FileMessageDisplay
          userIsAuthor={userIsAuthor}
          fileName={message.message.file.name}
          fileURL={fileURL}
        />
      )}
    </Stack>
  );
}
