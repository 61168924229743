import {
  Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import { ShareShipmentMenu } from '../ShareShipmentMenu';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  shipment: CondensedShipment;
}

export function ShareShipmentModal(props: Props) {
  const {
    open, onClose, shipment,
  } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h4">Share Shipment</Typography>
      </DialogTitle>
      <DialogContent>
        <ShareShipmentMenu shipment={shipment} onShipmentShared={onClose} />
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
}
