import CancelIcon from '@mui/icons-material/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { useShipperShipmentPageContext } from 'hooks';

interface Props {
  onOpenCancelShipment: () => void;
  onFinalizeProjectClicked: () => void;
}

export function CancelOrFinalizeShipment(props: Props) {
  const { onOpenCancelShipment, onFinalizeProjectClicked } = props;
  const { shipment } = useShipperShipmentPageContext();

  if (!shipment || shipment === undefined) {
    return <div />;
  }

  if (shipment.info.type === 'MULTI_SHIPMENT_PROJECT'
     && shipment?.shipment_status === 'ACTIVE'
      && shipment.project != null
       && shipment.project.loads_booked > 0
  ) {
    return (
      <ListItem>
        <Tooltip title={
          <Typography variant="h5">Your project will stop receiving quotes. Assigned shipments remain assigned. You can not undo this step.</Typography>
        }
        >
          <ListItemButton onClick={onFinalizeProjectClicked}>
            <ListItemIcon>
              <DoneAllIcon />
            </ListItemIcon>
            <ListItemText primary="Close Project" />
          </ListItemButton>
        </Tooltip>
      </ListItem>
    );
  }

  if (shipment?.shipment_status === 'ACTIVE') {
    return (
      <ListItem>
        <ListItemButton onClick={onOpenCancelShipment}>
          <ListItemIcon>
            <CancelIcon />
          </ListItemIcon>
          <ListItemText primary={`Cancel ${shipment?.info?.type === 'MULTI_SHIPMENT_PROJECT' ? 'Project' : 'Shipment'}`} />
        </ListItemButton>
      </ListItem>
    );
  }

  return <div />;
}
