import {
  clearLocalStorage, getMillisecondsLeftInSession, getTimeBoundedLocalStorageValue,
  localDataIsStale, ONE_MINUTE_IN_MILLISECONDS, removeLocalData, storeSessionTTL,
  storeValue,
} from '../utils/localStorageUtils';

const USER_LOCAL_STORAGE_KEY = 'user';
const AUTH_LOCAL_STORAGE_KEY = 'auth';
const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'refreshToken';
const SUBSCRIPTION_LOCAL_STORAGE_KEY = 'subscription';
const DEFAULT_TTL_MINUTES = 59;
const LOCAL_STORAGE_ROLE_KEY = 'role';

/*
  If the session data has TTL <= PREEMPTIVE_REFRESH_THRESHOLD_MINUTES
  the client data will be refreshed pre-emptively and the session TTL
  will be extended.
*/
const PREEMPTIVE_REFRESH_THRESHOLD_MINUTES = ONE_MINUTE_IN_MILLISECONDS * 15;

function getObjectOrNull<T>(stringifiedObject: string | null): T | null {
  if (stringifiedObject) {
    return JSON.parse(stringifiedObject) as T;
  }
  return null;
}

export function getLocalAuth(): object | null {
  const authData = getTimeBoundedLocalStorageValue(AUTH_LOCAL_STORAGE_KEY);
  return getObjectOrNull(authData);
}

export function getLocalUser(): User | null {
  const user = getTimeBoundedLocalStorageValue(USER_LOCAL_STORAGE_KEY);
  return getObjectOrNull<User>(user);
}

export function getLocalRefreshToken(): string | null {
  const token = getTimeBoundedLocalStorageValue(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
  return token;
}

export function storeSessionInitData(user: User, auth: SignInAuthData) {
  storeValue(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
  storeValue(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(auth));
  storeValue(REFRESH_TOKEN_LOCAL_STORAGE_KEY, auth.AuthenticationResult.RefreshToken);
  storeSessionTTL(DEFAULT_TTL_MINUTES);
}

export function clearClientData() {
  clearLocalStorage();
}

export function setUser(user: User) {
  storeValue(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
}

export function setRefreshToken(token: string) {
  storeValue(REFRESH_TOKEN_LOCAL_STORAGE_KEY, token);
  storeSessionTTL(DEFAULT_TTL_MINUTES);
}

export function extendSessionTTL() {
  storeSessionTTL(DEFAULT_TTL_MINUTES);
}

export function localStorageDataIsStale(): boolean {
  return localDataIsStale();
}

export function localStorageDataCanBeRefreshed(): boolean {
  if (localStorageDataIsStale()) {
    return true;
  }
  return getMillisecondsLeftInSession() <= PREEMPTIVE_REFRESH_THRESHOLD_MINUTES;
}

export function setLocalSubscription(subscription: CondensedSubscription) {
  storeValue(SUBSCRIPTION_LOCAL_STORAGE_KEY, JSON.stringify(subscription));
}

export function getLocalSubscription() {
  const subscription = getTimeBoundedLocalStorageValue(SUBSCRIPTION_LOCAL_STORAGE_KEY);
  return getObjectOrNull<CondensedSubscription>(subscription);
}

export function clearLocalSubscription() {
  removeLocalData(SUBSCRIPTION_LOCAL_STORAGE_KEY);
}

export function clearLocalRole() {
  removeLocalData(LOCAL_STORAGE_ROLE_KEY);
}

export function getLocalRole() {
  const role = getTimeBoundedLocalStorageValue(LOCAL_STORAGE_ROLE_KEY);
  return getObjectOrNull<Role>(role);
}

export function setLocalRole(role: Role) {
  storeValue(LOCAL_STORAGE_ROLE_KEY, JSON.stringify(role));
}
