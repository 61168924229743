import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { AddShipmentTrackingLinkModal } from 'components/ShipmentTrackingLink/AddShipmentTrackingLinkModal';
import { DeleteShipmentTrackingLinkModal } from 'components/ShipmentTrackingLink/DeleteShipmentTrackingLinkModal';
import { useDisclosure } from 'hooks';
import { ShipmentTrackingLinkDisplay as TrackingLinkDisplay } from '../../../../../../../ShipmentTrackingLink';

interface Props {
  dividerComponent: JSX.Element;
  trackingLink: ShipmentTrackingLink;
  showWarningOnCopy?: boolean;
}

export function ShipmentTrackingLinkDisplay(props: Props) {
  const { dividerComponent, trackingLink, showWarningOnCopy } = props;
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure(false);
  return (
    <>
      {dividerComponent}
      <TrackingLinkDisplay
        trackingLink={trackingLink}
        showWarningOnCopy={showWarningOnCopy}
        actions={(
          <Box>
            <Stack direction="row" spacing={1} justifyContent="flex-start">
              <Button
                variant="outlined"
                onClick={onOpen}
              >
                Update
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={onDeleteOpen}
              >
                Delete
              </Button>
            </Stack>

          </Box>
        )}
      />
      <AddShipmentTrackingLinkModal isOpen={isOpen} onClose={onClose} />
      <DeleteShipmentTrackingLinkModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        trackingLinkId={trackingLink.id}
      />
    </>
  );
}

ShipmentTrackingLinkDisplay.defaultProps = {
  showWarningOnCopy: false,
};
