import { useCallback, useEffect, useState } from 'react';
import { useAPI } from './useAPI';

import { useRoleContext } from './useRoleContext';
import { useSubscriptionStatus } from './useSubscriptionStatus';

interface ShipperCompanyPostingLimitsResponse {
  remaining_postings:number;
}

export function useShipperCompanyPostingLimits() {
  const [remainingPostings, setRemainingPostings] = useState<number | null>(null);
  const { role } = useRoleContext();
  const { subscription } = useSubscriptionStatus();
  const { api } = useAPI();

  const fetchLimits = useCallback(() => {
    if (role && (subscription?.subscription_plan === 'SHIPPER_SHIP_A'
    || subscription?.subscription_plan === 'SHIPPER_SHIP_B'
    || subscription?.subscription_plan === 'SHIPPER_OPTIMIZE_A'
    || subscription?.subscription_plan === 'SHIPPER_OPTIMIZE_B')) {
      api<ShipperCompanyPostingLimitsResponse>('get', `/subscription/company/${role.company?.id}/postings-left`).then((res) => {
        setRemainingPostings(res.data.remaining_postings);
      });
    }
  }, [role, subscription, api]);

  useEffect(() => {
    if (role != null && subscription) {
      fetchLimits();
    }
  }, [role, subscription, fetchLimits]);

  return {
    remainingPostings, refetchLimits: fetchLimits,
  };
}
