import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { VerifyFailed } from './components/VerifyFailed';
import { VerifySucceded } from './components/VerifySucceeded';
import { Verify } from './Verify';

/*
  This component will pull the verification code and the user email from url params.

  If confirmed, auth user and redirect to home
  If confirmation failed, display error with option to resend code
*/
export function VerifyContainer() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState({ isError: false, message: '' });
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [verifyFailedError, setVerifyFailedError] = useState<string | null>('');

  const userUUID = searchParams.get('user_uuid');
  const code = searchParams.get('code');

  useEffect(() => {
    let cancelled = false;

    async function confirmSignup() {
      if (!cancelled && isLoading && userUUID && code) {
        axios.post(`${process.env.REACT_APP_API_URL}/verify`, {
          user_uuid: userUUID,
          code,
        }).then((response) => {
          setUserEmail(response?.data?.email);
          setIsVerified(true);
        }).catch((e) => {
          setError({ isError: true, message: e?.response?.data?.message || 'Failed to verify your account. Please contact us.' });
          setVerifyFailedError(e?.response?.data?.message || 'Failed to verify your account. Please contact us.');
        }).finally(() => {
          setIsLoading(false);
        });
      } else if (isLoading) {
        setIsLoading(false);
        setError({ isError: true, message: 'Failed to verify your account - either email or code are missing' });
      }
    }

    confirmSignup();

    return () => {
      cancelled = true;
    };
  }, [isLoading, searchParams, code, userUUID]);

  const resendVerificationCode = (): void => {
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/verify/resend`, {
      user_uuid: userUUID,
    }).then(() => {
      setVerifyFailedError(null);
      setIsVerified(true);
      setError({ isError: false, message: '' });
    }).catch((e) => {
      setVerifyFailedError(e?.response?.data?.message || 'Failed to re-send code. Please contact us.');
      setError({ isError: true, message: 'Failed to re-send code. Please contact us.' });
    }).finally(() => setIsLoading(false));
  };

  return (
    <Verify
      isLoading={isLoading}
      body={!isVerified || error.isError == null ? (
        <VerifyFailed
          onResendVerficationCode={resendVerificationCode}
          error={verifyFailedError}
        />
      ) : <VerifySucceded email={userEmail} />}
    />
  );
}
