import { Stack } from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useFetchingComponentState, useRoleContext } from 'hooks';
import { useEffect, useState } from 'react';
import { ExploDashboard } from '../ExploDashboard';
import { CompanyDashboardInputs } from './components/CompanyDashboardInputs';
import { COMPANY_DASHBOARD_BRANCH_ID_KEY, COMPANY_DASHBOARD_SHIPPER_ROLE_ID_KEY } from './constants';
import { CompanyDashboardState, RenderCompanyDashboardResponse } from './types';

export function CompanyDashboard() {
  const { role } = useRoleContext();
  const [companyDashboardState,
    setCompanyDashboardState] = useState<CompanyDashboardState>({
    shipperRoleId: undefined,
    branchId: undefined,
  });
  const [dashboardConfig,
    setDashboardConfig] = useState<RenderCompanyDashboardResponse | null>(null);

  const {
    fetch, loading, error, success,
  } = useFetchingComponentState<RenderCompanyDashboardResponse>({
    errorMessage: 'Failed to fetch dashboard. Please contact us if the issue persists.',
    fetchConfig: {
      url: role ? `/shippers/${role.id}/viz/company-dashboard-v2` : '',
    },
  });
  const updateExploDashdoardVariable = (varName: string, value: string | null) => {
    // @ts-ignore
    const event = new CustomEvent<object>('updateExploDashboardVariable', {
      detail: {
        varName,
        value,
      },
    });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    if (role && !success && error == null) {
      fetch((response) => setDashboardConfig(response));
    }
  }, [role, fetch, success, error]);

  useEffect(() => {
    if (dashboardConfig != null) {
      setCompanyDashboardState({
        shipperRoleId: dashboardConfig.input_render_config.defaults.shipper_role_id,
        branchId: dashboardConfig.input_render_config.defaults.branch_id,
      });
    }
  }, [dashboardConfig]);

  if (role === null || role.type !== 'SHIPPER') {
    return <div />;
  }

  const handleBranchIdChange = (branchId: string) => {
    setCompanyDashboardState({
      ...companyDashboardState,
      branchId: parseInt(branchId, 10),
      shipperRoleId: null,
    });

    if (branchId === '-1') {
      // ugly hack: branchId is -1 when 'Shippers Without Branch' is selected
      // this value is not passed to Explo since explo expects >= 0 branchId
      updateExploDashdoardVariable(
        COMPANY_DASHBOARD_BRANCH_ID_KEY,
        '0',
      );
    } else {
      updateExploDashdoardVariable(
        COMPANY_DASHBOARD_BRANCH_ID_KEY,
        branchId.toString(),
      );
    }

    // reset shipper id every time branch is updated
    updateExploDashdoardVariable(
      COMPANY_DASHBOARD_SHIPPER_ROLE_ID_KEY,
      '0',
    );
  };

  const handleShipperIdChange = (shipperId: string) => {
    setCompanyDashboardState({
      ...companyDashboardState,
      shipperRoleId: parseInt(shipperId, 10),
    });
    updateExploDashdoardVariable(
      COMPANY_DASHBOARD_SHIPPER_ROLE_ID_KEY,
      shipperId.toString(),
    );
  };

  const adminResetFilters = () => {
    setCompanyDashboardState({
      shipperRoleId: null,
      branchId: null,
    });
    updateExploDashdoardVariable(
      COMPANY_DASHBOARD_BRANCH_ID_KEY,
      '0',
    );
    updateExploDashdoardVariable(
      COMPANY_DASHBOARD_SHIPPER_ROLE_ID_KEY,
      '0',
    );
  };

  const getDashboardVariables = () => ({
    [COMPANY_DASHBOARD_BRANCH_ID_KEY]:
      dashboardConfig?.input_render_config?.defaults?.branch_id != null ? dashboardConfig?.input_render_config?.defaults?.branch_id.toString() : '0',
    [COMPANY_DASHBOARD_SHIPPER_ROLE_ID_KEY]:
  dashboardConfig?.input_render_config?.defaults?.shipper_role_id != null ? dashboardConfig?.input_render_config?.defaults?.shipper_role_id.toString() : '0',
  });

  if (!loading && error !== null) {
    return (
      <Stack>
        <ErrorTypography error={error} />
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={3}>
      <LoadingWrappedNode loading={loading || dashboardConfig == null}>
        {dashboardConfig != null && (
          <>
            <CompanyDashboardInputs
              renderConfig={dashboardConfig.input_render_config}
              handleBranchIdChange={handleBranchIdChange}
              handleShipperIdChange={handleShipperIdChange}
              branchId={companyDashboardState.branchId}
              shipperRoleId={companyDashboardState.shipperRoleId}
              adminResetFilters={adminResetFilters}
            />
            <ExploDashboard
              clientData={dashboardConfig.explo}
              variables={getDashboardVariables()}
            />
          </>
        )}
      </LoadingWrappedNode>
    </Stack>
  );
}
