import HomeIcon from '@mui/icons-material/Home';
import {
  Box, IconButton, useMediaQuery,
} from '@mui/material';
import { Banner } from 'components/Banner';
import { RootSideBar } from 'components/RootSideBar';
import { useDisclosure } from 'hooks';
import { NavigationBar } from '../components/NavigationBar';

interface Props {
  content: React.ReactNode;
}

export function AppLayout(props: Props) {
  const { content } = props;

  const { isOpen, onOpen, onClose } = useDisclosure(false);

  // TODO: Make this better, this is hacky.
  const navbarHidden = useMediaQuery('(max-width:750px)');

  const navigationBar = (<NavigationBar />);

  return (
    <Box id="main" display="flex" sx={{ flexFlow: 'column', height: '100vh' }}>
      <Box
        id="main-child"
        display="flex"
        sx={{
          backgroundColor: '#0d2e45',
          height: '100%',
          padding: navbarHidden ? '.5rem .5rem .5rem 1rem 0' : '.5rem .5rem .5rem 1rem',
          overflow: 'hidden',
        }}
      >
        <RootSideBar isOpen={isOpen} onClose={onClose} content={navigationBar} />
        {!navbarHidden && (navigationBar)}
        <Box
          id="main-content"
          display="flex"
          sx={{
            flexFlow: 'column', width: '100%', overflowY: 'scroll', borderRadius: 4,
          }}
        >
          <Banner
            showNavBar={navbarHidden}
            navBar={(
              <Box>
                <IconButton
                  onClick={onOpen}
                >
                  <HomeIcon sx={{ color: 'white' }} fontSize="large" />
                </IconButton>
              </Box>
            )}
          />
          {content}
        </Box>
      </Box>
    </Box>
  );
}
