import { useEffect, useRef, useState } from 'react';
import { ShipmentQuoteList } from '../ShipmentQuoteList';

interface Props {
  shipment: CondensedShipment | undefined;
}

export function CarrierQuoteTab(props: Props) {
  const { shipment } = props;

  const shipmentRef = useRef<undefined | CondensedShipment>();
  const [refetchQuotes, setRefetchQuotes] = useState<boolean>(false);

  useEffect(() => {
    /*
    This hook keeps track of the shipment ID between renders to ensure
    that the quotes are re-fetched when another shipment is selected.
    */
    if (shipment && shipmentRef.current?.info?.id !== shipment.info.id) {
      shipmentRef.current = shipment;
      setRefetchQuotes(true);
    }
  }, [shipment]);

  useEffect(() => {
    if (shipment) {
      setRefetchQuotes(true);
    }
  }, [shipment]);

  const onQuotesRefetched = () => {
    setRefetchQuotes(false);
  };

  if (shipment === undefined) {
    return <div />;
  }

  return (
    <ShipmentQuoteList
      shipment={shipment}
      refetchQuotes={refetchQuotes}
      onQuotesRefetched={onQuotesRefetched}
    />
  );
}
