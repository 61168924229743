import { Group } from '@mui/icons-material';
import {
  IconButton, Tooltip, Zoom,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRoleContext } from '../../hooks';

export function CompanyAdminDetailsButton() {
  const navigate = useNavigate();
  const { getAdminRole } = useRoleContext();
  const role = getAdminRole();

  if (role) {
    return (
      <Tooltip title="Company Details" placement="right" TransitionComponent={Zoom}>
        <IconButton color="inherit" onClick={() => navigate(`/companies/${role.company?.id}`)}>
          <Group fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <div />
  );
}
