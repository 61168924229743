// stolen from docs
// https://github.com/googlemaps/js-samples/blob/c6a33fa8d34b620dadc03fe9b6b35fc7e3ac9c15/samples/react-map/index.tsx#L144-L150

import * as React from 'react';
import { createCustomEqual } from 'fast-equals';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';

const deepCompareEqualsForMaps = createCustomEqual(
  // @ts-expect-error also weird with type sig
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a)
        || a instanceof google.maps.LatLng
        || isLatLngLiteral(b)
        || b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // @ts-expect-error something weird with the type sig
    return deepEqual(a, b);
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies: any[],
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
