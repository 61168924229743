import * as React from 'react';

interface ShipmentFiltersProviderProps {
  children: React.ReactNode
}

interface ShipmentFiltersContextState {
  filters: ShipmentFilters | null;
}

interface SetFiltersAction {
  filters: ShipmentFilters
  type: 'setShipmentFilters'
}

interface ClearFiltersAction {
  type: 'clearShipmentFilters'
}

interface SetOriginAction {
  type: 'setOrigin';
  originKey: keyof ShipmentLocationFilter;
  value: string[];
}

interface SetDestinationAction {
  type: 'setDestination';
  destinationKey: keyof ShipmentLocationFilter;
  value: string[];
}

interface SetEquipmentTypeAction{
  type: 'setEquipmentType';
  equipments: string[];
}

interface SetCommodityAction {
  type: 'setCommodity'
  commodity: Commodity | null;
}

interface SetShipmentStatusAction {
  type: 'setShipmentStatus';
  status: ShipmentStatusValue | undefined;
}

interface SetOrderByAction {
  type: 'setOrderBy';
  orderBy: string;
}

interface SetServiceAction {
  type: 'setService';
  services: string[];
}

interface SetShipmentVisibility {
  type: 'setShipmentVisibility';
  visibility: ShipmentVisibility;
}

interface SetShipmentRelationship {
  type: 'setShipmentRelationship';
  shipmentRelation: ShipmentRelation;
}

interface SetShipmentName {
  type: 'setShipmentName';
  shipmentName: string;
}

interface SetShipmentTypeAction{
  type: 'setShipmentTypeAction';
  shipmentTypes: string[];
}

interface SetIncludeBranchShipments{
  type: 'SetIncludeBranchShipments';
  value: boolean;
}

interface SetIncludeCompanyShipments{
  type: 'SetIncludeCompanyShipments';
  value: boolean;
}

interface SetBranches {
  type: 'setBranches';
  branches: string[];
}

interface SetShippers {
  type: 'setShippers';
  shippers: string[];
}

type ShipmentFiltersActions = SetFiltersAction | ClearFiltersAction | SetOriginAction |
SetDestinationAction | SetEquipmentTypeAction | SetCommodityAction |
SetShipmentStatusAction | SetOrderByAction | SetServiceAction | SetShipmentVisibility
| SetShipmentRelationship | SetShipmentName |
SetShipmentTypeAction | SetIncludeBranchShipments |
SetIncludeCompanyShipments | SetBranches | SetShippers;

interface ShipmentFiltersContextDispatch {
  dispatch: React.Dispatch<ShipmentFiltersActions>
}

const initialState = {
  filters: null,
};

export const shipmentFiltersContext = React
  .createContext<ShipmentFiltersContextState & ShipmentFiltersContextDispatch>({
  ...initialState,
  dispatch: () => null,
});

function reducer(
  state: ShipmentFiltersContextState,
  action: ShipmentFiltersActions,
): ShipmentFiltersContextState {
  switch (action.type) {
    case 'setShipmentFilters':
      return {
        filters: action.filters,
      };
    case 'clearShipmentFilters':
      return {
        filters: null,
      };
    case 'setOrigin':
      if (state?.filters?.origin) {
        return {
          filters: {
            ...state.filters,
            origin: {
              ...state.filters.origin,
              [action.originKey]: action.value,
            },
          },
        };
      }
      return {
        filters: {
          ...state.filters,
          origin: {
            [action.originKey]: action.value,
          },
        },
      };
    case 'setDestination':
      if (state?.filters?.destination) {
        return {
          filters: {
            ...state.filters,
            destination: {
              ...state.filters.destination,
              [action.destinationKey]: action.value,
            },
          },
        };
      }
      return {
        filters: {
          ...state.filters,
          destination: {
            [action.destinationKey]: action.value,
          },
        },
      };

    case 'setEquipmentType':
      return {
        filters: {
          ...state.filters,
          equipment_types: action.equipments,
        },
      };
    case 'setShipmentTypeAction':
      return {
        filters: {
          ...state.filters,
          shipmentTypes: action.shipmentTypes,
        },
      };
    case 'setCommodity':
      return {
        filters: {
          ...state.filters,
          commodity: action.commodity ?? undefined,
        },
      };
    case 'setShipmentStatus':
      return {
        filters: {
          ...state.filters,
          status: action.status,
        },
      };
    case 'setOrderBy':
      return {
        filters: {
          ...state.filters,
          order_by: action.orderBy,
        },
      };
    case 'setService':
      return {
        filters: {
          ...state.filters,
          services: action.services,
        },
      };
    case 'setShipmentVisibility':
      return {
        filters: {
          ...state.filters,
          shipmentVisibility: action.visibility,
        },
      };
    case 'setShipmentRelationship':
      return {
        filters: {
          ...state.filters,
          shipmentRelationship: action.shipmentRelation,
        },
      };
    case 'setShipmentName':
      return {
        filters: {
          ...state.filters,
          shipmentName: action.shipmentName,
        },
      };
    case 'SetIncludeBranchShipments':
      if (state.filters) {
        return {
          filters: {
            ...state.filters,
            includeBranchShipments: action.value,
            /*
          Clear branch and shipper filter selections when branch-wide
          shipments are selected such that filters do not conflict with
          each other.
          */
            branches: action.value ? [] : state.filters.branches,
            shippers: action.value ? [] : state.filters.shippers,
          },
        };
      }
      return {
        filters: {
          includeBranchShipments: action.value,
        },
      };
    case 'SetIncludeCompanyShipments':
      if (state.filters) {
        return {
          filters: {
            ...state.filters,
            includeCompanyShipments: action.value,
            /*
            Clear branch and shipper filter selections when company wide
            shipments are selected such that filters do not conflict with
            each other.
            */
            branches: action.value ? [] : state.filters.branches,
            shippers: action.value ? [] : state.filters.shippers,
          },
        };
      }
      return {
        filters: {
          includeCompanyShipments: action.value,
        },
      };
    case 'setBranches':
      if (state.filters) {
        return {
          filters: {
            ...state.filters,
            branches: action.branches,
            /*
            if any branches are specified, set includeCompanyShipments
            and includeBranchShipments are set to False
            clear any selected shippers
            such that the filters do not conflict with each other
            */
            includeCompanyShipments: action.branches.length > 0
              ? false : state.filters.includeCompanyShipments,
            includeBranchShipments: action.branches.length > 0
              ? false : state.filters.includeCompanyShipments,
            shippers: action.branches.length > 0
              ? [] : state.filters.shippers,
          },

        };
      }
      // if filters is not defined can only set branches, since there is no existing state
      // to evaluate
      return {
        filters: {
          branches: action.branches,
        },
      };
    case 'setShippers': {
      if (state.filters) {
        return {
          filters: {
            ...state.filters,
            shippers: action.shippers,
            /*
            if any shippers are specified, set includeCompanyShipments
            and includeBranchShipments are set to False
            clear any selected shippers
            such that the filters do not conflict with each other
            In this case branches are not cleared as the shipper choice does not
            necessarily conflict with the branch. The backend will know to focus on the
            shipper even if a branch is specified in the request
            */
            includeCompanyShipments: action.shippers.length > 0
              ? false : state.filters.includeCompanyShipments,
            includeBranchShipments: action.shippers.length > 0
              ? false : state.filters.includeCompanyShipments,
          },

        };
      }
      return {
        filters: {
          shippers: action.shippers,
        },
      };
    }
    default:
      return state;
  }
}

export function ShipmentFiltersProvider(props: ShipmentFiltersProviderProps) {
  const { children } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const memoState = React.useMemo(() => ({ ...state, dispatch }), [state]);

  return (
    <shipmentFiltersContext.Provider value={memoState}>
      {children}
    </shipmentFiltersContext.Provider>
  );
}
