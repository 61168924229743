import { Typography } from '@mui/material';
import { PaymentMethodRequiredWrapper } from 'components/PaymentMethodRequiredWrapper';
import { useCarrierShipmentPageContext, useSubscriptionStatus } from 'hooks';
import { MultiShipmentSubmitQuoteForm } from '../MultiShipmentSubmitQuoteForm';
import { AddPaymentInformationLabel } from '../ShipmentOverviewExpanded/components/AddPaymentInformationLabel';

/*
  Controls access to the MultiShipmentSubmitQuoteForm component.
  Access works as follows:

  * carrier trials can not access form unless its a partner shipment
  * ppb carriers without payment method can not access form, unless its a partner shipment
  * paid carriers with limited postings can always access the form
*/

export function MultiShipmentSubmitQuoteFormAccessWrapper() {
  const { shipment } = useCarrierShipmentPageContext();
  const { subscription } = useSubscriptionStatus();

  if (!shipment || subscription?.subscription_plan === undefined) {
    return <div />;
  }

  const formComponent = <MultiShipmentSubmitQuoteForm shipment={shipment} />;

  // carrier trial can not access non-partner form
  if (subscription.subscription_plan === 'CARRIER_ESSENTIALS' && !shipment.is_partner_shipment) {
    return (
      <Typography sx={{ mt: '1rem' }}>
        <b>
          Multi shipment projects are only quotable by Evotrux members.
          Upgrade your account today to access and book projects like this one!
        </b>
      </Typography>
    );
  }
  // only allowed access to form without payment method: on partner shipments
  if (subscription.subscription_plan === 'CARRIER_ESSENTIALS' && shipment.is_partner_shipment) {
    return formComponent;
  }
  // PPB carriers without payment method can access form
  if (subscription.subscription_plan === 'CARRIER_PAY_PER_BOOKING' && shipment.is_partner_shipment) {
    return formComponent;
  }

  /*
  carriers on PPB can access form only if they have payment method
  paid subscription carriers can always access the form
  */
  return (
    <PaymentMethodRequiredWrapper
      enableWithSubscriptionPlan="CARRIER_PAY_PER_BOOKING"
      noPaymentMethodComponent={<AddPaymentInformationLabel />}
    >
      {formComponent}
    </PaymentMethodRequiredWrapper>

  );
}
