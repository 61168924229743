import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import {
  Button, CircularProgress, Stack, Typography,
} from '@mui/material';

interface Props {
  onSubmit: VoidFunction;
  requestSentStatus: 'AUTO_ACCEPT_SUCCESS' | 'AUTO_ACCEPT_FAILED' | null;
  requestAlreadySentForCarrier: boolean | null;
  error: string | null;
  isLoading: boolean;
  buttonVariant?: 'contained' | 'outlined';
}

export function AddCarrierToNetowrkButton(props: Props) {
  const {
    onSubmit,
    requestSentStatus,
    error,
    isLoading,
    buttonVariant,
    requestAlreadySentForCarrier,
  } = props;

  if (isLoading) {
    return (
      <Stack>
        <CircularProgress />
      </Stack>
    );
  }

  if (requestSentStatus != null) {
    return (
      <Stack>
        <Button variant="outlined" disabled>
          <CheckIcon />
          {requestSentStatus === 'AUTO_ACCEPT_SUCCESS' ? 'Added' : ' Partner Invite Sent'}
        </Button>
      </Stack>
    );
  }

  if (requestAlreadySentForCarrier) {
    return (
      <Stack>
        <Button variant="outlined" disabled>
          <CheckIcon />
          Partner Invite Sent
        </Button>
      </Stack>
    );
  }

  return (
    <Stack>
      <Button variant={buttonVariant || 'outlined'} onClick={onSubmit}>
        <AddIcon />
        Add Partner
      </Button>
      {error != null && <Typography color="red" align="center">{error}</Typography>}
    </Stack>
  );
}

AddCarrierToNetowrkButton.defaultProps = {
  buttonVariant: 'outlined',
};
