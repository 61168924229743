import CreateIcon from '@mui/icons-material/Create';
import {
  Box, Button, Stack,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { CreateCompanyReviewMenu } from 'components/CreateCompanyReviewMenu';
import { useCompanyProfile, useDisclosure, useRoleContext } from 'hooks';

interface Props {
  companyId: number;
  sx: any;
  onReviewSubmittedRefresh: VoidFunction;
}

export function CreateCompanyReviewModal(props: Props) {
  const { companyId, sx, onReviewSubmittedRefresh } = props;
  const {
    isOpen,
    onClose,
    onOpen,
  } = useDisclosure(false);
  const { role } = useRoleContext();
  const { companyProfile } = useCompanyProfile(companyId);

  if (role == null || companyProfile == null
    || role.company == null || companyProfile.company == null
    || role.company.id === companyProfile.company.id
    || role.type === 'CARRIER' || companyProfile.company.company_type === 'SHIPPER') {
    return <div />;
  }

  return (
    <Box sx={sx}>
      <Stack direction="column" alignItems="start">
        <Button onClick={onOpen} endIcon={<CreateIcon />} variant="contained">Write review</Button>
      </Stack>
      <Box>
        <Dialog
          open={isOpen}
          onClose={onClose}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <CreateCompanyReviewMenu
                companyId={companyId}
                onReviewSubmittedRefresh={onReviewSubmittedRefresh}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
