import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useAPI } from './useAPI';
import { useAuthContext } from './useAuthContext';
import { useShipmentFiltersContext } from './useShipmentFiltersContext';
import { useShipmentsUrl } from './useShipmentsUrl';

interface GetCondensedShipmentsResponse {
  results: CondensedShipment[];
  meta: ShipmentMeta;
}

interface ShipmentsState {
  data: CondensedShipment[]
}

interface ShipmentMeta{
  count: number;
}

interface PaginationProps{
  page: number;
  pageSize: number;
}

const buildShipmentTypeFilter = (shipmentTypes: string[] | undefined) => {
  if (shipmentTypes && shipmentTypes.find((type) => type === 'ALL')) {
    return null;
  }
  return shipmentTypes;
};

const buildFilterQueryParameters = (
  filters: ShipmentFilters | null,
  offset: number | null,
  limit: number | null,
) => {
  const filtersObject = {
    equipment_type: filters?.equipment_types ?? null,
    service: filters?.services ?? null,
    commodity: filters?.commodity?.code ?? null,
    status: filters?.status ?? null,
    order_by: filters?.order_by ?? null,
    origin_province: filters?.origin?.provinces ?? null,
    origin_country: filters?.origin?.countries ?? null,
    destination_province: filters?.destination?.provinces ?? null,
    destination_country: filters?.destination?.countries ?? null,
    offset: offset ?? null,
    limit: limit ?? null,
    shipment_name: filters?.shipmentName && filters.shipmentName !== '' ? filters.shipmentName : null,
    shipment_type: buildShipmentTypeFilter(filters?.shipmentTypes),
    include_branch_shipments: filters?.includeBranchShipments ? '1' : null,
    include_company_shipments: filters?.includeCompanyShipments ? '1' : null,
    branches: filters?.branches ?? null,
    shippers: filters?.shippers ?? null,
  };

  return qs.stringify(filtersObject, { skipNulls: true, arrayFormat: 'repeat' });
};

export function useShipments(props?: PaginationProps) {
  let offset: number | null = null;
  let limit: number | null = null;
  if (props !== undefined) {
    const { page, pageSize } = props;
    limit = pageSize;
    offset = (page - 1) * limit;
  }
  const { user } = useAuthContext();
  const { api } = useAPI();
  const { url } = useShipmentsUrl();
  const { filters } = useShipmentFiltersContext();
  const [error, setError] = useState<null | string>(null);
  const [count, setCount] = useState<number>(1);

  const [shipments, setShipments] = useState<ShipmentsState>({
    data: [],
  });
  const [shipmentsAreLoading, setShipmentsAreLoading] = useState<boolean>(false);

  const fetchShipments = useCallback(() => {
    const filtersQueryString = buildFilterQueryParameters(filters, offset, limit);
    const filteredUrl = `${url}?${filtersQueryString}`;
    if (user != null && url != null && filters != null) {
      setShipmentsAreLoading(true);
      api<GetCondensedShipmentsResponse>('get', filteredUrl).then((res) => {
        setShipments({ data: res.data.results });
        setCount(res.data.meta.count);
        setError(null);
      }).catch((e) => {
        if (e?.response?.status === 403 && e?.response?.data?.detail) {
          setError(e.response.data.detail);
        } else {
          setError(e?.response?.data?.message || 'Failed to fetch shipments. Please contact us.');
        }
        setShipments({ data: [] });
      }).finally(() => {
        setShipmentsAreLoading(false);
      });
    }
  }, [user, api, url, filters, offset, limit]);

  useEffect(() => {
    if (user?.id != null) {
      fetchShipments();
    }
  }, [user, filters, fetchShipments]);

  return {
    shipments: shipments.data,
    isLoading: shipmentsAreLoading,
    error,
    count,
    refreshShipments: fetchShipments,
  };
}
