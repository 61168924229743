import { useAPI, useShipmentPageContext } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { SimpleQuoteInteraction } from './SimpleQuoteInteraction';

interface Props {
  quoteId: number;
  onClose: VoidFunction;
  type: 'ACCEPT' | 'CANCEL' | 'DECLINE';
}

export function SimpleQuoteInteractionContainer(props: Props) {
  const { quoteId, onClose, type } = props;
  const { api } = useAPI();
  const [loading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const { onRefresh } = useShipmentPageContext();

  const getActionURL = useCallback(() => {
    if (type === 'ACCEPT') {
      return `/quotes/${quoteId}/accept`;
    } if (type === 'CANCEL') {
      return `/quotes/${quoteId}/cancel`;
    } if (type === 'DECLINE') {
      return `/quotes/${quoteId}/decline`;
    }
    throw Error('Unexpected action type');
  }, [type, quoteId]);

  const getContentText = () => {
    if (type === 'ACCEPT') {
      return 'Please confirm you wish to accept the selected quote.';
    } if (type === 'CANCEL') {
      return 'Please confirm you wish to cancel the selected quote.';
    } if (type === 'DECLINE') {
      return 'Please confirm you wish to decline the selected quote.';
    }
    throw Error('Unexpected action type');
  };

  const getTitletText = () => {
    if (type === 'ACCEPT') {
      return 'Book Shipment';
    } if (type === 'CANCEL') {
      return 'Cancel Quote';
    } if (type === 'DECLINE') {
      return 'Decline Quote';
    }
    throw Error('Unexpected action type');
  };

  const getActionButtonText = () => {
    if (type === 'ACCEPT') {
      return 'accept quote';
    } if (type === 'CANCEL') {
      return 'cancel quote';
    } if (type === 'DECLINE') {
      return 'decline quote';
    }
    throw Error('Unexpected action type');
  };

  useEffect(() => {
    if (loading) {
      api<unknown>('post', getActionURL()).then(() => {
        setError(null);
        if (onRefresh) {
          onRefresh();
        }
      }).catch((e) => {
        setError(e?.response?.data?.message || `Failed to ${type.toLowerCase()} quote`);
      });
      setIsLoading(false);
    }
  }, [loading, quoteId, api, getActionURL, type, onRefresh]);

  const onConfirmAction = () => {
    setIsLoading(true);
  };

  const getActionButtonColor = () => {
    if (type === 'ACCEPT') {
      return 'primary';
    } if (type === 'CANCEL') {
      return 'error';
    } if (type === 'DECLINE') {
      return 'warning';
    }
    throw Error('Unexpected action type');
  };

  return (
    <SimpleQuoteInteraction
      onClose={onClose}
      onConfirmAction={onConfirmAction}
      error={error}
      loading={loading}
      modalContentText={getContentText()}
      actionButtonText={getActionButtonText()}
      title={getTitletText()}
      actionButtonColor={getActionButtonColor()}
    />
  );
}
