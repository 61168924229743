import { shipperShipmentPageContext } from 'context';
import { useContext } from 'react';

/*
Exposes root information about the shipment detail page
to all compontents down the tree. Use this hook in components
that only SHIPPER role users will see.
*/
export function useShipperShipmentPageContext() {
  const shipperShipmentPageCtx = useContext(shipperShipmentPageContext);

  return {
    onRefresh: shipperShipmentPageCtx.onRefresh,
    shipment: shipperShipmentPageCtx.shipment,
  };
}
