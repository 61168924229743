import { NetworkMember } from './types';

export function getNetworkMemberFromFollowShipperRequest(request: FollowShipperRequest)
  : NetworkMember {
  return {
    email: request.target_email,
  };
}

export function getNetworkMemberFromFollowingCarrier(followingCarrier: FollowingCarrier)
  : NetworkMember {
  return {
    email: followingCarrier.user.email,
    firstName: followingCarrier.user.first_name,
    lastName: followingCarrier.user.last_name,
  };
}

export function getNetworkMemberArrayFromFollowingCarrierArray(networkMembers: FollowingCarrier[]) {
  return networkMembers.map(
    (member: FollowingCarrier) => getNetworkMemberFromFollowingCarrier(member),
  );
}

export function getEmailArrayFromNetworkMemberArray(networkMembers: FollowingCarrier[]) {
  return networkMembers.map(
    (member: FollowingCarrier) => member.user.email,
  );
}
