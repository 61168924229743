import { Stack } from '@mui/material';
import { useSubscriptionStatus } from 'hooks/useSubscriptionStatus';
import { RevealPriceButton } from './RevealPriceButton';
import { UpgradeToRevealPriceButton } from './UpgradeToRevealPriceButton';

interface Props {
  shipment: CarrierCondensedShipment
  dividerComponent: JSX.Element;
}

export function RevealPriceButtonContainer(props: Props) {
  const { shipment, dividerComponent } = props;

  const { isPayingCarrier } = useSubscriptionStatus();

  if (shipment?.info?.booked_at == null
    || shipment.booked_by_carrier || isPayingCarrier == null) {
    // scenarios where no component is rendered.
    return <div />;
  }

  let revealPriceComponent = <RevealPriceButton shipment={shipment} />;
  if (!isPayingCarrier) {
    revealPriceComponent = <UpgradeToRevealPriceButton />;
  }

  return (
    <Stack direction="column" spacing={1} mb="1rem">
      {dividerComponent}
      <Stack direction="row" justifyContent="left">
        {revealPriceComponent}
      </Stack>
    </Stack>
  );
}
