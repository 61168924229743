import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

export const SHIPMENT_PROGRESS_STEPS = [
  'Shipment Posted',
  'Carriers Notified',
  'Quotes Received',
  'Shipment Booked',
];

interface Props {
  shipment: ShipperCondensedShipment | undefined
  progress: ShipmentProgress | null
}

export function ShipmentProgressStepper(props: Props) {
  const { shipment, progress } = props;

  const getProgress = () => {
    if (!shipment || !progress) {
      return 0;
    }
    if (shipment.shipment_status === 'BOOKED') {
      return 3;
    } if (progress.quote_count > 0) {
      return 2;
    }
    return 1;
  };

  return (
    <Box>
      <Stepper activeStep={getProgress()} alternativeLabel>
        {SHIPMENT_PROGRESS_STEPS.map((label, index) => (
          <Step completed={getProgress() >= index} key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Typography justifyContent="center" align="center" variant="body2" color="text.secondary">
        Click here to view matches
      </Typography>
    </Box>
  );
}
