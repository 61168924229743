import { useEffect, useState } from 'react';
import { useRoleContext } from './useRoleContext';
import { useWriterComponentState } from './useWriterComponentState';

interface CreateCustomerBillingPortalSessionResponse {
  url: string;
}

interface Props {
  onSessionCreatedSuccess: (url: string) => void;
}

export function useStripeCustomerBillingPortal(props: Props) {
  const { onSessionCreatedSuccess } = props;
  const [portalURL, setPortalURL] = useState<string | null>(null);
  const [sessionRequested, setSessionRequested] = useState<boolean>(false);
  const { company } = useRoleContext();
  const {
    loading,
    error,
    write,
  } = useWriterComponentState({
    errorMessage: 'Failed to create customer billing portal session. Please contact us if the issue persists.',
    writeConfig: {
      method: 'post',
      url: company != null && company.id != null ? `/stripe/companies/${company.id}/customer-portal-session` : '/',
    },
  });

  useEffect(() => {
    if (sessionRequested) {
      write<CreateCustomerBillingPortalSessionResponse>(
        (response: CreateCustomerBillingPortalSessionResponse) => {
          setPortalURL(response.url);
          onSessionCreatedSuccess(response.url);
          setSessionRequested(false);
        },
      );
      setSessionRequested(false);
    }
  }, [sessionRequested, write, onSessionCreatedSuccess]);

  const requestCustomerBillingPortalSession = () => {
    setSessionRequested(true);
  };

  return {
    portalURL, loading, error, requestCustomerBillingPortalSession,
  };
}
