import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useShipmentPageContext, useWriterComponentState } from 'hooks';
import { useState } from 'react';

interface Props {
  quoteId: number;
  onClose: VoidFunction;
}

export function DeclineQuoteWitFeedbackInteraction(props: Props) {
  const { onClose, quoteId } = props;
  const [feedback, setFeedback] = useState<string>('');
  const [displayTextfield, setDisplayTextField] = useState<boolean>(false);
  const { onRefresh } = useShipmentPageContext();
  const {
    loading,
    error,
    write,
    resetState,
  } = useWriterComponentState({
    errorMessage: 'Failed to decline quote. Please contact us if the issue persists.',
    writeConfig: {
      method: 'post',
      url: `/quotes/${quoteId}/decline-with-feedback`,
      body: { feedback },
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const chosenOption = (event.target as HTMLInputElement).value;
    setFeedback(chosenOption);
    if (chosenOption === 'other') {
      setDisplayTextField(true);
      setFeedback('');
    } else {
      setDisplayTextField(false);
      setFeedback((event.target as HTMLInputElement).value);
    }
  };

  const onSubmitFeedback = () => {
    write(() => {
      if (onRefresh) {
        onRefresh();
      }
      onClose();
      resetState();
    });
  };

  return (
    <Dialog open fullWidth onClose={onClose}>
      <DialogTitle>
        <Typography variant="h4">Decline Quote</Typography>
      </DialogTitle>
      <Typography variant="h6" sx={{ ml: '1.5rem' }}>Give the carrier feedback so they can provide a more suitable quote.</Typography>
      <DialogContent>
        {loading
          ? <Stack alignItems="center"><CircularProgress /></Stack>
          : (
            <Stack mt="1rem">
              <FormControl>
                <RadioGroup
                  value={feedback}
                  onChange={handleChange}
                >
                  <FormControlLabel value="Price is too high" control={<Radio />} label="Price is too high" />
                  <FormControlLabel value="Equipment does not match" control={<Radio />} label="Equipment does not match" />
                  <FormControlLabel value="Dates do not match" control={<Radio />} label="Dates do not match" />
                  <FormControlLabel value="other" control={<Radio checked={displayTextfield} />} label="Other" />
                </RadioGroup>
              </FormControl>
              {displayTextfield && (
              <TextField
                label="Feedback"
                fullWidth
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
              )}
            </Stack>
          )}
        {error != null && !loading && <Typography color="red" align="center">{error}</Typography>}
      </DialogContent>
      {!loading && (
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="warning"
          disabled={loading || feedback === ''}
          onClick={() => onSubmitFeedback()}
        >
          DECLINE QUOTE
        </Button>
      </DialogActions>
      )}
    </Dialog>
  );
}
