import {
  Dialog,
  Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import { useDisclosure } from 'hooks';
import { useState } from 'react';
import { BranchOverview } from './components/BranchOverview';
import { ManageBranchDetail } from './components/ManageBranchDetail';

interface Props {
  branchesAndMembers: BranchMembers[];
  viewingAsRoles: Role[];
}
export function BranchManager(props: Props) {
  const { branchesAndMembers, viewingAsRoles } = props;
  const {
    isOpen,
    onClose,
    onOpen,
  } = useDisclosure(false);
  const [selectedBranch, setSelectedBranch] = useState<BranchMembers | null>(null);

  if (branchesAndMembers.length === 0 || viewingAsRoles.length === 0) {
    return <div />;
  }

  const onManageBranchSelected = (branch: BranchMembers) => {
    setSelectedBranch(branch);
    onOpen();
  };

  return (
    <Stack mt="1.5rem" direction="row" spacing={2} justifyContent="start" flexWrap="wrap">
      {branchesAndMembers.map((branchMembers) => (
        <BranchOverview
          key={branchMembers.branch.id}
          branchMembers={branchMembers}
          onManageBranch={onManageBranchSelected}
        />
      ))}
      <Dialog
        open={isOpen && selectedBranch !== null}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: '70vw', // 50% of the viewport width
            height: '70vh', // 50% of the viewport height
            maxWidth: 'none', // Ensures it doesn't shrink based on default max-width
            overflowY: 'scroll',
          },
        }}
      >
        <Box p="1.5rem">
          {selectedBranch != null && (
          <ManageBranchDetail
            branchMembers={selectedBranch}
            viewingAsRoles={viewingAsRoles}
          />
          )}
        </Box>
      </Dialog>
    </Stack>
  );
}
