import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { CountrySelect } from './CountrySelect';
import { RegionSelect } from './RegionSelect';

interface Props {
  country?: string;
  region?: string;
  onCountryChange: (value: string) => void;
  onRegionChange: (value: string) => void;
  direction?: 'row' | 'column';
}

export function RegionSelectContainer(props: Props) {
  const {
    country: c, region: r, direction, onCountryChange, onRegionChange,
  } = props;
  const [country, setCountry] = useState<string>('');
  const [region, setRegion] = useState<string>('');

  useEffect(() => {
    if (c) {
      setCountry(c);
    }
  }, [c]);

  useEffect(() => {
    if (r) {
      setRegion(r);
    }
  }, [r]);

  const handleCountryChange = (value: string) => {
    setCountry(value);
    onCountryChange(value);
  };

  return (
    <Stack direction={direction} width="100%">
      <CountrySelect onChange={handleCountryChange} country={country} />
      <RegionSelect onChange={onRegionChange} country={country} value={region} />
    </Stack>
  );
}

RegionSelectContainer.defaultProps = {
  country: '',
  region: '',
  direction: 'row',
};
