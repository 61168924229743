import { useAPI } from 'hooks';
import { useEffect, useState } from 'react';
import { emailIsValid } from 'utils';
import { ShareShipmentMenu } from './ShareShipmentMenu';

interface ShareShipmentPaylod {
  emails: string[];
  shipment_id?: number;
}

interface Props {
  shipment?: CondensedShipment | null;
  onShipmentShared?: VoidFunction;
}

export function ShareShipmentMenuContainer(props: Props) {
  const [error, setError] = useState<string | null>(null);
  const [text, setText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const { api } = useAPI();
  const MAX_FOLLOW_INVITATIONS_PER_REQUEST = 10;

  const onSubmit = (emailBlock: string) => {
    const emails = emailBlock.split(' ');
    const { shipment, onShipmentShared } = props;

    if (emails.length === 0 || emailBlock === '') {
      setError('One or more emails are in an incorrect format. Emails must be space separated with no other information. Example: harry@email.com joe@email.com');
    }

    if (emails.length > MAX_FOLLOW_INVITATIONS_PER_REQUEST) {
      setError('You are unable to submit more than 10 shipment shares at once. Please decrease the number of invitations or contact us for help.');
    }

    if (emailError == null) {
      const body: ShareShipmentPaylod = { emails: [] };
      emails.forEach((e: string) => {
        if (e !== ' ' && e !== '') {
          body.emails.push(e);
        }
      });

      if (shipment != null) {
        body.shipment_id = shipment.info.id;
      }

      setIsLoading(true);
      setError(null);
      setSuccess(false);
      api<unknown>('post', '/send-targeted-notifications', body).then(() => {
        setError(null);
        setText('');
        setSuccess(true);
        if (onShipmentShared) {
          onShipmentShared();
        }
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to share shipment. Please contact us.');
      }).finally(() => {
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (text === '') {
      return;
    }

    const splitEmails = text.split(' ');

    let errorFound = false;
    splitEmails.forEach((email) => {
      if (email !== '' && !emailIsValid(email)) {
        setEmailError(`Email ${email} has an invalid format.`);
        errorFound = true;
      }
    });

    if (!errorFound) {
      setEmailError(null);
    }
  }, [text, setEmailError]);

  return (
    <ShareShipmentMenu
      onSubmit={onSubmit}
      error={error}
      text={text}
      onTextChange={setText}
      isLoading={isLoading}
      emailError={emailError}
      success={success}
    />
  );
}

ShareShipmentMenuContainer.defaultProps = {
  shipment: null,
  onShipmentShared: null,
};
