import { CircularProgress, Stack } from '@mui/material';
import { useAPI } from 'hooks';
import { useEffect, useState } from 'react';
import { QuoteHistory } from './QuoteHistory';

interface Props {
  quoteId: number;
}

export function QuoteHistoryContainer(props: Props) {
  const { quoteId } = props;
  const [quoteActions, setQuoteActions] = useState<QuoteAction[]>([]);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | string>(null);
  const { api } = useAPI();

  useEffect(() => {
    if (loading) {
      api<QuoteInfo>('get', `/quotes/${quoteId}`).then((response) => {
        setQuoteActions(response.data.quote_actions);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch quote history.');
      });
      setIsLoading(false);
    }
  }, [loading, quoteId, api]);

  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <QuoteHistory
      error={error}
      actions={quoteActions}
    />
  );
}
