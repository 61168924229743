import { Button, Stack } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { capitalizeFirst } from '../../../../utils';

type AuthView = 'sign-up' | 'login';

export function SwitchView() {
  const navigate = useNavigate();
  const location = useLocation();

  let oppositeView: string | null = null;
  switch (location.pathname) {
    case '/login':
      oppositeView = '/sign-up';
      break;
    case '/sign-up':
      oppositeView = '/login';
      break;
    default:
      break;
  }

  if (oppositeView == null) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Button variant="contained" onClick={() => navigate(oppositeView as AuthView)}>
        {capitalizeFirst(oppositeView).replace('/', '')}
      </Button>
    </Stack>
  );
}
