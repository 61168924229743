import { useCompanyMembers, useRoleContext } from 'hooks';
import { ShareShipmentMenu } from './ShareShipmentMenu';

interface Props {
  setShareeRoleIds: (roleIds: number[]) => void;
  selectedSharees: number[];
  shareShipment: boolean;
  onShareShipmentChange: (newValue: boolean) => void;
  isProject: boolean;
}

export function ShareShipmentMenuContainer(props: Props) {
  const {
    setShareeRoleIds,
    selectedSharees,
    shareShipment,
    onShareShipmentChange,
    isProject,
  } = props;
  const { role } = useRoleContext();
  const {
    members,
    error: CompanyMemberFetchError,
    loading: CompanyMemberFetchLoading,
  } = useCompanyMembers();

  if (!role) {
    return <div />;
  }

  const companyMembersWithoutSelf = members.filter((member) => member.id !== role.id);

  return (
    <ShareShipmentMenu
      loading={CompanyMemberFetchLoading}
      loadCompanyMembersError={CompanyMemberFetchError}
      companyMembers={companyMembersWithoutSelf}
      setShareeRoleIds={setShareeRoleIds}
      selectedCompanyMembers={selectedSharees}
      shareShipment={shareShipment}
      onShareShipmentChange={onShareShipmentChange}
      isProject={isProject}
    />
  );
}
