import { Stack } from '@mui/material';
import { QuoteFilterByShipmentNameTextField } from '../QuoteFilterByShipmentNameTextField';
import { QuoteShipmentTypeFilter } from '../QuoteShipmentTypeFilter';
import { QuoteStatusFilterDropdown } from '../QuoteStatusFilterDropdown';

interface Props {
  onQuoteStatusChange: (value: string) => void;
  onShipmentNameChange: (value: string) => void;
  onShipmentTypeChange: (value: string[]) => void;
  filters: QuoteFilters;
}

export function QuoteFilters(props: Props) {
  const {
    onQuoteStatusChange,
    onShipmentNameChange,
    onShipmentTypeChange,
    filters,
  } = props;
  return (
    <Stack direction="row" spacing={1} sx={{ ml: '.5rem', mr: '.5rem', mb: '.5rem' }} alignContent="flex-start">
      <QuoteFilterByShipmentNameTextField
        shipmentName={filters?.shipmentName || ''}
        onShipmentNameChange={onShipmentNameChange}
      />
      <Stack direction="row" sx={{ width: '40%' }} spacing={1}>
        <QuoteStatusFilterDropdown
          status={filters.quoteStatus}
          onStatusChange={onQuoteStatusChange}
        />
        <QuoteShipmentTypeFilter
          shipmentTypes={filters.shipmentTypes ?? []}
          onShipmentTypeChange={onShipmentTypeChange}
        />
      </Stack>

    </Stack>
  );
}
