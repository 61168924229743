import {
  Box, Stack, useMediaQuery,
} from '@mui/material';
import { PageLayout } from '../../../layouts';

interface Props {
  companySetupComponent: JSX.Element;
}

export function CompanySetupLayout(props: Props) {
  const { companySetupComponent } = props;

  const isMobileLayout = useMediaQuery('(max-width:700px)');

  return (
    <PageLayout>
      <Stack direction={isMobileLayout ? 'column' : 'row'}>
        <Box mb="2rem" sx={!isMobileLayout ? { flexGrow: 1 } : null}>
          <Stack alignItems="center">
            <Box mt={isMobileLayout ? '2rem' : '4rem'} width="60%">
              {companySetupComponent}
            </Box>
          </Stack>
        </Box>
      </Stack>
    </PageLayout>
  );
}
