import {
  Button, Stack, TextField, Typography,
} from '@mui/material';
import { useAPI, useShipmentPageContext } from 'hooks';
import { useState } from 'react';

interface Props {
  render: boolean;
  postURL: string;
  textFieldLabel: string;
  addPadding: boolean;
}

export function SubmitQuestionMenu(props: Props) {
  const {
    render, postURL, textFieldLabel, addPadding,
  } = props;
  const { api } = useAPI();
  const [error, setError] = useState<null | string>(null);
  const [question, setQuestion] = useState<string>('');
  const { onRefresh } = useShipmentPageContext();

  const postQuestion = () => {
    api<unknown>('post', postURL, { question }).then(() => {
      if (onRefresh) {
        onRefresh();
      }
      setError(null);
    }).catch((e) => {
      setError(e?.response?.data?.message || 'Failed to submit question');
    });
  };

  if (!render) {
    return <div />;
  }

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing={1}
      >
        <TextField
          fullWidth
          label={textFieldLabel}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <Button disabled={question.length < 1} variant="contained" sx={addPadding ? { pl: '2rem', pr: '2rem' } : {}} onClick={postQuestion}>Send</Button>
      </Stack>
      <Typography color="red" align="center">{error}</Typography>
    </Stack>
  );
}
