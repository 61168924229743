import {
  Button, Modal, Stack, Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useWriterComponentState } from 'hooks';
import { useState } from 'react';
import { EmailNotificationPreferences } from '../EmailNotificationPreferences';

const MODAL_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  p: 4,
};

interface Props {
  shipment: ShipperCondensedShipment
  onModalClose: VoidFunction;
  onSettingsUpdated: VoidFunction;
  modalOpen: boolean
}

export function UpdateShipmentEmailNotificationSettingsModal(props: Props) {
  const {
    modalOpen, shipment, onModalClose, onSettingsUpdated,
  } = props;
  const [settings, setSettings] = useState<ShipperEmailNotificationType>(shipment.email_notification_settings != null ? shipment.email_notification_settings : 'ALL');
  const {
    loading, write, error,
  } = useWriterComponentState({
    errorMessage: 'Failed to update email settings. Please contact us if the issue persists',
    writeConfig: {
      method: 'patch',
      url: shipment?.info?.id ? `/shipments/${shipment.info.id}/update` : '/',
      body: { email_notification_settings: settings },
    },
  });
  const submit = () => {
    write(() => {
      onSettingsUpdated();
    });
  };
  return (
    <Modal open={modalOpen} onClose={onModalClose}>
      <Stack sx={MODAL_STYLE} direction="column" spacing={1}>
        <Typography variant="h6">Update Notification Preferences</Typography>
        <EmailNotificationPreferences
          value={settings}
          onChange={(newValue) => setSettings(newValue)}
          isProject={shipment.info?.type === 'MULTI_SHIPMENT_PROJECT'}
        />
        <LoadingWrappedNode loading={loading}>
          <Stack alignItems="center" mt="1rem">
            <Button variant="contained" onClick={submit}>
              Submit
            </Button>
          </Stack>
        </LoadingWrappedNode>

        <ErrorTypography error={error} />
      </Stack>
    </Modal>
  );
}
