import { Badge, Email } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import {
  Box, Divider,
  Tooltip,
  Typography,
} from '@mui/material';
import { ISOStringToLocalReadableTimestamp, userHasNeverLoggedIn } from 'utils';

interface Props {
  roles: UserRoleInfo[];
  title: string;
}

interface IconTextProps {
  icon: React.ReactNode;
  text: string;
}
const WARNING_TABLE_CELL_ROW = '#fce7c2';

function IconText(props: IconTextProps) {
  const { icon, text } = props;
  return (
    <Box display="flex" alignItems="center">
      {icon}
      <Typography ml="0.5rem" variant="body1">{text}</Typography>
    </Box>
  );
}

export function CompanyDetailRoles(props: Props) {
  const { roles, title } = props;

  return (
    <Box
      mr="1rem"
      borderRadius={1}
      maxHeight="50vh"
      borderColor="gray"
      sx={{
        overflowY: 'scroll',
        borderWidth: 1,
        borderStyle: 'solid',
        pl: '1rem',
        pr: '1rem',
      }}

    >
      <Box display="inline-flex" mt="1rem">
        <Typography variant="h5">
          {title}
          <Divider />
        </Typography>
      </Box>
      {roles.map((role) => (
        <Box
          key={role.user.id}
          sx={{
            backgroundColor: userHasNeverLoggedIn(role) ? WARNING_TABLE_CELL_ROW
              : undefined,
          }}
        >
          <Tooltip
            title={userHasNeverLoggedIn(role) ? 'This user has not activated their Evotrux account.' : ''}
            followCursor
          >
            <Box my="1rem">
              <IconText
                icon={<Badge />}
                text={`${role.user.first_name} ${role.user.last_name}`}
              />
              <IconText
                icon={<Email />}
                text={role.user.email}
              />
              {
                role?.branch?.name && (
                  <IconText
                    icon={<BusinessIcon />}
                    text={role.branch.name}
                  />
                )
              }
              <Typography variant="body2" color="text.secondary">
                Account created:
                {' '}
                {ISOStringToLocalReadableTimestamp(role.user.created_at)}
              </Typography>
            </Box>
          </Tooltip>
          <Divider />

        </Box>
      ))}
    </Box>
  );
}
