import { Box, Stack, Typography } from '@mui/material';
import { useAuthContext, useCompanyProfile, useRoleContext } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { CompanyReviewMetaLabel } from '../../../CompanyProfilePage/components/CompanyReviewMetaLabel';

export function CompanyProfileBanner() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { role, company } = useRoleContext();

  const companyId = company ? company.id : null;
  const { companyProfile } = useCompanyProfile(companyId);

  const showCompanyRep = company?.company_type === 'CARRIER';

  if (company == null || user == null
    || role == null || companyProfile == null || companyProfile.reviews_meta === undefined) {
    return <div />;
  }

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      spacing={2}
    >
      <Box
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={() => navigate('/account')}
      >
        <Typography variant="h6" color="white">{`${user?.first_name} ${user?.last_name}`}</Typography>
      </Box>
      <Typography variant="h6" color="white">|</Typography>
      <Stack
        flexWrap="wrap"
        direction="row"
        spacing={1}
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={() => navigate(`/companies/${role.company?.id}/profile`)}
      >
        <Typography
          noWrap
          variant="h6"
          color="white"
        >
          {role?.company?.name ?? ''}
        </Typography>
        {showCompanyRep && companyProfile?.reviews_meta && (
        <CompanyReviewMetaLabel
          reviewsMeta={companyProfile?.reviews_meta}
          labelType="h6"
          starIconSize="small"
          textColor="white"
          showNoReviewsText={false}
        />
        )}
      </Stack>
    </Stack>
  );
}
