import {
  Alert,
  Snackbar,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { useAPI, useDisclosure } from 'hooks';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyDetails } from './CompanyDetails';

export function CompanyDetailsContainer() {
  const params = useParams();
  const companyId = params.id;
  const [company, setCompany] = useState<CompanyDetailInfo | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { api } = useAPI();
  const { isOpen, onClose, onOpen } = useDisclosure(false);

  useEffect(() => {
    if (loading) {
      api<CompanyDetailInfo>('get', `/companies/${companyId}`).then((response) => {
        setCompany(response.data);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch company data.');
      });
      setLoading(false);
    }
  }, [loading, api, companyId]);

  const onRefresh = () => {
    setLoading(true);
    onOpen();
  };

  return (
    <>
      <CompanyDetails
        company={company}
        error={error}
        loading={loading}
        onRefreshCompanyInfo={onRefresh}
      />
      <Snackbar
        id="snackbar-update"
        open={isOpen}
        autoHideDuration={1000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionComponent={Slide}
      >
        <Alert onClose={onClose} severity="success" sx={{ width: '100%' }}>
          Page Updated
        </Alert>
      </Snackbar>
    </>
  );
}
