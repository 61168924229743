import { useRoleContext } from 'hooks';
import { useSubscriptionStatus } from 'hooks/useSubscriptionStatus';
import { FreeCarrierBanner } from './components/FreeCarrierBanner';
import { FreeTierShipperBanner } from './components/FreeTierShipperBanner';
import { PaidCarrierBanner } from './components/PaidCarrierBanner';
import { PaidShipperBanner } from './components/PaidShipperBanner';
import { TrialShipperBanner } from './components/TrialShipperBanner';

interface Props {
  showNavBar: boolean;
  navBar: React.ReactNode;
}

export function Banner(props: Props) {
  const { showNavBar, navBar } = props;
  const { company } = useRoleContext();
  const { subscription } = useSubscriptionStatus();

  if (subscription?.subscription_plan === 'CARRIER_ESSENTIALS'
   || subscription?.subscription_plan === 'CARRIER_PAY_PER_BOOKING') {
    return (
      <FreeCarrierBanner showNavBar={showNavBar} navBar={navBar} />
    );
  }
  if (subscription?.subscription_plan === 'SHIPPER_TRIAL') {
    return (
      <TrialShipperBanner showNavBar={showNavBar} navBar={navBar} />
    );
  }
  if (subscription?.subscription_plan === 'SHIPPER_FREE_TIER') {
    return (
      <FreeTierShipperBanner showNavBar={showNavBar} navBar={navBar} />
    );
  }

  if (company && company?.company_type === 'CARRIER') {
    return <PaidCarrierBanner showNavBar={showNavBar} navBar={navBar} />;
  } if (company && company?.company_type === 'SHIPPER') {
    return (
      <PaidShipperBanner showNavBar={showNavBar} navBar={navBar} />
    );
  }
  return <div />;
}
