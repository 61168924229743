import { Box, Stack, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { CARRIER_UPGRADE_RELATIVE_URL } from '../../../../constants';

export function CarrierPayPerBookingCenterBannerText() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={() => navigate(CARRIER_UPGRADE_RELATIVE_URL)}
    >
      <Stack direction="column" alignItems="center">
        <Typography variant="h6" color="white">Do not miss out on premium features!</Typography>
        <Typography variant="h6" color="yellow">
          <u>View Premium Plans</u>
        </Typography>
      </Stack>
    </Box>

  );
}
