import { Typography } from '@mui/material';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { IconText } from './IconText';

export function ShipmentFootageText<T extends number | null>(props: ShipmentInfoTextProps<T>) {
  const { data } = props;

  if (data == null) {
    return null;
  }

  return (
    <IconText
      icon={<SquareFootIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
      text={<Typography>{`${parseFloat(data.toString()).toFixed(1)} ft`}</Typography>}
    />
  );
}
