import {
  Button, Stack, TableCell, TableRow, Typography,
} from '@mui/material';

interface Props {
  request: FollowShipperRequest,
  onRespondToRequest: (requestId: number, requestApproved: boolean) => void;
}

export function PendingRequestTableRow(props: Props) {
  const { request, onRespondToRequest } = props;
  return (
    <TableRow
      key={request.id}
    >
      <TableCell>
        <Typography variant="body1">
          {request.created_by.user.first_name}
          {' '}
          {request.created_by.user.last_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{request.created_by.company.name}</Typography>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={() => onRespondToRequest(request.id, true)}>
            Follow
          </Button>
          <Button variant="text" onClick={() => onRespondToRequest(request.id, false)}>
            Ignore
          </Button>
        </Stack>
      </TableCell>
    </TableRow>
  );
}
