import * as React from 'react';

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// https://gist.github.com/babakness/faca3b633bc23d9a0924efb069c9f1f5

const { useEffect, useRef } = React;

type IntervalFunction = () => (unknown | void);

export function useInterval(callback: IntervalFunction, delayMilliseconds: number) {
  const savedCallback = useRef<IntervalFunction | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    const id = setInterval(tick, delayMilliseconds);
    return () => clearInterval(id);
  }, [delayMilliseconds]);
}
