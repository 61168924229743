import { Box } from '@mui/material';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': unknown;
    }
  }
}

interface Props {
  clientData: ExploDashboardClientInit | null;
  variables?: object | undefined;

}

export function ExploDashboard(props: Props) {
  const { clientData, variables } = props;
  return (
    <Box>
      {clientData && (
      <explo-dashboard
        dash-jwt={clientData.jwt}
        updateUrlParams={false}
        isProduction={clientData.is_prod}
        variables={variables ? JSON.stringify(variables) : undefined}
        environment={clientData.environment}
        id={clientData.jwt}
      />
      )}
    </Box>
  );
}
ExploDashboard.defaultProps = {
  variables: undefined,
};
