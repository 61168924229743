import {
  Checkbox,
  FormControl, InputLabel, ListItemText, MenuItem, Select as MUISelect, SelectProps,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface SelectOption {
  value: string;
  label: string;
}

export interface Props {
  inputLabel: string;
  defaultValue: string[];
  options: SelectOption[];
  selectProps?: SelectProps;
  onChange: (value: string[]) => void;
  disabled?: boolean;
  value?: string[];
  width?: string | number;
}

export function MultiSelect(props: Props) {
  const {
    defaultValue,
    inputLabel,
    options,
    selectProps,
    onChange,
    disabled,
    value: currentValue,
    width,
  } = props;
  const [value, setValue] = useState<string[]>(defaultValue);

  const handleChange = useCallback((newValue: string[]) => {
    setValue(newValue);
    onChange(newValue);
  }, [onChange]);

  const getRenderLabel = (selected: string[]) => {
    const labels: string[] = [];
    selected.forEach((selection) => {
      const label = options.find((o) => o.value === selection)?.label;
      if (label) {
        labels.push(label);
      }
    });
    return labels.join(', ');
  };

  useEffect(() => {
    if (currentValue != null) {
      setValue(currentValue);
    }
  }, [currentValue]);

  return (
    <FormControl sx={{ width }}>
      <InputLabel>{inputLabel}</InputLabel>
      <MUISelect
        autoWidth
        {...selectProps}
        label={inputLabel}
        value={value}
        onChange={(e) => {
          handleChange(e.target.value as unknown as string[]);
        }}
        disabled={disabled}
        multiple
        renderValue={(selected) => getRenderLabel(selected as unknown as string[])}
        MenuProps={MenuProps}
      >
        {
          options.map((o) => (
            <MenuItem key={o.value.toString()} value={o.value.toString()}>
              <Checkbox checked={value.indexOf(o.value.toString()) > -1} />
              <ListItemText primary={o.label.toString()} />
            </MenuItem>
          ))
        }
      </MUISelect>
    </FormControl>
  );
}

MultiSelect.defaultProps = {
  selectProps: {},
  value: null,
  width: 250,
  disabled: false,
};
