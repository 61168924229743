import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  Button, Divider, Modal, Stack, TextField, Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { SuccessTypography } from 'components/SuccessTypography';
import { useWriterComponentState } from 'hooks';
import { useState } from 'react';

interface Props {
  onModalClose: VoidFunction;
  isOpen: boolean;
  customName: string | null | undefined;
  shipmentId: number | null;
  onUpdateSuccess: VoidFunction;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  p: 4,
};

export function UpdateShipmentCustomNameModal(props: Props) {
  const {
    onModalClose,
    isOpen,
    customName,
    shipmentId,
    onUpdateSuccess,
  } = props;
  const [shipmentCustomName, setShipmentCustomName] = useState<string>('');

  const {
    loading, success, write, resetState, error,
  } = useWriterComponentState({
    errorMessage: 'Failed to update shipment custom name. Please contact us if the issue persists',
    writeConfig: {
      method: 'patch',
      url: shipmentId ? `/shipments/${shipmentId}/update` : '/',
      body: { custom_name: shipmentCustomName },
    },
  });

  const onSubmit = () => {
    if (shipmentId && shipmentCustomName.length > 0) {
      write(onUpdateSuccess);
    }
  };

  const handleClose = () => {
    onModalClose();
    setShipmentCustomName('');
    resetState();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <Stack justifyContent="flex-end" direction="row">
          <CloseIcon
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={handleClose}
          />
        </Stack>
        <Typography variant="h4" align="center" component="h2">
          {`${customName ? 'Edit' : 'Add'}`}
          {' '}
          shipment custom name
        </Typography>
        <Divider sx={{ my: '1rem' }} />
        <Typography variant="body1" align="center">The custom name will only be visible to your company and to the carrier who wins the shipment.</Typography>
        <Stack direction="column" spacing={2} justifyContent="center" mt="1rem">
          <TextField
            id="shipment-custom-name"
            variant="outlined"
            onChange={(e) => setShipmentCustomName(e.target.value)}
            value={shipmentCustomName}
          />
          <LoadingWrappedNode loading={loading}>
            <Stack justifyContent="center" alignContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmit}
                disabled={loading || shipmentCustomName.length === 0}
                sx={{
                  pl: '2rem', pr: '2rem', pt: '.5rem', pb: '.5rem',
                }}
              >
                Submit
              </Button>
            </Stack>
          </LoadingWrappedNode>
        </Stack>
        <ErrorTypography error={error} />
        <SuccessTypography success={success} message="Shipment custom name updated." />
      </Box>
    </Modal>
  );
}
