import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ExpandIcon from '@mui/icons-material/Expand';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useDisclosure } from 'hooks';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export function AdditionalDetailsTextField(props: Props) {
  const { value, onChange } = props;
  const {
    isOpen: isMultiline,
    onOpen: onMultline,
    onClose: onSingleLine,
  } = useDisclosure(false);

  return (
    <TextField
      label={value.length > 0 ? '' : 'Product Dimensions'}
      helperText="Product dimensions, number of items, stackable/non-stackable, etc."
      value={value}
      onChange={(event) => onChange(event.target.value)}
      sx={{ width: '50%' }}
      multiline={isMultiline}
      maxRows={20}
      minRows={5}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => (isMultiline ? onSingleLine() : onMultline())}>
              {isMultiline ? <CloseFullscreenIcon /> : <ExpandIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
