import { useCompanyBranches, useCompanyMembers } from 'hooks';
import { MultiSelect } from '../Select';

interface Props {
  onChange: (value: string[]) => void;
  value: string[] | undefined;
  selectedBranchIds: string[];
}

export function CompanyAdminShipperMultiSelect(props: Props) {
  const { onChange, value, selectedBranchIds } = props;
  const { branches, loading: loadingBranches } = useCompanyBranches();
  const { members: allCompanyShippers, loading: loadingCompanyMembers } = useCompanyMembers();

  const buildOptions = () => {
    if (loadingBranches
      || loadingCompanyMembers
      || allCompanyShippers.length === 0) {
      return [];
    }

    if (selectedBranchIds.length > 0) {
      /*
      If at least one branch is selected, only show shippers from the selected branches
      */

      const selectedBranches = selectedBranchIds.map(
        (branchId: string) => branches.find((branch) => branch.branch.id.toString() === branchId),
      ).filter((branch) => branch !== undefined) as BranchMembers[];

      const allBranchShippers = selectedBranches.flatMap(
        (branch: BranchMembers) => branch.members,
      ).filter((members) => members.type === 'SHIPPER');

      const options = allBranchShippers.map((shipper) => ({
        value: shipper.id.toString(),
        label: `${shipper.user.first_name} ${shipper.user.last_name}`,
      }));
      return options;
    }
    // else show all company shippers (with and without branch)
    const companyShippers = allCompanyShippers.filter(
      (members) => members.type === 'SHIPPER',
    );

    const options = companyShippers.map((shipper) => ({
      value: shipper.id.toString(),
      label: `${shipper.user.first_name} ${shipper.user.last_name}`,
    }));
    return options;
  };

  return (
    <MultiSelect
      inputLabel={loadingCompanyMembers || loadingBranches ? 'Loading Shippers...' : 'Shippers'}
      defaultValue={value || []}
      value={value}
      onChange={onChange}
      options={buildOptions()}
      disabled={loadingCompanyMembers || loadingBranches}
    />
  );
}
