import { Divider } from '@mui/material';
import { EVOTRUX_BLUE } from '../../constants';

export function EvotruxDivider() {
  return (
    <div>
      <Divider style={{ borderColor: EVOTRUX_BLUE }} />
      <Divider style={{ borderColor: EVOTRUX_BLUE }} />
    </div>
  );
}
