import {
  Box, Button, Stack, TextField, Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PersonalInformationDisclaimer } from 'components/PersonalInformationDisclaimer';
import { useAPI, useDisclosure, useShipmentPageContext } from 'hooks';
import { useState } from 'react';
import { shipmentIsActive } from 'utils';

interface Props {
  shipmentId: number | undefined;
  shipment: CondensedShipment
}

export function SubmitQuestionMenu(props: Props) {
  const { shipmentId, shipment } = props;
  const { onRefresh } = useShipmentPageContext();

  const { api } = useAPI();
  const [error, setError] = useState<null | string>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [question, setQuestion] = useState<string>('');

  const postQuestion = () => {
    api<unknown>('post', `/shipments/${shipmentId}/questions`, { question }).then(() => {
      if (onRefresh) {
        onRefresh();
      }
      onClose();
      setError(null);
    }).catch((e) => {
      setError(e?.response?.data?.message || 'Failed to submit question');
    });
  };

  const onModalClose = () => {
    setError(null);
    onClose();
  };

  if (!shipmentId) {
    return <div />;
  }

  const getPostQuestionDialog = () => (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        Confirm Question
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please confirm that you wish to send the following question:
        </DialogContentText>
        <Box mt="1rem" mb="1rem" textAlign="center">
          <em>{question}</em>
        </Box>
        <PersonalInformationDisclaimer />
        <Stack mt="1rem">
          <Typography color="red" align="center">{error}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onModalClose}>Cancel</Button>
        <Button onClick={postQuestion} autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div>
      {getPostQuestionDialog()}
      <Stack direction="column">
        {shipmentIsActive(shipment)
          ? (
            <Stack direction="row" mt="1rem" justifyContent="center" spacing={2}>
              <TextField
                fullWidth
                label="Ask the shipper a question"
                onChange={(e) => setQuestion(e.target.value)}
              />
              <Button disabled={question.length < 1} variant="contained" sx={{ pl: '2rem', pr: '2rem' }} onClick={onOpen}>Send</Button>
            </Stack>
          )
          : <Typography align="center" variant="body2">Questions can only be created while the shipment is active.</Typography>}
      </Stack>
    </div>
  );
}
