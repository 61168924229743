export function getShipmentTemplateVisibiliyLabel(
  template: ShipmentTemplate,
): string | null {
  if (template?.content?.notification_settings_type
    && (template?.content?.notification_settings_type === 'ALL_CARRIER_NETWORK'
    || template?.content?.notification_settings_type === 'SELECTED_NETWORK_CARRIERS')

  ) {
    return 'Public';
  }
  if (template?.content?.notification_settings_type
    && (template?.content?.notification_settings_type === 'PRIVATE_SHIPMENT'
    || template?.content?.notification_settings_type === 'PRIVATE_SHIPMENT_ALL_NETWORK')

  ) {
    return 'Private';
  }
  return null;
}
