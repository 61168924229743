import { useState } from 'react';
import { useAPI } from './useAPI';
import { useError } from './useError';
import { useLoading } from './useLoading';

interface WriteConfig {
  method: HTTPRequestMethod;
  url: string;
  body?: unknown;
}

interface Props {
  onWriteFailure?: (e: Error) => void;
  onWriteFinally?: VoidFunction;
  errorMessage: string;
  writeConfig: WriteConfig
  errorMessageKey?: string; // on error, use this key to get the error message from the response.
}

export function useWriterComponentState(props: Props) {
  const {
    onWriteFailure,
    onWriteFinally,
    errorMessage,
    writeConfig,
    errorMessageKey,
  } = props;
  const { api } = useAPI();
  const { error, setError } = useError();
  const { loading, setLoading } = useLoading(false);
  const [success, setSuccess] = useState<boolean>(false);

  const resetState = () => {
    setError(null);
    setLoading(false);
    setSuccess(false);
  };

  function write<T>(onWriteSuccess?: (responseData: T) => void) {
    setError(null);
    setLoading(true);
    api<T>(writeConfig.method, writeConfig.url, writeConfig.body).then((response) => {
      setSuccess(true);
      setError(null);
      if (onWriteSuccess) {
        onWriteSuccess(response.data);
      }
    }).catch((e) => {
      if (errorMessageKey) {
        setError(e?.response?.data?.[errorMessageKey] || errorMessage);
      } else {
        setError(errorMessage);
      }
      setSuccess(false);
      if (onWriteFailure) {
        onWriteFailure(e);
      }
    }).finally(() => {
      setLoading(false);
      if (onWriteFinally) {
        onWriteFinally();
      }
    });
  }

  return {
    loading, error, success, write, resetState,
  };
}

useWriterComponentState.defaultProps = {
  onWriteFailure: undefined,
  onWriteFinally: undefined,
};
