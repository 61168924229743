import {
  Box, Stack, Typography,
} from '@mui/material';
import { UnauthenticatedTawkToWrapper } from 'components/UnauthenticatedTawkToWrapper';
import { DashboardItemLayout, PageLayout } from 'layouts';
import { DateTime } from 'luxon';
import { ProductFruits } from 'react-product-fruits';
import { useSearchParams } from 'react-router-dom';
import { CreateShipmentAndAccountForm } from './components/CreateShipmentAndAccountForm';
import { FakeNavigationBar } from './components/FakeNavigationBar';
import { FakeShipmentList } from './components/FakeShipmentList';

export function NewShipperPage() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') ?? null;

  return (
    <Box display="flex" sx={{ flexFlow: 'column', height: '100vh' }}>
      <ProductFruits
        workspaceCode="lg24lvyWRjoM2d0l"
        language="en"
        user={{
          username: email ?? `new-shipper-${DateTime.now().toUnixInteger()}`,
        }}
      />
      <UnauthenticatedTawkToWrapper />
      <Box
        display="flex"
        sx={{
          backgroundColor: '#0d2e45',
          height: '100%',
          padding: '.5rem 2rem 2rem 2rem',
          overflow: 'hidden',
        }}
      >
        <FakeNavigationBar />
        <Box
          display="flex"
          sx={{
            flexFlow: 'column', width: '100%', borderRadius: 4, overflowY: 'scroll',
          }}
        >
          <Stack direction="row" p="1rem" spacing={3} justifyContent="right">
            <Typography variant="h5" color="white">
              <a style={{ color: 'white', textDecoration: 'none' }} href="/login">Log in </a>
            </Typography>
            <Typography variant="h5" color="white">|</Typography>
            <Typography variant="h5" color="white">
              <a style={{ color: 'white', textDecoration: 'none' }} href="/sign-up">Register</a>
            </Typography>
          </Stack>
          <PageLayout>
            <Stack direction="row" spacing={8}>
              <DashboardItemLayout style={{ p: '2rem' }}>
                <CreateShipmentAndAccountForm />
              </DashboardItemLayout>
              <DashboardItemLayout style={{ width: '50%' }}>
                <FakeShipmentList />
              </DashboardItemLayout>
            </Stack>
          </PageLayout>
        </Box>
      </Box>
    </Box>
  );
}
