import { useCarrierShipmentPageContext } from './useCarrierShipmentPageContext';
import { useRoleContext } from './useRoleContext';
import { useShipperShipmentPageContext } from './useShipperShipmentPageContext';

interface SharedShipmentPageContext {
  shipment: undefined | CarrierCondensedShipment | ShipperCondensedShipment;
  onRefresh: VoidFunction | null;
}

const UNINITIALIZED_CONTEXT = {
  shipment: undefined,
  onRefresh: null,
};

/*
Exposes root information about the shipment detail page
to all compontents down the tree. Use this hook in components
that both CARRIER an SHIPPER share.
*/
export function useShipmentPageContext(): SharedShipmentPageContext {
  const { role } = useRoleContext();
  const {
    shipment: carrierShipment,
    onRefresh: carrierOnRefresh,
  } = useCarrierShipmentPageContext();
  const {
    shipment: shipperShipment,
    onRefresh: shipperOnRefresh,
  } = useShipperShipmentPageContext();

  if (role && role.type === 'CARRIER') {
    return {
      shipment: carrierShipment,
      onRefresh: carrierOnRefresh,
    };
  } if (role && role.type === 'SHIPPER') {
    return {
      shipment: shipperShipment,
      onRefresh: shipperOnRefresh,
    };
  }
  return UNINITIALIZED_CONTEXT;
}
