import {
  Chip,
} from '@mui/material';

interface Props {
  businessType?: BusinessTypeValue | null;
}

export function CompanyBusinessTypeChip(props: Props) {
  const { businessType } = props;

  if (businessType === undefined || businessType == null || businessType === '') {
    return (
      <div />
    );
  }

  let text = '';
  if (businessType === 'BROKER') {
    text = 'Broker';
  } else if (businessType === 'DIRECT_SHIPPER') {
    text = 'Direct Shipper';
  } else if (businessType === 'TRUCKING_COMPANY') {
    text = 'Carrier';
  }

  const getChipColor = () => {
    if (businessType === 'DIRECT_SHIPPER') {
      return 'success';
    } if (businessType === 'TRUCKING_COMPANY') {
      return 'primary';
    }
    return undefined;
  };

  return (
    <Chip
      label={text}
      variant="outlined"
      size="small"
      color={getChipColor()}
    />
  );
}

CompanyBusinessTypeChip.defaultProps = {
  businessType: undefined,
};
