import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

interface SendJoinRequestDialogProps {
  company: Company;
  onCompanySelected: (companyID: number) => void;
  isOpen: boolean;
  onClose: () => void;
}
export function SendJoinRequestDialog(props: SendJoinRequestDialogProps) {
  const {
    company, onCompanySelected, isOpen, onClose,
  } = props;

  const onClickSubmit = () => {
    onCompanySelected(company.id);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Join Company?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="body1" gutterBottom>
            Are you sure you want to join
            {' '}
            <b>{company.name}</b>
            ? The administrator will be notified of your request.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onClickSubmit} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
