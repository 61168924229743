import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { BranchManager } from 'components/BranchManager';
import { useAuthContext, useRoleContext } from 'hooks';

export const CREATE_BRANCH_FORM_URL = 'https://form.jotform.com/242486894712872';

interface Props {
  branches: BranchMembers[];
  viewingAsRoles: Role[];
  viewingAsCompanyAdmin: boolean;
}
export function CompanyBranches(props: Props) {
  const { branches, viewingAsRoles, viewingAsCompanyAdmin } = props;
  const { user } = useAuthContext();
  const { company } = useRoleContext();

  if (user == null || viewingAsRoles.length < 1 || company == null) {
    return <div />;
  }

  const adminRole = viewingAsRoles[0];

  const fullUrl = `${CREATE_BRANCH_FORM_URL}?role_id=${adminRole.id}&company_id=${company.id}`;

  const redirectToAddBranch = () => {
    window.open(fullUrl, '_blank');
  };

  return (
    <Stack direction="column">
      <Box sx={{ mt: '1rem' }}>
        {viewingAsCompanyAdmin && (
        <Button
          variant="contained"
          onClick={redirectToAddBranch}
        >
          Create Branch
        </Button>
        )}
      </Box>
      {branches.length === 0 && (<Typography variant="h6" mt=".5rem">You have no branches. Create a branch today to better organize activity and activity data!</Typography>)}
      <BranchManager
        branchesAndMembers={branches}
        viewingAsRoles={viewingAsRoles}
      />
    </Stack>
  );
}
