import { Box, Stack, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import { EvotruxDivider } from 'components/EvotruxDivider';

interface Props {
  children: React.ReactNode;
  containerTitle: string;
}

export function InputsContainer(props: Props) {
  const { children, containerTitle } = props;

  return (
    <Stack>
      <AppBar
        position="fixed"
      >
        <Box>
          <Paper
            elevation={3}
            variant="elevation"
            sx={{
              p: '1rem',
              ml: '6rem',
            }}
          >
            <Stack direction="column">
              <Typography variant="body1">
                <b>{containerTitle}</b>
                <EvotruxDivider />
              </Typography>
              {children}
            </Stack>
          </Paper>
        </Box>
      </AppBar>
    </Stack>
  );
}
