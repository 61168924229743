import { Button } from '@mui/material';
import { useDisclosure } from 'hooks';
import { useEffect, useState } from 'react';
import { ShipmentQuote } from './ShipmentQuote';

interface Props {
  quote: CondensedQuote | ShipperCondensedQuote;
}

export function ShipmentQuoteContainer(props: Props) {
  const { quote } = props;
  const { isOpen, onClose, onOpen } = useDisclosure(false);

  const VIEW_HISTORY_BUTTON_TEXT = 'View History';
  const HIDE_HISTORY_BUTTON_TEXT = 'Hide History';

  const [historyButtonText, setHistoryButtonText] = useState<string>(VIEW_HISTORY_BUTTON_TEXT);

  useEffect(() => {
    if (isOpen) {
      // Changing to viewing history.
      setHistoryButtonText(HIDE_HISTORY_BUTTON_TEXT);
    } else {
      // Changing to hidden history.
      setHistoryButtonText(VIEW_HISTORY_BUTTON_TEXT);
    }
  }, [isOpen]);

  const historyButton = (
    <Button variant="text" onClick={isOpen ? onClose : onOpen}>{historyButtonText}</Button>
  );

  return (
    <ShipmentQuote
      viewQuoteHistory={historyButton}
      quote={quote}
      historyIsExpanded={isOpen}
    />
  );
}
