import { useEffect, useState } from 'react';
import { useAPI } from './useAPI';
import { useGetShipmentUrl } from './useGetShipmentUrl';

interface Props {
  shipmentId: number
}

export function useShipment(props: Props) {
  const [shipment,
    setShipment] = useState<CarrierCondensedShipment | ShipperCondensedShipment | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { shipmentId } = props;
  const { url } = useGetShipmentUrl({ shipmentId });
  const { api } = useAPI();

  useEffect(() => {
    if (url && loading) {
      api<CarrierCondensedShipment | ShipperCondensedShipment>('get', url).then((response) => {
        setShipment(response.data);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [url, loading, api]);

  useEffect(() => {
    setLoading(true);
  }, [shipmentId]);

  return {
    shipment, loading,
  };
}
