import {
  Button, TableCell, TableRow, Typography,
} from '@mui/material';

interface Props {
  followed: FollowedShipper;
  onUnfollowShipperClicked: (shipper: FollowedShipper) => void;

}

export function EstablishedNetworkTableRow(props: Props) {
  const { followed, onUnfollowShipperClicked } = props;
  return (
    <TableRow
      key={followed.id}
    >
      <TableCell>
        <Typography variant="body1">
          {followed.user.first_name}
          {' '}
          {followed.user.last_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">{followed.company.name}</Typography>
      </TableCell>
      <TableCell>
        <Button variant="outlined" onClick={() => onUnfollowShipperClicked(followed)}>
          Unfollow
        </Button>
      </TableCell>
    </TableRow>
  );
}
