import { useCallback, useEffect, useState } from 'react';
import { useAPI, useAuthContext } from '../../../../hooks';
import { validateEmail } from '../../../../utils';
import { CheckCarrierCompanyDetails } from '../types';
import { CreateCarrierCompanyForm } from './CreateCarrierCompanyForm';

interface Props {
  companyData: CheckCarrierCompanyDetails;
  handleSearchCompanySelected: () => void;
  handleResetCompanyData: () => void;
}

const initialAddressData = {
  address: '',
  city: '',
  province: '',
  country: '',
  postal: '',
};

const initialRemainingCompanyData = {
  email: '',
  description: '',
};

export function CreateCarrierCompanyFormContainer(props: Props) {
  const { refreshUser } = useAuthContext();
  const { companyData, handleSearchCompanySelected, handleResetCompanyData } = props;
  const { api } = useAPI();
  const [error, setError] = useState<null | string>(null);
  const [addressData, setAddressData] = useState<Partial<CompanyAddress>>(initialAddressData);
  const [remainingCompanyData, setRemainingCompanyData,
  ] = useState<Partial<Company>>(initialRemainingCompanyData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<null | string>(null);

  const onAddressValueChanged = useCallback((key: keyof CompanyAddress, value: string): void => {
    setAddressData((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const onCompanyValueChanged = (key: keyof Company, value: string): void => {
    setRemainingCompanyData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (remainingCompanyData.email) {
      setEmailError(validateEmail(remainingCompanyData.email));
    }
  }, [remainingCompanyData.email, setEmailError]);

  useEffect(() => {
    if (isLoading) {
      addressData.postal = addressData.postal?.toUpperCase().replace(/ /g, '');
      const body = {
        address: addressData,
        name: companyData.name,
        company_type: 'CARRIER',
        description: remainingCompanyData.description,
        email: remainingCompanyData.email,
      };
      api('post', '/companies', body).then(() => {
        /*
          Refreshing the user causes mounting the dashboard,
          since the user will be assigned an admin role linked
          to the company upon company creation
        */
        refreshUser();
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to create company!');
      });
      setIsLoading(false);
    }
  }, [api, addressData, isLoading, companyData, remainingCompanyData, refreshUser]);

  const onCreateCompany = (): void => {
    setIsLoading(true);
  };

  const createCompanyDisabled = (): boolean => {
    const addressDataComplete = Object.keys(addressData)
      .some((key) => (addressData[key as keyof CompanyAddress]?.length === 0));
    return addressDataComplete || emailError != null;
  };

  return (
    <CreateCarrierCompanyForm
      companyData={companyData}
      handleResetCompanyData={handleResetCompanyData}
      handleSearchCompanySelected={handleSearchCompanySelected}
      onAddressValueChanged={onAddressValueChanged}
      onCreate={onCreateCompany}
      error={error}
      createCompanyDisabled={createCompanyDisabled()}
      onCompanyValueChanged={onCompanyValueChanged}
      emailError={emailError}
    />
  );
}
