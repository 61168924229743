import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CARRIER_UPGRADE_RELATIVE_URL } from '../../../../../../../../constants';

export function UpgradeToRevealPriceButton() {
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      onClick={() => {
        window.history.pushState({}, window.location.href);
        navigate(CARRIER_UPGRADE_RELATIVE_URL);
      }}
      color="warning"
    >
      Upgrade to show booked rate
    </Button>
  );
}
