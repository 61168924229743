import { SHIPMENT_SERVICE_OPTIONS } from '../../constants';
import { Select } from '../Select';

interface Props {
  onChange: (value: string) => void;
  showAllOption?: boolean;
  defaultValue?: string;
  value?: string;
}

const ALL_OPTION = [
  {
    value: 'ALL',
    label: 'All',
  },
];

export function ServiceTypeSelect(props: Props) {
  const {
    onChange, showAllOption, defaultValue, value,
  } = props;
  const serviceTypeOptions = showAllOption
    ? ALL_OPTION.concat(SHIPMENT_SERVICE_OPTIONS)
    : SHIPMENT_SERVICE_OPTIONS;

  return (
    <Select<string>
      inputLabel="Service"
      defaultValue={defaultValue || ''}
      onChange={onChange}
      value={value}
      options={serviceTypeOptions}
    />
  );
}

ServiceTypeSelect.defaultProps = {
  showAllOption: false,
  defaultValue: '',
  value: null,
};
