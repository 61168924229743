import {
  Box, Stack, Typography,
} from '@mui/material';
import { CompanyPrivilegedReviews } from 'components/CompanyPrivilegedReviews';
import { CompanyPublicReviews } from 'components/CompanyPublicReviews';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { useRoleContext } from 'hooks';

interface Props {
  companyId: number;
}

export function CompanyProfileReviews(props: Props) {
  const { companyId } = props;
  const { role } = useRoleContext();

  let reviewsComponent = <div />;
  if (role != null && role.company != null) {
    if (role.company.id === companyId) {
      reviewsComponent = <CompanyPrivilegedReviews companyId={companyId} />;
    } else {
      reviewsComponent = <CompanyPublicReviews companyId={companyId} />;
    }
  }

  return (
    <Box mt="2rem">
      <Typography variant="h4">Company Reviews</Typography>
      <EvotruxDivider />
      <Stack direction="column">
        {reviewsComponent}
      </Stack>
    </Box>
  );
}
