import {
  Chip,
} from '@mui/material';

interface Props {
  quoted: boolean;
}

export function QuotedShipmentChip(props: Props) {
  const { quoted } = props;

  if (!quoted) {
    return (
      <div />
    );
  }

  return (
    <Chip
      label="Quoted"
      color="info"
      variant="outlined"
      size="small"
    />
  );
}
