import {
  Alert, Button, CircularProgress, Stack, TextField, Typography,
} from '@mui/material';
import { StarRatingMenu } from 'components/StarRatingMenu';

interface Props {
  setReviewRating: (val: CompanyRatingValue | null) => void;
  setReviewText: (val: string) => void;
  onSubmit: VoidFunction;
  canSubmit: boolean;
  companyProfile: CompanyProfile | null;
  rating: CompanyRatingValue | null
  error: string | null;
  loading: boolean;
  success: boolean
}

export function CreateCompanyReviewMenu(props: Props) {
  const {
    setReviewText,
    setReviewRating,
    onSubmit,
    canSubmit,
    companyProfile,
    rating,
    error,
    loading,
    success,
  } = props;

  return (
    <div>
      <Stack direction="column">
        <Stack alignItems="center" spacing={2}>
          {companyProfile && companyProfile.company.name && (<Typography sx={{ mb: '2rem' }} variant="h4">{companyProfile.company.name}</Typography>)}
          <StarRatingMenu onStarRatingSet={setReviewRating} rating={rating} />
          <TextField
            fullWidth
            minRows={4}
            multiline
            label="Detail your experience with the company."
            onChange={(e) => setReviewText(e.target.value)}
          />
          <Alert variant="outlined" severity="info" sx={{ mt: '1rem' }}>
            This review will be public and may include your name and company name.
          </Alert>
          <Stack mt="3rem" direction="column" alignItems="center">
            {success && <Typography color="green" mt="1rem">Review created.</Typography>}
            {loading ? <CircularProgress /> : <Button variant="contained" disabled={!canSubmit} onClick={onSubmit}>Submit Review</Button>}
            { error && <Typography mt="1rem" color="red">{error}</Typography>}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}
