import {
  Box, CircularProgress, Stack, Typography,
} from '@mui/material';
import { useCarrierCompanyBookingLimits, useStripeCustomerBillingPortal } from 'hooks';

export function LimitedBookingsCarrierRemainingBookingsText() {
  const { limits } = useCarrierCompanyBookingLimits();
  const {
    requestCustomerBillingPortalSession, loading,
  } = useStripeCustomerBillingPortal({
    onSessionCreatedSuccess: (url) => {
      window.open(url, '_blank');
    },
  });

  if (limits == null) {
    return <div />;
  }

  const getBookingsRemainingLabel = () => {
    if (limits.remainingBookings === 1) {
      return (
        <Typography variant="h6" color="white">
          {'You have '}
          <span style={{ color: 'yellow' }}>
            1
          </span>
          {' non-partner booking left'}
        </Typography>
      );
    } if (limits.remainingBookings === 0 || limits.remainingBookings < 0) {
      return (
        <Typography variant="h6" color="white">
          {'You have '}
          <span style={{ color: 'red' }}>
            0
          </span>
          {' non-partner bookings left'}
        </Typography>
      );
    }
    return (
      <Typography variant="h6" color="white">
        {`You have ${limits.remainingBookings} non-partner bookings left`}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={() => requestCustomerBillingPortalSession()}
    >
      <Stack direction="column" alignItems="center">
        {getBookingsRemainingLabel()}
        {loading ? <CircularProgress /> : (
          <Typography variant="h6" color="yellow">
            <u>Upgrade Today</u>
          </Typography>
        )}
      </Stack>
    </Box>

  );
}
