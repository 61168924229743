import { Stack, useMediaQuery } from '@mui/material';
import { useRef } from 'react';
import { DashboardItemLayout, PageLayout } from '../../../../layouts';
import { CarrierQuoteList } from '../CarrierQuoteList';
import { ShipmentFilterSavedSearch } from '../ShipmentFilterSavedSearch';

export function Dashboard() {
  const ref = useRef<HTMLDivElement | null>(null);
  const reduceDashboardItemSpacing = useMediaQuery('(max-width:1300px)');
  const useColumnLayout = useMediaQuery('(max-width:1350px)');

  const scrollToTop = () => {
    ref?.current?.scrollIntoView();
  };

  return (
    <PageLayout>
      <div style={{ display: 'hidden' }} ref={ref} />
      <Stack
        direction={useColumnLayout ? 'column' : 'row'}
        spacing={reduceDashboardItemSpacing ? 4 : 8}
      >
        <DashboardItemLayout style={{ width: !useColumnLayout ? '65%' : undefined }}>
          <ShipmentFilterSavedSearch scrollToTop={scrollToTop} />
        </DashboardItemLayout>
        <CarrierQuoteList useColumnLayout={useColumnLayout} />
      </Stack>
    </PageLayout>
  );
}
