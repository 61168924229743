import { Box, Chip, Stack } from '@mui/material';

interface Props {
  shipment: ShipperCondensedShipment | undefined
  progress: ShipmentProgress | null
  onClick: VoidFunction
}

export function ShipmentProgressStats(props: Props) {
  const { shipment, progress, onClick } = props;

  const CHIP_PROPS = {
    color: 'primary' as const,
    variant: 'outlined' as const,
    size: 'small' as const,
    onClick,
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="center" spacing={1}>
        <Chip
          label={progress ? `${progress.notified_network_carrier_count} partners notified` : 'partners notified'}
          {...CHIP_PROPS}
        />
        {shipment && progress && !shipment.is_private && progress.lane_match_count > 0 && (
          <Chip
            label={`${progress.lane_match_count} matches`}
            {...CHIP_PROPS}
          />
        )}
        {(!shipment || !progress) && (!shipment?.is_private && progress
        && progress.lane_match_count === 0) && (
          <Chip
            label="matches loading..."
            {...CHIP_PROPS}
          />
        )}
        <Chip
          label={progress ? `${progress.view_count} views` : 'views'}
          {...CHIP_PROPS}
        />
        <Chip
          label={progress ? `${progress.quote_count} quotes` : 'quotes'}
          {...CHIP_PROPS}
        />
      </Stack>
    </Box>
  );
}
