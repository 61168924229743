import { CompanyReview } from 'components/CompanyReview';
import { CompanyReviewList } from 'components/CompanyReviewList';

interface Props {
  loading: boolean;
  error: string | null;
  reviewProfile: PrivilegedCompanyReviewProfile | null;
}

export function CompanyPrivilegedReviews(props: Props) {
  const { loading, error, reviewProfile } = props;

  return (
    <CompanyReviewList
      loading={loading}
      error={error}
      reviewCount={reviewProfile?.reviews.length ?? null}
    >
      {
        reviewProfile?.reviews.map((review) => (
          <CompanyReview
            key={review.created_at}
            text={review.text}
            author={`${review.created_by.user.first_name} ${review.created_by.user.last_name}`}
            createdAt={review.created_at}
            rating={review.rating}
            authorCompany={review.created_by.company.name}
          />
        ))
      }
    </CompanyReviewList>
  );
}
