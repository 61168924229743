import { useMemo, useState } from 'react';
import { useRoleContext } from './useRoleContext';

export function useGetShipmentsUrl(shipmentId: string | undefined) {
  const [url, setUrl] = useState<string | null>(null);
  const { role } = useRoleContext();
  useMemo(() => {
    if (role != null && shipmentId !== undefined) {
      if (role.type === 'SHIPPER') {
        setUrl(`/shippers/${role.id}/shipments/${shipmentId}/condensed`);
      } else if (role.type === 'CARRIER') {
        setUrl(`/shipments/${shipmentId}/condensed`);
      }
    }
  }, [role, shipmentId]);
  return { url };
}
