import {
  Button, Stack, TextField, Typography,
} from '@mui/material';
import { useAPI, useShipmentPageContext } from 'hooks';
import { useState } from 'react';

interface Props {
  postQuestionURL: string;
}

export function SubmitAnswerMenu(props: Props) {
  const { postQuestionURL } = props;
  const { api } = useAPI();
  const [error, setError] = useState<null | string>(null);
  const [answer, setAnswer] = useState<string>('');
  const { onRefresh } = useShipmentPageContext();

  const postAnswer = () => {
    api<unknown>('post', postQuestionURL, { answer }).then(() => {
      setError(null);
      if (onRefresh) {
        onRefresh();
      }
    }).catch((e) => {
      setError(e?.response?.data?.message || 'Failed to submit answer');
    });
  };

  return (
    <div>
      <Stack direction="column" sx={{ mt: '1rem' }}>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <TextField
            fullWidth
            required
            label="Answer"
            onChange={(e) => setAnswer(e.target.value)}
          />
          <Button disabled={answer.length < 1} variant="contained" sx={{ pl: '2rem', pr: '2rem' }} onClick={postAnswer}>Send</Button>
        </Stack>
        <Typography color="red" align="center">{error}</Typography>
      </Stack>
    </div>
  );
}
