import { Select } from 'components/Select';

interface Props {
  status: string | undefined;
  onStatusChange: (value: string) => void;
}

const OPTIONS = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'ACCEPTED',
    label: 'Booked',
  },
  {
    value: 'DECLINED',
    label: 'Declined',
  },
  {
    value: 'CANCELLED',
    label: 'Cancelled',
  },
];

export function QuoteStatusFilterDropdown(props: Props) {
  const { status, onStatusChange } = props;
  return (
    <Select<string>
      inputLabel="Quote Status"
      defaultValue={status ?? 'ALL'}
      onChange={onStatusChange}
      options={OPTIONS}
    />
  );
}
