import * as React from 'react';

export function useDisclosure(initialOpenState = false) {
  const [isOpen, setIsOpen] = React.useState<boolean>(initialOpenState);

  const onOpen = (): void => {
    setIsOpen(true);
  };

  const onClose = (): void => {
    setIsOpen(false);
  };

  return { isOpen, onOpen, onClose };
}
