import {
  Box, Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CurrencySelect } from '../../../../../CurrencySelect';
import { NumberInput } from '../../../../../NumberInput';
import { CounterQuoteFormState } from './types';

interface Props {
  onClose: VoidFunction;
  onConfirmAction: VoidFunction;
  error: string | null;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  onInputChange: <T>(key: keyof CounterQuoteFormState, value: T) => void;
  disableSubmit: boolean;
  formState: CounterQuoteFormState
}

export function CounterQuoteInteraction(props: Props) {
  const {
    onClose,
    onConfirmAction,
    loading,
    error,
    onInputChange,
    disableSubmit,
    formState,
  } = props;
  const onCurrencyChanged = (newValue: string) => {
    onInputChange<string>('currency', newValue);
  };
  return (

    <Dialog open fullWidth onClose={onClose}>
      <DialogTitle>
        Send a counteroffer to negotiate a better price
      </DialogTitle>
      <DialogContent>
        {loading
          ? <Stack alignItems="center"><CircularProgress /></Stack>
          : (
            <Box>
              <Stack direction="row" spacing={1} m="1rem" flexWrap="wrap">
                <NumberInput
                  inputLabel="Price"
                  defaultValue={formState.price}
                  onChange={(val: string) => onInputChange<string>('price', val)}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
                <CurrencySelect onChange={onCurrencyChanged} defaultValue={formState.currency} />
              </Stack>
              <TextField
                sx={{ width: '80%', m: '1rem' }}
                label="Notes (Optional)"
                value={formState.notes}
                onChange={(e) => onInputChange<string>('notes', e.target.value)}
              />
            </Box>
          )}
        {error != null && !loading && <Typography color="red" align="center">{error}</Typography>}
      </DialogContent>
      {!loading && (
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={disableSubmit}
          onClick={onConfirmAction}
        >
          SEND COUNTEROFFER

        </Button>
      </DialogActions>
      )}
    </Dialog>
  );
}
