import {
  Box, CircularProgress, Stack, Typography,
} from '@mui/material';
import { getRouteDestination, getRouteOrigin } from '../../../../utils';
import { RoutePointDetail } from '../../../Shipment/components/RoutePointDetail';

interface Props {
  error: string | null
  route: Route | null
  loading: boolean;
}

export function RouteTab(props: Props) {
  const {
    error, route, loading,
  } = props;
  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  if (error != null) {
    return (
      <Stack>
        <Typography color="red" align="center">{error}</Typography>
      </Stack>
    );
  }

  if (!route?.route_points || route.route_points.length < 1) {
    return (
      <Stack>
        <Typography align="center">No route points found.</Typography>
      </Stack>
    );
  }

  const midPoints = route?.route_points.filter((rp) => rp.stop_type !== 'ORIGIN' && rp.stop_type !== 'DESTINATION').sort((a, b) => a.point_number - b.point_number);
  const origin = getRouteOrigin(route.route_points);
  const destination = getRouteDestination(route.route_points);
  return (
    <Stack direction="column">
      {origin != null && <RoutePointDetail routePoint={origin} />}
      {midPoints.map((rp) => (
        <Box key={rp.point_number}>
          <RoutePointDetail routePoint={rp} />
        </Box>
      ))}
      {destination != null && <RoutePointDetail routePoint={destination} />}
    </Stack>
  );
}
