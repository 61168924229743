import { AddShipmentTrackingLinkForm } from 'components/ShipmentTrackingLink/AddShipmentTrackingLinkForm';
import { useCarrierShipmentPageContext } from 'hooks';
import { ShipmentTrackingLinkDisplay } from './components';

interface Props {
  dividerComponent: JSX.Element;
}

export function ShipmentTrackingLinkSection(props: Props) {
  const { dividerComponent } = props;
  const { shipment } = useCarrierShipmentPageContext();

  // scenarios where no component is rendered.
  if (!shipment || shipment?.info?.booked_at == null || !shipment.booked_by_carrier
    || shipment.info.type === 'MULTI_SHIPMENT_PROJECT') {
    return <div />;
  }

  if (shipment.tracking_link_info.tracking_link !== null) {
    return (
      <ShipmentTrackingLinkDisplay
        dividerComponent={dividerComponent}
        trackingLink={shipment.tracking_link_info.tracking_link}
        showWarningOnCopy={false}
      />
    );
  }
  return (
    <AddShipmentTrackingLinkForm
      dividerComponent={dividerComponent}
      trackingLinkInfo={shipment.tracking_link_info}
    />
  );
}
