import TextField from '@mui/material/TextField';

interface Props {
  onValueChanged: (newValue: string) => void;
  value: string;
}

export function FreetextCommodityInput(props: Props) {
  const { value, onValueChanged } = props;
  return (
    <TextField
      id="freetext-commodity"
      label="Commodity"
      variant="outlined"
      value={value}
      sx={{ width: 300 }}
      required
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onValueChanged(event.target.value);
      }}
    />
  );
}
