import {
  Chip,
} from '@mui/material';

interface Props {
  quote: ShipperCondensedQuote
}

export function PartnerQuoteChip(props: Props) {
  const { quote } = props;

  if (!quote.is_partner_quote) {
    return <div />;
  }

  return (
    <Chip variant="outlined" label="PARTNER" color="primary" size="small" />
  );
}
