import {
  Button, CircularProgress, Stack, Typography,
} from '@mui/material';
import { useAPI, useShipmentQuoteCount } from 'hooks';
import { useEffect, useState } from 'react';
import { MINIMUM_QUOTES_ON_SHIPMENT_TO_DISPLAY_BOOKED_PRICE } from '../../../../../../../../constants';

interface Props {
  shipment: CarrierCondensedShipment
}

export function RevealPriceButton(props: Props) {
  const { shipment } = props;
  const { api } = useAPI();
  const [error, setError] = useState<null | string>(null);
  const [loadingPrice, setIsLoadingPrice] = useState<boolean>(false);
  const [bookedPrice, setBookdePrice] = useState<string | null>(null);
  const [
    shipmentQuoteCountEligible,
    setShipmentQuoteCountEligible,
  ] = useState<boolean | null>(null);

  const { quoteCount } = useShipmentQuoteCount(shipment?.info?.id);

  const getShipmentPrice = () => {
    setIsLoadingPrice(true);
  };

  useEffect(() => {
    if (quoteCount != null) {
      setShipmentQuoteCountEligible(
        quoteCount >= MINIMUM_QUOTES_ON_SHIPMENT_TO_DISPLAY_BOOKED_PRICE,
      );
    }
  }, [setShipmentQuoteCountEligible, quoteCount]);

  useEffect(() => {
    if (loadingPrice && shipmentQuoteCountEligible) {
      api<BookedShipmentPrice>('get', `/shipments/${shipment.info.id}/booked-price`).then((response) => {
        setBookdePrice(`$${response.data.price} ${response.data.currency.code}`);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch price');
      }).finally(() => {
        setIsLoadingPrice(false);
      });
    }
  }, [loadingPrice, shipment, api, shipmentQuoteCountEligible]);

  if (shipmentQuoteCountEligible == null) {
    return <div />;
  } if (shipmentQuoteCountEligible === false) {
    return (
      <Typography>
        Booked rate information is only shown on shipments with 4 or more quotes.
      </Typography>
    );
  }

  return (
    <Stack direction="column" spacing={1}>
      { !loadingPrice ? (
        <Button variant="contained" onClick={getShipmentPrice}>
          Show booked price
        </Button>
      )
        : (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      {bookedPrice != null && (
        <Typography variant="h4">{bookedPrice}</Typography>
      )}
      {error != null && (
        <Typography color="red" align="center">{error}</Typography>
      )}
    </Stack>

  );
}
