import ErrorIcon from '@mui/icons-material/Error';
import {
  Box, Button, Container, Stack, Typography,
} from '@mui/material';

interface Props {
  onResendVerficationCode: VoidFunction;
  error: string | null;
}

export function VerifyFailed(props: Props) {
  const { error, onResendVerficationCode } = props;
  return (
    <Container maxWidth="lg">
      <Stack spacing={4}>
        <Stack direction="row" alignItems="center">
          <ErrorIcon color="error" />
          <Box component="h3" ml="0.5rem">
            There was an error verifying your account
          </Box>
        </Stack>
        <Button variant="contained" onClick={onResendVerficationCode}>
          Resend Code
        </Button>
        {error != null && <Typography color="red" align="center">{error}</Typography>}
      </Stack>
    </Container>
  );
}
