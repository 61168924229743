import { DEFAULT_EQUIPMENT_TYPE_OPTIONS } from '../../constants';
import { Select } from '../Select';
import { ALL_OPTION } from './constants';

interface Props {
  onChange: (value: string) => void;
  showAllOption?: boolean;
  defaultValue?: string;
  value?: string;
  customOptions?: EquipmentOption[];
}

export function EquipmentTypeSelect(props: Props) {
  const {
    onChange, showAllOption, defaultValue, value, customOptions,
  } = props;

  let options = DEFAULT_EQUIPMENT_TYPE_OPTIONS;
  if (customOptions) {
    options = customOptions;
  }

  return (
    <Select<string>
      inputLabel="Equipment Type"
      defaultValue={defaultValue || ''}
      value={value}
      onChange={onChange}
      options={showAllOption ? ALL_OPTION.concat(options) : options}
    />
  );
}

EquipmentTypeSelect.defaultProps = {
  showAllOption: false,
  defaultValue: '',
  value: null,
  customOptions: null,
};
