import {
  createContext, useEffect, useMemo, useReducer,
} from 'react';

interface ShipperShipmentPageContextProps {
  children: React.ReactNode;
  shipment: ShipperCondensedShipment | undefined;
  onRefresh: VoidFunction;
}

interface ShipperShipmentPageContextState {
  onRefresh: VoidFunction | null;
  shipment: ShipperCondensedShipment | undefined;
}

interface PopulateOnRefreshAction {
  type: 'populateOnRefresh',
  onRefresh: VoidFunction;
}

interface PopulateShipmentAction {
  type: 'populateShipment',
  shipment: ShipperCondensedShipment
}

type ShipperShipmentContextActions = PopulateOnRefreshAction | PopulateShipmentAction;

interface ShipperShipmentContextDispatch {
  dispatch: React.Dispatch<ShipperShipmentContextActions>
}

const INITIAL_STATE: ShipperShipmentPageContextState = {
  onRefresh: null,
  shipment: undefined,
};

export const shipperShipmentPageContext = createContext<
ShipperShipmentPageContextState & ShipperShipmentContextDispatch>(
  {
    ...INITIAL_STATE,
    dispatch: () => null,
  },
);

function reducer(
  state: ShipperShipmentPageContextState,
  action: ShipperShipmentContextActions,
): ShipperShipmentPageContextState {
  switch (action.type) {
    case 'populateOnRefresh':
      return {
        ...state,
        onRefresh: action.onRefresh,
      };
    case 'populateShipment':
      return {
        ...state,
        shipment: action.shipment,
      };
    default:
      return state;
  }
}

export function ShipperShipmentPageProvider(props: ShipperShipmentPageContextProps) {
  const { children, shipment, onRefresh } = props;

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const memoState = useMemo(() => ({ ...state, dispatch }), [state]);

  // runs on context being created and shipment is not initialized
  useEffect(() => {
    if (state.shipment === undefined && shipment) {
      dispatch({ type: 'populateShipment', shipment });
    }
  }, [shipment, dispatch, state.shipment]);

  // runs when the shipment on props is different from the state: updates the context state
  useEffect(() => {
    if (state.shipment && shipment && state.shipment.version !== shipment.version) {
      dispatch({ type: 'populateShipment', shipment });
    }
  }, [shipment, dispatch, state.shipment]);

  // on context startup
  useEffect(() => {
    if (state.onRefresh === null) {
      dispatch({ type: 'populateOnRefresh', onRefresh });
    }
  }, [state.onRefresh, onRefresh]);

  // onRefresh changing, update the context state
  useEffect(() => {
    if (state.onRefresh !== onRefresh) {
      dispatch({ type: 'populateOnRefresh', onRefresh });
    }
  }, [state.onRefresh, onRefresh]);

  return (
    <shipperShipmentPageContext.Provider value={memoState}>
      {children}
    </shipperShipmentPageContext.Provider>
  );
}
