import { useSubscriptionStatus } from 'hooks';
import { BannerStack } from '../BannerStack';
import { CompanyProfileBanner } from '../CompanyProfileBanner';
import { LimitedPostingsShipperRemainingPostingsText } from '../LimitedPostingsShipperRemainingPostingsText';

interface Props {
  showNavBar: boolean;
  navBar: React.ReactNode;
}

export function PaidShipperBanner(props: Props) {
  const { showNavBar, navBar } = props;
  const { subscription } = useSubscriptionStatus();
  return (
    <BannerStack
      borderRadius={3}
      showNavBar={showNavBar}
      navBar={navBar}
    >
      <CompanyProfileBanner />
      {(subscription?.subscription_plan === 'SHIPPER_SHIP_A'
    || subscription?.subscription_plan === 'SHIPPER_SHIP_B'
    || subscription?.subscription_plan === 'SHIPPER_OPTIMIZE_A'
    || subscription?.subscription_plan === 'SHIPPER_OPTIMIZE_B') && (
    <LimitedPostingsShipperRemainingPostingsText />
      )}
    </BannerStack>
  );
}
