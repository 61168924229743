import {
  Alert,
  Box,
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { MultiShipmentProjectAndQuoteOverviewDisplay } from 'components/MultiShipmentProjectAndQuoteOverviewDisplay';
import { useShipmentPageContext, useWriterComponentState } from 'hooks';
import { useEffect, useState } from 'react';
import { isInvalidNumber } from 'utils';

interface Props {
  quote: CondensedQuote;
  onClose: VoidFunction;
}

export function AssignMultiShipmentProjectLoadsInteraction(props: Props) {
  const { quote, onClose } = props;
  const [assignmentAmount, setAssignmentAmount] = useState<string>('');
  const { onRefresh } = useShipmentPageContext();
  const { shipment } = useShipmentPageContext();
  const [inputError, setInputError] = useState<UIError>(null);
  const {
    loading,
    error: submitError,
    write,
    resetState,
  } = useWriterComponentState({
    errorMessage: 'Failed to book shipments. Please contact us if the issue persists.',
    errorMessageKey: 'message',
    writeConfig: {
      method: 'post',
      url: `/quotes/${quote.quote}/accept`,
      body: { loads_assigned: assignmentAmount },
    },
  });

  let loadsAssignable: number | null = null;
  if (shipment?.project) {
    loadsAssignable = shipment.project.loads_available - shipment.project.loads_booked;
  }

  const onSubmitLoadAssignment = () => {
    if (quote.min_loads_desired == null || quote.max_loads_desired == null) {
      setInputError('Quote is missing min or max loads desired');
      return;
    }

    if (isInvalidNumber(assignmentAmount)) {
      setInputError('Please enter a valid number of loads to assign');
      return;
    }

    if (parseInt(assignmentAmount, 10) < quote.min_loads_desired
    || parseInt(assignmentAmount, 10) > quote.max_loads_desired) {
      setInputError(`Please enter a number between or equal to ${quote.min_loads_desired} and ${quote.max_loads_desired}`);
      return;
    }

    if (parseInt(assignmentAmount, 10) < 1) {
      setInputError('Please enter a number greater than 0');
      return;
    }

    if (loadsAssignable != null && parseInt(assignmentAmount, 10) > loadsAssignable) {
      setInputError(`This project only has up to ${loadsAssignable} assignable loads.`);
      return;
    }

    write(() => {
      if (onRefresh) {
        onRefresh();
      }
      onClose();
      resetState();
    });
  };

  useEffect(() => {
    if (!isInvalidNumber(assignmentAmount)) {
      setInputError(null);
    }
  }, [assignmentAmount, setInputError]);

  if (!shipment
    || !quote
    || !quote.price
    || quote.min_loads_desired == null
    || quote.max_loads_desired == null
  ) {
    return <div />;
  }

  return (
    <Dialog open fullWidth onClose={onClose}>
      <DialogTitle>
        <Typography variant="h4">Assign Shipments</Typography>
      </DialogTitle>
      <Alert severity="info">
        <Typography variant="h6" sx={{ ml: '1.5rem' }}>
          {'Select how many shipments you would like to award to '}
          <b>{quote?.created_by_company?.name ? quote.created_by_company.name : '-'}</b>
        </Typography>
      </Alert>
      <DialogContent>
        {loading
          ? <Stack alignItems="center"><CircularProgress /></Stack>
          : (
            <Stack mt="1rem" spacing={1}>
              <Box mt="1rem">
                <MultiShipmentProjectAndQuoteOverviewDisplay
                  quote={quote}
                  shipment={shipment}
                />
              </Box>
              <Typography variant="h6">Assign Loads:</Typography>
              <TextField
                label="Shipment #"
                fullWidth
                value={assignmentAmount}
                onChange={(e) => setAssignmentAmount(e.target.value)}
              />
              <Typography variant="h5">
                <u>
                  <b>
                    {'Total: '}
                    {
                  isInvalidNumber(assignmentAmount) ? '' : `$${parseInt(assignmentAmount, 10) * quote.price} ${quote.currency}`
                }
                    {isInvalidNumber(assignmentAmount) ? '' : ` (${assignmentAmount} shipments x $${quote.price})`}
                  </b>
                </u>
              </Typography>
              <ErrorTypography error={submitError} />
              <ErrorTypography error={inputError} />
            </Stack>
          )}

      </DialogContent>
      {!loading && (
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="info"
          disabled={loading || assignmentAmount === ''}
          onClick={() => onSubmitLoadAssignment()}
        >
          {`Assign ${isInvalidNumber(assignmentAmount) ? '' : assignmentAmount} shipments`}
        </Button>
      </DialogActions>
      )}
    </Dialog>
  );
}
