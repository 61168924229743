import {
  Button, Chip, TableCell, TableRow, Typography,
} from '@mui/material';

interface Props {
  carrier: FollowingCarrier,
  onUnfollowCarrierClicked: (carrier: FollowingCarrier) => void,

}

export function ShipperNetworkTableRow(props: Props) {
  const { carrier, onUnfollowCarrierClicked } = props;

  return (
    <TableRow
      key={carrier?.id}
    >
      <TableCell>
        <Typography variant="body1">
          {carrier?.user?.first_name}
          {' '}
          {carrier?.user?.last_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1">
          <a href={`/companies/${carrier?.company?.id}/profile`} rel="noopener noreferrer">{carrier?.company?.name || ''}</a>
        </Typography>
      </TableCell>
      <TableCell>
        <Chip color="success" label="Approved" />
      </TableCell>
      <TableCell>
        <Button variant="outlined" onClick={() => onUnfollowCarrierClicked(carrier)}>
          Remove connection
        </Button>
      </TableCell>
    </TableRow>
  );
}
