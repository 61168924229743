import {
  Box, Checkbox, CircularProgress, Stack,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CreateAccountAndCompanyFormState } from '../types';

interface Props {
  handleCreateAccountFormValueChange: (key: keyof CreateAccountAndCompanyFormState,
    value: string) => void;
  onSubmit: VoidFunction;
  error: string | null;
  loading: boolean;
  createAccountFormState: CreateAccountAndCompanyFormState;
  shipmentPosted: boolean;
  setIsNotBroker: (value: boolean) => void;
}

export function CreateAccountModal(props: Props) {
  const {
    handleCreateAccountFormValueChange,
    onSubmit,
    error,
    loading,
    createAccountFormState,
    shipmentPosted,
    setIsNotBroker,
  } = props;

  const submitEnabled = createAccountFormState.email !== ''
    && createAccountFormState.firstName !== '' && createAccountFormState.lastName !== ''
    && createAccountFormState.companyName && createAccountFormState.isNotBroker;

  const getModalTitle = () => {
    if (shipmentPosted) {
      return 'Success!';
    }
    return 'One last thing...';
  };

  const getModalHeader = () => {
    if (shipmentPosted) {
      return null;
    }
    return 'We need a couple more details to activate your free account.';
  };

  const getModalBody = () => {
    if (shipmentPosted) {
      return (
        <Stack direction="column" spacing={2}>
          <Typography variant="h5">
            Carriers are being notified about your shipment.  You will receive quotes shortly.

          </Typography>
          <Typography variant="h5">
            Please check your email for a link to verify/activate your free Evotrux account.
          </Typography>
          <Typography variant="h5">
            If you need any assistance please contact us at
            {' '}
            <a href="mailto:support@evotrux.com">support@evotrux.com</a>
            .
          </Typography>
          <Typography>You can close this browser tab.</Typography>
        </Stack>
      );
    }
    return (
      <Stack direction="column" spacing={4}>
        <Typography variant="h4">{getModalHeader()}</Typography>
        <Stack direction="column" spacing={2}>
          <TextField
            label="Email"
            onChange={(event) => handleCreateAccountFormValueChange('email', event.target.value)}
            value={createAccountFormState.email}
          />
          <TextField
            label="First Name"
            onChange={(event) => handleCreateAccountFormValueChange('firstName', event.target.value)}
            value={createAccountFormState.firstName}
          />
          <TextField
            label="Last Name"
            onChange={(event) => handleCreateAccountFormValueChange('lastName', event.target.value)}
            value={createAccountFormState.lastName}
          />
          <TextField
            label="Company Name"
            onChange={(event) => handleCreateAccountFormValueChange('companyName', event.target.value)}
            value={createAccountFormState.companyName}
          />
          <Stack direction="row" alignItems="center">
            <Checkbox
              required
              checked={createAccountFormState.isNotBroker}
              onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => setIsNotBroker(event.target.checked)
  }
            />
            <Typography variant="h6" color="black">I am not a freight broker</Typography>

          </Stack>
          <Typography color="black">
            Freight brokers click
            {' '}
            <a href="/sign-up">here</a>
            {' '}
            to register as a carrier.
          </Typography>
          {error != null && <Typography color="red" align="center">{error}</Typography>}
        </Stack>
        <Box>
          {loading ? (
            <Stack>
              <CircularProgress />
            </Stack>
          ) : (
            <Stack justifyContent="center">
              <Button disabled={!submitEnabled} size="large" variant="contained" onClick={onSubmit} autoFocus>
                Post Shipment + Create Free Account
              </Button>
            </Stack>
          )}
        </Box>
      </Stack>
    );
  };

  return (
    <Dialog
      open
      onClose={() => {}}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h3">{getModalTitle()}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {getModalBody()}
        </DialogContentText>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
}
