import { Stack } from '@mui/material';
import { OpenDrawerMenuButton } from 'components/Carrier/components/OpenDrawerMenuButton';
import { DrawerMenu } from 'components/DrawerMenu';
import { useDisclosure } from 'hooks';

interface Props {
  chatSidebar: React.ReactNode;
  chatConversation: React.ReactNode;
}

export function MobileChatPage(props: Props) {
  const { chatSidebar, chatConversation } = props;
  const { isOpen, onClose, onOpen } = useDisclosure(false);
  return (

    <Stack direction="column">
      <OpenDrawerMenuButton onOpen={onOpen} buttonLabel="Chats" />
      <DrawerMenu isOpen={isOpen} onClose={onClose}>
        {chatSidebar}
      </DrawerMenu>
      {chatConversation}
    </Stack>
  );
}
