import { CircularProgress, Stack, Typography } from '@mui/material';
import { ChatConversation } from 'components/ChatConversation';
import { useAuthContext, useShipmentPageContext, useUserChatInfo } from 'hooks';
import { useLocation } from 'react-router-dom';
import { channelExistsInChatInfo } from 'utils';
import { channelShipmentMatchesPathAndContext } from './utils';

interface Props {
  channelName: string;
}

export function StandAloneLiveChat(props: Props) {
  const { channelName } = props;
  const { user, pubnub: pubnubUserInfo } = useAuthContext();
  const { chatInfo } = useUserChatInfo();
  const { shipment } = useShipmentPageContext();

  const location = useLocation();

  if (pubnubUserInfo == null
    || user == null
    || chatInfo == null
    || shipment === undefined
    || !location) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  /* failsafe for ensuring the shipment id from the path, matches the shipment id
  from the context and matches shipment id from the channel passed
  */
  if (!channelShipmentMatchesPathAndContext(shipment, channelName, location.pathname)) {
    return (
      <div />
    );
  }

  if (!channelExistsInChatInfo(chatInfo, channelName)) {
    <Stack alignItems="center">
      <Typography variant="h4" textAlign="center">
        Chat
        {channelName}
        {' '}
        not found
      </Typography>
    </Stack>;
  }

  return (
    <ChatConversation
      currentChannel={channelName}
      channelGroup={chatInfo?.user_channel_group}
      userPubnubData={pubnubUserInfo}
      useDesktopLayout={false}
      isStandaloneChat
    />
  );
}
