import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { rebuildEmailAlias } from 'utils';
import { useAuthContext, usePasswordFieldToggle } from '../../../../hooks';
import { Login } from './Login';
import { LoginData } from './types';

export function LoginContainer() {
  const { login, setLoginError, loginErrorMsg } = useAuthContext();
  const [searchParams] = useSearchParams();
  const { passwordIsVisible, handleShowPasswordChange } = usePasswordFieldToggle();

  let email;
  if (searchParams.get('email') != null) {
    email = rebuildEmailAlias(searchParams.get('email'));
  }

  const [form, setForm] = useState<LoginData>({
    email: email ?? '',
    password: '',
  });
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const handleFormChange = (key: keyof LoginData, value: string) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleLogin = () => {
    const { email: username, password } = form;
    if (form.email.length === 0 || form.password.length === 0) {
      setLoginError('Please enter your email and password');
    } else {
      setSubmitLoading(true);
      login(username, password).finally(() => {
        setSubmitLoading(false);
      });
    }
  };

  const clearError = useCallback(() => {
    setLoginError('');
  }, [setLoginError]);

  return (
    <Login
      onLogin={handleLogin}
      onFormChange={handleFormChange}
      onShowPasswordChange={handleShowPasswordChange}
      showPassword={passwordIsVisible}
      loginError={loginErrorMsg}
      submitLoading={submitLoading}
      email={form.email}
      password={form.password}
      clearError={clearError}
    />
  );
}
