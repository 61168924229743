import { Typography } from '@mui/material';

interface Props {
  error: UIError
}

export function ErrorTypography(props: Props) {
  const { error } = props;
  return (
    <div>
      {error != null && <Typography color="red" align="center">{error}</Typography>}
    </div>
  );
}
