import { useAPI, useAuthContext, useInterval } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { notificationIsSupported } from '../NotificationCard/content';
import { NotificationModal } from './NotificationModal';
import { NotificationUpdateParams, NotificationsResponse, UpdateNotificationsBody } from './types';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  onUnreadNotificationAmountChanged: (amount: number) => void;
}

export function NotificationModalContainer(props: Props) {
  const {
    open,
    onClose,
    onUnreadNotificationAmountChanged,
  } = props;
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [error, setError] = useState<null | string>(null);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { api } = useAPI();
  const { user } = useAuthContext();

  const notificationIsUnread = (notification: Notification) => (notification.read_at == null
    && notification.deleted_at == null && notificationIsSupported(notification.type));

  const getUnreadNotificationAmount = useCallback((
    fetchedNotifications: Notification[],
  ) => fetchedNotifications.filter(
    (notification) => notificationIsUnread(notification),
  ).length, []);

  const fetchNotifications = useCallback(() => {
    if (user) {
      api<NotificationsResponse>('get', '/notifications').then((response) => {
        setNotifications(response.data.results);
        setError(null);
        onUnreadNotificationAmountChanged(
          getUnreadNotificationAmount(response.data.results),
        );
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch notifications.');
      });
      setIsLoading(false);
    }
  }, [api, user, getUnreadNotificationAmount, onUnreadNotificationAmountChanged]);

  useInterval(fetchNotifications, 30000);

  useEffect(() => {
    if (loading) {
      fetchNotifications();
    }
  }, [loading, api, fetchNotifications]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
    }
  }, [open]);

  const buildUpdateParamsObject = (
    notification: Notification,
    read: boolean,
    delete_notification: boolean,
  ): NotificationUpdateParams => ({
    id: notification.id,
    read,
    delete: delete_notification,
  });

  const getRequestBody = (paramsList:NotificationUpdateParams[]): UpdateNotificationsBody => ({
    notifications: paramsList,
  });

  const postNotificationUpdates = (
    requestBody: UpdateNotificationsBody,
    fallbackErrorMsg: string,
  ): void => {
    setIsUpdating(true);
    api<unknown>('patch', '/notifications/update_many', requestBody).then(() => {
      setError(null);
      setIsLoading(true); // re-fetch notifications after doing an update
    }).catch((e) => {
      setError(e?.response?.data?.message || fallbackErrorMsg);
    }).finally(() => setIsUpdating(false));
  };

  const markAllAsRead = (): void => {
    const updates: NotificationUpdateParams[] = [];
    notifications.forEach((n) => {
      updates.push(buildUpdateParamsObject(n, true, false));
    });
    const requestBody = getRequestBody(updates);
    postNotificationUpdates(requestBody, 'Failed to mark notifications as read.');
  };

  const deleteAll = (): void => {
    const updates: NotificationUpdateParams[] = [];
    notifications.forEach((n) => {
      updates.push(buildUpdateParamsObject(n, true, true));
    });
    const requestBody = getRequestBody(updates);
    postNotificationUpdates(requestBody, 'Failed to delete notifications.');
  };

  const markNotifictionAsRead = (notification: Notification): void => {
    const requestBody = getRequestBody([buildUpdateParamsObject(notification, true, false)]);
    postNotificationUpdates(requestBody, 'Failed to mark notification as read');
  };

  const markNotifictionAsDeleted = (notification: Notification): void => {
    const requestBody = getRequestBody([buildUpdateParamsObject(notification, false, true)]);
    postNotificationUpdates(requestBody, 'Failed to mark notification as deleted');
  };

  return (
    <NotificationModal
      open={open}
      onClose={onClose}
      notifications={notifications}
      loading={loading}
      error={error}
      onMarkAllAsRead={markAllAsRead}
      onDeleteAll={deleteAll}
      onMarkNotifictionAsRead={markNotifictionAsRead}
      onMarkNotifictionAsDeleted={markNotifictionAsDeleted}
      isUpdating={isUpdating}
    />
  );
}
