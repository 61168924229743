import { Typography } from '@mui/material';

interface Props {
  shipment: CondensedShipment | undefined;
  variant: 'h4' | 'h5' | 'h6';
}

export function AvailableLoads(props: Props) {
  const { shipment, variant } = props;

  if (!shipment || shipment?.info.type !== 'MULTI_SHIPMENT_PROJECT' || !shipment.project) {
    return <div />;
  }

  const availableLoads = shipment.project.loads_available;
  const booked = shipment.project.loads_booked;

  return (
    <Typography variant={variant}>{`${booked}/${availableLoads} Shipments Booked`}</Typography>
  );
}
