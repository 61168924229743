import { MessageEnvelope } from '@pubnub/react-chat-components';
import { usePubNubChannelHistory, usePubNubChannelListener } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { MessageHistoryDisplay } from './MessageHistoryDisplay';

interface Props {
  currentChannel: string;
}

export function MessageHistoryDisplayContainer(props: Props) {
  const { currentChannel } = props;

  const [history, setHistory] = useState<MessageEnvelope[]>([]);

  const { messages } = usePubNubChannelHistory({
    channel: currentChannel,
  });

  const onNewMessage = useCallback((message: MessageEnvelope) => {
    if (messages) {
      setHistory((prevMessages) => [...prevMessages, message]);
    } else {
      setHistory([message]);
    }
  }, [setHistory, messages]);

  usePubNubChannelListener({
    channels: [currentChannel],
    onNewChannelMessage: onNewMessage,
    onNewFileMessage: onNewMessage,
  });

  useEffect(() => {
    if (messages) {
      setHistory(messages);
    } else {
      setHistory([]);
    }
    // history overwites messages
  }, [messages]);

  return (
    <MessageHistoryDisplay
      messages={history}
      currentChannel={currentChannel}
    />
  );
}
