import { Stack, Typography } from '@mui/material';
import { Select } from 'components/Select';
import { InputRenderConfig } from '../../../types';

interface Props {
  renderConfig: InputRenderConfig,
  handleBranchIdChange: (branchId: string) => void,
  handleShipperIdChange: (shipperId: string) => void,
  branchId: number | null | undefined;
  shipperRoleId: number | null | undefined;
}

export function BranchAdminInputs(props: Props) {
  const {
    renderConfig,
    handleBranchIdChange,
    handleShipperIdChange,
    branchId,
    shipperRoleId,
  } = props;

  if (renderConfig == null || branchId === undefined || shipperRoleId === undefined) {
    return <div />;
  }
  let selectedBranchShippers;
  if (branchId !== null) {
    const selectedBranch = renderConfig.branches?.find((branch) => branch.branch.id === branchId);
    selectedBranchShippers = selectedBranch?.members.filter((member) => member.type === 'SHIPPER');
  }
  return (
    <Stack direction="column" mt="1rem">
      <Stack direction="row" spacing={2}>
        <Stack direction="column" width="47%">
          <Typography variant="body1" color="text.secondary">Branches</Typography>
          <Select<string>
            inputLabel=""
            defaultValue={branchId != null ? branchId.toString() : ''}
            onChange={handleBranchIdChange}
            value={branchId != null ? branchId.toString() : ''}
            options={renderConfig.branches?.map((branch) => ({
              value: branch.branch.id.toString(),
              label: branch.branch.name,
            })) || []}
          />
        </Stack>
        {branchId != null && selectedBranchShippers && (
        <Stack direction="column" width="47%">
          <Typography variant="body1" color="text.secondary">Users</Typography>
          <Select<string>
            inputLabel=""
            defaultValue={shipperRoleId != null ? shipperRoleId.toString() : ''}
            onChange={handleShipperIdChange}
            value={shipperRoleId != null ? shipperRoleId.toString() : ''}
            options={selectedBranchShippers.map((member) => ({
              value: member.id.toString(),
              label: `${member.user.first_name} ${member.user.last_name}`,
            })) || []}
          />
        </Stack>
        )}
      </Stack>
    </Stack>
  );
}
