import { Stack } from '@mui/material';
import { MessageEnvelope, isFilePayload } from '@pubnub/react-chat-components';
import { useAuthContext } from 'hooks';
import { MessageHeader } from '../MessageHeader';
import { MessageTextDisplay } from '../MessageTextDisplay';
import { TextMessageFileDisplay } from '../TextMessageFileDisplay';

interface Props {
  message: MessageEnvelope;
  userIsAuthor: boolean;
}

export function TextMessage(props: Props) {
  const { message, userIsAuthor } = props;
  const { user } = useAuthContext();

  if (isFilePayload(message.message) || !user) {
    return <div />;
  }

  return (
    <Stack
      direction="column"
    >
      <MessageHeader
        userIsAuthor={userIsAuthor}
        messageAuthor={message?.message?.sender?.name ?? ''}
        messageCreatedAt={message?.message?.createdAt}
      />

      <MessageTextDisplay userIsAuthor={userIsAuthor} message={message} />
      <TextMessageFileDisplay message={message} userIsAuthor={userIsAuthor} />
    </Stack>
  );
}
