import {
  Box, Stack, useMediaQuery,
} from '@mui/material';
import { UnauthenticatedTawkToWrapper } from 'components/UnauthenticatedTawkToWrapper';
import { BG_ICON_COLOR } from '../../../constants';

interface Props {
  authComponent: React.ReactNode;
  switchAuthView: React.ReactNode;
}

export function MainLayout(props: Props) {
  const { authComponent, switchAuthView } = props;

  const isMobileLayout = useMediaQuery('(max-width:700px)');

  return (
    <Stack minHeight="100vh" minWidth="100vw" direction={isMobileLayout ? 'column' : 'row'}>
      <UnauthenticatedTawkToWrapper />
      <Stack sx={!isMobileLayout ? { flexGrow: 1.5 } : null} bgcolor={BG_ICON_COLOR} direction={isMobileLayout ? 'row' : 'column'} alignItems="center" alignContent="center" justifyContent="center">
        <Box>
          <img style={{ height: '200px', width: '200px' }} alt="badge logo" src="/images/e_logo.jpg" />
        </Box>
      </Stack>
      <Box sx={!isMobileLayout ? { flexGrow: 1 } : null}>
        <Stack alignItems="center">
          <Box mt={isMobileLayout ? '2rem' : '4rem'} width="60%">
            {authComponent}
          </Box>
        </Stack>
        {!isMobileLayout && (
          <Box position="absolute" top="2rem" right="2rem">
            {switchAuthView}
          </Box>
        )}
      </Box>
    </Stack>
  );
}
