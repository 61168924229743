import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreateIcon from '@mui/icons-material/Create';
import MailIcon from '@mui/icons-material/Mail';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useDisclosure, useShipperShipmentPageContext } from 'hooks';
import { CancelOrFinalizeShipment } from './components/CancelOrFinalizeShipment';
import { FinalizeProjectModal } from './components/FinalizeProjectModal';

interface Props {
  open: boolean;
  handleDrawerClose: () => void;
  onShareShipmentWithTeammatesOpen: () => void;
  onShipmentCopyOpen: () => void;
  onOpenCancelShipment: () => void;
  onCreateTemplateFromShipmentOpen: () => void;
  onUpdateEmailNotificationSettingsOpen: () => void;
}

export function SideBarMenu(props: Props) {
  const { shipment } = useShipperShipmentPageContext();
  const {
    open,
    handleDrawerClose,
    onShareShipmentWithTeammatesOpen,
    onShipmentCopyOpen,
    onOpenCancelShipment,
    onCreateTemplateFromShipmentOpen,
    onUpdateEmailNotificationSettingsOpen,
  } = props;

  const {
    isOpen: finalizeProjectModalOpen,
    onClose: onCloseFinalizeProjectModal,
    onOpen: onOpenFinalizeProjectModal,
  } = useDisclosure(false);

  if (!shipment || shipment === undefined) {
    return <div />;
  }

  return (
    <>
      <Drawer variant="persistent" anchor="right" open={open}>
        <div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </div>
        <List>
          {shipment && (shipment.access_type === 'OWNER' || shipment.access_type === 'ADMIN') && (
          <ListItem>
            <ListItemButton onClick={onShareShipmentWithTeammatesOpen}>
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary="Share Shipment" />
            </ListItemButton>
          </ListItem>
          )}
          <ListItem>
            <ListItemButton onClick={onShipmentCopyOpen}>
              <ListItemIcon>
                <ContentCopyIcon />
              </ListItemIcon>
              <ListItemText primary="Copy Shipment" />
            </ListItemButton>
          </ListItem>
          <CancelOrFinalizeShipment
            onOpenCancelShipment={onOpenCancelShipment}
            onFinalizeProjectClicked={onOpenFinalizeProjectModal}
          />
          <ListItem>
            <ListItemButton onClick={onCreateTemplateFromShipmentOpen}>
              <ListItemIcon>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="Create Template" />
            </ListItemButton>
          </ListItem>
          {shipment && shipment.access_type === 'OWNER' && (
          <ListItem>
            <ListItemButton onClick={onUpdateEmailNotificationSettingsOpen}>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary="Update Notification Settings" />
            </ListItemButton>
          </ListItem>
          )}
        </List>
      </Drawer>
      <FinalizeProjectModal
        onModalClose={onCloseFinalizeProjectModal}
        modalOpen={finalizeProjectModalOpen}
      />
    </>

  );
}
