import { useAPI, useDisclosure } from 'hooks';
import { useState } from 'react';
import { CreateShipmentBody } from '../CreateShipmentForm/types';
import { CreateShipmentTemplateButton } from './CreateShipmentTemplateButton';

interface Props {
  getShipmentBody: () => CreateShipmentBody;
}

interface CreateShipmentTemplateBody extends CreateShipmentBody {
  template_name?: string;
}

export function CreateShipmentTemplateButtonContainer(props: Props) {
  const { getShipmentBody } = props;
  const { isOpen, onClose, onOpen } = useDisclosure(false);
  const [templateName, setTemplateName] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { api } = useAPI();

  const getTemplateBody = (): CreateShipmentTemplateBody => {
    const body = getShipmentBody();
    /*
    Create shipment form has start/end route points pre-defined.
    if the point location is not defined, it means that no
    point was actually edited by the user (and therefore there is no need to save
    it along with the rest of the template)
    */
    if (body?.route && body.route.length === 2
      && body.route[0]?.point_location === undefined
      && body.route[1]?.point_location === undefined) {
      body.route = [];
    }
    const templateBody: CreateShipmentTemplateBody = { ...body, template_name: templateName };
    return templateBody;
  };

  const onSubmit = () => {
    const body = getTemplateBody();
    setSuccess(false);
    setIsLoading(true);
    api('post', '/shipment-templates', body).then(() => {
      setSuccess(true);
      setError(null);
      setTemplateName('');
      onClose();
    }).catch((e) => {
      setError(e?.response?.data?.message || 'Failed to shipment template. Please contact us.');
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const onModalClose = () => {
    if (success) {
      // if a template was posted, reset the form
      setTemplateName('');
    }
    setSuccess(false);
    setError(null);
    onClose();
  };

  return (
    <CreateShipmentTemplateButton
      loading={loading}
      modalOpen={isOpen}
      onModalClose={onModalClose}
      onModalOpen={onOpen}
      onSubmit={onSubmit}
      templateName={templateName}
      setTemplateName={(newValue: string) => { setTemplateName(newValue); }}
      error={error}
      success={success}
    />
  );
}
