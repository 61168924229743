import { Stack } from '@mui/material';
import { AvailableLoads } from 'components/AvailableLoads';
import { MultiShipmentProjectBookingsTable } from 'components/Carrier/components/MultiShipmentProjectBookingsTable';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useMultiShipmentProjectBookingsAsCarrier, useShipmentPageContext } from 'hooks';
import { useEffect, useRef } from 'react';

interface Props {
  dividerComponent: JSX.Element;
  shipmentId: number | undefined;
}
export function MultiShipmentProjectBookingsSection(props: Props) {
  const { dividerComponent, shipmentId } = props;
  const { shipment } = useShipmentPageContext();
  const shipmentRef = useRef<undefined | CondensedShipment>();
  const {
    projectBookings,
    refetchBookings,
    loading,
    error,
  } = useMultiShipmentProjectBookingsAsCarrier(shipmentId);

  /*
  Runs when the shipment version changes (when the shipment is refreshed)
  If the shipment version has changed
  changed between renders redetermine if the submit quote menu must be shown.
  */
  useEffect(() => {
    if (shipment && shipmentRef.current?.version !== shipment.version) {
      shipmentRef.current = shipment;
      refetchBookings();
    }
  }, [shipment, refetchBookings]);

  if (!shipment || shipment?.project === undefined || shipment.project == null || shipment.info.type !== 'MULTI_SHIPMENT_PROJECT') {
    return <div />;
  }

  return (
    <Stack direction="column" mb="1rem">
      {dividerComponent}
      <ErrorTypography error={error} />
      <LoadingWrappedNode loading={loading}>
        <Stack direction="column" spacing={2}>
          <AvailableLoads shipment={shipment} variant="h6" />
          <MultiShipmentProjectBookingsTable bookings={projectBookings} />
        </Stack>
      </LoadingWrappedNode>
    </Stack>
  );
}
