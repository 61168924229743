import { useEffect, useState } from 'react';
import { useAPI } from './useAPI';
import { useRoleContext } from './useRoleContext';

export function useShipmentQuotes(shipmentId: number | undefined) {
  const [quotes, setQuotes] = useState<CondensedQuote[]>([]);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | string>(null);
  const { role } = useRoleContext();
  const { api } = useAPI();

  useEffect(() => {
    if (loading && shipmentId !== 0 && shipmentId !== undefined) {
      api<CondensedQuote[]>('get', `/shipments/${shipmentId}/quotes/condensed?role=${role != null ? role.type : 'null'}`).then((response) => {
        setQuotes(response.data);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch quotes');
      }).finally(() => { setIsLoading(false); });
    }
  }, [loading, shipmentId, api, role]);

  const refetchQuotes = () => {
    setIsLoading(true);
  };
  return {
    quotes,
    isLoading: loading,
    error,
    refetchQuotes,
  };
}
