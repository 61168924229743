import {
  Button, Stack, TextField, Typography,
} from '@mui/material';
import { AddressAutocompleteForm } from '../../../AddressAutocompleteForm';
import { CheckCarrierCompanyDetails } from '../types';

interface CreateCompanyFormLabelProps {
  text: string
}
function CreateCompanyFormLabel(props: CreateCompanyFormLabelProps) {
  const { text } = props;
  return (
    <Typography variant="h6" fontWeight="bold">
      {text}
    </Typography>
  );
}

interface Props {
  companyData: CheckCarrierCompanyDetails;
  handleSearchCompanySelected: () => void;
  handleResetCompanyData: () => void;
  onAddressValueChanged: (key: keyof CompanyAddress, value: string) => void;
  onCompanyValueChanged: (key: keyof Company, value: string) => void;
  onCreate: () => void;
  error: string | null;
  createCompanyDisabled: boolean;
  emailError: string | null;
}

export function CreateCarrierCompanyForm(props: Props) {
  const {
    createCompanyDisabled,
    companyData,
    handleSearchCompanySelected,
    handleResetCompanyData,
    onAddressValueChanged,
    onCompanyValueChanged,
    onCreate,
    error,
    emailError,
  } = props;
  return (
    <Stack spacing={3} direction="column">
      <Typography textAlign="center" variant="h4" fontWeight="bold">
        Create your company profile
      </Typography>
      <CreateCompanyFormLabel text="Company Name" />
      <Typography variant="h6">
        {companyData.name}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Button variant="outlined" onClick={handleResetCompanyData}>Made a mistake? Reset Company Info</Button>
        <Typography variant="body1" fontWeight="bold">or</Typography>
        <Button variant="outlined" onClick={handleSearchCompanySelected}>Back to Company Search</Button>
      </Stack>
      {emailError != null && <Typography color="red" align="center">{emailError}</Typography>}
      <CreateCompanyFormLabel text="Company Description" />
      <TextField
        required={false}
        label="Description"
        onChange={(e) => onCompanyValueChanged('description', e.target.value)}
      />
      <CreateCompanyFormLabel text="Primary/Billing Address" />
      <AddressAutocompleteForm onValueChanged={onAddressValueChanged} useExactAddresses />
      <Button disabled={createCompanyDisabled} variant="contained" onClick={onCreate}>Create Company</Button>
      {error != null && <Typography color="red" align="center">{error}</Typography>}
    </Stack>
  );
}
