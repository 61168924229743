import { Typography } from '@mui/material';
import { ShipperSentRequests } from 'components/Network/ShipperSentRequests';
import { useRoleContext } from 'hooks';
import { PageLayout } from 'layouts';

export function Network() {
  const { role } = useRoleContext();
  if (role == null) {
    return <div />;
  }
  return (
    <PageLayout>
      <Typography variant="h4" fontWeight="600" mb="1rem">My Carrier Network</Typography>
      <Typography variant="subtitle1">
        Simplify your freight management by working with the carriers you already
        {' '}
        use for your business on Evotrux!
      </Typography>
      <Typography variant="subtitle1" mb="1rem">
        Invite them to follow you on Evotrux so they can be notified about
        {' '}
        your shipment opportunities here.
      </Typography>
      <ShipperSentRequests role={role} />
    </PageLayout>
  );
}
