import { useDisclosure, useShipperShipmentPageContext } from 'hooks';

import { UpdateShipmentCustomNameModal } from '../UpdateShipmentCustomNameModal';
import { EditableShipmentDetailTitle } from './EditableShipmentDetailTitle';

interface Props {
  shipment: ShipperCondensedShipment | undefined;
}

export function EditableShipmentDetailTitleContainer(props: Props) {
  const { shipment } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { onRefresh } = useShipperShipmentPageContext();

  const onUpdateSuccess = () => {
    if (onRefresh) {
      onRefresh();
    }
  };
  return (
    <EditableShipmentDetailTitle
      shipment={shipment}
      onOpenTextBox={onOpen}
      textBoxDialog={(
        <UpdateShipmentCustomNameModal
          onModalClose={onClose}
          isOpen={isOpen}
          customName={shipment?.custom_name}
          shipmentId={shipment?.info?.id ?? null}
          onUpdateSuccess={onUpdateSuccess}
        />
        )}
    />
  );
}
