import { Typography } from '@mui/material';

interface Props {
  shipment: CondensedShipment

}

export function ShipmentDetailTitle(props: Props) {
  const { shipment } = props;
  let shipmentNoun = 'Shipment';
  if (shipment?.info?.type === 'MULTI_SHIPMENT_PROJECT') {
    shipmentNoun = 'Project';
  }

  const titleText = shipment.custom_name != null ? `${shipmentNoun} ${shipment?.info.id}  (${shipment.custom_name})` : `${shipmentNoun} ${shipment.info.id}`;

  return (<Typography variant="h3">{titleText}</Typography>);
}
