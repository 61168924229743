import PersonIcon from '@mui/icons-material/Person';
import {
  Box, Divider, Rating, Stack, Typography,
} from '@mui/material';
import { ISOStringToReadableDate } from '../../utils';

interface Props {
  text: string | undefined;
  rating: number;
  author: string;
  createdAt: string;
  authorCompany?: string;
}

export function CompanyReview(props: Props) {
  const {
    text,
    rating,
    author,
    createdAt,
    authorCompany,
  } = props;
  return (
    <Box p=".5rem">
      <Stack direction="row" spacing={1}>
        <PersonIcon fontSize="large" />
        <Stack direction="column">
          <Typography variant="body1">{author}</Typography>
          <Typography variant="body1" color="text.secondary">{authorCompany}</Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Rating readOnly value={rating} />
        <Typography variant="body1" color="text.secondary">
          Submitted
          {' '}
          {ISOStringToReadableDate(createdAt)}
        </Typography>
      </Stack>
      <Box id="review-content">
        <p>{text}</p>
      </Box>
      <Divider />
    </Box>
  );
}

CompanyReview.defaultProps = {
  authorCompany: '',
};
