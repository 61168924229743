import { useState } from 'react';
import { areRouteTimesValid, getPlaceDetailsAsPointLocation } from '../../utils';
import { RoutesForm } from './RoutesForm';
import { DisplayableRoutePoint } from './types';

interface Props {
  onSubmit: (value: DisplayableRoutePoint) => void;
}

export function RoutesFormContainer(props: Props) {
  const { onSubmit } = props;
  const [point, setPoint] = useState<DisplayableRoutePoint>({
    stop_operation: 'PICKUP',
    stop_type: 'MID',
    addressId: '',
    addressReadable: '',
  });
  const [error, setError] = useState<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const handleValueChange = async <T,>(key: keyof DisplayableRoutePoint, value: T) => {
    const additional: Partial<DisplayableRoutePoint> = {};
    if (key === 'addressId') {
      const {
        pointLocation,
        addressReadable,
      } = await getPlaceDetailsAsPointLocation((value as unknown) as string);
      additional.point_location = pointLocation as RoutePointLocation;
      additional.addressReadable = addressReadable;
    }
    setPoint((prev) => ({
      ...prev,
      [key]: value,
      ...additional,
    }));
  };

  const validate = (): boolean => {
    const { start_time: startTime, end_time: endTime, addressId } = point;
    if (addressId === '') {
      setError('Address can not be empty.');
      return false;
    }
    if (areRouteTimesValid(startTime, endTime) != null) {
      setError(areRouteTimesValid(startTime, endTime));
      return false;
    }
    setError(null);
    return true;
  };

  const handleSubmit = () => {
    if (!validate()) {
      return;
    }

    onSubmit(point);
  };

  return (
    <RoutesForm
      values={point}
      onSubmit={handleSubmit}
      onValueChange={handleValueChange}
      error={error}
    />
  );
}
