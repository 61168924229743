import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Autocomplete, Box, Grid, TextField, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PlaceType } from './types';

export interface Props {
  isLoading: boolean;
  hasError: boolean;
  errorText: string;
  placeOptions: readonly PlaceType[];
  autocompleteValue: PlaceType | null;
  onChange: (newValue: PlaceType | null) => void;
  onInputChange: (newInputValue: string) => void;
  useOptionMainText?: boolean;
  inputLabelTitle?: string;
  isRequired?: boolean;
}

export function AddressAutocomplete(props: Props) {
  const {
    isLoading,
    hasError,
    errorText,
    placeOptions,
    autocompleteValue,
    onChange,
    onInputChange,
    useOptionMainText,
    inputLabelTitle,
    isRequired,
  } = props;

  const [noOptionsText, setNoOptionsText] = useState<string>('No options');

  useEffect(() => {
    if (hasError) {
      setNoOptionsText(errorText);
    }
  }, [hasError, errorText]);

  // TODO: Add Google attribution before going live
  // otherwise we violate TOS: https://developers.google.com/maps/documentation/places/web-service/policies
  return (
    <Autocomplete
      id="address-autocomplete"
      getOptionLabel={(option) => (
        useOptionMainText ? option.structured_formatting.main_text : option.description
      )}
      filterOptions={(placeTypes) => placeTypes}
      options={placeOptions}
      autoComplete
      includeInputInList
      filterSelectedOptions
      loading={isLoading}
      noOptionsText={noOptionsText}
      value={autocompleteValue}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        onInputChange(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputLabelTitle}
          required={isRequired}
          fullWidth
          error={hasError}
          helperText={hasError ? errorText : ''}
        />
      )}
      renderOption={(renderOptionProps, option) => (
        <li {...renderOptionProps}>
          <Grid container alignItems="center">
            <Grid item>
              <Box
                component={LocationOnIcon}
                sx={{ color: 'text.secondary', mr: 2 }}
              />
            </Grid>
            <Grid item xs>
              <Typography variant="body1">
                {option.structured_formatting.main_text}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
}

AddressAutocomplete.defaultProps = {
  useOptionMainText: false,
  inputLabelTitle: 'Address',
  isRequired: false,
};
