import { Stack, Typography } from '@mui/material';
import { ISOStringToLocalReadableTimestamp } from 'utils';

interface Props {
  userIsAuthor: boolean;
  messageAuthor: string;
  messageCreatedAt: string | undefined;
}
export function MessageHeader(props: Props) {
  const { userIsAuthor, messageAuthor, messageCreatedAt } = props;

  const timestampNode = (
    <Typography variant="body2" color="text.secondary">
      {messageCreatedAt && messageCreatedAt !== '' ? ISOStringToLocalReadableTimestamp(messageCreatedAt) : ''}
    </Typography>
  );
  const messageAuthorNode = (
    <Typography variant="h6" color="text.secondary">
      <u>
        {messageAuthor}
      </u>
    </Typography>
  );

  let messageHeader = (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
      {messageAuthorNode}
      {timestampNode}
    </Stack>
  );
  if (userIsAuthor) {
    messageHeader = (
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
        {timestampNode}
        {messageAuthorNode}
      </Stack>
    );
  }
  return messageHeader;
}
