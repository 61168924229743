import { CircularProgress, Stack } from '@mui/material';
import { CompanyBusinessTypeChip } from 'components/CompanyBusinessTypeChip';
import { CompanyReviewMetaLabel } from 'components/CompanyProfilePage/components/CompanyReviewMetaLabel';
import { useCompanyProfile } from 'hooks';

interface Props {
  companyId: number;
  companyBusinessType?: BusinessTypeValue | null;
}

export function CarrierCompanyNameAndReputationLabel(props: Props) {
  const { companyId, companyBusinessType } = props;
  const { companyProfile, loading } = useCompanyProfile(companyId);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <a href={`/companies/${companyId}/profile`} target="_blank" rel="noopener noreferrer">View Carrier Profile</a>
      {
        loading && (
          <CircularProgress />
        )
      }
      {companyProfile && (
      <CompanyReviewMetaLabel
        reviewsMeta={companyProfile.reviews_meta}
        labelType="body1"
        starIconSize="small"
        showShorteneNoReviews
        showNoReviewsText={false}
      />
      )}
      {companyBusinessType && (
      <CompanyBusinessTypeChip
        businessType={companyBusinessType}
      />
      )}
    </Stack>
  );
}

CarrierCompanyNameAndReputationLabel.defaultProps = {
  companyBusinessType: undefined,
};
