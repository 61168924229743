import {
  Box, Button, Divider, Stack, Typography,
} from '@mui/material';
import { ProvinceStateMultiSelect } from 'components/ProvinceStateMultiSelect';
import { ShipmentStatusSelect } from 'components/ShipmentStatusSelect';
import { useState } from 'react';
import { EquipmentTypeMultiSelect } from '../../../EquipmentTypeSelect';
import { ShipmentServiceMultiSelect } from '../../../ShipmentServiceMultiSelect';

export function FakeShipmentList() {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  return (
    <Box>
      <Box>
        <Stack direction="column" spacing={1}>
          <Stack mt=".5rem" direction="row" justifyContent="space-between">
            <Stack direction="row" ml="1rem" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Typography variant="h6">My Shipments</Typography>
              <p>
                (0)
              </p>
            </Stack>

            <Stack justifyContent="end" direction="row" mr="1rem">
              <Button
                variant="outlined"
                onClick={() => { setShowFilters(!showFilters); }}
              >
                {showFilters ? 'Hide filters' : 'More filters'}
              </Button>
            </Stack>
          </Stack>
          {showFilters && (
          <Stack pl="1rem" pr="1rem" mb="1rem" direction="row" spacing={1}>
            <EquipmentTypeMultiSelect onChange={() => {}} />
            <ShipmentServiceMultiSelect label="Services" onChange={() => {}} />
            <ProvinceStateMultiSelect label="Origins" onChange={() => {}} />
            <ProvinceStateMultiSelect label="Desinations" onChange={() => {}} />
            <Box>
              <ShipmentStatusSelect onChange={() => {}} showAllOption />
            </Box>
          </Stack>
          )}
          <Divider />
          <Stack direction="row" pt=".5rem" pb="2rem" justifyContent="center">
            <Typography>
              No shipments yet. Post your first shipment today!
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
