import { ShipmentFiltersProvider } from 'context';
import { Dashboard } from './components/Dashboard';

export function Shipper() {
  return (
    <ShipmentFiltersProvider>
      <Dashboard />
    </ShipmentFiltersProvider>
  );
}
