import {
  useAPI,
  useAuthContext, useCarrierShipmentPageContext, useShipmentQuotes,
} from 'hooks';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { SubmitOrCancelQuote } from './SubmitOrCancelQuote';

interface Props {
  shipment: CondensedShipment | undefined;
}

export function SubmitOrCancelQuoteContainer(props: Props) {
  const { shipment } = props;

  const { api } = useAPI();
  const { user } = useAuthContext();
  const { onRefresh } = useCarrierShipmentPageContext();
  const { quotes, isLoading, refetchQuotes } = useShipmentQuotes(shipment?.info.id ?? 0);
  const [canSubmitQuote, setCanSubmitQuote] = useState<boolean>(false);
  const shipmentRef = useRef<undefined | CondensedShipment>();

  const findCarrierActiveShipment = useCallback((q: CondensedQuote) => q.internal_state === 'ACTIVE' && q.created_by.id === user?.id, [user?.id]);

  useEffect(() => {
    if (shipment && shipmentRef.current?.info?.id !== shipment.info.id) {
      shipmentRef.current = shipment;
      refetchQuotes();
    }
  }, [shipment, refetchQuotes]);

  useEffect(() => {
    if (shipment && shipmentRef.current?.version !== shipment.version) {
      shipmentRef.current = shipment;
      refetchQuotes();
    }
  }, [shipment, refetchQuotes]);

  useEffect(() => {
    const result = !quotes.some((q) => findCarrierActiveShipment(q));
    setCanSubmitQuote(result);
  }, [quotes, setCanSubmitQuote, findCarrierActiveShipment]);

  const cancelLatestActiveQuote = () => {
    const activeQuote = quotes.find((q) => findCarrierActiveShipment(q));
    if (activeQuote) {
      api('post', `/quotes/${activeQuote?.quote}/cancel`).then(() => {
        if (onRefresh != null) {
          onRefresh();
        }
      });
    }
  };

  return (
    <SubmitOrCancelQuote
      isLoading={isLoading}
      shipment={shipment}
      canSubmitQuote={canSubmitQuote}
      onCancel={cancelLatestActiveQuote}
    />
  );
}
