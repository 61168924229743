import { Stack, useMediaQuery } from '@mui/material';
import { OpenDrawerMenuButton } from 'components/Carrier/components/OpenDrawerMenuButton';
import { DrawerMenu } from 'components/DrawerMenu';
import { DashboardBackButton } from 'components/Shipment/components/DashboardBackButton';
import { useDisclosure } from 'hooks';
import { PageLayout } from 'layouts';
import { ShipmentSideBar } from './components/ShipmentSideBar';

interface Props {
  shipmentOverview: React.ReactNode
  shipments: CondensedShipment[];
  shipmentSelectionIsDisabled: boolean;
  handlePageSelected: (e: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  pages: number;
  shipmentCount: number;

}

export function Shipment(props: Props) {
  const {
    shipmentOverview, shipments,
    shipmentSelectionIsDisabled, handlePageSelected, page, pages, shipmentCount,
  } = props;

  const showFullShipmentSideBar = !useMediaQuery('(max-width:1200px)');
  const { isOpen, onOpen, onClose } = useDisclosure(false);

  const shipmentBrowser = (
    <ShipmentSideBar
      shipments={shipments as ShipperCondensedShipment[]}
      shipmentSelectionIsDisabled={shipmentSelectionIsDisabled}
      page={page}
      pages={pages}
      handlePageSelected={handlePageSelected}
      shipmentCount={shipmentCount}
      showDashboardBackButton={showFullShipmentSideBar}
    />
  );

  return (
    <PageLayout>
      {!showFullShipmentSideBar && (
        <Stack direction="column">
          <DashboardBackButton />
          <OpenDrawerMenuButton onOpen={onOpen} buttonLabel="MY SHIPMENTS" />
        </Stack>
      )}
      <Stack direction="row" spacing={showFullShipmentSideBar ? 2 : undefined}>
        {showFullShipmentSideBar && (
          <Stack
            width="25%"
          >
            {shipmentBrowser}
          </Stack>
        )}
        <Stack spacing={showFullShipmentSideBar ? 2 : undefined} flex={1}>
          {!showFullShipmentSideBar && (
          <DrawerMenu
            isOpen={isOpen}
            onClose={onClose}
          >
            {shipmentBrowser}
          </DrawerMenu>
          )}
          {shipmentOverview}
        </Stack>
      </Stack>
    </PageLayout>
  );
}
