import { Box, Divider, Pagination } from '@mui/material';
import { useSubscriptionStatus } from 'hooks/useSubscriptionStatus';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { hasPaidCarrierSubscription } from 'utils';
import { CompactShipmentPreview } from '../ShipmentPreview';

interface Props {
  shipments: CarrierCondensedShipment[];
  shipmentSelectionIsDisabled: boolean;
  handlePageSelected: (e: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  pages: number;
}

export function QuickShipmentsBrowser(props: Props) {
  const {
    shipments, shipmentSelectionIsDisabled, handlePageSelected, page, pages,
  } = props;

  const params = useParams();
  const shipmentId = params.id;
  const { subscription } = useSubscriptionStatus();
  const [isPayingCarrier, setIsPayingCarrier] = useState<boolean>(false);

  const [selected, setSelected] = useState<number | undefined>(shipmentId
    ? +shipmentId : undefined);

  useEffect(() => {
    if (hasPaidCarrierSubscription(subscription) && !isPayingCarrier) {
      setIsPayingCarrier(true);
    }
  }, [subscription, isPayingCarrier]);

  return (
    <Box boxShadow={1}>
      {shipments.map((s, i) => (
        <Box key={s.info.id}>
          {i > 0 && <Divider />}
          <CompactShipmentPreview
            shipment={s}
            selected={s.info.id === selected}
            select={setSelected}
            selectionIsDisabled={shipmentSelectionIsDisabled}
            displayShipperCompanyName={isPayingCarrier}
          />
        </Box>
      ))}
      <Divider />
      <Box my="1rem" display="flex" alignItems="center" justifyContent="center">
        <Pagination
          count={pages}
          onChange={handlePageSelected}
          page={page}
        />
      </Box>
    </Box>
  );
}
