import { LIMITED_BOOKING_CARRIERS_SUBSCRIPTION_PLANS } from '../constants';

export function hasPaidCarrierSubscription(
  subscription: CondensedSubscription | null,
) {
  return (subscription && (subscription?.subscription_plan === 'CARRIER_OWNER_OPERATOR'
  || subscription?.subscription_plan === 'CARRIER_SMALL_BUSINESS'
  || subscription?.subscription_plan === 'CARRIER_ENTERPRISE'
  || subscription?.subscription_plan === 'CARRIER_BRONZE'
  || subscription?.subscription_plan === 'CARRIER_SILVER'
  || subscription?.subscription_plan === 'CARRIER_GOLD'
  || subscription?.subscription_plan === 'CARRIER_PLATINUM'
  || subscription?.subscription_plan === 'CARRIER_ENTERPRISE_2'));
}

export function carrierSubHasLimitedBookings(
  subscription: SubscriptionPlan,
) {
  return LIMITED_BOOKING_CARRIERS_SUBSCRIPTION_PLANS.has(subscription);
}
