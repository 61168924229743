import {
  CircularProgress, Paper, Stack, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { ShipperPendingRequestTableRow } from './ShipperPendingRequestTableRow';

interface Props{
  error: string | null;
  loading: boolean;
  pendingRequests: FollowShipperRequestAndResponse[];
}

export function ShipperPendingRequests(props: Props) {
  const { error, loading, pendingRequests } = props;

  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  if (pendingRequests.length === 0) {
    return (
      <Stack>
        <Typography variant="h6">You have no pending requests.</Typography>
      </Stack>
    );
  }
  return (
    <Stack direction="column" spacing={1}>
      {error != null && <Typography color="red" align="center">{error}</Typography>}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contact</TableCell>
              <TableCell>Sent At</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { pendingRequests.length > 0 ? pendingRequests.map((pr) => (
              <ShipperPendingRequestTableRow
                key={pr.request.id}
                pendingRequest={pr}
              />
            )) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
