import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button, Checkbox, CircularProgress, Divider,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { CommoditySelectInput } from 'components/CommoditySelectInput';
import { EquipmentTypeMultiSelect } from 'components/EquipmentTypeSelect';
import { ServiceTypeSelect } from 'components/ServiceTypeSelect';
import { UpdatePaymenInfoLabel } from 'components/UpdatePaymentInfoLabel';
import { ReactNode } from 'react';
import { NumberInput } from '../../../NumberInput';
import { BookNowMenu } from '../BookNowMenu';
import { EmailNotificationPreferences } from '../EmailNotificationPreferences';
import { ShipmentTypeMenu } from '../ShipmentTypeMenu';
import { AddStopButton } from './components/AddStopButton';
import { AdditionalDetailsTextField } from './components/AdditionalDetailsTextField';
import { FreetextCommodityInput } from './components/FreetextCommodityInput';
import { ShareShipmentMenu } from './components/ShareShipmentMenu';
import { ShipmentNotificationsSelectMenu } from './components/ShipmentNotificationsSelectMenu';
import { SpecialRequirementsTextField } from './components/SpecialRequirementsTextField';
import { CreateShipmentFormState } from './types';

const selectWrapperStyle = { mr: '1rem', mt: '1rem' };
const dividerStyle = { my: 4 };

interface Props {
  routePoints: React.ReactNode[];
  equipmentTypes: string[];
  serviceType: string;
  commodity: Commodity | null;
  weightLb: string;
  specialRequirements: string;
  additionalDetails: string;
  error: string | null;
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  onValueChanged: <T,>(key: keyof CreateShipmentFormState, value: T | null) => void;
  toggleCreateRouteOpen: VoidFunction;
  onSubmit: VoidFunction;
  submitLoading: boolean;
  setShipmentNotificationType: (type: ShipmentNotificationType) => void;
  setShipmentNotificationEmails: (emails: string[]) => void;
  usingCustomCommodity: boolean;
  onUseCustomCommodityChanged: (newValue: boolean) => void;
  commodityFreetext: string;
  shipmentNotificationType: ShipmentNotificationType;
  selectedCarriers: string[];
  showShipmentNotificationSettings?: boolean;
  createTemplateButton: ReactNode;
  showBookNow: boolean;
  bookNowPrice: string;
  bookNowCurrency: string;
  isBookNow: boolean;
  customName: string;
  emailNotificationType: ShipperEmailNotificationType;
  showShareMenu?: boolean;
  setShareeRoleIds: (roleIds: number[]) => void;
  selectedSharees: number[];
  shareShipment: boolean;
  onShareShipmentChange: (newValue: boolean) => void;
  shipmentType: ShipmentType;
  availableLoads: string;
}

export function CreateShipmentForm(props: Props) {
  const {
    error,
    routePoints,
    equipmentTypes,
    serviceType,
    commodity,
    weightLb,
    specialRequirements,
    additionalDetails,
    onSubmit,
    onValueChanged,
    toggleCreateRouteOpen,
    submitLoading,
    setShipmentNotificationType,
    setShipmentNotificationEmails,
    usingCustomCommodity,
    onUseCustomCommodityChanged,
    commodityFreetext,
    shipmentNotificationType,
    selectedCarriers,
    showShipmentNotificationSettings,
    createTemplateButton,
    showBookNow,
    bookNowCurrency,
    bookNowPrice,
    isBookNow,
    customName,
    emailNotificationType,
    showShareMenu,
    setShareeRoleIds,
    selectedSharees,
    shareShipment,
    onShareShipmentChange,
    shipmentType,
    availableLoads,
  } = props;

  const onEquipmentTypeChanged = (newValue: string[]) => {
    onValueChanged<string[]>('equipmentTypes', newValue);
  };
  const onServiceChanged = (newValue: string) => {
    onValueChanged<string>('service', newValue);
    if (newValue === 'TRUCK_LOAD') {
      onValueChanged<string>('footage', '');
    }
  };

  const onCommoditySelected = (newValue: Commodity | null) => {
    onValueChanged<Commodity>('commodity', newValue);
  };

  const onFreetextCommodityChanged = (newValue: string) => {
    onValueChanged<string>('commodityFreetext', newValue);
  };

  const handleCustomCommodityCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUseCustomCommodityChanged(event.target.checked);
  };

  return (
    <Box>
      <Typography variant="h6">Create a Shipment or Project</Typography>
      <ShipmentTypeMenu
        onAvailableLoadsChanged={(val: string) => onValueChanged<string>('availableLoads', val)}
        availableLoads={availableLoads}
        shipmentType={shipmentType}
        onShipmentTypeChanged={(val: ShipmentType) => onValueChanged<ShipmentType>('shipmentType', val)}
      />
      <Divider sx={dividerStyle} />
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h6">
          {shipmentType === 'MULTI_SHIPMENT_PROJECT' ? 'Project' : 'Shipment'}
          {' '}
          Details
        </Typography>
      </Stack>
      <Stack direction="row" flexWrap="wrap" mb={4} alignItems="flex-end">
        <Box sx={selectWrapperStyle}>
          <UpdatePaymenInfoLabel />
          <EquipmentTypeMultiSelect
            onChange={onEquipmentTypeChanged}
            defaultValue={equipmentTypes}
            value={equipmentTypes}
          />
        </Box>
        <Box sx={selectWrapperStyle}>
          <ServiceTypeSelect
            onChange={onServiceChanged}
            defaultValue={serviceType}
            value={serviceType}
          />
        </Box>
        <NumberInput
          inputLabel="Weight"
          defaultValue={weightLb}
          onChange={(val: string) => onValueChanged<string>('weightLb', val)}
          endAdornment={<InputAdornment position="end">lb</InputAdornment>}
        />
        <Stack direction="row" spacing={2} sx={selectWrapperStyle} alignItems="center">

          {usingCustomCommodity
            ? (
              <FreetextCommodityInput
                onValueChanged={onFreetextCommodityChanged}
                value={commodityFreetext}
              />
            )
            : (
              <CommoditySelectInput
                commodityValue={commodity}
                onCommoditySelected={onCommoditySelected}
                textFieldProps={{ required: true, label: 'Commodity' }}
              />
            )}
          <Box ml=".5rem">
            <FormGroup>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={usingCustomCommodity}
                    onChange={handleCustomCommodityCheckboxChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
  )}
                label="Use custom commodity"
              />
            </FormGroup>
          </Box>
        </Stack>
      </Stack>
      <Stack mt="2rem" spacing={4} direction="row">
        <SpecialRequirementsTextField
          value={specialRequirements}
          onChange={(value: string) => onValueChanged<string>('specialRequirements', value)}
        />
        <AdditionalDetailsTextField
          value={additionalDetails}
          onChange={(value: string) => onValueChanged<string>('additionalDetails', value)}
        />
      </Stack>
      <Stack mt="1rem" direction="row">
        <Tooltip followCursor title="Only the carrier who books the load will have access to the reference." placement="right" TransitionComponent={Zoom}>
          <TextField
            label="Shipper Reference"
            value={customName}
            onChange={(event) => onValueChanged<string>('customName', event.target.value)}
            sx={{ width: '45%' }}
          />
        </Tooltip>

      </Stack>
      <Divider sx={dividerStyle} />
      <Box>
        <Box display="flex">
          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">Create Route</Typography>
            </Stack>

            <Typography color="#b6b6b6">*Drag to Reorganize Route Stops</Typography>
          </Box>
          <AddStopButton onClick={toggleCreateRouteOpen} />
        </Box>
        <Stack py="1rem" spacing={1}>
          {routePoints}
        </Stack>

      </Box>
      <Divider sx={dividerStyle} />
      {showShipmentNotificationSettings && (
      <ShipmentNotificationsSelectMenu
        setShipmentNotificationType={setShipmentNotificationType}
        setShipmentNotificationEmails={setShipmentNotificationEmails}
        shipmentNotificationType={shipmentNotificationType}
        selectedCarriers={selectedCarriers}
        isProject={shipmentType === 'MULTI_SHIPMENT_PROJECT'}
      />
      )}
      {
        showBookNow && (
          <BookNowMenu
            currency={bookNowCurrency}
            price={bookNowPrice}
            isBookNow={isBookNow}
            setCurrency={(val: string) => onValueChanged<string>('bookNowCurrency', val)}
            setPrice={(val: string) => onValueChanged<string>('bookNowPrice', val)}
            setIsBookNow={(val: boolean) => onValueChanged<boolean>('isBookNow', val)}
          />
        )
      }
      <Divider sx={dividerStyle} />
      {
        showShareMenu && (
          <ShareShipmentMenu
            setShareeRoleIds={setShareeRoleIds}
            selectedSharees={selectedSharees}
            shareShipment={shareShipment}
            onShareShipmentChange={onShareShipmentChange}
            isProject={shipmentType === 'MULTI_SHIPMENT_PROJECT'}
          />
        )
      }
      <Divider sx={dividerStyle} />
      <Typography variant="h6">Notification Preferences</Typography>
      <Box mb="2rem">
        <EmailNotificationPreferences
          value={emailNotificationType}
          onChange={(newValue:
          ShipperEmailNotificationType) => onValueChanged<ShipperEmailNotificationType>('emailNotificationType', newValue)}
          isProject={shipmentType === 'MULTI_SHIPMENT_PROJECT'}
        />
      </Box>

      {error != null && <Typography sx={{ mt: '1rem', mb: '1rem' }} color="red">{error}</Typography>}
      {submitLoading ? (
        <CircularProgress />
      ) : (
        <Button
          variant="contained"
          size="large"
          onClick={onSubmit}
          sx={{ mr: '.5rem' }}
        >
          <AddIcon />
          {shipmentType === 'MULTI_SHIPMENT_PROJECT' ? 'Post Project' : 'Post Shipment'}
        </Button>
      ) }
      {createTemplateButton}
    </Box>
  );
}

CreateShipmentForm.defaultProps = {
  showShipmentNotificationSettings: true,
  showShareMenu: false,
};
