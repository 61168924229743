import MailIcon from '@mui/icons-material/Mail';
import {
  Box, Chip, Divider, Stack, Typography,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import {
  ChannelEntity,
} from '@pubnub/react-chat-components';
import { useRoleContext, useShipment } from 'hooks';
import React, { useEffect, useState } from 'react';
import { getBookedQuoteForShipperCondensedShipment, getShipmentIdFromShipmentBookedChatName } from 'utils';

export function ChannelRenderer(
  channel: ChannelEntity,
  onChannelSelected: (channelName: string) => void,
  channelsWithNewMessages: Set<string>,
  currentChannel: string,
) {
  const [richDescription, setRichDescription] = useState<React.ReactNode | null>(null);
  const [hasUnreadMessagesOnStartup, setHasUnreadMessagesOnStartup] = useState<boolean>(false);
  const { name, description, id } = channel;
  const { shipment, loading } = useShipment(
    { shipmentId: parseInt(getShipmentIdFromShipmentBookedChatName(id), 10) },
  );
  const { role } = useRoleContext();

  const onChannelClick = () => {
    // if the same channel that is current selected, there is no need to call the parent callback
    if (id !== currentChannel) {
      onChannelSelected(id);
    }
  };

  useEffect(() => {
    if (shipment && role && role.type === 'SHIPPER') {
      /* eslint-disable max-len */
      const bookedQuote = getBookedQuoteForShipperCondensedShipment(shipment as ShipperCondensedShipment);

      setRichDescription(
        <Stack direction="column">
          <Typography>
            <b>Origin: </b>
            {shipment.first_point.point_location.city}
          </Typography>
          <Typography>
            <b> Desination: </b>
            {shipment.last_point.point_location.city}
          </Typography>
          <Typography>
            <b> Carrier: </b>
            {bookedQuote?.created_by_company?.name || ''}
          </Typography>
        </Stack>,
      );
    }
  }, [shipment, loading, role]);

  useEffect(() => {
    if (shipment && role && role.type === 'CARRIER') {
      setRichDescription(
        <Stack direction="column">
          <Typography>
            <b>Origin: </b>
            {shipment.first_point.point_location.city}
          </Typography>
          <Typography>
            <b> Desination: </b>
            {shipment.last_point.point_location.city}
          </Typography>
          <Typography>
            <b> Shipper: </b>
            {shipment?.shipment_company.name || ''}
          </Typography>
        </Stack>,
      );
    }
  }, [shipment, loading, role]);

  const getNewMessageComponent = (): React.ReactNode => (
    <Box sx={{ color: 'action.active' }}>
      <Badge color="secondary" variant="dot">
        <MailIcon fontSize="large" />
      </Badge>
    </Box>
  );

  useEffect(() => {
    if (id === currentChannel) {
      setHasUnreadMessagesOnStartup(false);
    }
  }, [currentChannel, id]);

  const showUnreadMessageRibbon = () => {
    if (id === currentChannel) {
      return false;
    }
    return channelsWithNewMessages.has(id) || hasUnreadMessagesOnStartup;
  };

  return (
    <Box
      key={id}
      onClick={onChannelClick}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
        },
      }}
    >
      <Stack direction="row" m="1rem" justifyContent="space-between" alignItems="center">
        <Stack direction="column">
          <Typography variant="h6">{shipment ? `${name} ${shipment.custom_name ? ` - ${shipment.custom_name}` : ''}` : name}</Typography>
          {richDescription || <Typography variant="body2">{description}</Typography>}
        </Stack>
        {id === currentChannel && <Chip variant="outlined" label="selected" color="primary" size="small" />}
        {showUnreadMessageRibbon() && getNewMessageComponent()}
      </Stack>
      <Divider />

    </Box>
  );
}
