import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { CancelShipmentFormModalContent } from './CancelShipmentFormModalContent';

interface Props {
  open: boolean
  onClose: VoidFunction;
  onCancelShipment: VoidFunction;
  error: string | null;
  loading: boolean;
  cancellationReason: string;
  onCancellationUpdate: (newValue: string) => void;
}

export function CancelShipmentForm(props: Props) {
  const {
    open,
    onClose,
    onCancelShipment,
    error,
    loading,
    cancellationReason,
    onCancellationUpdate,
  } = props;

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h4">
          Cancel Shipment
        </Typography>
      </DialogTitle>
      <DialogContent>
        <CancelShipmentFormModalContent
          cancellationReason={cancellationReason}
          onCancellationUpdate={onCancellationUpdate}
          loading={loading}
          error={error}
        />
      </DialogContent>
      {!loading && (
      <DialogActions>
        <Button autoFocus variant="contained" onClick={onClose}>
          Go Back
        </Button>
        <Button
          onClick={onCancelShipment}
          disabled={cancellationReason === ''}
        >
          {!error ? 'Cancel Shipment' : 'retry'}
        </Button>
      </DialogActions>
      )}
    </Dialog>
  );
}
