import { Stack } from '@mui/material';
import { CreateCompanyReviewModal } from 'components/CreateCompanyReviewModal';
import { useAPI } from 'hooks';
import { useEffect, useState } from 'react';
import { CompanyPublicReviews } from './CompanyPublicReviews';

interface Props {
  companyId: number;
}

export function CompanyPublicReviewsContainer(props: Props) {
  const { companyId } = props;
  const { api } = useAPI();
  const [loading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | string>(null);
  const [reviewProfile, setReviewProfile] = useState<PublicCompanyReviewProfile | null>(null);

  useEffect(() => {
    if (loading) {
      api<PublicCompanyReviewProfile>('get', `/companies/${companyId}/public-reviews`).then((response) => {
        setReviewProfile(response.data);
        setError(null);
      }).catch((e) => {
        if (e?.response?.status === 403 && e?.response?.data?.detail) {
          setError(e.response.data.detail);
        } else {
          setError(e?.response?.data?.message || 'Failed to fetch company reviews');
        }
      });
      setIsLoading(false);
    }
  }, [loading, companyId, api]);

  return (
    <Stack direction="column" spacing={3}>
      <CreateCompanyReviewModal
        sx={{ mt: '1rem' }}
        companyId={companyId}
        onReviewSubmittedRefresh={() => { setIsLoading(true); }}
      />
      <CompanyPublicReviews
        error={error}
        loading={loading}
        reviewProfile={reviewProfile}
      />
    </Stack>
  );
}
