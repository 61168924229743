import { Box, Typography } from '@mui/material';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { EstablishedNetwork } from 'components/Network/EstablishedNetwork';
import { PendingRequests } from 'components/Network/PendingRequests';
import { useRoleContext } from 'hooks';
import { PageLayout } from 'layouts';

export function Network() {
  const { role } = useRoleContext();
  if (role == null) {
    return <div />;
  }
  return (
    <PageLayout>
      <Typography variant="h4" fontWeight="600" mb="1rem">My Shipper Network</Typography>
      <Box mt="2rem">
        <Typography variant="h5">
          Shippers I&apos;m Following
        </Typography>
        <EvotruxDivider />
        <Box mt="1rem">
          <EstablishedNetwork role={role} />
        </Box>
      </Box>
      <Box mt="2rem">
        <Typography variant="h5">
          Follow Requests
        </Typography>
        <EvotruxDivider />
        <Box mt="1rem">
          <PendingRequests role={role} />
        </Box>
      </Box>

    </PageLayout>
  );
}
