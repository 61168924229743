import { ConnectWithoutContact } from '@mui/icons-material';
import {
  Badge, IconButton, Tooltip, Zoom,
} from '@mui/material';
import { useAPI, useRoleContext } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function NetworkButton() {
  const navigate = useNavigate();
  const { api } = useAPI();
  const { role } = useRoleContext();
  const [requests, setRequests] = useState<number>(0);

  useEffect(() => {
    if (role !== null && role.type === 'CARRIER' && role.company) {
      api<FollowShipperRequest[]>('get', `/carriers/${role?.id}/follow_requests/received?status=ACTIVE`).then((response) => {
        setRequests(response.data.length);
      });
    }
  }, [api, role]);

  return (
    <Tooltip title="Network" placement="right" TransitionComponent={Zoom}>
      <Badge badgeContent={requests} color="secondary">
        <IconButton color="inherit" onClick={() => navigate('/network')}>
          <ConnectWithoutContact fontSize="large" />
        </IconButton>
      </Badge>
    </Tooltip>
  );
}
