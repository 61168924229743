import { useAPI, useRoleContext, useShipmentPageContext } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { QuoteQA } from './QuoteQA';

interface Props {
  quote: CondensedQuote;
}

export function QuoteQAContainer(props: Props) {
  const { quote } = props;
  const { shipment } = useShipmentPageContext();
  const { api } = useAPI();
  const [error, setError] = useState<null | string>(null);
  const [qaList, setQAList] = useState<QuoteQA[]>([]);
  const [loading, setIsLoading] = useState<boolean>(true);
  const { role } = useRoleContext();
  const shipmentRef = useRef<undefined | CondensedShipment>();

  useEffect(() => {
    if (shipment && shipmentRef.current?.info?.id !== shipment.info.id) {
      shipmentRef.current = shipment;
      setIsLoading(true);
    }
  }, [shipment, setIsLoading]);

  useEffect(() => {
    if (shipment && shipmentRef.current?.version !== shipment.version) {
      shipmentRef.current = shipment;
      setIsLoading(true);
    }
  }, [shipment, setIsLoading]);

  useEffect(() => {
    if (loading) {
      api<QuoteQA[]>('get', `/quotes/${quote.quote}/qa`).then((response) => {
        setQAList(response.data);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch quote Q&A');
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [loading, api, quote]);

  return (
    <QuoteQA
      loading={loading}
      error={error}
      renderSubmitQuestion={role != null && role.type === 'SHIPPER'}
      qaList={qaList}
      quote={quote}
    />
  );
}
