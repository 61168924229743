import { Typography } from '@mui/material';

interface Props {
  shipment: CondensedShipment
}

export function MultiShipmentProjectLoadsAvailableLoadsLabel(props: Props) {
  const { shipment } = props;

  if (shipment?.info?.type !== 'MULTI_SHIPMENT_PROJECT' || !shipment.project
    || shipment.project.loads_available === undefined || shipment.project.loads_booked === undefined
  ) {
    return <div />;
  }

  const {
    loads_available: loadsAvailable, loads_booked: loadsBooked,
  } = shipment.project;

  const loadsRemaining = loadsAvailable - loadsBooked;

  let text = 'Shipments Available';
  if (loadsAvailable === 1) {
    text = 'Shipment Available';
  }

  return (
    <Typography variant="body1">
      <b>
        <u>
          {`${loadsRemaining} of ${loadsAvailable} ${text}`}
        </u>
      </b>
    </Typography>
  );
}
