import { CompanyReview } from 'components/CompanyReview';
import { CompanyReviewList } from 'components/CompanyReviewList';

interface Props {
  reviewProfile: PublicCompanyReviewProfile | null;
  loading: boolean;
  error: string | null;
}

export function CompanyPublicReviews(props: Props) {
  const { reviewProfile, loading, error } = props;
  return (
    <CompanyReviewList
      loading={loading}
      error={error}
      reviewCount={reviewProfile?.reviews.length ?? null}
    >
      {
        reviewProfile?.reviews.map((review) => (
          <CompanyReview
            key={review.created_at}
            text={review.content.text}
            author={review.author}
            createdAt={review.created_at}
            rating={review.content.rating}
          />
        ))
      }
    </CompanyReviewList>
  );
}
