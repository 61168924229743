import { Typography } from '@mui/material';
import { AssignMultiShipmentProjectLoadsInteraction } from '../AssignMultiShipmentProjectLoadsInteraction';
import { CounterQuoteInteraction } from '../CounterQuoteInteraction';
import { DeclineQuoteWitFeedbackInteraction } from '../DeclineWithFeedbackQuoteInteraction/DeclineWithFeedbackQuoteInteraction';
import { SimpleQuoteInteraction } from '../SimpleQuoteInteraction';

interface Props{
  action: CondensedQuoteActionValue | null;
  onClose: VoidFunction;
  quote: CondensedQuote;
}

export function QuoteActionModal(props: Props) {
  const {
    onClose, action, quote,
  } = props;
  let quoteActionFormComponent;
  const quoteId = quote.quote;
  switch (action) {
    case 'CANCEL':
    case 'DECLINE':
      quoteActionFormComponent = (
        <SimpleQuoteInteraction
          type={action}
          quoteId={quoteId}
          onClose={onClose}
        />
      );
      break;
    case 'ACCEPT':
      if (quote?.quote_type === 'MULTI_SHIPMENT_PROJECT') {
        quoteActionFormComponent = (
          <AssignMultiShipmentProjectLoadsInteraction
            quote={quote}
            onClose={onClose}
          />
        );
      } else {
        quoteActionFormComponent = (
          <SimpleQuoteInteraction
            type={action}
            quoteId={quoteId}
            onClose={onClose}
          />
        );
      }
      break;
    case 'COUNTER':
      quoteActionFormComponent = <CounterQuoteInteraction quoteId={quoteId} onClose={onClose} />;
      break;
    case 'DECLINE_WITH_FEEDBACK':
      quoteActionFormComponent = (
        <DeclineQuoteWitFeedbackInteraction quoteId={quoteId} onClose={onClose} />
      );
      break;
    default:
      quoteActionFormComponent = <Typography color="error" variant="body1">Invalid action. Please contact us for assistance.</Typography>;
      break;
  }

  return quoteActionFormComponent;
}
