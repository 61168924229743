import {
  Button, Stack, TextField, Typography,
} from '@mui/material';
import { CheckCarrierCompanyDetails } from '../types';

interface Props {
  onCheckIfCompanyExists: () => void;
  handleCheckCompanyFormChange: (key: keyof CheckCarrierCompanyDetails, value: string) => void;
  handleSearchCompanySelected: () => void;
  checkCompanyFormState: CheckCarrierCompanyDetails;
  error: string | null;
}

export function CheckCarrierCompanyExists(props: Props) {
  const {
    error,
    checkCompanyFormState,
    onCheckIfCompanyExists,
    handleCheckCompanyFormChange,
    handleSearchCompanySelected,
  } = props;
  const nextButtonDisabled = checkCompanyFormState.name.length === 0;
  return (
    <Stack spacing={3} direction="column">
      <Typography textAlign="center" variant="h4" fontWeight="bold">
        Create your company profile
      </Typography>
      <Typography variant="h6">
        Company Name
      </Typography>
      <TextField
        onChange={(e) => handleCheckCompanyFormChange('name', e.target.value)}
        placeholder="My carrier company inc."
        required
      />
      <Button variant="contained" disabled={nextButtonDisabled} onClick={onCheckIfCompanyExists}>continue</Button>
      {error != null && <Typography color="red" align="center">{error}</Typography>}
      <Stack direction="row" justifyContent="center" spacing={2} alignItems="center">
        <Typography variant="body1"><b>Is your company new to Evotrux?</b></Typography>
        <Button variant="outlined" onClick={handleSearchCompanySelected}>Join your company on Evotrux</Button>
      </Stack>
    </Stack>
  );
}
