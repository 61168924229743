import {
  Chip,
} from '@mui/material';
import { quoteCountText } from 'utils';

interface Props {
  quoteCount: number
}

export function QuoteCountChip(props: Props) {
  const { quoteCount } = props;

  return (
    <Chip
      label={(
        <div className="quotes">
          {quoteCountText(quoteCount)}
        </div>
)}
      color="success"
    />
  );
}
