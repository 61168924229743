import { Select } from 'components/Select';

interface Props {
  onShipmentRelationChange: (value: ShipmentRelation) => void;
  value: ShipmentRelation;
}

const CARRIER_SHIPMENT_RELATION_OPTIONS = [
  { value: 'public' as ShipmentRelation, label: 'Public Shipments' },
  { value: 'partner' as ShipmentRelation, label: 'Partner Shipments' },
];
export function PartnerShipmentDropdown(props: Props) {
  const { onShipmentRelationChange, value } = props;
  return (
    <Select
      inputLabel=""
      options={CARRIER_SHIPMENT_RELATION_OPTIONS}
      onChange={onShipmentRelationChange}
      defaultValue="partner"
      value={value}
    />
  );
}
