import {
  Container, CircularProgress,
} from '@mui/material';

interface Props {
  isLoading: boolean;
  body: React.ReactNode;
}

export function Verify(props: Props) {
  const { body, isLoading } = props;

  let renderBody = body;
  if (isLoading) {
    renderBody = <CircularProgress data-testid="verify-loading" />;
  }

  return (
    <Container maxWidth="lg">{renderBody}</Container>
  );
}
