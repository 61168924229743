import { Typography } from '@mui/material';

interface Props {
  shipment: CondensedShipment
}

export function MultiShipmentProjectLoadsAvailableLoadsLabel(props: Props) {
  const { shipment } = props;

  if (shipment?.info?.type !== 'MULTI_SHIPMENT_PROJECT') {
    return <div />;
  }

  const loadsAvailable = shipment?.project?.loads_available;
  const loadsCovered = shipment?.project?.loads_booked;

  return (
    <Typography variant="body1">
      <b>
        {`${loadsCovered}/${loadsAvailable} Loads Covered`}
      </b>
    </Typography>

  );
}
