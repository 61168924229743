import { useCompanyBranches } from 'hooks';
import { MultiSelect } from '../Select';

interface Props {
  onChange: (value: string[]) => void;
  value: string[] | undefined;
}

export function BranchMultiSelect(props: Props) {
  const { onChange, value } = props;
  const { branches, loading } = useCompanyBranches();

  const options = branches.map((branch) => ({
    value: branch.branch.id.toString(),
    label: branch.branch.name,
  }));
  return (
    <MultiSelect
      inputLabel={loading ? 'Loading Branches...' : 'Branches'}
      defaultValue={value || []}
      value={value}
      onChange={onChange}
      options={options}
      disabled={loading}
    />
  );
}
