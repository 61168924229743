import {
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography,
} from '@mui/material';

interface Props {
  onClose: VoidFunction;
  onConfirmAction: VoidFunction;
  error: string | null;
  modalContentText: string;
  actionButtonText: string;
  title: string;
  loading: boolean;
  actionButtonColor?: 'primary' | 'warning' | 'error';
}

export function SimpleQuoteInteraction(props: Props) {
  const {
    onClose,
    onConfirmAction,
    loading,
    error,
    modalContentText,
    actionButtonText,
    title,
    actionButtonColor,
  } = props;
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {loading
          ? <Stack alignItems="center"><CircularProgress /></Stack>
          : <Typography variant="subtitle1">{modalContentText}</Typography>}
        {error != null && !loading && <Typography color="red" align="center">{error}</Typography>}
      </DialogContent>
      {!loading && (
      <DialogActions>
        <Button
          autoFocus
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color={actionButtonColor}
          onClick={onConfirmAction}
        >
          {
          actionButtonText
}
        </Button>
      </DialogActions>
      )}
    </Dialog>
  );
}

SimpleQuoteInteraction.defaultProps = {
  actionButtonColor: 'primary',
};
