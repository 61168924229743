import { Typography } from '@mui/material';
import { IconText } from './IconText';

export function ShipmentLocationText<T extends string>(
  props: ShipmentInfoTextProps<T> & { icon: React.ReactNode },
) {
  const { data, icon } = props;

  if (data == null) {
    return null;
  }

  return (
    <IconText
      icon={icon}
      text={<Typography sx={{ fontSize: '0.9rem' }}>{data}</Typography>}
    />
  );
}
