import {
  Box,
} from '@mui/material';
import { Select } from 'components/Select';
import provinces from './provinces';
import states from './states';

function getRegionName(country: string): string {
  switch (country) {
    case 'Canada':
      return 'Province';
    case 'United States':
      return 'State';
    default:
      return 'Region';
  }
}

interface Props {
  onChange: (value: string) => void;
  country: string;
  value?: string;
}

export function RegionSelect(props: Props) {
  const { onChange, country, value } = props;

  return (
    <Box>
      <Select<string>
        selectProps={{ 'data-testid': 'region-select' }}
        inputLabel={getRegionName(country)}
        defaultValue=""
        value={value}
        onChange={onChange}
        disabled={!country}
        options={country === 'CA' ? provinces : states}
      />
    </Box>
  );
}

RegionSelect.defaultProps = {
  value: undefined,
};
