import { Typography } from '@mui/material';
import { useSubscriptionStatus } from 'hooks';

export function ShipperTrialDaysRemainingText() {
  const { subscription } = useSubscriptionStatus();

  if (subscription?.subscription_plan !== 'SHIPPER_TRIAL') {
    return (
      <div />
    );
  }
  return (
    <Typography variant="h6" color="white">
      {subscription.shipper_trial_detail?.days_remaining}
      {' '}
      days of your
      {' '}
      <span style={{ color: 'yellow' }}>
        Premium
      </span>
      {' '}
      free trial remain
    </Typography>
  );
}
