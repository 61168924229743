import { Alert } from '@mui/material';
import { WHAT_ARE_MUTLI_SHIPMENT_PROJECTS_BLOG_URL } from '../../constants';

interface Props {
  shipment: CondensedShipment | undefined
}

export function WhatAreMultiShipmentProjectsAlert(props: Props) {
  const { shipment } = props;
  if (!shipment || shipment?.info?.type !== 'MULTI_SHIPMENT_PROJECT') {
    return <div />;
  }
  return (
    <Alert severity="info">
      {'This is a Multi-Shipment project. To learn more about them, click '}
      <a target="_blank" href={WHAT_ARE_MUTLI_SHIPMENT_PROJECTS_BLOG_URL} rel="noreferrer">
        here.
      </a>
    </Alert>
  );
}
