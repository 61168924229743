import {
  Button, Dialog, DialogActions, DialogContent,
} from '@mui/material';
import { CreateShipmentFormContainer } from 'components/Shipper/components/CreateShipmentForm/CreateShipmentFormContainer';
import { CreateShipmentFormState } from 'components/Shipper/components/CreateShipmentForm/types';
import { useShipperShipmentPageContext, useSubscriptionStatus } from 'hooks';

interface Props {
  shipment: CondensedShipment | undefined;
  onClose: VoidFunction;
  error: string | null;
  shipmentState: CreateShipmentFormState | undefined;
}

export function ShipmentCopyModal(props: Props) {
  const {
    shipment, onClose, error, shipmentState,
  } = props;
  const { subscription } = useSubscriptionStatus();
  const { onRefresh } = useShipperShipmentPageContext();

  const onShipmentPosted = () => {
    if (onRefresh) {
      onRefresh();
    }
    onClose();
  };
  return (
    <Dialog open onClose={onClose} maxWidth="md" style={{ height: '90%' }}>
      <DialogContent>
        <CreateShipmentFormContainer
          shipmentState={shipmentState}
          commodity={shipment?.info.commodity ?? undefined}
          error={error}
          useCustomCommodity={shipment != null
              && shipment?.info && shipment?.info?.commodity_freetext != null && shipment?.info?.commodity_freetext !== ''}
          postShipmentUrl={
                subscription?.subscription_plan === 'SHIPPER_PAY_PER_POSTING' ? '/shipments/pay-per-posting' : '/shipments'
              }
          onSubmitShipmentSuccessCallback={onShipmentPosted}
          showShareMenu
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
