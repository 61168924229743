import { useState } from 'react';
import { useAPI } from '../../../../hooks';
import { DeleteShipmentSearch } from './DeleteShipmentSearch';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  shipmentSearch: ShipmentSearch;
  reloadSearches: VoidFunction;
}

export function DeleteShipmentSearchContainer(props: Props) {
  const {
    open, onClose, shipmentSearch, reloadSearches,
  } = props;
  const [error, setError] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { api } = useAPI();

  const deleteShipmentSearch = (): void => {
    setError(null);
    setIsDeleting(true);
    api<unknown>('delete', `/lanes/${shipmentSearch.id}`).then(() => {
      setError(null);
      onClose();
      reloadSearches();
    }).catch((e) => {
      setError(e?.response?.data?.message || 'Failed to delete saved search');
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const onModalClose = () => {
    setError(null);
    onClose();
  };

  return (
    <DeleteShipmentSearch
      open={open}
      onClose={onModalClose}
      onDeleteShipmentSearch={deleteShipmentSearch}
      error={error}
      loading={isDeleting}
      shipmentSearchName={shipmentSearch.name}
    />
  );
}
