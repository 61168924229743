import { useAPI } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { RouteTab } from './RouteTab';
import { GetRouteResponse } from './types';

interface Props {
  shipment: CondensedShipment | undefined;
}

export function RouteTabContainer(props: Props) {
  const { api } = useAPI();
  const [loading, setIsLoading] = useState<boolean>(true);
  const [route, setRoute] = useState<Route | null>(null);
  const [error, setError] = useState<null | string>(null);
  const shipmentRef = useRef<undefined | CondensedShipment>();

  const { shipment } = props;

  useEffect(() => {
    if (loading && shipment?.info?.route) {
      api<GetRouteResponse>('get', `/routes/${shipment.info.route}`).then((response) => {
        setRoute({
          id: response.data?.route?.id,
          type: response.data?.route?.type,
          route_points: response?.data.route?.route_points,
        });
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch route');
      }).finally(() => { setIsLoading(false); });
    }
  }, [loading, shipment, api]);

  useEffect(() => {
    /*
    This hook keeps track of the shipment ID between renders to ensure
    that the route is re-fetched when another shipment is selected.
    */
    if (shipment && shipmentRef.current?.info?.id !== shipment.info.id) {
      shipmentRef.current = shipment;
      setIsLoading(true);
    }
  }, [shipment]);

  if (!shipment) {
    return <div />;
  }

  return (
    <RouteTab
      error={error}
      route={route}
      loading={loading}
    />
  );
}
