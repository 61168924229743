import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box, Stack, Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { CarrierUpgradeNowButton } from 'components/Carrier/components/CarrierUpgradeNowButton';

interface Props {
  onClose: VoidFunction;
  open: boolean;
  onCloseCallback: VoidFunction | null;
}

export function NoBookingsRemainingModal(props: Props) {
  const {
    onClose,
    open,
    onCloseCallback,
  } = props;

  const onCloseButtonClicked = () => {
    onClose();
    if (onCloseCallback) {
      onCloseCallback();
    }
  };

  if (!open) {
    return <div />;
  }

  return (
    <Dialog open={open} onClose={onCloseButtonClicked}>
      <Box mb="2rem">
        <Stack justifyContent="flex-end" direction="row">
          <CloseIcon
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={onCloseButtonClicked}
          />
        </Stack>
        <Stack direction="column" spacing={1} pl="1rem" pr="1rem">
          <Stack
            direction="row"
            justifyContent="center"
            alignContent="baseline"
            spacing={1}
          >
            <WarningIcon color="warning" fontSize="large" />
            <Typography
              textAlign="center"
              color="red"
              variant="h5"
            >
              WARNING - You have no available bookings
            </Typography>
          </Stack>

          <Typography variant="h6">
            The shipper
            {' '}
            <u> can not receive or book your quote</u>
            {' '}
            until you upgrade your account.
          </Typography>
          <Typography variant="h6">Click the button below to select a subscription or pay-as-you-go plan.</Typography>
          <Stack mt="2rem" spacing={4} justifyContent="center" m="1rem" alignItems="center">
            <CarrierUpgradeNowButton text="View plans" />
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}
