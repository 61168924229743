import { useRoleContext } from 'hooks';
import { ShipmentQuoteDetail as CarrierQuoteDetail } from '../../../Carrier/components/ShipmentQuoteDetail';
import { ShipmentQuoteDetail as ShipperQuoteDetail } from '../../../Shipper/components/ShipmentQuoteDetail';

interface Props {
  quote: CondensedQuote | ShipperCondensedQuote;
}

export function QuoteDetail(props: Props) {
  const {
    quote,
  } = props;

  const { role } = useRoleContext();

  if (role?.type === 'CARRIER') {
    return (
      <CarrierQuoteDetail quote={quote} />
    );
  } if (role?.type === 'SHIPPER') {
    return (
      <ShipperQuoteDetail quote={quote as ShipperCondensedQuote} />
    );
  }
  return <p>Something went wrong. Please contact us</p>;
}
