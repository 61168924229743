import { Box, CircularProgress } from '@mui/material';

interface Props {
  children: React.ReactNode;
  loading: boolean;
}

export function LoadingWrappedNode(props: Props) {
  const { children, loading } = props;
  if (loading) {
    return (
      <Box
        id="loading-wrapped-component-spinner-container"
        display="flex"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box id="loading-wrapped-component">
      {children}
    </Box>
  );
}
