import { ShipmentQuoteList } from '../ShipmentQuoteList';

interface Props {
  shipment: CondensedShipment | undefined
}

export function ShipperQuoteTab(props: Props) {
  const { shipment } = props;

  if (shipment === undefined) {
    return <div />;
  }
  return (
    <ShipmentQuoteList shipment={shipment as ShipperCondensedShipment} />
  );
}
