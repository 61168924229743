import { Stack, Typography } from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { ExploShipperPage } from 'components/ExploShipperPage';
import { CompanyDashboard } from 'components/ExploShipperPage/components/CompanyDashboard';
import {
  useExploAccess,
  useSubscriptionStatus,
} from 'hooks';
import { PageLayout } from 'layouts';
import { ShipperUpgradeRequestInfoButton } from '../ShipperUpgradePage/components';

export function DataPageWrapper() {
  const { subscription } = useSubscriptionStatus();
  const { hasAccess, error } = useExploAccess();

  if (subscription === null) {
    return <div />;
  }

  if (
    (subscription.subscription_plan === 'SHIPPER_TRIAL' && !subscription.is_active)
    || subscription.subscription_plan === 'SHIPPER_FREE_TIER'
  || subscription.subscription_plan === 'SHIPPER_SHIP_A'
    || subscription.subscription_plan === 'SHIPPER_SHIP_B'
    || subscription.subscription_plan === 'SHIPPER_SHIP_C') {
    return (
      <PageLayout>
        <Stack
          textAlign="center"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            p="2rem"
            direction="column"
            justifyContent="center"
          >
            <Typography
              textAlign="center"
              variant="h4"
            >
              The Analytics dashboard is a Premium feature.
            </Typography>
            <Typography
              textAlign="center"
              variant="h4"
              sx={{ mt: '2rem', mb: '2rem' }}
            >
              Go Premium for powerful insights and more!
            </Typography>
          </Stack>
          <ShipperUpgradeRequestInfoButton />
        </Stack>
      </PageLayout>
    );
  }

  if (hasAccess == null) {
    if (error !== null) {
      return (
        <Stack>
          <ErrorTypography error={error} />
        </Stack>
      );
    }
    return (<div />);
  }

  if (hasAccess === false) {
    return (
      <PageLayout>
        <Stack
          p="2rem"
        >
          <Typography
            textAlign="center"
            variant="h4"
            sx={{ mt: '3rem' }}
          >
            Your data package has not been activated. Please contact us to activate it.
          </Typography>
        </Stack>
      </PageLayout>
    );
  }
  return (
    <ExploShipperPage
      title="Company Dashboard"
      dashboard={<CompanyDashboard />}
    />
  );
}
