import { useEffect, useState } from 'react';
import { useAPI } from '../../../../hooks';
import { CheckCarrierCompanyDetails } from '../types';
import { CheckCarrierCompanyExists } from './CheckCarrierCompanyExists';

interface Props {
  handleSearchCompanySelected: () => void;
  onCompanyDoesNotAlreadyExist: (companyInfo: CheckCarrierCompanyDetails) => void;
}

export function CheckCarrierCompanyExistsContainer(props: Props) {
  const { api } = useAPI();
  const { handleSearchCompanySelected, onCompanyDoesNotAlreadyExist } = props;
  const [error, setError] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkCompanyFormState, setCheckCompanyFormState] = useState<CheckCarrierCompanyDetails>({
    name: '',
  });

  const handleCheckCompanyFormChange = (key: keyof CheckCarrierCompanyDetails, value: string) => {
    setCheckCompanyFormState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (isLoading) {
      const queryParams = {
        name: checkCompanyFormState.name,
      };
      const encodedParams = new URLSearchParams(queryParams).toString();
      const url = `/companies/find-company?${encodedParams}`;
      api<Company[]>('get', url).then((response) => {
        if (response.data.length > 0) {
          setError("A company with the same MC number or the same name already exists. Please ensure your company's info is correct or find and join and the company. If you think this is a mistake, please contact us.");
        } else {
          onCompanyDoesNotAlreadyExist(
            { name: checkCompanyFormState.name },
          );
        }
      }).catch(() => {
        setError('Failed to check name and MC number availability');
      });
      setIsLoading(false);
    }
  }, [api, isLoading, checkCompanyFormState, onCompanyDoesNotAlreadyExist]);

  const checkCompanyInfoCollision = (): void => {
    setIsLoading(true);
  };
  return (
    <CheckCarrierCompanyExists
      error={error}
      onCheckIfCompanyExists={checkCompanyInfoCollision}
      handleCheckCompanyFormChange={handleCheckCompanyFormChange}
      handleSearchCompanySelected={handleSearchCompanySelected}
      checkCompanyFormState={checkCompanyFormState}
    />
  );
}
