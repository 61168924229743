import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  stringToCopy: string;
  message: string;
  onClick?: () => void | null;
}
export function CopyToClipboardButton(props: Props) {
  const { stringToCopy, message, onClick } = props;
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(stringToCopy);
  };

  useEffect(() => {
    if (open && onClick) {
      onClick();
    }
  }, [open, onClick]);

  return (
    <>
      <IconButton
        onClick={handleClick}
      >
        <ContentCopyIcon />
      </IconButton>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={3000}
        message={message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
}

CopyToClipboardButton.defaultProps = {
  onClick: null,
};
