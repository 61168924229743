export const INITIAL_FILTER_STATE: ShipmentsFilterState = {
  equipmentTypes: [],
  services: [],
  commodity: null,
  shipmentStatus: 'ALL',
  origin: { provinces: [], countries: [] },
  destination: { provinces: [], countries: [] },
  orderBy: 'created_at',
  sortOrder: 'DESC',
  shipmentVisibility: 'public',
  shipmentRelation: 'public',
  shipmentName: '',
  shipmentTypes: [],
  includeBranchShipments: false,
  includeCompanyShipments: false,
  branches: [],
  shippers: [],
};
