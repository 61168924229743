import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ISOStringToLocalReadableTimestamp } from 'utils';

interface Props {
  bookings: MultiShipmentProjectBooking[];
}

export function MultiShipmentProjectBookingsTable(props: Props) {
  const {
    bookings,
  } = props;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer component={Paper}>
        <Table
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>Shipment ID</TableCell>
              <TableCell>Shipment Reference</TableCell>
              <TableCell align="right">Assigned by</TableCell>
              <TableCell align="right">Booked for $</TableCell>
              <TableCell align="right">Currency</TableCell>
              <TableCell align="right">Booked At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookings.map((booking: MultiShipmentProjectBooking) => (
              <TableRow
                key={booking.child_shipment.id}
              >
                <TableCell component="th" scope="row">
                  <a target="_blank" href={`/shipments/${booking.child_shipment.id}`} rel="noreferrer">
                    <Typography sx={{ textDecoration: 'underline blue', color: 'blue' }}>
                      {booking.child_shipment.id}
                    </Typography>
                  </a>
                </TableCell>
                <TableCell>{booking.child_shipment.custom_name}</TableCell>
                <TableCell align="right">{`${booking.shipper_assigner.user.first_name} ${booking.shipper_assigner.user.last_name}`}</TableCell>
                <TableCell align="right">{`$${parseFloat(booking.booked_price).toFixed(2)}`}</TableCell>
                <TableCell align="right">{booking.booked_currency.code}</TableCell>
                <TableCell align="right">{ISOStringToLocalReadableTimestamp(booking.created_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
