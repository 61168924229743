import { Typography } from '@mui/material';

export function FreeTierBannerText() {
  return (
    <Typography variant="h6" color="white">
      Your free trial has expired.
      {' '}
      <span style={{ color: 'yellow' }}>
        Upgrade now
      </span>
      {' '}
      to continue using Evotrux!
    </Typography>
  );
}
