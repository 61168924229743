import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';

interface Props {
  loading: boolean;
  loadCompanyMembersError: UIError;
  companyMembers: CompanyMember[];
  selectedCompanyMembers: number[];
  setShareeRoleIds: (roleIds: number[]) => void;
  shareShipment: boolean;
  onShareShipmentChange: (newValue: boolean) => void;
  isProject: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface RoleIdToCompanyMemberMap {
  [key: number]: CompanyMember;
}

export function ShareShipmentMenu(props: Props) {
  const {
    loading,
    loadCompanyMembersError,
    companyMembers,
    selectedCompanyMembers,
    setShareeRoleIds,
    shareShipment,
    onShareShipmentChange,
    isProject,
  } = props;

  const roleIdMap:
  RoleIdToCompanyMemberMap = companyMembers.reduce((acc, member: CompanyMember) => {
    acc[member.id] = member;
    return acc;
  }, {} as RoleIdToCompanyMemberMap);

  const getCompanyMemberLabel = (member: CompanyMember) => `${member.user.first_name ?? ''} ${member.user.last_name ?? ''}`;

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;
    if (typeof value === 'string') {
      const roleStringArray = value.split(',');
      setShareeRoleIds(roleStringArray.map((stringRoleId) => parseInt(stringRoleId, 10)));
    } else {
      setShareeRoleIds(value);
    }
  };

  const handleShareShipmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onShareShipmentChange(event.target.value === 'true');
  };

  return (
    <Stack direction="column" mb="1rem" mt="1rem">
      <Typography variant="h6">{`Who on your team can manage this ${isProject ? 'project' : 'shipment'}?`}</Typography>
      <FormControl>
        <RadioGroup
          value={shareShipment}
          onChange={handleShareShipmentChange}
        >
          <FormControlLabel value="false" control={<Radio />} label="Only me" />
          <FormControlLabel value="true" control={<Radio />} label="Me and:" />
        </RadioGroup>
      </FormControl>
      <LoadingWrappedNode loading={loading}>
        <Stack>
          <FormControl>
            <InputLabel>Teammates</InputLabel>
            <Select
              multiple
              value={selectedCompanyMembers}
              onChange={handleChange}
              input={<OutlinedInput label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((roleId: number) => (
                    <Chip key={roleId} label={getCompanyMemberLabel(roleIdMap[roleId])} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {
                companyMembers.map((member: CompanyMember) => (
                  <MenuItem
                    key={member.id}
                    value={member.id}
                  >
                    {getCompanyMemberLabel(member)}
                  </MenuItem>
                ))
                }
            </Select>
          </FormControl>
        </Stack>
        <Typography sx={{ mt: '.5rem' }} variant="body1" color="text.secondary">Company and Branch administratos will automtically be able to view and manage this shipment.</Typography>
      </LoadingWrappedNode>
      <ErrorTypography error={loadCompanyMembersError} />
    </Stack>
  );
}
