import { useAPI, useRoleContext } from 'hooks';
import { useEffect, useState } from 'react';
import { ShipmentNotificationsSelectMenu } from './ShipmentNotificationsSelectMenu';
import { NetworkMember } from './types';
import { getEmailArrayFromNetworkMemberArray, getNetworkMemberArrayFromFollowingCarrierArray, getNetworkMemberFromFollowShipperRequest } from './utils';

interface Props {
  setShipmentNotificationType: (type: ShipmentNotificationType) => void;
  setShipmentNotificationEmails: (emails: string[]) => void;
  shipmentNotificationType: ShipmentNotificationType;
  selectedCarriers: string[];
  isProject: boolean;
}

export function ShipmentNotificationsSelectMenuContainer(props: Props) {
  const {
    setShipmentNotificationType,
    setShipmentNotificationEmails,
    shipmentNotificationType,
    selectedCarriers,
    isProject,
  } = props;

  const [carrierNetworkError, setCarrierNetwokrError] = useState<string | null>(null);
  const [activeExternalRequestsError,
    setActiveExternalRequestsError] = useState<string | null>(null);

  const [loadingActiveExternalRequests,
    setIsLoadingActiveExternalRequests] = useState<boolean>(true);

  const [loadingNetwork,
    setLoadingNetwork] = useState<boolean>(true);

  const [activeExternalRequestInvites,
    setActiveExternalRequestInvites] = useState<FollowShipperRequest[]>([]);

  const [networkMembers,
    setNetworkMembers] = useState<FollowingCarrier[]>([]);

  const [combinedNetworkMembers, setCombinedNetworkMembers] = useState<NetworkMember[]>([]);

  const { api } = useAPI();
  const { role } = useRoleContext();

  const onShipmentVisibilityChanged = (value: 'PUBLIC' | 'PRIVATE') => {
    if (value === 'PUBLIC') {
      if (shipmentNotificationType === 'PRIVATE_SHIPMENT') {
        setShipmentNotificationType('SELECTED_NETWORK_CARRIERS');
      }
      if (shipmentNotificationType === 'PRIVATE_SHIPMENT_ALL_NETWORK') {
        setShipmentNotificationType('ALL_CARRIER_NETWORK');
      }
    }

    if (value === 'PRIVATE') {
      if (shipmentNotificationType === 'ALL_CARRIER_NETWORK') {
        setShipmentNotificationType('PRIVATE_SHIPMENT_ALL_NETWORK');
      }
      if (shipmentNotificationType === 'SELECTED_NETWORK_CARRIERS') {
        // purge carrier email list from unconfirmed network members here
        const confirmedNetworkMemberEmails = getEmailArrayFromNetworkMemberArray(networkMembers);
        const fiteredMembers = selectedCarriers.filter(
          (email) => confirmedNetworkMemberEmails.includes(email),
        );
        setShipmentNotificationEmails(fiteredMembers);
        setShipmentNotificationType('PRIVATE_SHIPMENT');
      }
    }
  };

  const onNetworkNotificationTypeChanged = (value: 'ALL_NETWORK' | 'SELECTED_CARRIERS') => {
    if (value === 'ALL_NETWORK') {
      if (shipmentNotificationType === 'SELECTED_NETWORK_CARRIERS') {
        setShipmentNotificationType('ALL_CARRIER_NETWORK');
      }
      if (shipmentNotificationType === 'PRIVATE_SHIPMENT') {
        setShipmentNotificationType('PRIVATE_SHIPMENT_ALL_NETWORK');
      }
    }
    if (value === 'SELECTED_CARRIERS') {
      if (shipmentNotificationType === 'ALL_CARRIER_NETWORK') {
        setShipmentNotificationType('SELECTED_NETWORK_CARRIERS');
      }
      if (shipmentNotificationType === 'PRIVATE_SHIPMENT_ALL_NETWORK') {
        setShipmentNotificationType('PRIVATE_SHIPMENT');
      }
    }
  };

  useEffect(() => {
    if (loadingNetwork && role != null && role.type === 'SHIPPER') {
      api<FollowingCarrier[]>('get', `/shippers/${role.id}/followers`).then((response) => {
        setNetworkMembers(response.data);
        setCarrierNetwokrError(null);
      }).catch((e) => {
        setCarrierNetwokrError(e?.response?.data?.message || 'Failed to fetch carrier network.');
      }).finally(() => {
        setLoadingNetwork(false);
      });
    }
  }, [api, role, loadingNetwork]);

  useEffect(() => {
    if (loadingActiveExternalRequests && role != null && role.type === 'SHIPPER') {
      api<FollowShipperRequest[]>('get', `/shippers/${role.id}/follow_requests/sent/active-external`).then((response) => {
        setActiveExternalRequestInvites(response.data);
        setActiveExternalRequestsError(null);
      }).catch((e) => {
        setActiveExternalRequestsError(e?.response?.data?.message || 'Failed to fetch carrier network.');
      }).finally(() => {
        setIsLoadingActiveExternalRequests(false);
      });
    }
  }, [api, role, loadingActiveExternalRequests]);

  useEffect(() => {
    const followingCarriersAsMembers = getNetworkMemberArrayFromFollowingCarrierArray(
      networkMembers,
    );

    const activeRequestsAsMembers = activeExternalRequestInvites.map(
      (request: FollowShipperRequest) => getNetworkMemberFromFollowShipperRequest(request),
    );
    setCombinedNetworkMembers(followingCarriersAsMembers.concat(activeRequestsAsMembers));
  }, [activeExternalRequestInvites, networkMembers]);

  return (
    <ShipmentNotificationsSelectMenu
      confirmedNetworkMembers={getNetworkMemberArrayFromFollowingCarrierArray(networkMembers)}
      networkMembers={combinedNetworkMembers}
      activeExternalFetchError={activeExternalRequestsError}
      carrierNeworkFetchError={carrierNetworkError}
      loadingNetworkMembers={loadingNetwork}
      loadingActiveExternalInvites={loadingActiveExternalRequests}
      onNetworkNotificationTypeChanged={onNetworkNotificationTypeChanged}
      onShipmentVisibilityChanged={onShipmentVisibilityChanged}
      shipmentVisibility={shipmentNotificationType === 'ALL_CARRIER_NETWORK'
      || shipmentNotificationType === 'SELECTED_NETWORK_CARRIERS' ? 'PUBLIC' : 'PRIVATE'}
      networkNotification={shipmentNotificationType === 'ALL_CARRIER_NETWORK'
      || shipmentNotificationType === 'PRIVATE_SHIPMENT_ALL_NETWORK' ? 'ALL_NETWORK' : 'SELECTED_CARRIERS'}
      setShipmentNotificationEmails={setShipmentNotificationEmails}
      selectedCarriers={selectedCarriers}
      isProject={isProject}
    />
  );
}
