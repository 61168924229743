import { PROVINCE_STATE_LIST } from '../../constants';
import { MultiSelect } from '../Select';

interface Props {
  onChange: (value: string[]) => void;
  defaultValue?: string[];
  label: string;
  value?: string[];
  width?: string | number;
  customOptions?: EquipmentOption[];
}

export function ProvinceStateMultiSelect(props: Props) {
  const {
    onChange, defaultValue, value, label, width, customOptions,
  } = props;

  return (
    <MultiSelect
      inputLabel={label}
      defaultValue={defaultValue || []}
      value={value}
      onChange={onChange}
      options={customOptions || PROVINCE_STATE_LIST}
      width={width}
    />
  );
}

ProvinceStateMultiSelect.defaultProps = {
  defaultValue: '',
  value: null,
  width: 150,
  customOptions: null,
};
