import {
  Chip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CARRIER_UPGRADE_RELATIVE_URL } from '../../constants';

interface Props {
  status: InternalQuoteStatusValue;
  size: 'small' | 'medium' | undefined;
  isHidden?: boolean;
}

export function QuoteStatusBadge(props: Props) {
  const { status, size, isHidden } = props;
  const navigate = useNavigate();

  const getBadgeColor = (): 'primary' | 'success' | 'warning' | 'info' | 'error' => {
    if (isHidden) {
      return 'error';
    }

    switch (status) {
      case 'COUNTERED':
      case 'ACTIVE':
        if (isHidden) {
          return 'error';
        }
        return 'primary';
      case 'ACCEPTED':
        return 'success';
      case 'CANCELLED':
        return 'error';
      case 'SHIPMENT_LOST':
      case 'SHIPMENT_CANCELLED':
      case 'SHIPMENT_EXPIRED':
      case 'EXPIRED':
      case 'DECLINED':
        return 'warning';
      default:
        return 'info';
    }
  };

  const getChipLabel = () => {
    if (isHidden) {
      return 'PENDING UPGRADE';
    }
    if (status === 'ACCEPTED') {
      return 'BOOKED';
    }
    if (status === 'SHIPMENT_LOST') {
      return 'LOST';
    }
    return status.replaceAll('_', ' ');
  };

  return (
    <Chip
      variant={isHidden && (status === 'ACTIVE' || status === 'COUNTERED') ? 'filled' : 'outlined'}
      label={getChipLabel() ?? 'unknown'}
      color={getBadgeColor()}
      size={size}
      onClick={isHidden ? () => navigate(CARRIER_UPGRADE_RELATIVE_URL) : undefined}
    />
  );
}

QuoteStatusBadge.defaultProps = {
  isHidden: false,
};
