import { useState } from 'react';
import { CheckShipperCompanyDetails } from '../types';
import { CreateShipperCompany } from './CreateShipperCompany';

interface Props {
  handleSearchCompanySelected: () => void;
}

const initialCompanyData = {
  name: '',
};

export function CreateShipperCompanyContainer(props: Props) {
  const { handleSearchCompanySelected } = props;
  const [companyExists, setCompanyExists] = useState<boolean>(true);
  const [companyData, setCompanyData] = useState<CheckShipperCompanyDetails>(initialCompanyData);

  const onCompanyDoesNotAlreadyExist = (companyInfo: CheckShipperCompanyDetails): void => {
    setCompanyExists(false);
    setCompanyData(companyInfo);
  };

  const handleResetCompanyData = (): void => {
    setCompanyData(initialCompanyData);
    setCompanyExists(true);
  };

  return (
    <CreateShipperCompany
      handleSearchCompanySelected={handleSearchCompanySelected}
      companyExists={companyExists}
      onCompanyDoesNotAlreadyExist={onCompanyDoesNotAlreadyExist}
      companyData={companyData}
      handleResetCompanyData={handleResetCompanyData}
    />
  );
}
