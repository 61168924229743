import {
  Box, Button, Stack, Typography,
} from '@mui/material';
import { useDisclosure } from 'hooks';
import { DateTime } from 'luxon';
import { AddressAutocomplete } from '../../../../../AddressAutocomplete';
import { TimeInput } from '../../../../../TimeInput';
import { getTimeInputLabel } from '../../utils';
import { DisplayableRoutePoint } from '../RoutesForm/types';

interface Props {
  routePoint: DisplayableRoutePoint;
  isEditing: boolean;
  error: string | null;
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  onValueChange: <T,>(key: keyof DisplayableRoutePoint, value: T) => void;
  editButton: React.ReactNode;
  onSave: VoidFunction;
}

export function RoutePoint(props: Props) {
  const {
    routePoint, isEditing, onValueChange, editButton, error, onSave,
  } = props;

  // controls if the address input is shown or not.
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getAddressContent = () => {
    const savedAddressDisplay = (
      <Stack direction="row" alignItems="baseline">
        <Typography sx={{ mb: '1rem' }}>
          {routePoint.addressReadable}
        </Typography>
        <Button onClick={onOpen}>Edit</Button>
      </Stack>
    );

    const addressInput = <AddressAutocomplete onChange={(value) => onValueChange('addressId', value)} isRequired />;

    return (
      <Stack direction="column" minWidth="300px" maxWidth="500px" sx={{ ml: '1rem' }}>
        {!routePoint?.addressReadable || isOpen ? addressInput : savedAddressDisplay }
      </Stack>
    );
  };

  const isEditingContent = (
    <Stack direction="column" flexWrap="wrap">
      {getAddressContent()}
      <Stack direction="row" spacing={1} sx={{ ml: '1rem', mt: '1.5rem' }}>
        <Box>
          <TimeInput
            timeFormat="date"
            label={getTimeInputLabel(routePoint)[0]}
            onChange={(value) => onValueChange('start_time', value)}
            initialValue={routePoint.start_time ?? DateTime.now().toFormat("yyyy-MM-dd'T'T")}
          />
        </Box>
        <Box>
          <TimeInput
            timeFormat="date"
            label={getTimeInputLabel(routePoint)[1]}
            onChange={(value) => onValueChange('end_time', value)}
            initialValue={routePoint.end_time ?? DateTime.now().toFormat("yyyy-MM-dd'T'T")}
          />
        </Box>
      </Stack>
    </Stack>
  );

  return (
    <Box p="1rem" boxShadow={1} display="flex" alignItems="center">
      <Box width="100%">
        <Box mb="1rem" display="flex" alignItems="center">
          <Typography sx={{ textTransform: 'capitalize' }} fontSize="1.1rem">
            {routePoint.stop_type?.toLowerCase()}
          </Typography>

        </Box>
        {isEditing ? isEditingContent : (
          <Stack direction="row" spacing={6}>
            <Typography color="#a6a6a6">
              {DateTime.fromISO(routePoint?.start_time ?? '').toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
            </Typography>
            <Typography color="#b6b6b6">
              {routePoint.addressReadable}
            </Typography>
          </Stack>
        )}
        <Stack direction="row" sx={{ ml: '1rem', mt: '1rem' }} alignContent="baseline" spacing={3}>
          {isEditing
            ? (
              <Button
                variant="outlined"
                onClick={() => { onSave(); onClose(); }}
              >
                Save
              </Button>
            )
            : editButton}
          {error != null && (
          <Typography color="red">{error}</Typography>
          )}
        </Stack>

      </Box>
    </Box>
  );
}
