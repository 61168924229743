import { RevealPriceButton } from './components/RevealPriceButton';

interface Props {
  shipment: CarrierCondensedShipment
  dividerComponent: JSX.Element;
}

export function ShipmentDataSection(props: Props) {
  const { shipment, dividerComponent } = props;
  return (
    <RevealPriceButton shipment={shipment} dividerComponent={dividerComponent} />
  );
}
