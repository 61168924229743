import { CheckCarrierCompanyExists } from '../CheckCarrierCompanyExists';
import { CreateCarrierCompanyForm } from '../CreateCarrierCompanyForm';
import { CheckCarrierCompanyDetails } from '../types';

interface Props {
  handleSearchCompanySelected: () => void;
  onCompanyDoesNotAlreadyExist: (companyInfo: CheckCarrierCompanyDetails) => void;
  companyExists: boolean;
  companyData: CheckCarrierCompanyDetails;
  handleResetCompanyData: () => void;
}

export function CreateCarrierCompany(props: Props) {
  const {
    handleSearchCompanySelected,
    companyExists,
    onCompanyDoesNotAlreadyExist,
    companyData,
    handleResetCompanyData,
  } = props;

  if (companyExists) {
    return (
      <CheckCarrierCompanyExists
        handleSearchCompanySelected={handleSearchCompanySelected}
        onCompanyDoesNotAlreadyExist={onCompanyDoesNotAlreadyExist}
      />
    );
  }
  return (
    <CreateCarrierCompanyForm
      companyData={companyData}
      handleSearchCompanySelected={handleSearchCompanySelected}
      handleResetCompanyData={handleResetCompanyData}
    />
  );
}
