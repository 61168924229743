import { CircularProgress, Stack, useMediaQuery } from '@mui/material';
import { useShipments } from 'hooks';
import { useState } from 'react';
import { SHIPMENTS_PAGE_SIZE } from 'utils';
import { useAuthContext } from '../../../../hooks';
import { DashboardItemLayout, PageLayout } from '../../../../layouts';
import { ShipmentListContainer } from '../ShipmentListContainer';
import { ShipmentTemplateAndCreateShipmentFormDashboardItem } from '../ShipmentTemplateAndCreateShipmentFormDashboardItem';

export function Dashboard() {
  const { user } = useAuthContext();
  const [page, setPage] = useState<number>(1);
  const {
    shipments, error, isLoading, count, refreshShipments,
  } = useShipments({ page, pageSize: SHIPMENTS_PAGE_SIZE });

  const reduceDashboardItemSpacing = useMediaQuery('(max-width:1300px)');
  const useColumnLayout = useMediaQuery('(max-width:1300px)');

  if (!user) {
    <Stack>
      <CircularProgress />
    </Stack>;
  }
  return (
    <PageLayout>
      <Stack
        direction={useColumnLayout ? 'column' : 'row'}
        spacing={reduceDashboardItemSpacing ? 4 : 8}
      >
        <DashboardItemLayout
          style={{
            p: '1rem',
            width: !useColumnLayout ? '45%' : undefined,
          }}
        >
          <ShipmentTemplateAndCreateShipmentFormDashboardItem
            refreshShipments={refreshShipments}
          />
        </DashboardItemLayout>
        <DashboardItemLayout
          style={{
            width: !useColumnLayout ? '55%' : undefined,
          }}
        >
          <ShipmentListContainer
            page={page}
            setPage={setPage}
            shipments={shipments}
            shipmentsError={error}
            shipmentsLoading={isLoading}
            shipmentCount={count}
          />
        </DashboardItemLayout>
      </Stack>
    </PageLayout>
  );
}
