import {
  Box, Divider, Stack, Typography,
} from '@mui/material';

import {
  ChannelEntity,
  ChannelList,
} from '@pubnub/react-chat-components';
import { useEffect, useRef, useState } from 'react';
import { getChannelEntityFromChannel, sortChannelEntities } from 'utils';
import { ChannelRenderer } from './components/ChannelRenderer';

interface Props {
  onChannelSelected: (channelName: string) => void;
  channels: Channel[];
  channelsWithNewMessages: Set<string>;
  currentChannel: string;
  useDesktopLayout: boolean;
}

export function ChatSidebar(props: Props) {
  const {
    onChannelSelected,
    channels,
    channelsWithNewMessages,
    currentChannel,
    useDesktopLayout,
  } = props;

  const channeListContainerRef = useRef(null);

  useEffect(() => {
    function handleResize() {
      const windowHeight = window.innerHeight;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const containerTop = channeListContainerRef.current.offsetTop;
      const containerMargin = 120; // adjust as needed
      const containerMaxHeight = windowHeight - containerTop - containerMargin;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      channeListContainerRef.current.style.minHeight = `${containerMaxHeight}px`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      channeListContainerRef.current.style.maxHeight = `${containerMaxHeight}px`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      channeListContainerRef.current.style.overflowY = 'auto';
    }

    handleResize(); // set initial height
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /*
    A copy of channelsWithNewMessages, updated everytime there is a change in the prop with the goal
    of forcing a re-render of the ChannelList component
  */
  const [channelsWithNewMessagesStateCopy,
    setChannelsWithNewMessagesStateCopy] = useState<Set<string>>(new Set());

  const [currentChannelCopy, setCurrentChannelCopy] = useState<string>('');

  useEffect(() => {
    setChannelsWithNewMessagesStateCopy(new Set(channelsWithNewMessages.values()));
  }, [channelsWithNewMessages, setChannelsWithNewMessagesStateCopy]);

  useEffect(() => {
    setCurrentChannelCopy(currentChannel);
  }, [currentChannel, setCurrentChannelCopy]);

  return (
    <Stack
      direction="column"
      borderRadius={useDesktopLayout ? 2 : undefined}
      boxShadow={useDesktopLayout ? 2 : undefined}
      width={useDesktopLayout ? '30%' : '100%'}
      height="100%"
      id="channels-list"
    >
      <Box ml="1rem" mt=".5rem" mb=".3rem">
        <Typography variant="h5">Chats</Typography>
      </Box>
      <Divider />
      <Box id="channel-list-container" ref={channeListContainerRef}>
        <ChannelList
          sort={sortChannelEntities}
          channels={channels.map((c) => getChannelEntityFromChannel(c))}
          onChannelSwitched={(channel: ChannelEntity) => { onChannelSelected(channel.id); }}
          channelRenderer={(c: ChannelEntity) => ChannelRenderer(
            c,
            onChannelSelected,
            channelsWithNewMessagesStateCopy,
            currentChannelCopy,
          )}
        />
      </Box>
    </Stack>
  );
}
