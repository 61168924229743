import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, IconButton, Typography } from '@mui/material';

interface Props {
  id: string;
  iconButton: boolean;
  onRefresh?: VoidFunction
  hardReload?: boolean;
}

export function RefreshPageButton(props: Props) {
  const {
    id, iconButton, onRefresh, hardReload,
  } = props;

  if (iconButton) {
    return (
      <IconButton onClick={() => {
        if (onRefresh) {
          onRefresh();
          return false;
        }
        if (hardReload) {
          window.location.reload();
        }
        return false;
      }}
      >
        <RefreshIcon />
      </IconButton>
    );
  }

  return (
    <Button
      id={id}
      size="large"
      color="primary"
      variant="outlined"
      onClick={() => {
        if (onRefresh) {
          onRefresh();
          return false;
        }
        window.location.reload();
        return false;
      }}
    >
      <Typography variant="body1" sx={{ mr: '.3rem' }}>Refresh</Typography>
      <RefreshIcon />
    </Button>
  );
}

RefreshPageButton.defaultProps = {
  onRefresh: undefined,
  hardReload: true,
};
