import {
  Box, Button, Divider, Stack, TextField, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ResetPasswordFormErrorState } from '../../types';
import { PasswordToggableIconEndAdornment } from '../PasswordToggableIconEndAdornment';
import { ConfirmForgotPasswordData } from './types';

export interface Props {
  onConfirmForgotPassword: VoidFunction;
  onFormChange: (key: keyof ConfirmForgotPasswordData, value: string) => void;
  onShowPasswordChange: () => void;
  formError: ResetPasswordFormErrorState;
  disabledResetPassword: boolean;
  showPassword: boolean;
  error: string | null
  email: string | undefined;
  backToLogin: boolean;
}

export function ConfirmForgotPassword(props: Props) {
  const {
    onConfirmForgotPassword,
    onFormChange,
    onShowPasswordChange,
    formError,
    disabledResetPassword,
    showPassword,
    error,
    email,
    backToLogin,
  } = props;
  const navigate = useNavigate();
  return (
    <Box>
      <Typography textAlign="center" variant="h4" fontWeight="bold">
        Enter password reset code
      </Typography>
      <Stack my="2rem" spacing={2}>
        <TextField
          data-testid="email-input"
          id="email-input"
          label="Email"
          variant="outlined"
          defaultValue={email || ''}
          InputProps={{
            readOnly: !backToLogin,
          }}
          required
          onChange={(e) => onFormChange('email', e.target.value)}
          error={formError.email != null}
          helperText={formError.email ?? ''}
        />
        <TextField
          data-testid="code-input"
          id="code-input"
          label="Code from your email inbox"
          variant="outlined"
          autoComplete="new-password"
          required
          onChange={(e) => onFormChange('code', e.target.value)}
        />
        <TextField
          data-testid="new-password-input"
          id="new-password-input"
          label="New password"
          variant="outlined"
          autoComplete="new-password"
          required
          onChange={(e) => onFormChange('new_password', e.target.value)}
          type={showPassword ? 'text' : 'password'}
          error={formError.new_password != null}
          helperText={formError.new_password ?? ''}
          InputProps={{
            endAdornment: (<PasswordToggableIconEndAdornment
              showPassword={showPassword}
              onIconClick={onShowPasswordChange}
            />
            ),
          }}
        />
        {error != null && <Typography color="red" align="center">{error}</Typography>}
        <Button disabled={disabledResetPassword} variant="contained" data-testid="button" onClick={onConfirmForgotPassword}>
          Reset Password
        </Button>
        <Divider variant="middle" />
        {backToLogin && <Button onClick={() => navigate('/login')}>Back to login</Button>}
      </Stack>
    </Box>
  );
}
