import { Checkbox, Stack, Typography } from '@mui/material';

interface Props {
  value: ShipperEmailNotificationType;
  onChange: (newValue: ShipperEmailNotificationType) => void;
  isProject: boolean;
}

export function EmailNotificationPreferences(props: Props) {
  const {
    value, onChange, isProject,
  } = props;

  const handleEmailNotificationTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const notificationType = event.target.checked ? 'ALL' : 'MUTE';
    onChange(notificationType);
  };

  return (
    <Stack direction="row" justifyItems="left" alignItems="center">
      <Checkbox
        checked={value === 'ALL'}
        onChange={handleEmailNotificationTypeChange}
      />
      <Typography variant="body1">{`I would like to receive email notifications from events related to this ${isProject ? 'project' : 'shipment'}.`}</Typography>
    </Stack>
  );
}
