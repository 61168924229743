import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useDisclosure, useRoleContext, useSubscriptionStatus } from 'hooks';
import { formatText } from 'utils';

export function ShipperUpgradePage() {
  const { role, company } = useRoleContext();
  const { subscription } = useSubscriptionStatus();
  const {
    isOpen: showingOptimize,
    onOpen: onShowPPB,
    onClose: onShowPricingTable,
  } = useDisclosure(false);

  if (company == null || role == null || role.type == null || role.type !== 'SHIPPER' || subscription == null || !subscription?.subscription_plan) {
    return (
      <Stack alignItems="center" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  const getPricingTable = () => {
    if (!showingOptimize) {
      return (
        <stripe-pricing-table
          style={{ height: '100%' }}
          pricing-table-id={process.env.REACT_APP_SHIPPER_SHIP_PRICING_TABLE_ID}
          publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          client-reference-id={`SC${company.id}`}
        />
      );
    }
    return (
      <stripe-pricing-table
        style={{ height: '100%' }}
        pricing-table-id={process.env.REACT_APP_SHIPPER_OPTIMIZE_TABLE_ID}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
        client-reference-id={`SC${company.id}`}
      />
    );
  };

  return (
    <Box height="100%" sx={{ backgroundColor: 'white', paddingTop: '2rem', borderRadius: '1rem' }}>
      <Stack direction="column">
        {subscription != null && <Typography ml="1rem">{`Your current subscription: ${formatText(subscription.subscription_plan)}`}</Typography>}
        { subscription?.subscription_plan !== 'CARRIER_PAY_PER_BOOKING' && (
          <Stack direction="column">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" mb="1rem">
              <ButtonGroup
                disableElevation
              >
                <Button size="large" variant={showingOptimize ? 'outlined' : 'contained'} onClick={onShowPricingTable}>SHIP</Button>
                <Button size="large" variant={showingOptimize ? 'contained' : 'outlined'} onClick={onShowPPB}>OPTIMIZE</Button>
              </ButtonGroup>
            </Stack>
            {
              showingOptimize
                ? <Typography variant="h6" textAlign="center" mt="1rem" mb="1rem">Powerful analytics and advanced features</Typography>
                : <Typography variant="h6" textAlign="center" mt="1rem" mb="1rem">Everything you need for efficient shipping</Typography>
            }

            <Divider sx={{ mt: '.5rem', mb: '1rem' }} />
          </Stack>
        )}
        {getPricingTable()}
      </Stack>
      <Typography variant="body2" color="text.secondary" textAlign="center">
        {'If you wish to pay using PAD or ACH, please '}
        <a href="mailto:support@evotrux.com">contact us</a>
        .
      </Typography>
      <Typography sx={{ ml: '1rem' }}>*Tracking available based on carrier capabilities</Typography>
    </Box>
  );
}
