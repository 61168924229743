import {
  Box, Button, CircularProgress, Stack, Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  open: boolean
  onClose: VoidFunction;
  onDeleteShipmentSearch: VoidFunction;
  error: string | null;
  loading: boolean;
  shipmentSearchName: string;
}

export function DeleteShipmentSearch(props: Props) {
  const {
    open, onClose, onDeleteShipmentSearch, error, loading, shipmentSearchName,
  } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Saved Search?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this saved search?
        </DialogContentText>
        {loading
          ? <Stack alignItems="center"><CircularProgress /></Stack>
          : (
            <Box mt="1rem" mb="1rem" textAlign="center">
              <em>{shipmentSearchName}</em>
            </Box>
          )}
        {error != null && !loading && <Typography color="red" align="center">{error}</Typography>}
      </DialogContent>
      {!loading && (
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Go Back
        </Button>
        <Button onClick={onDeleteShipmentSearch}>
          {!error ? 'Delete' : 'retry'}
        </Button>
      </DialogActions>
      )}
    </Dialog>
  );
}
