export function getShipmentDetailTableTitle(
  shipment: CondensedShipment,
  progress: ShipmentProgress,
) {
  if (shipment.is_private) {
    return `${progress.notified_network_carrier_count} Partners Notified • ${progress.view_count} Views • ${progress.quote_count} Quotes`;
  }
  if (progress.lane_match_count === 0) {
    return `Matches Loading.. • ${progress.notified_network_carrier_count} Partners Notified • ${progress.view_count} Views • ${progress.quote_count} Quotes`;
  }
  return `${progress.lane_match_count} Matches • ${progress.notified_network_carrier_count} Partners Notified • ${progress.view_count} Views • ${progress.quote_count} Quotes`;
}
