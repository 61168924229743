import { useDisclosure } from 'hooks';
import { getPageCount, SHIPMENTS_PAGE_SIZE, sortShipmentsByDate } from 'utils';
import { ShipmentsList } from '../ShipmentList/ShipmentsList';
import { ShipmentsFilter } from '../ShipmentsFilter';

interface Props {
  page: number;
  setPage: (pageNumber: number) => void;
  shipments: CondensedShipment[];
  shipmentsError: string | null;
  shipmentsLoading: boolean;
  shipmentCount: number;
}

export function ShipmentListContainer(props: Props) {
  const {
    page,
    setPage,
    shipments,
    shipmentsError,
    shipmentsLoading,
    shipmentCount,
  } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const pages = getPageCount(shipmentCount, SHIPMENTS_PAGE_SIZE);

  const handlePageChange = (e: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  return (
    <>
      <ShipmentsFilter
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        shipmentCount={shipmentCount}
      />
      <ShipmentsList
        shipments={sortShipmentsByDate(
          shipments as ShipperCondensedShipment[],
        )}
        error={shipmentsError}
        isLoading={shipmentsLoading}
        page={page}
        pages={pages}
        handlePageSelected={handlePageChange}
      />
    </>
  );
}
