// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { TAWK_TO_PROPERTY_ID, TAWK_TO_WIDGET_ID } from '../../constants';

export function UnauthenticatedTawkToWrapper() {
  return (
    <div>
      <TawkMessengerReact
        propertyId={TAWK_TO_PROPERTY_ID}
        widgetId={TAWK_TO_WIDGET_ID}
      />
    </div>
  );
}
