import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import {
  IconButton, Tooltip, Zoom,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRoleContext } from '../../hooks';

export function CompanyProfileButton() {
  const navigate = useNavigate();
  const { role } = useRoleContext();

  if (role) {
    return (
      <Tooltip title="Company Profile" placement="right" TransitionComponent={Zoom}>
        <IconButton color="inherit" onClick={() => navigate(`/companies/${role.company?.id}/profile`)}>
          <SupervisedUserCircleIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <div />
  );
}
