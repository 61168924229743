import { Typography } from '@mui/material';
import { MessageEnvelope, isFilePayload } from '@pubnub/react-chat-components';

interface Props {
  userIsAuthor: boolean;
  message: MessageEnvelope;
}

export function MessageTextDisplay(props: Props) {
  const { userIsAuthor, message } = props;
  if (isFilePayload(message.message)) {
    return <div />;
  }

  return (
    <Typography
      variant="body1"
      textAlign={userIsAuthor ? 'right' : 'left'}
    >
      {message.message.text ?? ''}
    </Typography>
  );
}
