import { Stack, Typography } from '@mui/material';
import { ISOStringToLocalReadableTimestamp } from 'utils';

interface Props {
  cancellationInfo: ShipmentCancellationFeedback
}

export function ShipmentCancellationReasonDisplay(props: Props) {
  const { cancellationInfo } = props;
  return (
    <Stack direction="column">
      <Typography variant="h6" sx={{ mt: '1rem' }}>
        <b>{'Reason: '}</b>
        {cancellationInfo.feedback}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
      >
        {`Added ${ISOStringToLocalReadableTimestamp(cancellationInfo.created_at)}`}
      </Typography>
    </Stack>
  );
}
