import { useMemo, useState } from 'react';
import { useRoleContext } from './useRoleContext';
import { useShipmentFiltersContext } from './useShipmentFiltersContext';

export function useShipmentsUrl() {
  const [url, setUrl] = useState<string | null>(null);
  const { role } = useRoleContext();
  const { filters } = useShipmentFiltersContext();

  useMemo(() => {
    if (role != null) {
      if (role.type === 'SHIPPER') {
        setUrl(`/shippers/${role.id}/shipments/condensed`);
      } else if (role.type === 'CARRIER') {
        if (filters?.shipmentVisibility && filters.shipmentVisibility === 'private') {
          setUrl(`/carriers/${role.id}/shipments/private/condensed`);
        } else if (filters?.shipmentRelationship && filters.shipmentRelationship === 'partner') {
          setUrl(`/carriers/${role.id}/shipments/partner`);
        } else {
          setUrl('/shipments/condensed');
        }
      }
    }
  }, [role, filters?.shipmentVisibility, filters?.shipmentRelationship]);
  return { url };
}
