import { Stack } from '@mui/material';

interface Props {
  chatSidebar: React.ReactNode;
  chatConversation: React.ReactNode;
}

export function DesktopChatPage(props: Props) {
  const { chatSidebar, chatConversation } = props;
  return (
    <Stack direction="row" spacing={2}>
      {chatSidebar}
      {chatConversation}
    </Stack>
  );
}
