import {
  Button, Modal, Stack, Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useRoleContext, useShipperShipmentPageContext, useWriterComponentState } from 'hooks';

const MODAL_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  p: 4,
};

interface Props {
  onModalClose: VoidFunction;
  modalOpen: boolean
}

export function FinalizeProjectModal(props: Props) {
  const { shipment, onRefresh } = useShipperShipmentPageContext();
  const {
    modalOpen, onModalClose,
  } = props;
  const { role } = useRoleContext();

  const {
    loading, write, error,
  } = useWriterComponentState({
    errorMessage: 'Failed to close project. Please contact us if the issue persists',
    writeConfig: {
      method: 'post',
      url: shipment?.info?.id && role ? `/shippers/${role.id}/projects/${shipment.info.id}/finalize` : '/',
    },
  });

  const submit = () => {
    write(() => {
      if (onRefresh) {
        onRefresh();
      }
      onModalClose();
    });
  };

  return (
    <Modal open={modalOpen} onClose={onModalClose}>
      <Stack sx={MODAL_STYLE} direction="column" spacing={2}>
        <Typography variant="h6">Close Project</Typography>
        <Typography>
          Closing your project will cause the project to stop receiving quotes. All
          {' '}
          <b>{shipment?.project?.loads_booked}</b>
          {' '}
          {' '}
          shipments assigned will remain active for fulfillment.
          Your project will still be visible in the shipper dashboard.
        </Typography>
        <LoadingWrappedNode loading={loading}>
          <Stack alignItems="center" justifyContent="center" spacing={3} mt="1rem" direction="row">
            <Button variant="outlined" onClick={onModalClose}>Cancel</Button>
            <Button variant="contained" color="warning" onClick={submit}>
              Close Project
            </Button>
          </Stack>
        </LoadingWrappedNode>

        <ErrorTypography error={error} />
      </Stack>
    </Modal>
  );
}
