import ReplyIcon from '@mui/icons-material/Reply';
import {
  Box, Button, Divider, Stack, Typography,
} from '@mui/material';
import { useDisclosure, useRoleContext } from 'hooks';
import { SubmitAnswerMenu } from '../SubmitAnswerMenu';

interface Props {
  qa: QuoteQA;
}

export function QuoteQADisplay(props: Props) {
  const { qa } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { role } = useRoleContext();

  const getReplyButton = () => {
    // Only render reply button if user is a carrier AND question has not been answered already
    if (role && role.type === 'CARRIER' && qa.answer == null) {
      return (
        <Box sx={{ ml: '1rem' }}>
          { isOpen ? <Button onClick={onClose}>Cancel</Button>
            : <Button size="small" variant="contained" onClick={onOpen} startIcon={<ReplyIcon />}>Reply</Button> }
        </Box>
      );
    }
    return <div />;
  };

  const getAnswer = () => (qa.answer != null ? (
    <Stack direction="row" alignContent="flex-start" ml=".5rem" alignItems="flex-end">
      <Box sx={{ border: '1px solid #8d8d8dad', height: '2rem', mr: '.2rem' }} />
      <Stack direction="column">

        <Typography variant="body1">
          <b>A: </b>
          {qa?.answer?.text}
        </Typography>
      </Stack>
    </Stack>
  ) : null);

  return (
    <Stack direction="column">
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Typography variant="body1">
          <b> Q: </b>
          {qa?.question?.text ?? '-'}
        </Typography>
        <>
          {getReplyButton()}
        </>
      </Stack>
      {getAnswer()}
      {isOpen && <SubmitAnswerMenu postQuestionURL={`/quoteQAs/${qa.question.id}/answers`} /> }
      <Divider sx={{ mt: '1rem' }} />
    </Stack>
  );
}
