import { Box } from '@mui/material';
import { useDisclosure } from '../../../../hooks';
import { DeleteShipmentSearch } from '../DeleteShipmentSearch';
import { ShipmentSearch } from './ShipmentSearch';

interface Props{
  shipmentSearch: ShipmentSearch;
  onSavedSearchClick: VoidFunction;
  reloadSearches: VoidFunction;
}

export function ShipmentSearchContainer(props: Props) {
  const {
    shipmentSearch, onSavedSearchClick, reloadSearches,
  } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box>
      <ShipmentSearch
        shipmentSearch={shipmentSearch}
        onOpenDeleteShipmentSearch={onOpen}
        onClick={onSavedSearchClick}
      />
      <DeleteShipmentSearch
        open={isOpen}
        onClose={onClose}
        shipmentSearch={shipmentSearch}
        reloadSearches={reloadSearches}
      />

    </Box>
  );
}
