import { Button, Stack } from '@mui/material';
import { useCarrierShipmentPageContext, useDisclosure } from 'hooks';
import { BookNowConfirmationModal } from '../BookNowConfirmationModal';

interface Props {
  shipment: CondensedShipment | undefined;

}

export function BookNowButton(props: Props) {
  const { shipment } = props;
  const {
    isOpen: bookNowOpen,
    onClose: onCloseBookNow,
    onOpen: onOpenBookNow,
  } = useDisclosure(false);
  const { onRefresh } = useCarrierShipmentPageContext();

  if (shipment === undefined || shipment.shipment_status === 'BOOKED') {
    return <div />;
  }
  return (
    <Stack direction="row" spacing="1" sx={{ mt: '1rem' }}>
      <BookNowConfirmationModal
        shipment={shipment}
        visible={bookNowOpen}
        onClose={onCloseBookNow}
        onBook={() => {
          onCloseBookNow();
          if (onRefresh) {
            onRefresh();
            // hack to refetch shipment and not re-render the book now button  after a booking.
            setTimeout(onRefresh, 3000);
          }
        }}
      />
      <Button onClick={onOpenBookNow} variant="contained" color="success">
        {`BOOK NOW $${shipment.book_now_info.book_now_price} ${shipment.book_now_info.book_now_currency}`}
      </Button>
    </Stack>

  );
}
