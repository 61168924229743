import { useAPI } from 'hooks';
import { useEffect, useState } from 'react';
import { EstablishedNetwork } from './EstablishedNetwork';

interface Props {
  role: Role;
}

export function EstablishedNetworkContainer(props: Props) {
  const { role } = props;
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { api } = useAPI();
  const [followed, setFollowed] = useState<FollowedShipper[]>([]);

  useEffect(() => {
    if (loading && role?.company) {
      api<FollowedShipper[]>('get', `/carriers/${role.id}/followed_shippers/following`).then((response) => {
        setFollowed(response.data);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch followed shippers.');
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [api, role, loading]);

  const unfollowShipper = (shipper: FollowedShipper) => {
    if (role != null) {
      api<FollowedShipper[]>('post', `/carriers/${role.id}/followed_shippers/${shipper.id}/unfollow`, {}).then(() => {
        setLoading(true);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to unfollow shipper');
      });
    }
  };

  return (
    <EstablishedNetwork
      error={error}
      loading={loading}
      followed={followed}
      onUnfollowShipperClicked={unfollowShipper}
    />
  );
}
