import { Stack, Typography } from '@mui/material';
import { DashboardBackButton } from 'components/Shipment/components/DashboardBackButton';
import { QuickShipmentsBrowser as ShipperQuickShipmentsBrowser } from 'components/Shipper/components/QuickShipmentsBrowser';

interface Props {
  shipments: ShipperCondensedShipment[];
  shipmentSelectionIsDisabled: boolean;
  handlePageSelected: (e: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  pages: number;
  shipmentCount: number;
  showDashboardBackButton?: boolean;
}

export function ShipmentSideBar(props: Props) {
  const {
    shipments,
    shipmentSelectionIsDisabled,
    handlePageSelected,
    page,
    pages,
    shipmentCount,
    showDashboardBackButton,
  } = props;
  return (
    <Stack direction="column">
      {showDashboardBackButton && (<DashboardBackButton />)}
      <Typography variant="h6" mb="0.5rem">
        My Active Shipments (
        {shipmentCount ?? '-'}
        )
      </Typography>
      <ShipperQuickShipmentsBrowser
        shipments={shipments}
        shipmentSelectionIsDisabled={shipmentSelectionIsDisabled}
        page={page}
        pages={pages}
        handlePageSelected={handlePageSelected}
      />
    </Stack>
  );
}

ShipmentSideBar.defaultProps = {
  showDashboardBackButton: true,
};
