import { useShipmentFiltersContext } from 'hooks/useShipmentFiltersContext';
import { useEffect, useState } from 'react';
import { ShipmentsFilter } from './ShipmentsFilter';
import {
  INITIAL_FILTER_STATE,
} from './constants';

interface Props {
  title: string;
  readFromContext: boolean;
  resetReadFromContext: VoidFunction;
  shipmentCount: number;
  onShipmentSearchCreated: VoidFunction;
}

export function ShipmentsFilterContainer(props: Props) {
  const {
    title,
    readFromContext,
    resetReadFromContext,
    shipmentCount,
    onShipmentSearchCreated,
  } = props;
  const [filterState, setFilterState] = useState<ShipmentsFilterState>(INITIAL_FILTER_STATE);

  const {
    updateOrigin,
    updateDestination,
    updateEquipmentType,
    updateServiceType,
    updateShipmentRelation,
    filters,
  } = useShipmentFiltersContext();

  const handleValueChange = (key: keyof ShipmentsFilterState, value: string | string[]): void => {
    if (key === 'equipmentTypes') {
      setFilterState((prev) => ({
        ...prev,
        equipmentTypes: value as string[],
      }));
    } else {
      setFilterState((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const handleLocationChange = (
    key: keyof Location,
    addressKey: keyof ShipmentLocationFilter,
    value: string[],
  ): void => {
    if (key === 'origin') {
      updateOrigin(addressKey, value);
    } else if (key === 'destination') {
      updateDestination(addressKey, value);
    }
    setFilterState((prev: any) => ({
      ...prev,
      [key]: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
        ...filterState[key],
        [addressKey]: value,
      },
    }));
  };

  const handleEquipmentTypeChange = (value: string[]): void => {
    updateEquipmentType(value);
    handleValueChange('equipmentTypes', value);
  };

  const handleServiceTypeChange = (value: string[]): void => {
    updateServiceType(value);
    handleValueChange('services', value);
  };

  const handleShipmentRelationChange = (newRelation: ShipmentRelation) => {
    handleValueChange('shipmentRelation', newRelation);
    updateShipmentRelation(newRelation);
  };

  useEffect(() => {
    if (readFromContext && resetReadFromContext !== undefined) {
      const savedFilterState: ShipmentsFilterState = {
        equipmentTypes: filters?.equipment_types ? filters.equipment_types : [],
        services: filters?.services ? filters.services : [],
        commodity: filters?.commodity ? filters.commodity : null,
        shipmentStatus: filters?.status ? filters.status : 'ALL',
        origin: {
          provinces: filters?.origin?.provinces ? filters.origin.provinces : [],
          countries: filters?.origin?.countries ? filters.origin.countries : [],
        },
        destination: {
          provinces: filters?.destination?.provinces ? filters.destination.provinces : [],
          countries: filters?.destination?.countries ? filters.destination.countries : [],
        },
        orderBy: 'created_at',
        sortOrder: 'DESC',
        shipmentVisibility: 'public',
        shipmentRelation: 'public', // update here o set to partner if account is new and has network connections
        shipmentName: filters?.shipmentName ? filters.shipmentName : '',
        shipmentTypes: filters?.shipmentTypes ? filters.shipmentTypes : [],
        includeBranchShipments: filters?.includeBranchShipments ?? false,
        includeCompanyShipments: filters?.includeCompanyShipments ?? false,
        branches: filters?.branches ?? [],
        shippers: filters?.shippers ?? [],
      };
      setFilterState(savedFilterState);
      resetReadFromContext();
    }
  }, [readFromContext, filters, resetReadFromContext]);

  const refetchSearch = () => {
    // dirty hack to refetch values by feeding back the current filter state values to the context,
    // which causes the shipment fetching component to react by refetching shipments
    if (filters) {
      updateServiceType(filters.services ?? []);
    }
  };

  return (
    <ShipmentsFilter
      title={title}
      filters={filterState}
      onLocationChanged={handleLocationChange}
      onEquipmentTypeChanged={handleEquipmentTypeChange}
      onServiceTypeChanged={handleServiceTypeChange}
      shipmentCount={shipmentCount}
      onShipmentSearchCreated={onShipmentSearchCreated}
      refetchSearch={refetchSearch}
      onShipmentRelationChange={handleShipmentRelationChange}
    />
  );
}
