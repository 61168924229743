import { useCallback, useState } from 'react';
import { useAPI } from './useAPI';
import { useError } from './useError';
import { useLoading } from './useLoading';

interface FetcheConfig {
  url: string;
}

interface Props {
  onFetchFailure?: (e: Error) => void;
  onFetchFinally?: VoidFunction;
  errorMessage: string;
  fetchConfig: FetcheConfig;
  errorMessageKey?: string; // on error, use this key to get the error message from the response.
  // If no message is available, the supplied errorMessage will be used.
}

export function useFetchingComponentState<T>(props: Props) {
  const {
    onFetchFailure,
    onFetchFinally,
    errorMessage,
    fetchConfig,
    errorMessageKey,
  } = props;
  const { api } = useAPI();
  const { error, setError } = useError();
  const { loading, setLoading } = useLoading(false);
  const [success, setSuccess] = useState<boolean>(false);

  const resetState = () => {
    setError(null);
    setLoading(false);
    setSuccess(false);
  };

  const fetch = useCallback((fetchSucessCallback: (fetchedValue: T) => void) => {
    if (!loading) {
      setError(null);
      setLoading(true);
      api<T>('get', fetchConfig.url).then((response) => {
        setSuccess(true);
        setError(null);
        fetchSucessCallback(response.data);
      }).catch((e) => {
        if (errorMessageKey) {
          setError(e?.response?.data?.[errorMessageKey] || errorMessage);
        } else {
          setError(errorMessage);
        }
        setSuccess(false);
        if (onFetchFailure) {
          onFetchFailure(e);
        }
      }).finally(() => {
        setLoading(false);
        if (onFetchFinally) {
          onFetchFinally();
        }
      });
    }
  }, [api,
    errorMessage,
    fetchConfig.url,
    loading,
    onFetchFailure,
    onFetchFinally,
    setError,
    setLoading,
    errorMessageKey,
  ]);

  return {
    loading, error, success, fetch, resetState,
  };
}

useFetchingComponentState.defaultProps = {
  onWriteFailure: undefined,
  onWriteFinally: undefined,
};
