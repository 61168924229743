import { Typography } from '@mui/material';
import { useRoleContext } from '../../hooks';
import { Carrier } from '../Carrier';
import { Shipper } from '../Shipper';

export function ContentSwitcher() {
  const { role } = useRoleContext();

  if (role == null) {
    return <div />;
  }

  switch (role.type) {
    case 'SHIPPER':
      return <Shipper />;
    case 'CARRIER':
      return <Carrier />;
    default:
      return <Typography variant="h1">Invalid role. Please contact us for assistance.</Typography>;
  }
}
