import { useMemo, useState } from 'react';
import { useRoleContext } from './useRoleContext';

interface Props {
  shipmentId: number;
}

export function useGetShipmentUrl(props: Props) {
  const { shipmentId } = props;
  const [url, setUrl] = useState<string | null>(null);
  const { role } = useRoleContext();
  useMemo(() => {
    if (role != null) {
      if (role.type === 'SHIPPER') {
        setUrl(`/shippers/${role.id}/shipments/${shipmentId}/condensed`);
      } else if (role.type === 'CARRIER') {
        setUrl(`/shipments/${shipmentId}/condensed`);
      }
    }
  }, [role, shipmentId]);
  return { url };
}
