import { useMediaQuery } from '@mui/material';
import { ShipperUpgradeNowButton } from 'components/Shipper/components/ShipperUpgradeNowButton/ShipperUpgradeNowButton';
import { BannerStack } from '../BannerStack';
import { CompanyProfileBanner } from '../CompanyProfileBanner';
import { FreeTierBannerText } from './components';

interface Props {
  showNavBar: boolean;
  navBar: React.ReactNode;
}

export function FreeTierShipperBanner(props: Props) {
  const { showNavBar, navBar } = props;
  const showText = useMediaQuery('(min-width:900px)');

  return (
    <BannerStack
      borderRadius={3}
      showNavBar={showNavBar}
      navBar={navBar}
    >
      <CompanyProfileBanner />
      {showText && <FreeTierBannerText />}
      <ShipperUpgradeNowButton buttonSize="large" />
    </BannerStack>
  );
}
