import {
  Chip, Tooltip,
  Zoom,
} from '@mui/material';

interface Props {
  shipment: ShipperCondensedShipment;
}

export function ShipmentAuthorChip(props: Props) {
  const { shipment } = props;
  if (!shipment?.shipment_creator?.first_name
    || shipment.shipment_creator.first_name.length < 1
    || shipment.shipment_creator.last_name.length < 1) {
    return <div />;
  }

  const chip = (
    <Chip
      label={`${shipment.shipment_creator.first_name[0]}.${shipment.shipment_creator.last_name[0]}.`}
      size="medium"
      color="primary"
      variant="outlined"
    />
  );

  if (shipment?.info?.branch && shipment.info.branch?.name) {
    return (
      <Tooltip title={`${shipment?.shipment_creator?.first_name || ''} ${shipment?.shipment_creator?.last_name || ''}, ${shipment.info.branch.name}`} placement="right" TransitionComponent={Zoom}>
        {chip}
      </Tooltip>

    );
  }
  return chip;
}
