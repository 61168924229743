import { AccountCircle } from '@mui/icons-material';
import {
  IconButton, Tooltip, Zoom,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function UserAccountButton() {
  const navigate = useNavigate();

  return (
    <Tooltip title="Account" placement="right" TransitionComponent={Zoom}>
      <IconButton color="inherit" onClick={() => navigate('/account')}>
        <AccountCircle fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}
