import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Typography } from '@mui/material';
import { getCurrencyName } from '../../utils';
import { IconText } from './IconText';

interface Props {
  price: number | null;
  currency: number | string | null;
}

export function ShipmentPriceText(props: Props) {
  const { price, currency } = props;

  if (price == null) {
    return null;
  }

  const getCurrencyDisplayLabel = () => {
    if (!currency) {
      return '-';
    }
    return getCurrencyName(currency as number);
  };

  return (
    <>
      <IconText
        icon={<AttachMoneyIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
        text={(
          <Typography>
            {parseFloat(price.toString()).toFixed(2)}
          </Typography>
  )}
      />
      <IconText
        icon={<CurrencyExchangeIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
        text={(
          <Typography>
            {getCurrencyDisplayLabel()}
          </Typography>
)}
      />
    </>
  );
}
