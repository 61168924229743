import { useCallback, useState } from 'react';
import { AddressAutocomplete } from '../AddressAutocomplete';
import { AddressAutocompleteForm } from './AddressAutocompleteForm';

interface Props {
  onValueChanged: (key: keyof CompanyAddress, value: string) => void;
  useExactAddresses?: boolean;
}

export function AddressAutocompleteFormContainer(props: Props) {
  const {
    onValueChanged,
    useExactAddresses,
  } = props;
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [province, setProvince] = useState<string>('');
  const [postal, setPostal] = useState<string>('');
  const [type, setType] = useState<string>('');

  /**
   * Extract the address component based on the component type from all address components.
   *
   * @param addressComponents - A list of GeocoderAddressComponents
   * @param componentType - The component type to extract within addressComponents
   * @return void
   */
  const getAddressComponentValue = useCallback((
    addressComponents: google.maps.GeocoderAddressComponent[] | undefined,
    componentType: string,
  ) => {
    const addressComponent = addressComponents?.find(
      (component: google.maps.GeocoderAddressComponent) => component.types.includes(componentType),
    );
    if (addressComponent) {
      return addressComponent.long_name;
    }
    return '';
  }, []);

  /**
   * Extract the city address component (which could be found under different component types.)
   * `locality` has higher authority than `sublocality_level_1`.
   *
   * @param addressComponents - A list of GeocoderAddressComponents
   */
  const getAddressCityComponentValue = useCallback((
    addressComponents: google.maps.GeocoderAddressComponent[] | undefined,
  ) => {
    let cityComponent = getAddressComponentValue(addressComponents, 'locality');
    // Certain regions, like Brooklyn, NY for example, can't be found in the locality.
    if (!cityComponent) {
      cityComponent = getAddressComponentValue(addressComponents, 'sublocality_level_1');
    }
    return cityComponent;
  }, [getAddressComponentValue]);

  /**
   * This function is responsible for parsing the address components from the PlaceResult
   * into the respective address parts and then setting those states in this container
   * and letting the parent that passed `onValueChanged` choose how to handle each address
   * component that was updated.
   *
   * @param placeDetails - The selected PlaceResult from the google places API
   * @returns void
   */
  const selectAddress = useCallback((placeDetails: google.maps.places.PlaceResult | null) => {
    const addressComponents = placeDetails?.address_components;
    const routeNumber = getAddressComponentValue(addressComponents, 'street_number');
    const routeName = getAddressComponentValue(addressComponents, 'route');
    const cityName = getAddressCityComponentValue(addressComponents);
    const countryName = getAddressComponentValue(addressComponents, 'country');
    const regionName = getAddressComponentValue(addressComponents, 'administrative_area_level_1');
    const postalCode = getAddressComponentValue(addressComponents, 'postal_code');

    // Set the state values so that the form can display the correct values.
    setCity(cityName);
    setCountry(countryName);
    setProvince(regionName);
    setPostal(postalCode);
    // Call the handler so we can inform the parent what the address components are.
    onValueChanged('address', `${routeNumber} ${routeName}`);
    onValueChanged('city', cityName);
    onValueChanged('postal', postalCode);
    onValueChanged('province', regionName);
    onValueChanged('country', countryName);
  }, [getAddressComponentValue, getAddressCityComponentValue, onValueChanged]);

  /**
   * Update the respective address type in this container's state and let the parent
   * that passed `onValueChanged` choose how to handle the address component that was updated.
   *
   * @param addressType - A CompanyAddress key
   * @param value - The address value
   * @return void
   */
  const onUpdateAddressValue = (addressType: keyof CompanyAddress, value: string) => {
    if (addressType === 'city') {
      setCity(value);
    } else if (addressType === 'postal') {
      setPostal(value);
    } else if (addressType === 'province') {
      setProvince(value);
    } else if (addressType === 'country') {
      setCountry(value);
    } else if (addressType === 'type') {
      setType(value);
    }
    onValueChanged(addressType, value);
  };

  return (
    <AddressAutocompleteForm
      addressAutocomplete={(
        <AddressAutocomplete
          onAddressSelected={selectAddress}
          useAddressLineValue
          addressTypes={useExactAddresses ? ['address'] : []}
          isRequired
        />
      )}
      onUpdateAddressValue={onUpdateAddressValue}
      city={city}
      country={country}
      province={province}
      postal={postal}
      addressType={type}
    />
  );
}

AddressAutocompleteFormContainer.defaultProps = {
  useExactAddresses: true,
};
