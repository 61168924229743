import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { QUOTING_MUTLI_SHIPMENT_PROJECTS_BLOG_URL, SHIPMENT_TRACKING_LINK_BLOG_URL } from '../../../../constants';
import { CurrencySelect } from '../../../CurrencySelect';
import { EquipmentTypeSelect } from '../../../EquipmentTypeSelect';
import { NumberInput } from '../../../NumberInput';
import { SubmitMultiShipmentProjectQuoteFormState } from './types';

interface Props{
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  onValueChanged: <T,>(key: keyof SubmitMultiShipmentProjectQuoteFormState, value: T) => void;
  onSubmit: VoidFunction;
  error: string | null;
  loading: boolean;
  formState: SubmitMultiShipmentProjectQuoteFormState;
  quoteRefetchLoading: boolean;
  canSubmitQuote: boolean;
  formValidationError: UIError
}

export function MultiShipmentSubmitQuoteForm(props: Props) {
  const {
    onValueChanged,
    onSubmit,
    error,
    loading,
    formState,
    quoteRefetchLoading,
    canSubmitQuote,
    formValidationError,
  } = props;
  const onEquipmentTypeChanged = (newValue: string) => {
    onValueChanged<string>('equipmentType', newValue);
  };
  const onCurrencyChanged = (newValue: string) => {
    onValueChanged<string>('currency', newValue);
  };

  const bigScreen = !useMediaQuery('(max-width:750px)');

  if (!canSubmitQuote) {
    return (
      <Box pt="1rem">
        <Typography variant="body1">You already have an active quote on this shipment. To submit a new quote, cancel the existing quote.</Typography>
      </Box>
    );
  }

  if (quoteRefetchLoading) {
    return (
      <Stack direction="row" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Box pt="1rem">
      <Alert severity="info">
        {'This is a multi shipment project. To learn more about quoting multi shipment projects, click '}
        <a
          href={QUOTING_MUTLI_SHIPMENT_PROJECTS_BLOG_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          here.
        </a>
      </Alert>
      <Stack
        spacing={2}
        flexWrap="wrap"
        direction={bigScreen ? 'row' : 'column'}
        justifyContent="flex-start"
        mt="1.2rem"
      >
        <NumberInput
          inputLabel="Price"
          defaultValue={formState.price}
          onChange={(val: string) => onValueChanged<string>('price', val)}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
        <CurrencySelect onChange={onCurrencyChanged} defaultValue="CAD" />
        <TextField
          id="datetime-local"
          label="First Pickup ETA"
          type="date"
          value={formState.pickupStart}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => onValueChanged<string>('pickupStart', e.target.value)}
          required
        />
        <TextField
          id="datetime-local"
          label="Last Pickup ETA"
          type="date"
          value={formState.pickupEnd}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => onValueChanged<string>('pickupEnd', e.target.value)}
          required
        />
      </Stack>
      <Box mt=".3rem">
        <Typography variant="body2">
          Quotes are all-in
          {' '}
          <b><u>PER LOAD</u></b>
          {' '}
          (exclusive of tax) unless you explicitly state otherwise.
        </Typography>
      </Box>
      <Stack direction="row" spacing={2} mt="1rem" alignItems="center">
        <Tooltip title={
          <Typography variant="h5">The minimum and maximum amount of loads your quote is valid for. Minimum can be equal to maximum.</Typography>
        }
        >
          <Typography variant="body1">Quote Valid For:</Typography>
        </Tooltip>
        <NumberInput
          inputLabel="Minimum Loads"
          defaultValue={formState.minimumLoads}
          onChange={(val: string) => onValueChanged<string>('minimumLoads', val)}
          startAdornment={<InputAdornment position="start">#</InputAdornment>}
        />
        <NumberInput
          inputLabel="Maximum Loads"
          defaultValue={formState.maxLoads}
          onChange={(val: string) => onValueChanged<string>('maxLoads', val)}
          startAdornment={<InputAdornment position="start">#</InputAdornment>}
        />
      </Stack>
      <Box mt="1rem" width="100%">
        <EquipmentTypeSelect onChange={onEquipmentTypeChanged} value={formState.equipmentType} />
      </Box>
      <Box mt="1rem">
        <TextField
          sx={{ width: '100%' }}
          onChange={(e) => onValueChanged<string>('notes', e.target.value)}
          label="Notes (Optional)"
          value={formState.notes}
        />
      </Box>
      <Stack direction="row" mt="1rem" alignItems="center">
        <Checkbox
          checked={formState.trackingLinkOffered}
          onChange={
  (event: React.ChangeEvent<HTMLInputElement>) => onValueChanged<boolean>('trackingLinkOffered', event.target.checked)
      }
        />
        <span>
          {'I can provide a tracking link for this shipment. '}
          <a
            href={SHIPMENT_TRACKING_LINK_BLOG_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            What are tracking links?
          </a>
        </span>
      </Stack>
      <Stack direction="column" spacing={1}>
        <ErrorTypography error={formValidationError} />
        <ErrorTypography error={error} />
      </Stack>
      <Stack sx={{ mt: '1.5rem' }}>
        {!loading ? (
          <Stack direction="row" spacing={2}>
            <Button
              sx={{ p: '1rem' }}
              onClick={onSubmit}
              variant="contained"
            >
              Submit Quote
            </Button>
          </Stack>
        ) : <Stack alignItems="center"><CircularProgress /></Stack>}
      </Stack>
    </Box>
  );
}
