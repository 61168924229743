import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { AddressAutocomplete } from '../../../../../AddressAutocomplete';
import { TimeInput } from '../../../../../TimeInput';
import { AddStopButton } from '../AddStopButton';
import { DisplayableRoutePoint } from './types';

interface Props {
  values: Partial<RoutePoint>;
  error: string | null;
  onValueChange: <T>(key: keyof DisplayableRoutePoint, value: T) => void;
  onSubmit: VoidFunction;
}

export function RoutesForm(props: Props) {
  const {
    error, values, onSubmit, onValueChange,
  } = props;

  return (
    <Stack mb="2rem" spacing={4}>
      <FormControl>
        <FormLabel>
          Operation
        </FormLabel>
        <RadioGroup
          row
          value={values.stop_operation}
          onChange={(e) => onValueChange('stop_operation', e.target.value)}
        >
          <FormControlLabel value="PICKUP" control={<Radio />} label="Pickup" />
          <FormControlLabel value="DROPOFF" control={<Radio />} label="Drop Off" />
        </RadioGroup>
      </FormControl>
      <AddressAutocomplete onChange={(value) => onValueChange('addressId', value)} isRequired />
      <TimeInput
        timeFormat="date"
        initialValue={DateTime.now().toFormat("yyyy-MM-dd'T'T")}
        label={values.stop_operation === 'PICKUP' ? 'Earliest Pickup' : 'Earliest Dropoff'}
        onChange={(value) => onValueChange('start_time', value)}
      />
      <TimeInput
        timeFormat="date"
        initialValue={DateTime.now().toFormat("yyyy-MM-dd'T'T")}
        label={values.stop_operation === 'PICKUP' ? 'Latest Pickup' : 'Latest Dropoff'}
        onChange={(value) => onValueChange('end_time', value)}
      />
      {error != null && <Typography sx={{ mt: '1rem', mb: '1rem' }} color="red">{error}</Typography>}
      <AddStopButton onClick={onSubmit} />
    </Stack>
  );
}
