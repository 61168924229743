import { Alert } from '@mui/material';
import { PaymentMethodRequiredWrapper } from 'components/PaymentMethodRequiredWrapper';
import { useCarrierShipmentPageContext } from 'hooks';
import { MultiShipmentSubmitQuoteFormAccessWrapper } from '../MultiShipmentSubmitQuoteFormAccessWrapper';
import { AddPaymentInformationLabel } from '../ShipmentOverviewExpanded/components/AddPaymentInformationLabel';
import { SubmitQuoteForm } from '../SubmitQuoteForm';

export function SubmitQuote() {
  const { shipment } = useCarrierShipmentPageContext();

  if (shipment && shipment.shipment_status === 'CANCELLED') {
    return <Alert severity="warning">This shipment has been cancelled.</Alert>;
  }

  let formComponent = <SubmitQuoteForm shipment={shipment as CarrierCondensedShipment} />;
  if (shipment && shipment?.info?.type === 'MULTI_SHIPMENT_PROJECT') {
    formComponent = (
      <MultiShipmentSubmitQuoteFormAccessWrapper />
    );
  }

  return (
    <div>
      {shipment && !shipment?.is_partner_shipment ? (
        <PaymentMethodRequiredWrapper
          enableWithSubscriptionPlan="CARRIER_PAY_PER_BOOKING"
          noPaymentMethodComponent={<AddPaymentInformationLabel />}
        >
          {formComponent}
        </PaymentMethodRequiredWrapper>
      ) : (
        <div>
          {formComponent}
        </div>
      )}
    </div>
  );
}
