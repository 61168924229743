import { subscriptionContext } from 'context';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { hasPaidCarrierSubscription, userBelongsToCompany } from 'utils';
import { clearLocalSubscription, getLocalSubscription, setLocalSubscription } from '../clientData';
import { useAPI } from './useAPI';
import { useAuthContext } from './useAuthContext';

export function useSubscriptionStatus() {
  const subscriptionCtx = useContext(subscriptionContext);
  const { isAuthenticated, user } = useAuthContext();
  const [requestMade, setRequestMade] = useState<boolean>(false);
  const { api } = useAPI();

  const setSubscription = useCallback((subscription: CondensedSubscription) => {
    subscriptionCtx.dispatch({ type: 'setSubscription', subscription });
    setLocalSubscription(subscription);
  }, [subscriptionCtx]);

  const clearSubscription = useCallback(() => {
    subscriptionCtx.dispatch({ type: 'clearSubscription' });
    clearLocalSubscription();
  }, [subscriptionCtx]);

  const fetchSubscription = useCallback(() => {
    if (user !== null && userBelongsToCompany(user) && !requestMade) {
      const companyId = user?.roles[0]?.company?.id;
      setRequestMade(true);
      api<CondensedSubscription>('get', `/subscription/condensed/company/${companyId}`).then((res) => {
        setSubscription(res.data);
      }).finally(() => {
        setRequestMade(false);
      });
    }
  }, [user, api, setSubscription, setRequestMade, requestMade]);

  /*
  useEffect runs whenever subscription in context is null
  and user is authenticated. Its task is to always mantain
  the subscription context populated.
  */
  useEffect(() => {
    if (user && isAuthenticated && subscriptionCtx.subscription == null) {
      const subscription = getLocalSubscription();
      if (subscription == null) {
        // subscription not persisted in local storage -> refetch
        fetchSubscription();
      } else {
        // subscription in local storage -> bring back to in memory sub ctx
        setSubscription(subscription);
      }
    }
  }, [
    user,
    isAuthenticated,
    fetchSubscription,
    subscriptionCtx.subscription,
    setSubscription,
  ]);

  /*
  Runs whenever user becomes unauthenticated. Clears local storage and
  and context for subscription info
   */
  useEffect(() => {
    if (user == null || !isAuthenticated) {
      clearSubscription();
    }
  }, [user, isAuthenticated, clearSubscription]);

  const isPayingCarrier = hasPaidCarrierSubscription(subscriptionCtx.subscription);

  return {
    subscription: subscriptionCtx.subscription,
    isPayingCarrier,
    clearSubscription,
  };
}
