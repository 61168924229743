import { Box, useMediaQuery } from '@mui/material';
import { PropsWithChildren } from 'react';

export function PageLayout(props: PropsWithChildren<React.ReactNode>) {
  const { children } = props;
  const bigScreen = !useMediaQuery('(max-width:1200px)');

  return (
    <Box
      id="page-layout"
      sx={{
        backgroundColor: '#fefefe',
        flex: 1,
        padding: bigScreen ? '2rem' : '.5rem',
        borderRadius: 4,
      }}
    >
      {children}
    </Box>
  );
}
