import {
  CREATE_USER_IN_BRANCH_URL,
  MOVE_USERS_TO_BRANCH_URL,
  PROMOTE_USER_TO_BRANCH_ADMIN_URL,
  REMOVE_BRANCH_ADMIN_URL,
  REMOVE_USER_FROM_BRANCH,
} from './constants';
import { ManageBranchForm } from './types';

export function userCanBePromotedToAdmin(members: UserRoleInfo[], user: UserInfo) {
  const found = members.find((member: UserRoleInfo) => member.type === 'BRANCH_ADMINISTRATOR' && member.user.id === user.id);
  return !found;
}

export function getAdminOrBranchAdminRole(roles: Role[], branchId: number) {
  const companyAdmin = roles.find((role: Role) => role.type === 'ADMINISTRATOR');
  if (companyAdmin) {
    return companyAdmin;
  }
  return roles.find((role: Role) => role.type === 'BRANCH_ADMINISTRATOR' && role.branch === branchId);
}

export function buildFormUrl(
  operation: ManageBranchForm,
  forRole: Role,
  branch: Branch,
  targetRole?: UserRoleInfo | undefined,
) {
  if (forRole.company == null) {
    return undefined;
  }
  if (operation === 'MOVE_USERS_TO_BRANCH') {
    return `${MOVE_USERS_TO_BRANCH_URL}?admin_role_id=${forRole.id}&company_id=${forRole.company.id}&branch_id=${branch.id}&branchName=${branch.name}`;
  }
  if (operation === 'CREATE_USER_IN_BRANCH') {
    return `${CREATE_USER_IN_BRANCH_URL}?role_id=${forRole.id}&company_id=${forRole.company.id}&branch_id=${branch.id}&branchName=${branch.name}`;
  }
  if (operation === 'REMOVE_USER_FROM_BRANCH' && targetRole) {
    return `${REMOVE_USER_FROM_BRANCH}?role_id=${forRole.id}&company_id=${forRole.company.id}&branch_id=${branch.id}&remove_role_id=${targetRole.id}&userName=${targetRole.user.first_name}%20${targetRole.user.last_name}&removeFrom=${branch.name}`;
  }
  if (operation === 'PROMOTE_TO_BRANCH_ADMIN' && targetRole) {
    return `${PROMOTE_USER_TO_BRANCH_ADMIN_URL}?role_id=${forRole.id}&company_id=${forRole.company.id}&branch_id=${branch.id}&promote_user_id=${targetRole.user.id}&userName=${targetRole.user.first_name}%20${targetRole.user.last_name}&branchName=${branch.name}`;
  }
  if (operation === 'REMOVE_BRANCH_ADMIN' && targetRole) {
    return `${REMOVE_BRANCH_ADMIN_URL}?role_id=${forRole.id}&company_id=${forRole.company.id}&branch_id=${branch.id}&remove_role_id=${targetRole.id}&userName=${targetRole.user.first_name}%20${targetRole.user.last_name}&branchName=${branch.name}`;
  }
  return undefined;
}
