import {
  CircularProgress, Stack, Typography,
} from '@mui/material';
import { useAuthContext } from 'hooks';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { rebuildEmailAlias } from 'utils';

export function VoluntellLoginContainer() {
  const [searchParams] = useSearchParams();
  const [canAttemptLogin, setCanAttemptLogin] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [shipment, setShipment] = useState<string | null>(null);

  const {
    login, isLoading, attemptedVoluntellLogin, loginErrorMsg,
  } = useAuthContext();

  useEffect(() => {
    const encoded = searchParams.get('encoded') ?? null;
    const emailFromParams = searchParams.get('email') ?? null;
    setCanAttemptLogin(encoded != null && emailFromParams != null);
  }, [setCanAttemptLogin, searchParams]);

  useEffect(() => {
    const readShipment = searchParams.get('shipment') ?? null;
    setShipment(readShipment);
  }, [searchParams]);

  useEffect(() => {
    if (canAttemptLogin) {
      setPassword(searchParams.get('encoded') ?? null);
      setEmail(searchParams.get('email') ?? null);
    }
  }, [canAttemptLogin, setEmail, setPassword, searchParams]);

  useEffect(() => {
    if (email != null && password != null && !attemptedVoluntellLogin) {
      const cleanedEmail = rebuildEmailAlias(email);
      login(cleanedEmail as string, password, true, shipment);
    }
  }, [email, password, attemptedVoluntellLogin, login, shipment]);

  const errorMsg = 'Login information not found. Please contact us using the chat icon on the lower right corner of your screen.';

  if (attemptedVoluntellLogin && !isLoading && loginErrorMsg != null) {
    return <Typography textAlign="center" variant="h6">{errorMsg}</Typography>;
  }

  return (

    <Stack alignItems="center">
      <Typography textAlign="center" variant="h6">Activating your account...</Typography>

      <CircularProgress />
    </Stack>
  );
}
