import { useAuthContext } from '../../hooks';
import { ContentSwitcher } from './ContentSwitcher';

export function ContentSwitcherContainer() {
  const { user, isLoading } = useAuthContext();

  if (isLoading || user == null) {
    return <div />;
  }

  return <ContentSwitcher />;
}
