import { useAPI, useDisclosure, useRoleContext } from 'hooks';
import { useShipmentFiltersContext } from 'hooks/useShipmentFiltersContext';
import { useState } from 'react';
import { ShipmentSaveSearch } from './ShipmentSaveSearch';

interface Props {
  onShipmentSearchCreated: VoidFunction;
}

export function ShipmentSaveSearchContainer(props: Props) {
  const { onShipmentSearchCreated } = props;
  const { filters } = useShipmentFiltersContext();
  const { api } = useAPI();
  const { role } = useRoleContext();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>('');
  const [searchName, setSearchName] = useState<string>('');

  const onChangeSearchName = (name: string) => {
    setSearchName(name);
  };

  const onSubmitSaveSearch = () => {
    if (role !== null) {
      setIsLoading(true);
      const body: CreateShipmentSearch = {
        name: searchName,
        equipment_types: filters?.equipment_types,
        services: filters?.services,
        origin_provinces: filters?.origin?.provinces,
        origin_countries: filters?.origin?.countries,
        destination_provinces: filters?.destination?.provinces,
        destination_countries: filters?.destination?.countries,
      };
      api<unknown>('post', `/carriers/${role.id}/lanes`, body).then(() => {
        setError(null);
        setSearchName('');
        onClose();
        onShipmentSearchCreated();
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to save search');
      }).finally(() => { setIsLoading(false); });
    }
  };

  return (
    <ShipmentSaveSearch
      saveSearchModalIsOpen={isOpen}
      searchName={searchName}
      onCloseModal={onClose}
      onOpenModal={onOpen}
      onSubmitSaveSearch={onSubmitSaveSearch}
      handleChangeSearchName={onChangeSearchName}
      submitSaveSearchIsLoading={isLoading}
      submitSaveSearchError={error}
    />
  );
}
