import { Stack } from '@mui/material';

interface Props{
  borderRadius?: number;
  children: React.ReactNode;
  showNavBar: boolean;
  navBar: React.ReactNode;
}

export function BannerStack(props: Props) {
  const {
    borderRadius, navBar, children, showNavBar,
  } = props;
  return (
    <Stack
      bgcolor="#0d2e45"
      mx="0.5rem"
      my="0.5rem"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      borderRadius={borderRadius}
    >
      {showNavBar && navBar
        ? navBar
        : children }
    </Stack>
  );
}

BannerStack.defaultProps = {
  borderRadius: 3,
};
