import { Box } from '@mui/material';

interface IconTextProps {
  icon: React.ReactNode;
  text: React.ReactNode;
}

export function IconText(props: IconTextProps) {
  const { icon, text } = props;
  return (
    <Box display="flex" alignItems="center">
      {icon}
      {text}
    </Box>
  );
}
