import { Badge } from '@mui/material';
import { useChannelAPI, useInterval } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: React.ReactNode
}

const TWO_MINUTES_IN_MILLISECONDS = 120000;

export function NewChatPollWrapper(props: Props) {
  const { children } = props;
  const [hasUnreadMessages, setHasUnreadMessages] = useState<boolean>(false);
  const { getChannelsWithUnreadMessages } = useChannelAPI();
  const location = useLocation();

  useEffect(() => {
    // if user opens up chat window then clear the badge
    if (location.pathname.startsWith('/chat')) {
      setHasUnreadMessages(false);
    }
  }, [location]);

  const unreadMessagesCallback = (unreadMesageChannels: Set<string> | undefined) => {
    if (unreadMesageChannels) {
      setHasUnreadMessages(unreadMesageChannels.size > 0);
    }
  };

  const checkUnreadMesages = useCallback(() => {
    if (!location.pathname.startsWith('/chat')) {
      getChannelsWithUnreadMessages([], unreadMessagesCallback);
    }
  }, [getChannelsWithUnreadMessages, location]);

  useInterval(checkUnreadMesages, TWO_MINUTES_IN_MILLISECONDS);

  if (!hasUnreadMessages) {
    return (
      <div>
        {children}
      </div>
    );
  }

  return (
    <Badge color="primary" badgeContent="">
      {children}
    </Badge>
  );
}
