import { Typography } from '@mui/material';
import { CARRIER_UPGRADE_RELATIVE_URL } from '../../../../constants';

export function CompanyNameUpgradeText() {
  return (
    <a target="_blank" href={CARRIER_UPGRADE_RELATIVE_URL} rel="noreferrer">
      <Typography sx={{ textDecoration: 'underline blue', color: 'blue' }}>
        Upgrade to view company name
      </Typography>
    </a>
  );
}
