import {
  Chip, TableCell, TableRow, Typography,
} from '@mui/material';
import { ISOStringToLocalReadableTimestamp } from 'utils';

interface Props {
  pendingRequest: FollowShipperRequestAndResponse,
}

export function ShipperPendingRequestTableRow(props: Props) {
  const { pendingRequest } = props;

  return (
    <TableRow
      key={pendingRequest.request.id}
    >
      <TableCell>
        {pendingRequest.request.type === 'SHIPPER_FOLLOW_REQUEST' ? (
          <Typography variant="body1">
            {pendingRequest.request.target_role.user.first_name}
            {' '}
            {pendingRequest.request.target_role.user.last_name}
          </Typography>
        ) : (
          <Typography variant="body1">
            {pendingRequest.request.target_email}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body1">{ISOStringToLocalReadableTimestamp(pendingRequest.request.created_at)}</Typography>
      </TableCell>
      <TableCell>
        <Chip color="warning" label="Pending" />
      </TableCell>
    </TableRow>
  );
}
