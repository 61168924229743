import { Typography } from '@mui/material';

interface Props {
  success: boolean,
  message: string;
}

export function SuccessTypography(props: Props) {
  const { success, message } = props;
  return (
    <div>
      {success && <Typography sx={{ mt: '1rem' }} color="green" align="center">{message}</Typography>}
    </div>
  );
}
