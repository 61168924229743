import { useAPI, useShipperShipmentPageContext } from 'hooks';
import { useState } from 'react';
import { CancelShipmentForm } from './CancelShipmentForm';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  shipment: CondensedShipment;
}

export function CancelShipmentFormContainer(props: Props) {
  const {
    open, onClose, shipment,
  } = props;
  const [error, setError] = useState<string | null>(null);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [cancellationReason, setCancellationReason] = useState<string>('');
  const { onRefresh } = useShipperShipmentPageContext();
  const { api } = useAPI();

  const postFeedback = (): void => {
    const body = { reason: cancellationReason, is_public: true };
    api<unknown>(
      'post',
      `/shipments/${shipment.info.id}/cancellation-reason`,
      body,
    ).then(() => {
      if (onRefresh) {
        onRefresh();
      }
    });
  };

  const cancelShipment = (): void => {
    setIsCancelling(true);
    api<unknown>('post', `/shipments/${shipment.info.id}/cancel`).then(() => {
      setError(null);
      onClose();
      if (cancellationReason !== '') {
        postFeedback();
      } else if (onRefresh) {
        onRefresh();
      }
    }).catch((e) => {
      if (e?.response?.data?.message) {
        setError(e?.response?.data?.message);
      } else if (e?.response.data?.detail) {
        setError(e?.response.data?.detail);
      } else {
        setError('Failed to cancel shipment');
      }
    }).finally(() => {
      setIsCancelling(false);
    });
  };

  const onModalClose = () => {
    // reset error so that the error is 'cleared' upon closing
    setError(null);
    onClose();
  };

  return (
    <CancelShipmentForm
      open={open}
      onClose={onModalClose}
      onCancelShipment={cancelShipment}
      error={error}
      loading={isCancelling}
      cancellationReason={cancellationReason}
      onCancellationUpdate={(newValue: string) => setCancellationReason(newValue)}
    />
  );
}
