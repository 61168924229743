import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Button, CircularProgress, Divider, Modal, Stack, Typography,
} from '@mui/material';
import { useAPI, useDisclosure, useRoleContext } from 'hooks';
import { useEffect, useState } from 'react';

interface ReferralMetadata {
  [key: string]: string | number;
}
interface Props {
  referralMetadata: ReferralMetadata;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  p: 4,
};

export function GetPaidFactoringButton(props: Props) {
  const { referralMetadata } = props;
  const { role } = useRoleContext();
  const { api } = useAPI();
  const { isOpen, onOpen, onClose } = useDisclosure(false);

  const [isReferred, setIsReferred] = useState<boolean>(true);
  const [loadingReferrals, setLoadingReferrals] = useState<boolean>(true);
  const [completedReferrals, setCompletedReferrals] = useState<CompletedExternalReferrals |
  null>(null);
  const [loadingPostLead, setIsLoadingPostLead] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorOnLeadPost, setErrorOnLeadPost] = useState<string | null>(null);

  useEffect(() => {
    if (role != null && loadingReferrals) {
      api<CompletedExternalReferrals>('get', `/carriers/${role.id}/external-referrals`).then((res) => {
        setCompletedReferrals(res.data);
      }).finally(() => {
        setLoadingReferrals(false);
      });
    }
  }, [role, loadingReferrals, api]);

  useEffect(() => {
    if (completedReferrals != null) {
      setIsReferred(completedReferrals.referrals.includes('JD_FACTORS'));
    }
  }, [completedReferrals]);

  const onModalConfirm = () => {
    if (role != null) {
      setIsLoadingPostLead(true);
      setErrorOnLeadPost(null);
      setSuccess(false);
      api('post', `/carriers/${role.id}/external-referrals/leads`, {
        type: 'JD_FACTORS',
        metadata: referralMetadata,
      }).then(() => {
        setSuccess(true);
        setErrorOnLeadPost(null);
      }).catch((e) => {
        setSuccess(false);
        setErrorOnLeadPost(e?.response?.data?.message || 'Failed to confirm lead');
      }).finally(() => {
        setIsLoadingPostLead(false);
      });
    }
  };

  if (isReferred) {
    return <div />;
  }
  return (
    <Box>
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={style}>
          <Stack justifyContent="flex-end" direction="row">
            <CloseIcon
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={onClose}
            />
          </Stack>
          <Typography variant="h4" component="h2">Next Day Funding with JD Factors</Typography>
          <Divider sx={{ my: '1rem' }} />
          <ul>
            <li>
              <Typography variant="h6">Credit-Guaranteed Cash Advances</Typography>
            </li>
            <li>
              <Typography variant="h6">True Non-Recourse Factoring</Typography>
            </li>
            <li>
              <Typography variant="h6">No &apos;Bad Debt&apos; Losses</Typography>
            </li>
            <li>
              <Typography variant="h6">Transparent Pricing</Typography>
            </li>
            <li>
              <Typography variant="h6">No Hidden Fees</Typography>
            </li>
          </ul>
          <Typography variant="h6">Click the button below and a representative from JD Factors will send you an email with more information.</Typography>
          <Stack direction="row" justifyContent="center" mt="1rem">
            {loadingPostLead ? <CircularProgress /> : (
              <Button
                variant="contained"
                color="success"
                onClick={onModalConfirm}
                disabled={success}
              >
                I&apos;m Interested
              </Button>
            )}
          </Stack>
          {errorOnLeadPost != null && <Typography color="red" align="center">{errorOnLeadPost}</Typography>}
          {success && <Typography sx={{ mt: '1rem' }} color="green" align="center">Thanks, someone from JD factors will contact you shortly.</Typography>}
        </Box>
      </Modal>
      <Button
        variant="contained"
        color="success"
        onClick={onOpen}
        disabled={success}
      >
        Get Paid Fast!
      </Button>
    </Box>
  );
}
