import { useEffect, useState } from 'react';
import { useFetchingComponentState } from './useFetchingComponentState';
import { useRoleContext } from './useRoleContext';

export function useCompanyMembers() {
  const { role, company } = useRoleContext();
  const [members, setMembers] = useState<CompanyMember[]>([]);
  const {
    fetch, loading, error, success,
  } = useFetchingComponentState<CompanyMember[]>({
    errorMessage: 'Failed to fetch company members',
    fetchConfig: {
      url: company != null ? `/companies/${company.id}/members` : '/',
    },
  });

  useEffect(() => {
    if (role != null && !success) {
      fetch((res) => {
        setMembers(res);
      });
    }
  }, [fetch, loading, error, success, role]);

  return {
    members,
    error,
    loading,
  };
}
