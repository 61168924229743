import { InputRenderConfig } from '../../../types';

export function listAllShippersInConfig(renderConfig: InputRenderConfig): UserRoleInfo[] {
  const shippersInBranch = renderConfig.branches.map((branch) => branch.members.filter((member) => member.type === 'SHIPPER')).flat();
  const allShippers = renderConfig.roles_without_branch.concat(shippersInBranch);
  return allShippers;
}

export function listShippersInBranchOptions(branch: BranchMembers) {
  const shipperRoles = branch.members.filter((member) => member.type === 'SHIPPER');
  return shipperRoles.map((member) => ({
    value: member.id.toString(),
    label: `${member.user.first_name} ${member.user.last_name}`,
  }));
}

export function listBranchlessShipperOptions(renderConfig: InputRenderConfig) {
  const shippersWithoutBranch = renderConfig.roles_without_branch.filter((member) => member.type === 'SHIPPER');
  return shippersWithoutBranch.map((member) => ({
    value: member.id.toString(),
    label: `${member.user.first_name} ${member.user.last_name}`,
  }));
}
