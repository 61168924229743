import { carrierSubHasLimitedBookings } from 'utils';

export const showPricingAdvisoryModal = (
  subscription: SubscriptionPlan,
  partnerShipment: boolean,
) => {
  if (partnerShipment) {
    return false;
  }
  return carrierSubHasLimitedBookings(subscription);
};
