import { Switcher } from '../components/Switcher';
import { SwitchView } from '../components/SwitchView';
import { MainLayout } from '../layouts';

export function AuthenticationContainer() {
  return (
    <MainLayout
      authComponent={<Switcher />}
      switchAuthView={<SwitchView />}
    />
  );
}
