import {
  Box, Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import * as React from 'react';
import { useDisclosure } from '../../../../hooks';
import { CompanyResultsTableRow } from '../CompanyResultsTableRow';
import { SendJoinRequestDialog } from '../SendJoinRequestDialog';

interface Props {
  companies: Company[];
  onCompanySelected: (companyID: number) => void;
  searchBegun: boolean;
}

function CompanyResultsNotFoundRow() {
  return (
    <TableRow key="no-results-1">
      <TableCell component="th" scope="row">
        <Stack justifyContent="center">
          <Box textAlign="center">
            <p>No results found</p>
          </Box>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

export function CompanyResultsTable(props: Props) {
  const { companies, onCompanySelected, searchBegun } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedCompany, setSelectedCompany] = React.useState<Company | null>(null);

  const handleSendJoinRequest = (chosenCompany: Company): void => {
    setSelectedCompany(chosenCompany);
    onOpen();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            { companies.length > 0 || !searchBegun ? companies.map((company) => (
              <CompanyResultsTableRow
                key={company.name}
                company={company}
                onCompanySelected={handleSendJoinRequest}
              />
            )) : <CompanyResultsNotFoundRow />}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedCompany != null && isOpen && (
      <SendJoinRequestDialog
        company={selectedCompany}
        onCompanySelected={onCompanySelected}
        isOpen={isOpen}
        onClose={onClose}
      />
      )}
    </>
  );
}
