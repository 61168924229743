import { Select } from 'components/Select';

interface Props {
  onShipmentTypeChange: (value: string[]) => void;
  shipmentTypes: string[];
}

const OPTIONS = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'MULTI_SHIPMENT_PROJECT',
    label: 'Project Shipments',
  },
  {
    value: 'SINGLE',
    label: 'Single Shipments',
  },
];

export function QuoteShipmentTypeFilter(props: Props) {
  const { onShipmentTypeChange, shipmentTypes } = props;

  return (
    <Select<string>
      inputLabel="Shipment Type"
      defaultValue={shipmentTypes[0] ?? 'ALL'}
      onChange={(value: string) => { onShipmentTypeChange([value]); }}
      options={OPTIONS}
    />
  );
}
