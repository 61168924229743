import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePasswordFieldToggle } from '../../../../hooks';
import { validateEmail } from '../../../../utils';
import { ResetPasswordFormErrorState } from '../../types';
import { validatePassword } from '../utils';
import { ConfirmForgotPassword } from './ConfirmForgotPassword';
import { ConfirmForgotPasswordData } from './types';

const validationFns: { [key: string]: (val: string) => string | null } = {
  email: validateEmail,
  new_password: validatePassword,
};

interface Props {
  email?: string;
  backToLogin: boolean;
  onSuccessFromAccountPage?: (success: boolean) => void;
}

export function ForgotPasswordContainer(props: Props) {
  const { email, backToLogin, onSuccessFromAccountPage } = props;
  const [error, setError] = useState<null | string>('');
  const [form, setForm] = useState<ConfirmForgotPasswordData>({
    email: email || '',
    code: '',
    new_password: '',
  });

  const [formError, setFormError] = useState<ResetPasswordFormErrorState>({
    email: null,
    new_password: null,
  });

  const { passwordIsVisible, handleShowPasswordChange } = usePasswordFieldToggle();

  const navigate = useNavigate();

  const handleFormChange = (key: keyof ConfirmForgotPasswordData, value: string) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
    if (['email', 'new_password'].includes(key)) {
      setFormError((prev) => ({
        ...prev,
        [key]: validationFns[key](value),
      }));
    }
  };

  const handleConfirmForgotPassword = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/confirm-forgot-password`, form).then(
      () => {
        setError(null);
        if (backToLogin) {
          navigate('/login');
        }
        if (onSuccessFromAccountPage != null) {
          onSuccessFromAccountPage(true);
        }
      },
    ).catch(
      (e) => {
        setError(e?.response?.data?.message || 'There was an error while resetting your password. Please contact us if the issue persists.');
      },
    );
  };

  return (
    <ConfirmForgotPassword
      onConfirmForgotPassword={handleConfirmForgotPassword}
      onFormChange={handleFormChange}
      formError={formError}
      disabledResetPassword={form.email.length === 0
        || form.code.length === 0
        || form.new_password.length === 0
        || formError.email !== null
        || formError.new_password !== null}
      showPassword={passwordIsVisible}
      onShowPasswordChange={handleShowPasswordChange}
      error={error}
      email={email}
      backToLogin={backToLogin}
    />
  );
}

ForgotPasswordContainer.defaultProps = {
  email: null,
  onSuccessFromAccountPage: null,
};
