import { clearLocalRole, getLocalRole, setLocalRole } from 'clientData';
import * as React from 'react';
import { userBelongsToCompany } from 'utils';
import { roleContext } from '../context';
import { useAuthContext } from './useAuthContext';

export function useRoleContext() {
  const roleCtx = React.useContext(roleContext);
  const { user } = useAuthContext();

  const getAnyRole = React.useCallback(() => {
    if (user != null) {
      const foundRole = user.roles.find((role: Role) => (role.type === 'CARRIER' || role.type === 'SHIPPER'));
      if (!foundRole) {
        return null;
      }
      return foundRole;
    }
    return null;
  }, [user]);

  const refreshRole = React.useCallback(() => {
    if (getAnyRole() != null) {
      roleCtx.dispatch({ type: 'setRole', role: getAnyRole() as Role });
      setLocalRole(getAnyRole() as Role);
    }
  }, [getAnyRole, roleCtx]);

  function getAdminRole(): Role | null {
    if (user != null) {
      const foundRole = user.roles.find((role: Role) => (role.type === 'ADMINISTRATOR'));
      if (foundRole) {
        return foundRole;
      }
    }
    return null;
  }

  function getBranchAdminRoles(): Role[] {
    if (user != null) {
      const foundRoles = user.roles.filter((role: Role) => (role.type === 'BRANCH_ADMINISTRATOR'));
      if (foundRoles.length > 0) {
        return foundRoles;
      }
    }
    return [];
  }

  function hasBranchAdminRole(): boolean {
    const roles = getBranchAdminRoles();
    return roles && roles.length > 0;
  }

  function hasCompanyAdminRole(): boolean {
    const admin = getAdminRole();
    return admin != null;
  }

  function getBranchAdminRole(branchId: number): Role | null {
    if (user != null) {
      const foundRole = user.roles.find((role: Role) => (role.type === 'BRANCH_ADMINISTRATOR' && role.branch === branchId));
      if (foundRole) {
        return foundRole;
      }
    }
    return null;
  }

  React.useEffect(() => {
    if (user == null) {
      roleCtx.dispatch({ type: 'clearRole' });
      clearLocalRole();
    } else if (roleCtx.role == null) {
      const localRole = getLocalRole();
      if (localRole == null) {
        if (getAnyRole() != null) {
          roleCtx.dispatch({ type: 'setRole', role: getAnyRole() as Role });
          setLocalRole(getAnyRole() as Role);
        }
        // if the ctx has no role, local storage does not have a role and
        // the user has no role then there's nothing to do.
      } else {
        roleCtx.dispatch({ type: 'setRole', role: localRole });
      }
    } else if (roleCtx.role
      && roleCtx.role.company === null
      && userBelongsToCompany(user)
    ) {
      refreshRole();
    }
  }, [user, getAnyRole, refreshRole, roleCtx]);
  return {
    role: roleCtx.role,
    company: roleCtx.company,
    getAdminRole,
    getBranchAdminRoles,
    getBranchAdminRole,
    hasBranchAdminRole,
    hasCompanyAdminRole,
  };
}
