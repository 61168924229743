import { Button, Stack, Typography } from '@mui/material';
import { Select } from 'components/Select';
import { InputRenderConfig } from '../../../types';
import { listAllShippersInConfig, listBranchlessShipperOptions, listShippersInBranchOptions } from './utils';

interface Props {
  renderConfig: InputRenderConfig,
  handleBranchIdChange: (branchId: string) => void,
  handleShipperIdChange: (shipperId: string) => void,
  branchId: number | null | undefined;
  shipperRoleId: number | null | undefined;
  adminResetFilters: VoidFunction;
}

const BRANCHLESS_ROLES_OPTION = {
  value: '-1',
  label: 'Shippers Without Branch',
};

export function CompanyAdminInputs(props: Props) {
  const {
    renderConfig,
    handleBranchIdChange,
    handleShipperIdChange,
    branchId,
    shipperRoleId,
    adminResetFilters,
  } = props;

  if (renderConfig == null || branchId === undefined || shipperRoleId === undefined) {
    return <div />;
  }

  const listBranchOptions = () => {
    const options = renderConfig.branches?.map((branch) => ({
      value: branch.branch.id.toString(),
      label: branch.branch.name,
    }));
    options.push(BRANCHLESS_ROLES_OPTION);
    return options;
  };

  const listUserOptions = () => {
    if (branchId === null || branchId === 0) {
      // show all users: both with and without branch
      const allUsersInBranches = listAllShippersInConfig(renderConfig);
      return allUsersInBranches.map((member: UserRoleInfo) => ({
        value: member.id.toString(),
        label: `${member.user.first_name} ${member.user.last_name}`,
      }));
    }

    if (branchId === -1) {
      // 'users without branch' is selected
      return listBranchlessShipperOptions(renderConfig);
    }

    // a branch is selected
    const selectedBranch = renderConfig.branches?.find((branch) => branch.branch.id === branchId);
    if (selectedBranch) {
      return listShippersInBranchOptions(selectedBranch);
    }
    return [];
  };

  return (

    <Stack direction="column" mt="1rem">
      <Stack
        id="parent"
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        flexWrap="wrap"
        alignItems="center"
      >
        <Stack width="8%" direction="column">
          <Button onClick={adminResetFilters} variant="outlined">Reset Filters</Button>
        </Stack>
        <Stack direction="row" width="90%" justifyContent="flex-start" spacing={2}>
          <Stack direction="column" width="45%">
            <Typography variant="body1" color="text.secondary">Branches</Typography>
            <Select<string>
              inputLabel=""
              defaultValue={branchId != null ? branchId.toString() : ''}
              onChange={handleBranchIdChange}
              value={branchId != null ? branchId.toString() : ''}
              options={listBranchOptions() || []}
            />
          </Stack>
          <Stack direction="column" width="45%">
            <Typography variant="body1" color="text.secondary">Users</Typography>
            <Select<string>
              inputLabel=""
              defaultValue={shipperRoleId != null ? shipperRoleId.toString() : ''}
              onChange={handleShipperIdChange}
              value={shipperRoleId != null ? shipperRoleId.toString() : ''}
              options={listUserOptions() || []}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>

  );
}
