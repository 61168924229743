import {
  Typography,
} from '@mui/material';
import { SuccessMessageDialog } from 'components/SuccessMessageDialog';
import {
  useAPI,
  useAuthContext,
  useCarrierCompanyBookingLimits,
  useCarrierShipmentPageContext,
  useDisclosure,
  useShipmentQuotes,
} from 'hooks';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { isInvalidNumber, naiveDateToISOString, startLessOrEqualToEnd } from '../../../../utils';
import { SubmitQuoteForm } from './SubmitQuoteForm';
import { NoBookingsRemainingModal } from './components/NoBookingsRemainingModal';
import { SubmitQuoteFormState } from './types';

const INIITAL_STATE: SubmitQuoteFormState = {
  equipmentType: '',
  pickupStart: '',
  pickupStartTime: '',
  pickupEnd: '',
  pickupEndTime: '',
  currency: 'CAD',
  price: '',
  notes: '',
  trackingLinkOffered: false,
};

interface Props {
  shipment: CarrierCondensedShipment;

}

export function SubmitQuoteFormContainer(props: Props) {
  const { shipment } = props;
  const { user } = useAuthContext();
  const {
    isOpen: quoteSentDialogOpen,
    onClose: onCloseQuoteSentDialog,
    onOpen: onOpenQuoteSentDialog,
  } = useDisclosure(false);
  const { onRefresh } = useCarrierShipmentPageContext();
  const { limits, refetchLimits } = useCarrierCompanyBookingLimits();

  const [error, setError] = useState<string | null>(null);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [quoteSubmitted, setQuoteSubmitted] = useState<boolean>(false);
  const { api } = useAPI();
  const [formState, setFormState] = useState<SubmitQuoteFormState>(INIITAL_STATE);
  const [canSubmitQuote, setCanSubmitQuote] = useState<boolean>(true);
  const shipmentRef = useRef<undefined | CondensedShipment>();
  const [showEssentialsNoBookingModal, setShowEssentialsNoBookingModal] = useState<boolean>(false);

  const {
    quotes,
    refetchQuotes,
    isLoading: quoteRefetchLoading,
  } = useShipmentQuotes(shipment?.info.id ?? 0);
  const findCarrierActiveShipment = useCallback((q: CondensedQuote) => (q.internal_state === 'ACTIVE' || q.internal_state === 'COUNTERED') && q.created_by.id === user?.id, [user?.id]);
  /*
  Runs whenever the Shipment prop changes. This useEffect takes care
  of reacting to changes on the shipment status which results in rendering/not rendering
  the submit quote menu.

  If the shipment ID has changed between renders redetermine if the submit quote menu must be shown.
  Additionally, set the form state to default empty, since the shipment being viewed has changed.
  */
  useEffect(() => {
    if (shipment && (shipmentRef.current?.info?.id !== shipment.info.id)) {
      shipmentRef.current = shipment;
      setCanSubmitQuote(true);
      refetchQuotes();
      setFormState(INIITAL_STATE);
      refetchLimits();
    }
  }, [shipment, refetchQuotes, refetchLimits]);

  /*
  Runs when the shipment version changes (when the shipment is refreshed)
  If the shipment version has changed
  changed between renders redetermine if the submit quote menu must be shown.

  */
  useEffect(() => {
    if (shipment && (shipmentRef.current && shipmentRef.current.version !== shipment.version
    )) {
      shipmentRef.current = shipment;
      setCanSubmitQuote(true);
      refetchQuotes();
      refetchLimits();
    }
  }, [shipment, refetchQuotes, refetchLimits]);

  useEffect(() => {
    if (quotes && quotes.length > 0) {
      const result = !quotes.some((q) => findCarrierActiveShipment(q));
      setCanSubmitQuote(result);
    } else {
      setCanSubmitQuote(true);
    }
  }, [quotes, setCanSubmitQuote, findCarrierActiveShipment]);

  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const onValueChanged = <T,>(key: keyof SubmitQuoteFormState, value: T): void => {
    setFormState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const canSubmit = useCallback(() => {
    let ret = true;
    Object.entries(formState).forEach(([key, value]) => {
      if (value == null || value === undefined) {
        ret = false;
      }
      if (key === 'price' && (value === 0 || Number.isNaN(value))) {
        ret = false;
      } if (value === '' && key !== 'pickupStartTime' && key !== 'pickupEndTime' && key !== 'notes') {
        ret = false;
      }
    });
    return ret;
  }, [formState]);

  useEffect(() => {
    if (loading) {
      if (!startLessOrEqualToEnd(formState.pickupStart, formState.pickupEnd)) {
        setError('Pickup start must be before or equal to Pickup end.');
        setIsLoading(false);
        return;
      }

      if (isInvalidNumber(formState.price)) {
        setError('Price must be a number.');
        setIsLoading(false);
        return;
      }

      const body = {
        equipment_type: formState.equipmentType,
        pickup_start: naiveDateToISOString(formState.pickupStart),
        pickup_start_time: null,
        pickup_end: naiveDateToISOString(formState.pickupEnd),
        pickup_end_time: null,
        price: formState.price,
        currency: formState.currency,
        notes: formState.notes,
        tracking_link_offered: formState.trackingLinkOffered,
      };
      setError(null);
      api<unknown>('post', `/shipments/${shipment.info.id}/quotes`, body).then(() => {
        setError(null);
        setQuoteSubmitted(true);
        if (onRefresh) {
          onRefresh();
        }
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to submit quote. Please contact us if the problem persists.');
        setQuoteSubmitted(false);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [loading, formState, api, shipment, onRefresh]);

  useEffect(() => {
    if (quoteSubmitted) {
      setQuoteSubmitted(false);
    }
  }, [quoteSubmitted]);

  useEffect(() => {
    if (quoteSubmitted) {
      if (limits != null
        && limits.remainingBookings < 1
        && !shipment.is_partner_shipment as boolean) {
        setShowEssentialsNoBookingModal(
          true,
        );
      } else {
        onOpenQuoteSentDialog();
      }
    }
  }, [quoteSubmitted, setShowEssentialsNoBookingModal, onOpenQuoteSentDialog, shipment, limits]);

  const onSubmit = () => {
    setIsLoading(true);
  };
  return (
    <>
      <SuccessMessageDialog
        onClose={onCloseQuoteSentDialog}
        open={quoteSentDialogOpen}
        redirect={false}
        onCloseCallback={onRefresh || undefined}
        dialogBody={(
          <Typography variant="h5">
            The shipper has received your quote. You will be notified immediately if they
            book your truck or send you a counteroffer. If you book your truck on another load,
            please be sure to return and cancel this quote.
          </Typography>
          )}
        buttonVariant="contained"
        buttonLabel="Sounds good!"
      />
      <NoBookingsRemainingModal
        onClose={() => setShowEssentialsNoBookingModal(false)}
        open={showEssentialsNoBookingModal}
        onCloseCallback={onRefresh || null}
      />
      <SubmitQuoteForm
        onValueChanged={onValueChanged}
        enableSubmit={canSubmit()}
        onSubmit={onSubmit}
        error={error}
        loading={loading}
        quoteSubmitted={quoteSubmitted}
        canSubmitQuote={canSubmitQuote}
        formState={formState}
        quoteRefetchLoading={quoteRefetchLoading}
      />
    </>
  );
}
