import {
  Box, CircularProgress, Divider, Pagination, Stack, Typography,
} from '@mui/material';
import { useShipmentFiltersContext } from 'hooks';

import { ShipmentPreview } from '../ShipmentPreview';

interface Props {
  shipments: CarrierCondensedShipment[]
  error: string | null;
  handlePageSelected: (e: React.ChangeEvent<unknown>, value: number) => void;
  pages: number;
  isLoading: boolean;
  page: number;
  isPayingCarrier: boolean;
}

export function ShipmentsList(props: Props) {
  const {
    shipments, error, handlePageSelected, pages, isLoading, page,
    isPayingCarrier,
  } = props;

  const { filters } = useShipmentFiltersContext();

  if (isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (error != null) {
    return (
      <Stack mb="1em" alignItems="center">
        <Typography color="red">{error}</Typography>
      </Stack>
    );
  }

  if (shipments.length === 0) {
    return (
      <Stack direction="column" justifyContent="center">
        <Typography sx={{ my: '4rem' }} textAlign="center" variant="body1">
          {filters?.shipmentRelationship && filters.shipmentRelationship === 'partner' ? 'Your partners do not have any shipments posted at the moment. Change the filter above to "Public Shipments" to find & book shipments from other shippers.'
            : 'There are no available shipments at this time. Save your lane to be notified when something becomes available and try another search.'}
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack>
      {shipments.map((shipment) => (
        <Box key={shipment.info.id}>
          <Divider style={{ margin: '0' }} />
          <ShipmentPreview shipment={shipment} isPayingCarrier={isPayingCarrier} />
        </Box>
      ))}
      <Divider />
      <Box my="1rem" display="flex" alignItems="center" justifyContent="center">
        <Pagination
          count={pages}
          onChange={handlePageSelected}
          page={page}
        />
      </Box>
    </Stack>
  );
}
