import { useCallback, useEffect, useState } from 'react';
import { useAPI } from './useAPI';

import { useRoleContext } from './useRoleContext';
import { useSubscriptionStatus } from './useSubscriptionStatus';

interface CarrierEssentialLimits {
  remainingBookings: number;
}

interface CarrierCompanyLimitsResponse {
  remaining_bookings:number;
}

export function useCarrierCompanyBookingLimits() {
  const [limits, setLimits] = useState<CarrierEssentialLimits | null>(null);
  const { role } = useRoleContext();
  const { subscription } = useSubscriptionStatus();
  const { api } = useAPI();

  const fetchLimits = useCallback(() => {
    if (role && (subscription?.subscription_plan === 'CARRIER_ESSENTIALS'
    || subscription?.subscription_plan === 'CARRIER_BRONZE'
    || subscription?.subscription_plan === 'CARRIER_SILVER'
    || subscription?.subscription_plan === 'CARRIER_GOLD'
    || subscription?.subscription_plan === 'CARRIER_PLATINUM')) {
      api<CarrierCompanyLimitsResponse>('get', `/subscription/company/${role.company?.id}/bookings-left`).then((res) => {
        setLimits({ remainingBookings: res.data.remaining_bookings });
      });
    }
  }, [role, subscription, api]);

  useEffect(() => {
    if (role != null && subscription) {
      fetchLimits();
    }
  }, [role, subscription, fetchLimits]);

  return {
    limits, refetchLimits: fetchLimits,
  };
}
