import axios, { AxiosRequestConfig } from 'axios';
import { localStorageDataCanBeRefreshed } from 'clientData';
import { useCallback } from 'react';
import { useAuthContext } from './useAuthContext';

export function useAPI() {
  const {
    idToken,
    signout,
    refreshTokens,
  } = useAuthContext();

  const api = useCallback(async <T>(
    method: HTTPRequestMethod,
    url: string,
    requestBody?: unknown,
  ): Promise<AxiosResponse<T>> => {
    if (idToken == null) {
      signout();
    }

    const config: AxiosRequestConfig = {
      method,
      url: process.env.REACT_APP_API_URL + url,
      headers: { Authorization: `Bearer ${idToken}` },
    };

    if (requestBody) {
      config.data = requestBody;
    }
    return axios.request(config).catch((e) => {
      /*
        401 is returned when idToken has expired.
        Tokens should have been refreshed before expiry
        through localStorageDataCanBeRefreshed().
        Sign the user out for the session to restart.
      */
      if (e?.response?.status === 401) {
        signout();
        throw new Error('401 status code received from API.');
      }
      throw e;
    }).finally(() => {
      if (localStorageDataCanBeRefreshed() && refreshTokens) {
        refreshTokens();
      }
    });
  }, [idToken, signout, refreshTokens]);

  const unauthenticatedApi = useCallback(async <T>(
    method: 'get' | 'post' | 'patch' | 'delete' | 'put',
    url: string,
    requestBody?: unknown,
  ): Promise<AxiosResponse<T>> => {
    const config: AxiosRequestConfig = {
      method,
      url: process.env.REACT_APP_API_URL + url,
    };

    if (requestBody) {
      config.data = requestBody;
    }
    return axios.request(config);
  }, []);

  return { api, unauthenticatedApi };
}
