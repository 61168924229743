import * as React from 'react';

interface SubscriptionProviderProps {
  children: React.ReactNode;
}

interface SubscriptionContextState {
  subscription: null | CondensedSubscription;
}

interface SetSubscriptionAction {
  subscription: CondensedSubscription;
  type: 'setSubscription'
}

interface ClearSubscriptionAction {
  type: 'clearSubscription'
}

type SubscriptionActions = SetSubscriptionAction | ClearSubscriptionAction;

interface SubscriptionContextDispatch {
  dispatch: React.Dispatch<SubscriptionActions>
}

const INITIAL_STATE: SubscriptionContextState = {
  subscription: null,
};

export const subscriptionContext = React.createContext<
SubscriptionContextState & SubscriptionContextDispatch>({
  ...INITIAL_STATE,
  dispatch: () => null,
});

function reducer(
  state: SubscriptionContextState,
  action: SubscriptionActions,
): SubscriptionContextState {
  switch (action.type) {
    case 'clearSubscription':
      return {
        subscription: null,
      };
    case 'setSubscription': {
      return {
        subscription: action.subscription,
      };
    }
    default:
      return state;
  }
}

export function SubscriptionProvider(props: SubscriptionProviderProps) {
  const { children } = props;
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
  const memoState = React.useMemo(() => ({ ...state, dispatch }), [state]);

  return (
    <subscriptionContext.Provider value={memoState}>
      {children}
    </subscriptionContext.Provider>
  );
}
