import { MultiSelect } from 'components/Select';
import { useRoleContext, useShipmentFiltersContext } from 'hooks';

const OPTIONS = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: 'SINGLE',
    label: 'Single',
  },
  {
    value: 'MULTI_SHIPMENT_PROJECT',
    label: 'Multi-Shipment Project',
  },
];

export function FilterShipmentByTypeDropdown() {
  const {
    filters,
    updateShipmentType,
  } = useShipmentFiltersContext();

  const { role } = useRoleContext();

  if (role == null) {
    return <div />;
  }

  const handleShipmentTypeChange = (value: string[]) => {
    updateShipmentType(value);
  };

  return (
    <MultiSelect
      inputLabel="Shipment Types"
      defaultValue={filters?.shipmentTypes || []}
      value={filters?.shipmentTypes || []}
      onChange={handleShipmentTypeChange}
      options={OPTIONS}
    />
  );
}
