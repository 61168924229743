import FmdGoodIcon from '@mui/icons-material/FmdGood';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {
  Box, Divider, Grid, Stack, Typography,
} from '@mui/material';
import { CompanyBusinessTypeChip } from 'components/CompanyBusinessTypeChip';
import { PrivateShipmentChip } from 'components/PrivateShipmentChip';
import { ShipmentEquipmentTypeText } from 'components/Texts/ShipmentEquipmentTypeText';
import { ShipmentFootageText } from 'components/Texts/ShipmentFootageText';
import { ShipmentIDText } from 'components/Texts/ShipmentIDText';
import { ShipmentLocationText } from 'components/Texts/ShipmentLocationText';
import { ShipmentPriceText } from 'components/Texts/ShipmentPriceText';
import { ShipmentWeightText } from 'components/Texts/ShipmentWeightText';
import { useNavigate } from 'react-router-dom';
import { ISOStringToShorthandDate, countryIsFullySupported, formatText } from 'utils';
import { EVOTRUX_BLUE, getServiceTypeDisplayLabel } from '../../../../constants';
import { getCommdityNameForCondensedShipmentShipment } from '../../../../utils';
import { CompanyNameUpgradeText } from '../CompanyNameUpgradeText';
import { MultiShipmentProjectLoadsAvailableLoadsLabel } from '../MultiShipmentProjectLoadsAvailableLoadsLabel';
import { PartnerShipmentChip } from '../PartnerShipmentChip';
import { QuotedShipmentChip } from '../QuotedShipmentChip';

interface Props {
  shipment: CarrierCondensedShipment;
  isPayingCarrier: boolean;
}

export function ShipmentPreview(props: Props) {
  const navigate = useNavigate();
  const { shipment, isPayingCarrier } = props;
  const shipmentInfo = shipment.info;
  const firstPointLocation = shipment?.first_point?.point_location;
  const lastPointLocation = shipment?.last_point?.point_location;

  return (
    <Box
      display="flex"
      px="2rem"
      py="1rem"
      sx={{
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
        },
      }}
      onClick={() => navigate(`/shipments/${shipmentInfo.id}`)}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <Stack spacing={1}>
            <ShipmentEquipmentTypeText data={shipment?.equipments} />
            <MultiShipmentProjectLoadsAvailableLoadsLabel shipment={shipment} />
            <Typography sx={{ textTransform: 'capitalize' }}>{getServiceTypeDisplayLabel(shipmentInfo.service)}</Typography>
            <ShipmentWeightText data={shipment?.info?.weight_lb} />
            <Stack direction="row" spacing={1}>
              <ShipmentIDText shipment={shipment} />

              <PartnerShipmentChip partner={shipment?.is_partner_shipment ?? false} />
              <CompanyBusinessTypeChip businessType={shipment.shipment_company?.business_type} />
              <PrivateShipmentChip
                isPrivate={shipment?.is_private}
                isProject={shipment?.info?.type === 'MULTI_SHIPMENT_PROJECT'}
              />
              <QuotedShipmentChip quoted={shipment?.has_carrier_active_quote} />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
          <Box
            ml="2rem"
            flex={1}
            alignSelf="center"
          >
            <Typography
              sx={{ textTransform: 'capitalize' }}
              variant="body1"
            >
              {formatText(getCommdityNameForCondensedShipmentShipment(shipmentInfo))}
            </Typography>
            <Stack my="0.5rem" direction="row" alignItems="center">
              <Stack direction="column" spacing={1}>
                <ShipmentLocationText
                  data={countryIsFullySupported(firstPointLocation.country) ? `${firstPointLocation.city}, ${firstPointLocation.province}` : `${firstPointLocation.city}, ${firstPointLocation.country}`}
                  icon={<TripOriginIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
                />

                <p>
                  {ISOStringToShorthandDate(shipment?.first_point?.start_time)}
                  -
                </p>
                <p>
                  {ISOStringToShorthandDate(shipment?.first_point?.end_time)}
                </p>
              </Stack>

              <Box flex={1} mx="1rem">
                <Divider />
              </Box>
              <Stack direction="column" spacing={1}>
                <Stack direction="row" justifyContent="left">
                  {
                    isPayingCarrier || shipment.is_partner_shipment
                      ? <Typography color={EVOTRUX_BLUE}><b>{shipment?.shipment_company?.name || ''}</b></Typography>
                      : <CompanyNameUpgradeText />
                  }
                </Stack>
                <ShipmentLocationText
                  data={countryIsFullySupported(lastPointLocation.country) ? `${lastPointLocation.city}, ${lastPointLocation.province}` : `${lastPointLocation.city}, ${lastPointLocation.country}`}
                  icon={<FmdGoodIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
                />
                <p>
                  {ISOStringToShorthandDate(shipment?.last_point?.start_time)}
                  -
                </p>
                <p>
                  {ISOStringToShorthandDate(shipment?.last_point?.end_time)}
                </p>
              </Stack>

            </Stack>
          </Box>
          <Stack direction="row" spacing={4} mx="2rem" justifySelf="flex-end" alignSelf="center">
            <ShipmentFootageText data={shipment?.info?.footage} />
            <ShipmentPriceText
              price={shipment?.info?.preferred_price}
              currency={shipment?.info?.currency}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
