import { Button } from '@mui/material';

interface Props {
  shipment: CondensedShipment | undefined;
  canSubmitQuote: boolean;
  isLoading: boolean;
  onCancel: VoidFunction;
}

export function SubmitOrCancelQuote(props: Props) {
  const {
    canSubmitQuote, shipment, isLoading, onCancel,
  } = props;

  if (!isLoading && shipment?.shipment_status === 'ACTIVE') {
    if (canSubmitQuote) {
      return (
        <div />
      );
    }
    return (
      <Button
        variant="contained"
        color="error"
        onClick={onCancel}
        size="large"
      >
        Cancel Quote
      </Button>
    );
  }
  return null;
}
