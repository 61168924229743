import { Button, Typography } from '@mui/material';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useStripeCustomerBillingPortal, useSubscriptionStatus } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { CARRIER_UPGRADE_RELATIVE_URL } from '../../../../constants';

interface Props {
  text?: string;
  size?: 'medium' | 'large' | 'small'
}

export function CarrierUpgradeNowButton(props: Props) {
  const navigate = useNavigate();
  const { text, size } = props;

  const { subscription } = useSubscriptionStatus();
  const {
    requestCustomerBillingPortalSession, loading,
  } = useStripeCustomerBillingPortal({
    onSessionCreatedSuccess: (url) => {
      window.open(url, '_blank');
    },
  });

  const onClick = () => {
    if (subscription && (subscription.subscription_plan === 'CARRIER_ESSENTIALS'
       || subscription?.subscription_plan === 'CARRIER_PAY_PER_BOOKING')) {
      // carriers without an account upgrade from pricing page
      navigate(CARRIER_UPGRADE_RELATIVE_URL);
    } else {
      // carriers with account upgrade/update sub through stripe customer portal
      requestCustomerBillingPortalSession();
    }
  };

  return (
    <Button
      size={size || 'large'}
      variant="contained"
      onClick={onClick}
      sx={{
        backgroundColor: 'yellow',
        backgroundImage: 'linear-gradient(to right, #FFFF00, #FFCD01)',
        textTransform: 'none',
      }}
    >
      <LoadingWrappedNode loading={loading}>
        <Typography variant="body1" color="black"><b>{text}</b></Typography>
      </LoadingWrappedNode>
    </Button>
  );
}

CarrierUpgradeNowButton.defaultProps = {
  text: 'Upgrade Now',
  size: 'large',
};
