import AddIcon from '@mui/icons-material/Add';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
  Button,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ISOStringToLocalReadableTimestamp, ISOStringToShorthandDate, userHasNeverLoggedIn } from 'utils';
import { buildFormUrl, getAdminOrBranchAdminRole, userCanBePromotedToAdmin } from './utils';

const WARNING_TABLE_CELL_ROW = '#fce7c2';

interface Props {
  branchMembers: BranchMembers;
  viewingAsRoles: Role[];
}

export function ManageBranchDetail(props: Props) {
  const { branchMembers, viewingAsRoles } = props;
  const { branch, members } = branchMembers;
  const adminRole = getAdminOrBranchAdminRole(viewingAsRoles, branch.id);
  if (!adminRole || !adminRole?.company?.id) {
    return <div />;
  }

  return (
    <Stack
      direction="column"
    >
      <Typography variant="h4">{branch.name}</Typography>
      <Divider />
      <Stack mt=".5rem" direction="row" justifyContent="start" spacing={2}>
        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{`${members.length} ${members.length === 1 ? 'member' : 'members'}`}</Typography>
        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{`Created on ${ISOStringToShorthandDate(branch.created_at)}`}</Typography>
        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{`Created by ${branch.created_by.user.first_name} ${branch.created_by.user.last_name}`}</Typography>
      </Stack>
      <Stack mt=".5rem" direction="row" justifyContent="start" spacing={2}>
        <Button
          variant="contained"
          startIcon={<AddIcon fontSize="large" />}
          onClick={() => window.open(buildFormUrl('CREATE_USER_IN_BRANCH', adminRole, branch), '_blank')}

        >
          Create user in branch
        </Button>
        <Button
          variant="outlined"
          startIcon={<ArrowRightAltIcon />}
          onClick={() => window.open(buildFormUrl('MOVE_USERS_TO_BRANCH', adminRole, branch), '_blank')}
        >
          Move users to branch
        </Button>
      </Stack>
      <Typography mt="1rem" variant="h6">Branch Members</Typography>
      <Paper sx={{ mt: '1rem', width: '100%', overflow: 'hidden' }}>
        <TableContainer component={Paper}>
          <Table
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Joined Evotrux</TableCell>
                <TableCell>Last Login</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.filter((member: UserRoleInfo) => member.type !== 'BRANCH_ADMINISTRATOR'
              && member.type !== 'ADMINISTRATOR').map((member: UserRoleInfo) => (
                <Tooltip
                  title={userHasNeverLoggedIn(member) ? 'This user has not activated their Evotrux account.' : ''}
                  followCursor
                >

                  <TableRow
                    key={member.user.id}
                    sx={{
                      backgroundColor: userHasNeverLoggedIn(member) ? WARNING_TABLE_CELL_ROW
                        : undefined,
                    }}
                    hover
                  >
                    <TableCell component="th" scope="row">
                      {`${member.user.first_name} ${member.user.last_name}`}
                    </TableCell>
                    <TableCell>{member.type}</TableCell>
                    <TableCell>
                      {ISOStringToLocalReadableTimestamp(member.user.created_at)}
                    </TableCell>
                    <TableCell>
                      {member?.user?.last_login ? ISOStringToLocalReadableTimestamp(member?.user?.last_login) : '-'}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" justifyContent="start" spacing={1}>
                        <Button
                          size="small"
                          color="warning"
                          variant="outlined"
                          onClick={() => window.open(buildFormUrl('REMOVE_USER_FROM_BRANCH', adminRole, branch, member), '_blank')}
                        >
                          Remove from branch
                        </Button>
                        {userCanBePromotedToAdmin(members, member.user) && (
                        <Button
                          size="small"
                          color="info"
                          variant="outlined"
                          onClick={() => window.open(buildFormUrl('PROMOTE_TO_BRANCH_ADMIN', adminRole, branch, member), '_blank')}
                        >
                          Promote to branch admin
                        </Button>
                        )}
                      </Stack>
                    </TableCell>

                  </TableRow>
                </Tooltip>

              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Typography mt="1rem" variant="h6">Branch Administrators</Typography>
      <Paper sx={{ mt: '1rem', width: '100%', overflow: 'hidden' }}>
        <TableContainer component={Paper}>
          <Table
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Joined Evotrux</TableCell>
                <TableCell>Last Login</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.filter((member: UserRoleInfo) => member.type === 'BRANCH_ADMINISTRATOR').map((member: UserRoleInfo) => (
                <Tooltip
                  title={userHasNeverLoggedIn(member) ? 'This user has not activated their Evotrux account.' : ''}
                  followCursor
                >
                  <TableRow
                    key={member.user.id}
                    sx={{
                      backgroundColor: userHasNeverLoggedIn(member) ? WARNING_TABLE_CELL_ROW
                        : undefined,
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {`${member.user.first_name} ${member.user.last_name}`}
                    </TableCell>
                    <TableCell>
                      {ISOStringToLocalReadableTimestamp(member.user.created_at)}
                    </TableCell>
                    <TableCell>
                      {member?.user?.last_login ? ISOStringToLocalReadableTimestamp(member?.user?.last_login) : '-'}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" justifyContent="start" spacing={1}>
                        <Button
                          size="small"
                          color="warning"
                          variant="outlined"
                          onClick={() => window.open(buildFormUrl('REMOVE_BRANCH_ADMIN', adminRole, branch, member), '_blank')}
                        >
                          Remove Admin
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </Tooltip>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
}
