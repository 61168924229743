import {
  Box,
  Divider, Stack,
  Typography,
} from '@mui/material';
import { EquipmentTypeMultiSelect } from 'components/EquipmentTypeSelect';
import { ProvinceStateMultiSelect } from 'components/ProvinceStateMultiSelect';
import { RefreshPageButton } from 'components/RefreshPageButton';
import { ShipmentSaveSearch } from 'components/ShipmentSaveSearch';
import { ShipmentServiceMultiSelect } from 'components/ShipmentServiceMultiSelect';
import { FilterShipmentByTypeDropdown } from './components/FilterShipmentByTypeDropdown';
import { PartnerShipmentDropdown } from './components/PartnerShipmentDropdown';

interface Props {
  title?: string;
  filters: ShipmentsFilterState;
  onLocationChanged: (
    key: keyof Location,
    addressKey: keyof ShipmentLocationFilter,
    value: string[],
  ) => void;
  onEquipmentTypeChanged: (value: string[]) => void;
  onServiceTypeChanged: (value: string[]) => void;
  shipmentCount: number;
  onShipmentSearchCreated: VoidFunction;
  refetchSearch: VoidFunction;
  onShipmentRelationChange: (value: ShipmentRelation) => void;
}

export function ShipmentsFilter(props: Props) {
  const {
    title,
    filters,
    onLocationChanged,
    onEquipmentTypeChanged,
    onServiceTypeChanged,
    shipmentCount,
    onShipmentSearchCreated,
    refetchSearch,
    onShipmentRelationChange,
  } = props;

  return (
    <Box margin={2}>
      <Box mb={1}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <Typography variant="h6">{title}</Typography>
            <p>
              (
              {shipmentCount ?? '-'}
              )
            </p>
            <RefreshPageButton
              id="dashboard-shipments-refresh"
              onRefresh={refetchSearch}
              iconButton
            />
          </Stack>
        </Stack>
      </Box>

      <Box mt={1}>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={1}>
            <Box>
              <PartnerShipmentDropdown
                onShipmentRelationChange={onShipmentRelationChange}
                value={filters.shipmentRelation}
              />
            </Box>
            <FilterShipmentByTypeDropdown />
          </Stack>
          <Divider />
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            alignItems="center"
            sx={{ rowGap: '1rem' }}
          >
            <Box>
              <EquipmentTypeMultiSelect
                onChange={onEquipmentTypeChanged}
                defaultValue={filters.equipmentTypes}
                value={filters.equipmentTypes}
              />
            </Box>
            <Box>
              <ShipmentServiceMultiSelect
                label="Services"
                value={filters.services}
                onChange={(newValue) => onServiceTypeChanged(newValue)}
              />
            </Box>
            <Box>
              <ProvinceStateMultiSelect
                label="Origins"
                value={filters.origin.provinces}
                onChange={(value) => onLocationChanged('origin', 'provinces', value)}
              />
            </Box>
            <Box>
              <ProvinceStateMultiSelect
                label="Destinations"
                value={filters.destination.provinces}
                onChange={(value) => onLocationChanged('destination', 'provinces', value)}
              />
            </Box>

            <ShipmentSaveSearch
              onShipmentSearchCreated={onShipmentSearchCreated}
            />
            <Typography variant="body2" color="text.secondary" sx={{ mt: '.3rem', mb: '.3rem' }}>
              Save your lanes to be notified when matching shipments are posted.
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

ShipmentsFilter.defaultProps = {
  title: 'Filters',
};
