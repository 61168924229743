import { useAuthContext, useRoleContext, useSubscriptionStatus } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { ProductFruits } from 'react-product-fruits';

export function ProductFruitsWrapper() {
  const { user } = useAuthContext();
  const { role, company } = useRoleContext();
  const { subscription } = useSubscriptionStatus();

  const getPFValueForCarrierRole = useCallback((sub: CondensedSubscription) => {
    if (sub.subscription_plan === 'CARRIER_ESSENTIALS') {
      return 'IsTrialCarrier';
    }
    return sub.subscription_plan;
  }, []);

  const [productFruitsUser, setProductFruitsUser] = useState({
    username: user?.email || '',
    email: user?.email || '',
    firstname: user?.first_name || '',
    lastname: user?.last_name || '',
    signUpAt: user?.created_at || '',
    role: role?.type || '',
    props: {
      is_unverified_carrier: 0,
    },
  });

  useEffect(() => {
    if (subscription != null
      && (productFruitsUser.role === 'CARRIER' || productFruitsUser.role === '')) {
      setProductFruitsUser((prev) => ({
        ...prev,
        role: getPFValueForCarrierRole(subscription),
      }));
    }
  }, [subscription, getPFValueForCarrierRole, productFruitsUser]);

  useEffect(() => {
    if (company != null) {
      if (company?.company_type === 'CARRIER' && company.verified_at == null && !productFruitsUser.props.is_unverified_carrier) {
        setProductFruitsUser((prev) => ({
          ...prev,
          props: {
            is_unverified_carrier: 1,
          },
        }));
      }
    }
  }, [company, productFruitsUser]);

  if (role == null || subscription == null || user == null) {
    return <div />;
  }

  return (
    <ProductFruits
      workspaceCode="lg24lvyWRjoM2d0l"
      language="en"
      user={productFruitsUser}
    />
  );
}
