import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Stack, Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ErrorTypography } from 'components/ErrorTypography';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { useCompanyMembers, useRoleContext, useWriterComponentState } from 'hooks';
import { useState } from 'react';

const MODAL_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  p: 4,
};

interface Props {
  shipment: ShipperCondensedShipment
  onModalClose: VoidFunction;
  onTeammatesAdded: VoidFunction;
  modalOpen: boolean
}

export function ShareShipmentWithTeammatesModal(props: Props) {
  const {
    modalOpen, shipment, onModalClose, onTeammatesAdded,
  } = props;
  const { role } = useRoleContext();
  const {
    members,
    error: CompanyMemberFetchError,
    loading: CompanyMemberFetchLoading,
  } = useCompanyMembers();
  const [newShareesIds, setNewShareesIds] = useState<number[]>([]);
  const {
    loading, write, error, resetState,
  } = useWriterComponentState({
    errorMessage: 'Failed to add teammates. Please contact us if the issue persists',
    writeConfig: {
      method: 'patch',
      url: shipment?.info?.id ? `/shipments/${shipment.info.id}/update` : '/',
      body: { sharee_role_ids: newShareesIds },
    },
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const onClose = () => {
    resetState();
    setNewShareesIds([]);
    onModalClose();
  };

  const submit = () => {
    write(() => {
      onClose();
      onTeammatesAdded();
    });
  };

  const getCompanyMemberLabel = (member: CompanyMember) => `${member.user.first_name ?? ''} ${member.user.last_name ?? ''}`;

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;
    if (typeof value === 'string') {
      const roleStringArray = value.split(',');
      setNewShareesIds(roleStringArray.map((stringRoleId) => parseInt(stringRoleId, 10)));
    } else {
      setNewShareesIds(value);
    }
  };

  if (!role) {
    return <div />;
  }

  const companyMembersWithoutSelf = members.filter((member) => member.id !== role.id);
  const roleIdMap:
  RoleIdToCompanyMemberMap = companyMembersWithoutSelf.reduce((acc, member: CompanyMember) => {
    acc[member.id] = member;
    return acc;
  }, {} as RoleIdToCompanyMemberMap);

  const existingShareesIdSet = new Set(shipment.sharees.map((sharee) => sharee.sharee.id));

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <Stack sx={MODAL_STYLE} direction="column" spacing={2}>
        <Typography variant="h6" textAlign="center">Add Teammates</Typography>
        <Typography variant="body1">Select the teammates you would like to share this shipment with.</Typography>
        <LoadingWrappedNode loading={CompanyMemberFetchLoading}>
          <Stack>
            <FormControl>
              <InputLabel>Teammates</InputLabel>
              <Select
                multiple
                value={newShareesIds}
                onChange={handleChange}
                input={<OutlinedInput label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((roleId: number) => (
                      <Chip key={roleId} label={getCompanyMemberLabel(roleIdMap[roleId])} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {
                companyMembersWithoutSelf.map((member: CompanyMember) => (
                  <MenuItem
                    key={member.id}
                    value={member.id}
                    disabled={existingShareesIdSet.has(member.id)}
                  >
                    {`${getCompanyMemberLabel(member)} ${existingShareesIdSet.has(member.id) ? '(Already shared)' : ''}`}
                  </MenuItem>
                ))
                }
              </Select>
            </FormControl>
            <ErrorTypography error={CompanyMemberFetchError} />
          </Stack>
        </LoadingWrappedNode>
        <LoadingWrappedNode loading={loading}>
          <Stack alignItems="center" mt="2rem">
            <Button variant="contained" onClick={submit} size="large">
              Add Teammates
            </Button>
          </Stack>
        </LoadingWrappedNode>
        <ErrorTypography error={error} />
      </Stack>
    </Modal>
  );
}
