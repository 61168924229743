import StarRateIcon from '@mui/icons-material/StarRate';
import {
  Stack,
  Typography,
} from '@mui/material';

interface Props {
  reviewsMeta: CompanyReviewsMeta;
  labelType: 'h3' | 'h4' | 'h5' | 'h6' | 'body1';
  starIconSize: 'small' | 'medium' | 'large';
  textColor?: 'black' | 'white' | null;
  showNoReviewsText?: boolean;
  showShorteneNoReviews?: boolean;
}

export function CompanyReviewMetaLabel(props: Props) {
  const {
    reviewsMeta, labelType, starIconSize, textColor, showNoReviewsText, showShorteneNoReviews,
  } = props;

  if (reviewsMeta.count === 0 && showNoReviewsText) {
    return (<Typography variant={labelType} color={textColor ?? 'text.secondary'}>No reviews made yet</Typography>);
  }

  if (reviewsMeta.count === 0 && showShorteneNoReviews) {
    return (
      <Stack direction="row" alignItems="center">
        <StarRateIcon sx={{ color: '#b5b3b3' }} fontSize={starIconSize} />
        <Typography variant={labelType}>(0 Reviews)</Typography>
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap">
      <Typography color={textColor ?? 'black'} variant={labelType}>{reviewsMeta.average}</Typography>
      <StarRateIcon sx={{ color: '#faaf00' }} fontSize={starIconSize} />
      <Typography
        variant="body1"
        color={textColor ?? 'text.secondary'}
      >
        { reviewsMeta.count > 1 || reviewsMeta.count === 0 ? `(${reviewsMeta.count} reviews)` : `(${reviewsMeta.count} review)`}
      </Typography>
    </Stack>
  );
}

CompanyReviewMetaLabel.defaultProps = {
  textColor: null,
  showNoReviewsText: true,
  showShorteneNoReviews: false,
};
