import { useAPI } from 'hooks';
import { useState } from 'react';
import { validateEmails } from 'utils';
import { CreateFollowRequest } from './CreateFollowRequest';

interface Props {
  onFollowRequestsCreatedRefresh: VoidFunction;
}

export function CreateFollowRequestContainer(props: Props) {
  const { onFollowRequestsCreatedRefresh } = props;
  const [error, setError] = useState<string | null>(null);
  const [text, setText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { api } = useAPI();
  const MAX_FOLLOW_INVITATIONS_PER_REQUEST = 20;

  const onSubmit = (emailBlock: string) => {
    const validated = validateEmails(emailBlock);
    if (validated.length > 0 && validated.length <= MAX_FOLLOW_INVITATIONS_PER_REQUEST) {
      const body = { emails: validated };
      setIsLoading(true);
      setError(null);
      api<unknown>('post', '/follow_requests?source=email-input-network-page', body).then(() => {
        setError(null);
        setText('');
        onFollowRequestsCreatedRefresh();
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to send follow invites.');
      }).finally(() => {
        setIsLoading(false);
      });
    } else if (validated.length === 0) {
      setError('One or more emails were in incorrect format. Emails must be comma separated with no other information. Example: harry@email.com, joe@email.com');
    } else {
      setError('You are unable to submit more than 20 invitations at once. Please decrease the number of invitations or contact us for help.');
    }
  };

  return (
    <CreateFollowRequest
      onSubmit={onSubmit}
      error={error}
      text={text}
      onTextChange={setText}
      isLoading={isLoading}
    />
  );
}
