import {
  createContext, useEffect, useMemo, useReducer,
} from 'react';

interface CarrierShipmentPageContextProps {
  children: React.ReactNode;
  shipment: CarrierCondensedShipment | undefined;
  onRefresh: VoidFunction;
}

interface CarrierShipmentPageContextState {
  onRefresh: VoidFunction | null;
  shipment: CarrierCondensedShipment | undefined;
}

interface PopulateOnRefreshAction {
  type: 'populateOnRefresh',
  onRefresh: VoidFunction;
}

interface PopulateShipmentAction {
  type: 'populateShipment',
  shipment: CarrierCondensedShipment
}

type CarrierShipmentContextActions = PopulateOnRefreshAction | PopulateShipmentAction;

interface CarrierShipmentContextDispatch {
  dispatch: React.Dispatch<CarrierShipmentContextActions>
}

const INITIAL_STATE: CarrierShipmentPageContextState = {
  onRefresh: null,
  shipment: undefined,
};

export const carrierShipmentPageContext = createContext<
CarrierShipmentPageContextState & CarrierShipmentContextDispatch>(
  {
    ...INITIAL_STATE,
    dispatch: () => null,
  },
);

function reducer(
  state: CarrierShipmentPageContextState,
  action: CarrierShipmentContextActions,
): CarrierShipmentPageContextState {
  switch (action.type) {
    case 'populateOnRefresh':
      return {
        ...state,
        onRefresh: action.onRefresh,
      };
    case 'populateShipment':
      return {
        ...state,
        shipment: action.shipment,
      };
    default:
      return state;
  }
}

export function CarrierShipmentPageProvider(props: CarrierShipmentPageContextProps) {
  const { children, shipment, onRefresh } = props;

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const memoState = useMemo(() => ({ ...state, dispatch }), [state]);

  // runs on context being created and shipment is not initialized
  useEffect(() => {
    if (state.shipment === undefined && shipment) {
      dispatch({ type: 'populateShipment', shipment });
    }
  }, [shipment, dispatch, state.shipment]);

  // runs when the shipment on props is different from the state: updates the context state
  useEffect(() => {
    if (state.shipment && shipment && state.shipment.version !== shipment.version) {
      dispatch({ type: 'populateShipment', shipment });
    }
  }, [shipment, dispatch, state.shipment]);

  // on context startup
  useEffect(() => {
    if (state.onRefresh === null) {
      dispatch({ type: 'populateOnRefresh', onRefresh });
    }
  }, [state.onRefresh, onRefresh]);

  // onRefresh changing, update the context state
  useEffect(() => {
    if (state.onRefresh !== onRefresh) {
      dispatch({ type: 'populateOnRefresh', onRefresh });
    }
  }, [state.onRefresh, onRefresh]);

  return (
    <carrierShipmentPageContext.Provider value={memoState}>
      {children}
    </carrierShipmentPageContext.Provider>
  );
}
