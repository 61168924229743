import { Stack, Typography } from '@mui/material';
import { useAPI, useRoleContext, useShipmentQuotes } from 'hooks';
import { useEffect, useState } from 'react';
import { getBookedQuoteFromQuotes } from 'utils';
import { AddCarrierToNetworkButton } from '../../../../Shipper/components/AddCarrierToNetworkButton';

interface Props {
  shipment: CondensedShipment;
  buttonVariant?: 'contained' | 'outlined';
}

export function ChatAddCarrierToNetworkButton(props: Props) {
  const { shipment, buttonVariant } = props;
  const { quotes } = useShipmentQuotes(shipment.info.id);
  const [carrierId, setCarrierId] = useState<any>(null);
  const { role } = useRoleContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [followers, setFollowers] = useState<FollowingCarrier[]>([]);
  const [error, setError] = useState<string | null>(null);

  const { api } = useAPI();

  useEffect(() => {
    if (loading && role != null) {
      api<FollowingCarrier[]>('get', `/shippers/${role.id}/followers`).then((response) => {
        setFollowers(response.data);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch carrier network.');
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [api, role, loading]);

  useEffect(() => {
    if (quotes.length > 0) {
      const booked = getBookedQuoteFromQuotes(quotes);
      if (booked) {
        const carrierFoundInNetwork = followers.find((carrier) => carrier.id === booked.carrier_id);
        if (carrierFoundInNetwork) {
          // component to add carrier to network should not show up if carrier is already in network
          setCarrierId(null);
        } else {
          // if carrier was not found, display the button
          setCarrierId(booked.carrier_id);
        }
      }
    }
  }, [quotes, followers]);

  if (carrierId == null) {
    return <div />;
  }
  if (error != null) {
    return (
      <Stack>
        <Typography color="red">{error}</Typography>
      </Stack>
    );
  }
  return (
    <AddCarrierToNetworkButton
      carrierId={carrierId}
      buttonVariant={buttonVariant}
      checkForRequestAlreadySent
    />
  );
}

ChatAddCarrierToNetworkButton.defaultProps = {
  buttonVariant: 'outlined',
};
