import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { SuccessTypography } from 'components/SuccessTypography';
import { useCarrierShipmentPageContext, useWriterComponentState } from 'hooks';
import { useEffect } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  trackingLinkId: number;
}

export function DeleteShipmentTrackingLinkModal(props: Props) {
  const { isOpen, onClose, trackingLinkId } = props;
  const { onRefresh } = useCarrierShipmentPageContext();
  const {
    loading,
    error,
    write,
    success,
    resetState,
  } = useWriterComponentState({
    errorMessage: 'Failed to create tracking link. Please contact us if the issue persists.',
    writeConfig: {
      method: 'delete',
      url: `/tracking-links/${trackingLinkId}`,
    },
    errorMessageKey: 'message',
  });

  useEffect(() => {
    if (success && isOpen) {
      if (onRefresh) {
        onRefresh();
      }
      onClose();
      resetState();
    }
  }, [success, onRefresh, onClose, isOpen, resetState]);

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h4">
          Delete Tracking Link
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">Are you sure want to delete this link?</Typography>
        <SuccessTypography success={success} message="Tracking link deleted." />
        <ErrorTypography error={error} />
      </DialogContent>
      {!loading && (
      <DialogActions>
        {loading ? <Stack><CircularProgress /></Stack>
          : (
            <>
              <Button
                variant="contained"
                color="error"
                onClick={() => write()}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                onClick={onClose}
              >
                Cancel
              </Button>
            </>
          )}
      </DialogActions>
      )}
    </Dialog>
  );
}
