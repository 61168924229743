import { useCallback, useEffect, useState } from 'react';
import { userBelongsToCompany } from 'utils';
import { useAPI } from './useAPI';
import { useAuthContext } from './useAuthContext';

export function useUserChatInfo() {
  const { isAuthenticated, user, refreshChatTokens } = useAuthContext();
  const { api } = useAPI();
  const [chatInfo, setChatInfo] = useState<UserChatInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getChatInfo = useCallback(() => {
    if (user !== null && userBelongsToCompany(user)) {
      setLoading(true);
      api<UserChatInfo>('get', '/user/chat').then((res) => {
        setChatInfo(res.data);
      }).finally(() => setLoading(false));
    }
  }, [user, api]);

  useEffect(() => {
    refreshChatTokens();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && isAuthenticated && chatInfo === null) {
      getChatInfo();
    }
  }, [user,
    isAuthenticated,
    getChatInfo,
    refreshChatTokens, chatInfo]);

  const refreshTokens = useCallback((onRefreshSuccess?: () => void) => {
    if (onRefreshSuccess) {
      refreshChatTokens(onRefreshSuccess);
    } else {
      refreshChatTokens();
    }
  }, [refreshChatTokens]);

  return {
    chatInfo, loading, refreshChatInfo: getChatInfo, refreshChatTokens: refreshTokens,
  };
}
