import InventoryIcon from '@mui/icons-material/Inventory';
import { Typography } from '@mui/material';
import { IconText } from './IconText';

export function ShipmentShipperReferenceText<T>(props: ShipmentInfoTextProps<T>) {
  const { data } = props;

  return (
    <IconText
      icon={<InventoryIcon sx={{ fontSize: '0.9rem', marginRight: '5px' }} />}
      text={<Typography>{`Shipper Ref: ${data}`}</Typography>}
    />
  );
}
