import { Button, Drawer, Stack } from '@mui/material';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  content: React.ReactNode
}

export function RootSideBar(props: Props) {
  const {
    isOpen, onClose, content,
  } = props;
  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
    >
      <Stack direction="column">
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Close
        </Button>
        {content}
      </Stack>
    </Drawer>
  );
}
