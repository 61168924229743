import { useAPI } from 'hooks';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { emailIsValid } from 'utils';
import { CreateAccountAndCompanyFormState } from '../types';
import { CreateShipmentAndAccountForm } from './CreateShipmentAndAccountForm';

const DEFAULT_ACCOUNT_CREATE_FORM_STATE = {
  email: '',
  companyName: '',
  firstName: '',
  lastName: '',
  isNotBroker: false,
};

export function CreateShipmentAndAccountFormContainer() {
  const [searchParams] = useSearchParams();
  const [postShipmentBody, setPostShipmentBody] = useState<object | null>(null);
  const [showAccountCreationModal, setShowAccountCreationModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [createAccountFormState,
    setCreateAccountFormState] = useState<CreateAccountAndCompanyFormState>(
    DEFAULT_ACCOUNT_CREATE_FORM_STATE,
  );
  const [success, setSuccess] = useState<boolean>(false);
  const { unauthenticatedApi } = useAPI();

  const handleCreateAccountFormValueChange = (
    key: keyof CreateAccountAndCompanyFormState,
    value: string,
  ) => {
    setCreateAccountFormState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const setIsNotBroker = (value: boolean) => {
    setCreateAccountFormState((prev) => ({
      ...prev,
      isNotBroker: value,
    }));
  };

  useEffect(() => {
    const email = searchParams.get('email');
    if (email != null) {
      const emailNoBlankSpaces = email.replace(' ', '+');
      handleCreateAccountFormValueChange('email', emailNoBlankSpaces);
    }
  }, [searchParams]);

  useEffect(() => {
    if (postShipmentBody !== null && !showAccountCreationModal) {
      setShowAccountCreationModal(true);
    }
  }, [postShipmentBody, showAccountCreationModal]);

  const formIsValid = () => {
    if (postShipmentBody == null) {
      setError('Shipment is empty');
      return false;
    }
    if (
      createAccountFormState.email === ''
    || createAccountFormState.firstName === ''
    || createAccountFormState.lastName === ''
    || createAccountFormState.companyName === ''

    ) {
      setError('Please complete all fields');
      return false;
    }

    if (!emailIsValid(createAccountFormState.email)) {
      setError('Invalid email');
      return false;
    }

    setError(null);
    return true;
  };

  const getShipmentAndAccountBody = () => {
    const body = {
      shipment: postShipmentBody,
      account: {
        email: createAccountFormState.email,
        first_name: createAccountFormState.firstName,
        last_name: createAccountFormState.lastName,
        company_name: createAccountFormState.companyName,
      },
    };
    return body;
  };

  const submitShipmentAndAccount = () => {
    const canSubmit = formIsValid();
    if (canSubmit && !loading) {
      const body = getShipmentAndAccountBody();
      setLoading(true);
      setError(null);
      unauthenticatedApi('post', '/new-shippers', body).then(() => {
        setSuccess(true);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to create shipment and account. Please contact us.');
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <CreateShipmentAndAccountForm
      setPostShipmentBody={setPostShipmentBody}
      showAccountCreationModal={showAccountCreationModal}
      loading={loading}
      error={error}
      handleCreateAccountFormValueChange={handleCreateAccountFormValueChange}
      createAccountFormState={createAccountFormState}
      onSubmitAccountForm={submitShipmentAndAccount}
      shipmentPosted={success}
      setIsNotBroker={setIsNotBroker}
    />
  );
}
