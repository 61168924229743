import { useState } from 'react';
import axios from 'axios';
import { useAuthContext } from '../../../../hooks';
import { SignUpSuccess } from './SignUpSuccess';

export function SignUpSuccessContainer() {
  const { user } = useAuthContext();

  const [error, setError] = useState(false);
  const [messageResent, setMessageResent] = useState(false);

  const handleResendVerify = () => {
    if (user?.cognito_id) {
      axios.post(`${process.env.REACT_APP_API_URL}/verify/resend`, {
        user_uuid: user.cognito_id,
      }).then(() => {
        setMessageResent(true);
        setError(false);
      }).catch(() => {
        setError(true);
      });
    }
  };

  return (
    <SignUpSuccess
      onResendVerify={handleResendVerify}
      error={error}
      messageResent={messageResent}
    />
  );
}
