import { useShipmentQuotes } from 'hooks';
import { useEffect } from 'react';
import { ShipmentQuoteList } from './ShipmentQuoteList';

interface Props {
  shipment: CondensedShipment;
  refetchQuotes: boolean;
  onQuotesRefetched: VoidFunction;
}

export function ShipmentQuoteListContainer(props: Props) {
  const { shipment, refetchQuotes, onQuotesRefetched } = props;
  const {
    quotes, isLoading: loading, error, refetchQuotes: fetchQuotes,
  } = useShipmentQuotes(shipment.info.id);

  useEffect(() => {
    if (refetchQuotes) {
      fetchQuotes();
      onQuotesRefetched();
    }
  }, [fetchQuotes, refetchQuotes, onQuotesRefetched]);

  return <ShipmentQuoteList quotes={quotes} loading={loading} error={error} />;
}
