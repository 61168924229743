/**
 * @param query the location input.
 * @returns An AutoCompletePrediction[] will resolve on success, null if nothing found.
 */
export async function getPlacePredictions(query: string, addressTypes: Array<string> | undefined):
Promise<google.maps.places.AutocompletePrediction[] | null> {
  return new Promise((resolve, reject) => {
    if (!query) {
      reject(new Error('No valid query'));
    }
    if (typeof window === 'undefined') {
      // This is because the google maps library loads into the window.
      reject(new Error('Expected valid window object'));
    }
    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
    const autocompleteService = new google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions({
      input: query,
      types: addressTypes,
    }).then((autocompleteResponse) => {
      resolve(autocompleteResponse.predictions);
    }).catch((error) => {
      reject(error);
    });
  });
}

/**
 * @param placeId the string identifying the place.
 * @param fields optional array of fields to include in the PlaceResult.
 * @returns A PlaceResult will resolve on success, null if nothing found, or Error.
 */
export async function getPlaceDetails(placeId: string, fields?: Array<string> | undefined):
Promise<google.maps.places.PlaceResult | null> {
  return new Promise((resolve, reject) => {
    if (!placeId) {
      reject(new Error('getPlaceDetails requires placeId'));
    }
    if (typeof window === 'undefined') {
      // This is because the google maps library loads into the window.
      reject(new Error('Expected valid window object'));
    }
    /*
      The PlacesService does not work without a map since this is where it will render
      attribution.
      We must remember to place attribution wherever we display Google Places results or else
      we will be violating Terms of Service.
      Since we're not using a map, we can use a temporary div to store this attribution.
    */
    const mapContainer = new google.maps.Map(document.createElement('div'));
    const placesService = new google.maps.places.PlacesService(mapContainer);

    try {
      placesService.getDetails({
        placeId,
        fields,
      }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(place);
        } else {
          reject(status);
        }
      });
    } catch (e) {
      reject(new Error('Unable to get place details.'));
    }
  });
}
