import { useDisclosure } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { QuoteInteraction } from './QuoteInteraction';
import { DEFAULT_QUOTE_STATE, QuoteActionsState } from './types';

interface Props {
  actions: Array<string>;
  viewQuoteHistory: React.ReactNode;
  quote: CondensedQuote;
}

export function QuoteInteractionContainer(props: Props) {
  const {
    actions,
    viewQuoteHistory,
    quote,
  } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [quoteActionsState,
    setQuoteActionState] = useState<QuoteActionsState>(DEFAULT_QUOTE_STATE);
  const [modalAction, setModalAction] = useState<CondensedQuoteActionValue | null>(null);

  const onActionClick = useCallback((actionType: CondensedQuoteActionValue) => {
    setModalAction(actionType);
    onOpen();
  }, [onOpen]);

  const setupActionState = useCallback((
    actionType: keyof QuoteActionsState,
  ) => {
    setQuoteActionState((prev) => ({
      ...prev,
      [actionType]: {
        actionType,
        isDisplayed: actions.includes(actionType),
      },
    }));
  }, [actions]);

  useEffect(() => {
    actions.forEach((action) => {
      if (action === 'ACCEPT' || action === 'COUNTER' || action === 'CANCEL' || action === 'DECLINE_WITH_FEEDBACK' || action === 'DECLINE') {
        setupActionState(action);
      }
    });
  }, [actions, setupActionState]);

  return (
    <QuoteInteraction
      viewQuoteHistory={viewQuoteHistory}
      acceptQuote={quoteActionsState.ACCEPT}
      counterQuote={quoteActionsState.COUNTER}
      cancelQuote={quoteActionsState.CANCEL}
      declineQuoteWithFeedback={quoteActionsState.DECLINE_WITH_FEEDBACK}
      declineQuote={quoteActionsState.DECLINE}
      modalOpen={isOpen}
      onCloseModal={onClose}
      onActionClicked={onActionClick}
      modalAction={modalAction}
      quote={quote}
    />
  );
}
