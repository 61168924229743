import { Stack, Typography } from '@mui/material';
import { useShipperShipmentPageContext } from 'hooks';
import { ShipmentTrackingLinkDisplay } from '../../../../../ShipmentTrackingLink';

interface Props {
  dividerComponent: JSX.Element;
}

export function ShipmentTrackingLinkSection(props: Props) {
  const { dividerComponent } = props;
  const { shipment } = useShipperShipmentPageContext();

  // scenarios where no component is rendered.
  if (!shipment || shipment?.info?.booked_at == null || shipment.info.type === 'MULTI_SHIPMENT_PROJECT') {
    return <div />;
  }

  if (shipment.tracking_link_info.tracking_link !== null) {
    return (
      <>
        {dividerComponent}
        <ShipmentTrackingLinkDisplay
          trackingLink={shipment.tracking_link_info.tracking_link}
          showWarningOnCopy
        />
      </>

    );
  } if (!shipment.tracking_link_info.link_expected) {
    return (
      <>
        {dividerComponent}
        <Stack mb="2rem" direction="column" spacing={1}>
          <Typography mt="1rem" variant="h6">No tracking link provided yet.</Typography>
        </Stack>
      </>
    );
  }
  return (
    <>
      {dividerComponent}
      <Stack mb="2rem" direction="column" spacing={1}>
        <Typography mt="1rem" variant="h6">No tracking link created yet.</Typography>
        {shipment.tracking_link_info.link_expected && shipment.tracking_link_info.link_due && (
        <Typography variant="h6">The tracking link is due. Carrier has been sent a reminder.</Typography>
        )}
      </Stack>

    </>
  );
}
