import { Alert, Box } from '@mui/material';
import { UpdatePaymentInformationButton } from 'components/UpdatePaymentInformationButton';
import { useAPI, useRoleContext, useSubscriptionStatus } from 'hooks';
import { useCallback, useEffect, useState } from 'react';

export function UpdatePaymenInfoLabel() {
  const { api } = useAPI();
  const { role } = useRoleContext();
  const { subscription } = useSubscriptionStatus();
  const [isPPPShipper, setIsPPPShipper] = useState<boolean>(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState<boolean | null>(null);

  const isPayPerPostingShipper = useCallback(() => role !== null && role.type === 'SHIPPER'
  && subscription?.subscription_plan === 'SHIPPER_PAY_PER_POSTING', [role, subscription]);

  useEffect(() => {
    setIsPPPShipper(isPayPerPostingShipper());
  }, [role, subscription, isPayPerPostingShipper]);

  useEffect(() => {
    if (isPPPShipper && role != null && role.company?.id) {
      api<HasPaymentMethodResponse>('get', `/stripe/companies/${role.company.id}/has-payment-method`).then((response) => {
        setHasPaymentMethod(response.data.has_payment_method);
      });
    }
  }, [isPPPShipper, setHasPaymentMethod, api, role]);

  if (hasPaymentMethod == null || hasPaymentMethod) {
    return <div />;
  }
  return (
    <Box sx={{ mb: '1rem', w: '100%' }}>
      <Alert severity="warning">
        You do not have a payment method.
        {' '}
        <UpdatePaymentInformationButton />
      </Alert>
    </Box>
  );
}
