import { Stack, Typography } from '@mui/material';
import { EvotruxDivider } from 'components/EvotruxDivider';

interface Props {

  shipment: CondensedShipment;
  quote: CondensedQuote;
}

export function MultiShipmentProjectAndQuoteOverviewDisplay(props: Props) {
  const { shipment, quote } = props;
  if (!shipment || shipment?.info.type !== 'MULTI_SHIPMENT_PROJECT' || !shipment.project) {
    return <div />;
  }

  if (!quote || quote?.quote_type !== 'MULTI_SHIPMENT_PROJECT'
  || !quote.min_loads_desired
  || !quote.max_loads_desired) {
    return <div />;
  }

  const detailTypographyType = 'h6';

  return (
    <Stack
      direction="column"
      sx={{
        borderColor: '#cecdcd',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 2,
        p: '.5rem',
        ml: '1.5rem',
        mr: '1rem',
      }}
    >
      <Typography variant="h5">Project Summary</Typography>
      <EvotruxDivider />
      <Stack mt="1rem" mb="1rem" direction="column" spacing={1}>
        <Typography variant={detailTypographyType}>
          <b>Available shipments: </b>
          {' '}
          {shipment.project.loads_available || ''}
        </Typography>
        <Typography variant={detailTypographyType}>
          <b>Booked shipments: </b>
          {' '}
          {shipment.project.loads_booked || '0'}
        </Typography>
        <Typography variant={detailTypographyType}>
          <u>
            <b>Assignable shipments: </b>
            {' '}
            {shipment.project.loads_available - shipment.project.loads_booked || ''}
          </u>
        </Typography>
      </Stack>
      <Typography variant="h5">Quote Summary</Typography>
      <EvotruxDivider />
      <Stack mt="1rem" mb="1rem" direction="column" spacing={1}>
        <Typography variant={detailTypographyType}>
          <b>Carrier desired shipments (min): </b>
          {' '}
          {quote?.min_loads_desired || ''}
        </Typography>
        <Typography variant={detailTypographyType}>
          <b>Carrier desired shipments (max): </b>
          {' '}
          {quote?.max_loads_desired || ''}
        </Typography>
        <Typography variant={detailTypographyType}>
          <u>
            <b>Price per shipment: </b>
            {' '}
            {`$${quote?.price ?? '-'} ${quote?.currency ?? '-'}`}
          </u>
        </Typography>
      </Stack>

    </Stack>
  );
}
