import {
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactNode } from 'react';

interface Props {
  onClose: VoidFunction;
  open: boolean;
  onCloseRedirectURL?: string;
  dialogBody: ReactNode;
  buttonVariant: 'outlined' | 'contained';
  buttonLabel: string;
  redirect?: boolean;
  onCloseCallback?: VoidFunction;
}

export function SuccessMessageDialog(props: Props) {
  const {
    onClose,
    open,
    onCloseRedirectURL,
    dialogBody,
    buttonVariant,
    buttonLabel,
    redirect,
    onCloseCallback,
  } = props;

  const onCloseButtonClicked = () => {
    onClose();
    if (redirect && onCloseRedirectURL) {
      window.location.href = onCloseRedirectURL;
    }

    if (onCloseCallback != null) {
      onCloseCallback();
    }
  };

  if (!open) {
    return <div />;
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h3">Success</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogBody}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={buttonVariant} onClick={onCloseButtonClicked} autoFocus>
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SuccessMessageDialog.defaultProps = {
  redirect: true,
  onCloseCallback: null,
  onCloseRedirectURL: undefined,
};
