import { useState } from 'react';
import { CheckCarrierCompanyDetails } from '../types';
import { CreateCarrierCompany } from './CreateCarrierCompany';

interface Props {
  handleSearchCompanySelected: () => void;
}

const initialCompanyData = {
  mcNumber: '',
  name: '',
};

export function CreateCarrierCompanyContainer(props: Props) {
  const { handleSearchCompanySelected } = props;
  const [companyExists, setCompanyExists] = useState<boolean>(true);
  const [companyData, setcompanyData] = useState<CheckCarrierCompanyDetails>(initialCompanyData);

  const onCompanyDoesNotAlreadyExist = (companyInfo: CheckCarrierCompanyDetails): void => {
    setCompanyExists(false);
    setcompanyData(companyInfo);
  };

  const handleResetCompanyData = (): void => {
    setcompanyData(initialCompanyData);
    setCompanyExists(true);
  };

  return (
    <CreateCarrierCompany
      handleSearchCompanySelected={handleSearchCompanySelected}
      companyExists={companyExists}
      onCompanyDoesNotAlreadyExist={onCompanyDoesNotAlreadyExist}
      companyData={companyData}
      handleResetCompanyData={handleResetCompanyData}
    />
  );
}
