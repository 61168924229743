import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import {
  IconButton, Tooltip, Zoom,
} from '@mui/material';
import { useRoleContext } from 'hooks';
import { useNavigate } from 'react-router-dom';

export function ChatNavBarButton() {
  const navigate = useNavigate();
  const { role } = useRoleContext();

  if (role) {
    return (
      <Tooltip title="Chat" placement="right" TransitionComponent={Zoom}>
        <IconButton color="inherit" onClick={() => navigate('/chat')}>
          <ChatBubbleIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <div />
  );
}
